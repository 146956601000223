
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestItemModel } from '../add-chemical-details/request-item.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RequestItemAttachmentService } from 'src/app/services/request-item-attachment-service/request-item-attachment.service';
import { RequestItemAttachmentModel } from '../request-item-attachments/request-item-attachments.model';
import { DocumentType } from '../request-item-attachments/document-type.model';



@Component({
  selector: 'app-request-item-attachments',
  templateUrl: './request-item-attachments.component.html',
  styleUrls: ['./request-item-attachments.component.css'],
  providers: [MessageService, RequestItemAttachmentService,
    ConfirmationService]
})

export class RequestItemAttachments implements OnInit {

  @Input() requestItem = new RequestItemModel();
  @Input() editRequestItemModel = new RequestItemModel();
  @Output() uploadedFilesEvent = new EventEmitter();
  @Output() deleteFile = new EventEmitter<string>();
  @Output() updateDocumentType = new EventEmitter<{fileName: string, documentTypeId: number}>();
  requestItemAttachmentModel:RequestItemAttachmentModel = new RequestItemAttachmentModel() ;
  growlMsgs: ToastModule[] = [];
  uploadedFiles: any[] = [];
  public msgs: any[];
  exists: boolean;
  isAllowed: boolean;
  numberOfFileInvalid: number = 0;
  numberOfFileValid: number = 0;
  requestItemAttachmentId: number = 0;
  serviceResponse: any;
  alreadyExistsFileName: string[] = [];
  documentTypes: DocumentType[] = [];
  validFileExtensions = ["jpg", "png", "pdf", "docx", "xlsx", "rtf", "mht", "htm", "msg"];

  constructor(private messageService: MessageService, private confirmationService: ConfirmationService, private requestItemAttachmentService: RequestItemAttachmentService,
  ) {

  }
  ngOnInit() {
    
    if (this.editRequestItemModel.RequestItemAttachmentList.length > 0) {
      this.requestItemAttachmentId = this.editRequestItemModel.RequestItemAttachmentList[0].RequestItemAttachmentId;           
    }
    this.requestItemAttachmentService.GetDocumentTypeList().subscribe((response) => {
      this.documentTypes = response;
      
      this.editRequestItemModel.RequestItemAttachmentList.map((att, i) => {
        if(att.DocumentTypeId ==  null)
        {
          this.editRequestItemModel.RequestItemAttachmentList[i] = {...att, DocumentType:  this.documentTypes.filter(x => x.Name == "Other")[0], DocumentTypeId:  this.documentTypes.filter(x => x.Name == "Other")[0].Id}
        }
        else{
          const docType = this.documentTypes.filter(x => x.Id == att.DocumentTypeId)[0];
          this.editRequestItemModel.RequestItemAttachmentList[i] = {...att, DocumentType: docType}
        }
      })
   })
    
  }

  //------------------------------------------
  // To attach file to the request item
  //------------------------------------------
  public onUpload(event, requestItem: RequestItemModel, form, editRequestItemModel: RequestItemModel) {

    if (this.editRequestItemModel.RequestItemAttachmentList.length > 0) {
      this.requestItemAttachmentId = this.editRequestItemModel.RequestItemAttachmentList[0].RequestItemAttachmentId;
    }

    this.isAllowed = true;
    this.uploadedFiles = [];
    this.exists = false;
    this.numberOfFileInvalid = 0;
    var validationMessageRow: string = '';
    this.messageService.clear('fileUploadValidation');
    this.messageService.clear('fileUploadSummary');
    var fileUploadSummaryTable = '';
    // To check total no. of files selected. Max file allowed is 10.
    if ((event.files.length + editRequestItemModel.RequestItemAttachmentList.length) > 10) {
      this.isAllowed = false;
      validationMessageRow = validationMessageRow + '<tr><td> </td><td>File attach limit reached, max allowed are 10 files.</td></tr>'
      form.clear();
    }

    if (this.isAllowed) {
      for (let file of event.files) {
        // File types validation. Allowed files type are png,jpg,pdf,docx,xlsx,rtf
        let incomingFileExtension = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
        if(!this.validFileExtensions.includes(incomingFileExtension)) {
          this.isAllowed = false;
          if (validationMessageRow.search(file.name) < 0) {
            validationMessageRow = validationMessageRow + '<tr> <td> ' + file.name + ' </td> <td> Invalid file type. </td> </tr>'
            this.numberOfFileInvalid = this.numberOfFileInvalid + 1;
          }
          form.clear();
        }

        // Files size validation. Max allowed file size is 10 MB.
        if (file.size > "10485760") {
          this.isAllowed = false;
          if (validationMessageRow.search(file.name) < 0) {
            validationMessageRow = validationMessageRow + '<tr> <td> ' + file.name + ' </td> <td> File size cannot exceed 10 MB. </td> </tr>'
            this.numberOfFileInvalid = this.numberOfFileInvalid + 1;
          }

          form.clear();
        }

         // File name validation. Max allowed file name length  is 500 including extension.
         if (file.name.length > 150) {
          this.isAllowed = false;
          if (validationMessageRow.search(file.name) < 0) {
            validationMessageRow = validationMessageRow + '<tr> <td> ' + file.name + ' </td> <td> File name  cannot exceed 150 characters including extension. </td> </tr>'
            this.numberOfFileInvalid = this.numberOfFileInvalid + 1;
          }

          form.clear();
        }
      }
    }

    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    form.clear();
    this.alreadyExistsFileName = [];
    editRequestItemModel.RequestItemAttachmentList.forEach(element => {
      this.uploadedFiles.forEach(r => {
        if (element.FileName == r.name) {
          this.exists = true;
          this.alreadyExistsFileName.push(r.name);
        }
      })
    });

    // Duplicate file validation
    if (this.exists) {
      this.isAllowed = false;
      form.clear();
      this.alreadyExistsFileName.forEach(fileName => {
        if (validationMessageRow.search(fileName) < 0) {
          validationMessageRow = validationMessageRow + '<tr> <td> ' + fileName + ' </td> <td> File already exists. </td> </tr>'
          this.numberOfFileInvalid = this.numberOfFileInvalid + 1;
        }
      })
    }
  
    if (this.isAllowed) {
      this.numberOfFileInvalid = 0;
      this.numberOfFileValid = this.uploadedFiles.length;
      
      this.uploadedFiles.forEach(r => { 
        r.DocumentTypeId = this.documentTypes.filter(x => x.Name == "Other")[0].Id;
        r.Comment =null;
        this.requestItemAttachmentModel = new RequestItemAttachmentModel();
        this.requestItemAttachmentModel.RequestItemId = editRequestItemModel.RequestItemId;
        this.requestItemAttachmentModel.FileName = r.name;
        this.requestItemAttachmentModel.RequestItemAttachmentId = 0;
        this.requestItemAttachmentModel.Comment =null;
        this.requestItemAttachmentModel.File =null;
       // this.requestItemAttachmentModel.File = r;
        this.requestItemAttachmentModel.DocumentTypeId =this.documentTypes.filter(x => x.Name == "Other")[0].Id;
        this.requestItemAttachmentModel.DocumentType = this.documentTypes.filter(x => x.Name == "Other")[0]
 //requestItem.RequestItemAttachmentList.push(this.requestItemAttachmentModel);
        editRequestItemModel.RequestItemAttachmentList.push(this.requestItemAttachmentModel);
         
      })

      this.uploadedFilesEvent.emit(this.uploadedFiles);
      // this.requestItemAttachmentService.saveRequestItemAttachment(this.uploadedFiles, editRequestItemModel).subscribe(() => {

      //   this.messageService.add({ severity: 'success', summary: 'File Uploaded successfully', detail: '', life: 500 });
      //   fileUploadSummaryTable = '<table class = "file-upload-summary" ' + '><tr><th colspan="2">Recently Uploaded File(s) : ' + this.uploadedFiles.length + '   |       Valid File(s) : ' + this.numberOfFileValid + '   |     Invalid File(s) : ' + this.numberOfFileInvalid + '              |  Upload Status :  Success   ' + ' </th> </tr></table>';
      //   this.messageService.add({ key: 'fileUploadSummary', severity: 'info', summary: fileUploadSummaryTable, detail: '' });

      //   this.requestItemAttachmentService.getRequestItemAttachmentList(editRequestItemModel.RequestId, editRequestItemModel.RequestItemId).subscribe(response => {
      //     requestItem.RequestItemAttachmentList = response;
      //     editRequestItemModel.RequestItemAttachmentList = response;
      //   },
      //     error => {
      //       this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
      //     })
      // },
      //   error => {
      //     this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
      //   });
    }
    else {
      var totalFiles = 0;
      if (event.files.length + editRequestItemModel.RequestItemAttachmentList.length > 10) {
        totalFiles = event.files.length + editRequestItemModel.RequestItemAttachmentList.length;
        this.numberOfFileValid = 0;
        this.numberOfFileInvalid = 0;
      }
      else {
        totalFiles = event.files.length;
        this.numberOfFileValid = totalFiles - this.numberOfFileInvalid;

      }
      fileUploadSummaryTable = '<table class = "file-upload-summary" ' + '><tr><th colspan="2">Selected File(s) : ' + totalFiles + '   |      Valid File(s) : ' + this.numberOfFileValid + '   |     Invalid File(s) : ' + this.numberOfFileInvalid + '              |  Upload Status :  Fail   ' + ' </th> </tr></table>';
      this.messageService.add({ key: 'fileUploadSummary', severity: 'info', summary: fileUploadSummaryTable, detail: '' });
      var validationMessageTable: string = '<table class = "uploadFileValidation" ' + '><tr><td class = "font-bold"  colspan="2">Following errors occured during the upload.</tr><tr><td class = "font-bold">File Name</td><td class = "font-bold">Error</td></tr>';
      this.messageService.add({ key: 'fileUploadValidation', severity: 'error', summary: validationMessageTable + validationMessageRow + '</table>', detail: '' });
    }
  }

  //------------------------------------------
  // To download the attached file
  //------------------------------------------
  public downloadAttachment(requestId: number, requestItemId: number, attachmentId: number, filename: string) {
    this.requestItemAttachmentService.downloadFile(requestId, requestItemId, attachmentId).subscribe(response => {
      var binaryData = [];
      binaryData.push(response);
      var blob = new Blob(binaryData, { type: "application/octet-stream" });
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.click();
    },
      error => {
        this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
      });
  }

  //------------------------------------
  // Remove attachment from request.
  //------------------------------------
  public removeAttachment(requestItem: RequestItemModel, requestItemAttachmentId: number,FileName:string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the selected attachment?',
      header: 'Remove Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.clear('fileUploadValidation');
        this.messageService.clear('fileUploadSummary');
        if(requestItemAttachmentId == 0)
        {
          this.deleteFile.emit(FileName);
      }

      this.editRequestItemModel.RequestItemAttachmentList.forEach((element,index)=>{
        if(element.FileName== FileName) this.editRequestItemModel.RequestItemAttachmentList.splice(index,1);
     }); 
  //    requestItem.RequestItemAttachmentList.forEach((element,index)=>{
  //     if(element.FileName== FileName) requestItem.RequestItemAttachmentList.splice(index,1);
  //  });


   
        // this.requestItemAttachmentService.deleteAttachment(requestItem.RequestId, requestItem.RequestItemId, requestItemAttachmentId).subscribe(res => this.serviceResponse = res,
        //   error => {
        //     this.messageService.add({ severity: 'error', summary: '', detail: error.message });
        //   },
        //   () => {
        //     //this.msgs = [];
        //     this.messageService.add({ severity: 'success', summary: '', detail: 'Attachment Removed from the Request', life: 500 });

        //     this.requestItemAttachmentService.getRequestItemAttachmentList(requestItem.RequestId, requestItem.RequestItemId).subscribe(response => {
        //       requestItem.RequestItemAttachmentList = response;
        //       this.editRequestItemModel.RequestItemAttachmentList = response;
        //     },
        //       error => {
        //         this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
        //       })

         // });
      },
      reject: () => {

      }
    });
  }

  public updateDocumentTypeId(fileName:string ,documentType : any )
  {

    this.editRequestItemModel.RequestItemAttachmentList.filter(x => x.FileName  == fileName)[0].DocumentTypeId = documentType.value.Id;
    this.updateDocumentType.emit({fileName : fileName, documentTypeId : documentType.value.Id});
     
  }
}
