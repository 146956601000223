import { Injectable } from '@angular/core';
import { Role } from 'src/app/role/role.enum';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigurationSettings } from 'src/app/configuration-settings';

@Injectable()
export class CommonService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private options = { headers: this.headers };
    public configurations:any = {"CompositeSearchTimeoutError":"Please provide appropriate and narrower criteria.",
    "SearchCriteriaCannotBeEmptyWithLimit":"Search criteria cannot be empty and should not exceed {0} digits",
    "SearchCriteriaCannotBeEmpty":"Search criteria cannot be empty",
    "EnterTradeName" :"Enter Trade Name or Common Name",
    "EnterFormulation" : "Enter Formulation Composition",
    "EnterSynonym" :"Enter a Synonym to search",
    "EnterCortevaTradeName" :"Enter Corteva Trade Name",
    "EnterPartyTradeName":"Enter 3rd Party Trade Name",
    "EnterFormulationLot":"Enter Formulation Lot",
    "EnterCoreMaterialId" :"Enter CORE Material ID",
    "EnterCoreMaterialLotId":"Enter CORE Material Lot ID",
    "EnterAIC":"Please enter AIC",
    "EnterConcept":"Please enter concept",
    "EnterComposition":"Please enter composition",
    "AICAlphaNumeric":"AIC should contain only alpha numeric values and should not exceed 10 characters",
    "EnterLot":"Please enter lot",
    "EnterTsn":"Enter TSN",
    "Coformulant":"Coformulant",
    "FieldInert" :"FieldInert",
    "CortevaTradeName":"CortevaTradeName",
    "ThirdPartyTradeName": "ThirdPartyTradeName" ,
    "FACTSFormulationName" :"FACTSFormulationName",
    "FACTSSynonym" :"FACTSSynonym",
    "FACTSFormulationNameGlp" :"FACTSFormulationNameGlp",
    "COREMaterialId" :"COREMaterialId",
    "CORESynonym" :"CORESynonym",
    "COREMaterialLotGlp" :"COREMaterialLotGlp",
    "FACTTSNGlp" :"FACTTSNGlp",
    "EnterTSNLot":"Enter TSN",
    "TsnGlp":"TsnGlp"
    };
    constructor(private _http: HttpClient) { }
    public checkRequestForRequestType(requestType:string) {
        let userRequestTypeRoleModel = JSON.parse(localStorage["msal.role"]);
        return  (requestType === 'P2P' || userRequestTypeRoleModel.filter(s => s.RequestTypeName == requestType && s.RoleName == Role.Requester).length > 0 ? true : false);
    }

    public checkRequester() {
        let userRequestTypeRoleModel = JSON.parse(localStorage["msal.role"]);
        return  (userRequestTypeRoleModel.filter(s => s.RoleName == Role.Requester).length > 0 ? true : false);
    }


    public checkAdministrator() {
        let userRequestTypeRoleModel = JSON.parse(localStorage["msal.role"]);
        return  (userRequestTypeRoleModel.filter(s => s.RoleName == Role.Administrator).length > 0 ? true : false);
    }

    public checkSupport() {
        let userRequestTypeRoleModel = JSON.parse(localStorage["msal.role"]);
        return  (userRequestTypeRoleModel.filter(s => s.RoleName == Role.Support).length > 0 ? true : false);
    }
}
