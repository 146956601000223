import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { RequesterInformationModel } from './requester-information.model';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { RequesterInformationService } from 'src/app/services/requester-information-service/requester-information.service';
import { ValidationService } from 'src/app/services/validation-service/validation-service';
import { UserLookUpModel } from 'src/app/user-look-up/user-look-up.model';
import { RequestModel } from '../request/request.model';
import { UserModel } from 'src/app/user/user.model';
import { UserRequestTypeRoleModel } from 'src/app/user-request-type-role/user-request-type-role.model';
import { constants } from '../../constants/constants';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RecipientModel} from '../../recipient-profile/recipient-profile/recipient.model'

@Component({
  selector: 'app-requester-information',
  templateUrl: './requester-information.component.html',
  styleUrls: ['./requester-information.component.css'],
  providers: [MessageService, RequesterInformationService, ValidationService, RecipientService]
})
export class RequesterInformationComponent implements OnInit {

  @Input() requesterInformationModel: RequesterInformationModel;
  @Input() requestModel: RequestModel
  @Output() returnRequesterInformationModel = new EventEmitter<RequesterInformationModel>();
  serviceResponse: any;
  restApiURL: string;
  requesterList: UserModel[] = [];
  requester: UserModel;
  requesterInputTextClass: HTMLElement;
  results: RecipientModel[] = [];

  constructor(private messageService: MessageService, 
    private requesterinformationService: RequesterInformationService, public validationService: ValidationService
    ,public recipientService: RecipientService) 
    {

  }

  ngOnInit() {

    //To get request number passed from card detail page.  
    // azure-ad/users/search
    this.restApiURL = ConfigurationSettings.REST_API_URL + "/users/search?searchString=";
    this.PopulateContactNumber();
    this.requester = this.setRequesterObject();
  }

  //--------------------------------------------------------------
  // Get the logged in User Contact Number.
  //--------------------------------------------------------------
  private PopulateContactNumber(){
    let loggedInUserEmail = localStorage["loggedInUsername"];
    let requesterEmail = this.requesterInformationModel.Email;
    let type = "internal";  
    this.recipientService.getRecipients(type,loggedInUserEmail).subscribe(response => {
        this.results = response.sort((a, b) => b.RecipientId - a.RecipientId);
        let index = 0;
        while (index < this.results.length) {
          if(this.results[index].Email == requesterEmail && this.results[index].ContactNumber != null)
          {            
            this.requesterInformationModel.ContactNumber = this.results[index].ContactNumber;
            this.requestModel.RequesterInformationModel.ContactNumber = this.requesterInformationModel.ContactNumber;     
            this.updateRequesterInformation(this.requesterInformationModel);
            break;
          }
          index++;
        }       
    });   
}

//--------------------------------------------------------------
// Get the Contact Number for selected User.
//--------------------------------------------------------------
private GetContactNumber(selectedUserEmail: string)
{
  let index = 0;
  while (index < this.results.length) {
    if(this.results[index].Email == selectedUserEmail && this.results[index].ContactNumber != null)
    {            
      this.requesterInformationModel.ContactNumber = this.results[index].ContactNumber;
      break;
    }
    index++;
  } 
}

//---------------------------------------------------------------------
  // To set the properties of UserLookModel and return the object.
  //---------------------------------------------------------------------
  private setRequesterObject(): UserModel {
    var recipient = {
      DisplayName: this.requesterInformationModel.DisplayName,
      Email: this.requesterInformationModel.Email,
    }
    return recipient;
  }

  //--------------------------------------------------------------
  // Fire when user select user from requester search text box.
  //--------------------------------------------------------------
  public requesterOnSelect() {
    var roleList = this.requester.UserRequestTypeRoleList.filter(c => c.RoleName.toLowerCase() === constants.REQUESTER_ROLE)
    if (this.roleAndRequestTypeValidationForRequester(roleList)) {
     
      this.requesterInformationModel.Email = this.requester.Email;
      this.requesterInformationModel.DisplayName = this.requester.DisplayName;     
      this.GetContactNumber(this.requester.Email);  
      if (this.requesterInputTextClass != undefined)
        this.requesterInputTextClass.style.border = "1px solid #a6a6a6";
    }
    else {
      this.messageService.add({ severity: 'error', summary: '', detail: "Selected user does not have required permission. Please contact administrator" });

      if (this.requestModel.RequesterInformationModel.DisplayName != null) {
        this.requesterInformationModel.DisplayName = this.requestModel.RequesterInformationModel.DisplayName;
        this.requesterInformationModel.Email = this.requestModel.RequesterInformationModel.Email;
        this.GetContactNumber(this.requester.Email);
        this.ngOnInit();

      }
      else {
        this.setRequesterObject();
      }
    }
    this.updateRequesterInformation(this.requesterInformationModel);
  }
  //-------------------------------------
  // Fire when user clicks on save button
  //---------------------------------------
  saveRequesterDetails() {
    if (!this.validationforRequester()) {
      return;
    }
    else {
      this.updateRequesterInformation(this.requesterInformationModel);
    }

  }

  //----------------------------------------------
  // Update requester information
  //----------------------------------------------
  private updateRequesterInformation(requesterInformation) {
    this.requesterinformationService.updateRequesterInformation(requesterInformation).subscribe(res => this.serviceResponse = res,
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Requester details saved successfully' });
        this.returnRequesterInformationModel.emit(JSON.parse(JSON.stringify(requesterInformation)));
      });
  }

  //---------------------------------
  // Validation for Requester field
  //---------------------------------
  validationforRequester() {
    this.requesterInputTextClass = document.querySelectorAll("[class*=p-autocomplete-input]")[0] as HTMLElement;
    if (this.requester == null || this.requester == undefined || this.requester.toString() == "" || this.requester.Email == null) {
      this.requesterInputTextClass.style.border = "1px solid #dc3545"
      this.messageService.add({ severity: 'error', summary: '', detail: 'Please select a requester' });
      return false;
    }
    return true;
  }

  //---------------------------------------------------
  // Validation for role and request type for requester
  //---------------------------------------------------
  private roleAndRequestTypeValidationForRequester(roleList: UserRequestTypeRoleModel[]): boolean {
    var requestTypeList = roleList.filter(x => x.RequestTypeName.toLowerCase() === this.requestModel.RequestTypeName.toLowerCase())
    if (roleList.length == 0) {
      return false;
    }
    if (requestTypeList.length == 0) {
      return false;
    }
    return true;
  }
}
