
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UntypedFormBuilder } from '@angular/forms';
import { CountryService } from '../../services/country-service/country.service';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RecipientAddressService } from '../../services/recipient-address/recipient-address.service';
import { AddressModel } from '../../address/address.model';
import { FunctionModel } from '../../function/function.model';
import { FunctionService } from '../../services/function-service/function.service';
import { UserLookUpModel } from '../../user-look-up/user-look-up.model';
import { RecipientModel } from '../recipient-profile/recipient.model';
import { AddressService } from '../../services/address-service/address.service'
import { AddRecipientProfileComponent } from '../add/add-recipient-profile.component';
import { CountryModel } from 'src/app/country/country.model';

@Component({
    selector: 'add-internal-recipient-profile',
    templateUrl: './add-internal-recipient-profile.component.html',
    styleUrls: ['../add/add-recipient-profile.component.css'],
    providers: [RecipientService, MessageService, CountryService, FunctionService, AddressService, RecipientAddressService]
})

export class AddInternalRecipientProfileComponent extends AddRecipientProfileComponent implements OnInit {

    recipientLookupModel: UserLookUpModel;
    supervisorLookupModel: UserLookUpModel;
    public userLookUpModelList: UserLookUpModel[];
    public isHidden = false;
    functionList: FunctionModel[] = [];
    countryList: CountryModel[] = [];

    selectedCountry: CountryModel;
    selectedFunction: FunctionModel;

    recipientSelected: boolean = false;
    addressModel: AddressModel = {};
    addAddressToProfileCheckbox: boolean = false;
    recipientDomElement: HTMLElement;
    supervisorDomElement: HTMLElement;
    functionDomElement: HTMLElement;

    contentStyleObject: object = {
        'max-height': '75vh',
        'overflow': 'auto',
        'max-width': '730px',
        'min-width': '730px'
    }

    constructor(private formBuilder: UntypedFormBuilder, recipientService: RecipientService, private countryService: CountryService,
        messageService: MessageService, private functionService: FunctionService, private addressService: AddressService
    ) {
        super(recipientService, messageService);
        this.recipientType = "Internal";
    }
    ngOnInit() {
        this.getFunctionList();
        this.getCountryList();
    }

    //-----------------------------------------------
    // To save the recipient and address
    //-----------------------------------------------
    public save() {

        if (this.validateRequiredFields() && this.validateRecipientModel() && this.validateSupervisor()) {
            this.setRecipientModel();
            this.validateIfRecipientExists().then(exists => {
                if (!exists)
                    this.saveRecipientAndAddress(this.recipientModel, this.addressModel);
            });
        }
    }

    //------------------------------------------------
    // To validate recipient model
    //--------------------------------------------- --
    private validateRecipientModel() {
        if (typeof this.recipientLookupModel === 'string') {
            this.messageService.add({ severity: 'error', summary: '', detail: "Please select a valid username" })
            return false;
        }
        return true;
    }

    // -----------------------------------------------------
    // Method to set the recipient Model to save
    //------------------------------------------------------
    private setRecipientModel() {
        if (this.recipientModel.Email != undefined && this.recipientLookupModel.Email != undefined) {
            this.recipientModel.CountryId = this.selectedCountry.CountryId;
            this.recipientModel.FunctionId = this.selectedFunction.FunctionId;
            this.recipientModel.Country = this.selectedCountry.Name;
            this.recipientModel.Function = this.selectedFunction.Name;
            this.recipientModel.DisplayName = this.recipientLookupModel.DisplayName;
            this.recipientModel.FirstName = this.recipientLookupModel.FirstName;
            this.recipientModel.LastName = this.recipientLookupModel.LastName;
            this.recipientModel.SupervisorName = this.supervisorLookupModel.DisplayName;
            this.recipientModel.SupervisorEmail = this.supervisorLookupModel.Email;
            if (this.recipientLookupModel.MobilePhone)
                this.recipientModel.ContactNumber = this.recipientLookupModel.MobilePhone
            return true;
        }
        return false;
    }

    //-------------------------------------------
    // To validate supervisor
    //-------------------------------------------- 
    private validateSupervisor() {
        if (typeof this.supervisorLookupModel === 'string') {
            this.messageService.add({ severity: 'error', summary: '', detail: "Please select a valid Supervisor" })
            return false;
        }
        return true;
    }

    //-------------------------------------
    // To validate required fields
    //-------------------------------------
    private validateRequiredFields() {
        var nodeList = document.querySelectorAll("[class*=ui-autocomplete-input]");
        this.recipientDomElement = nodeList[nodeList.length - 2] as HTMLElement;
        this.supervisorDomElement = document.getElementById("supervisorSearch").getElementsByTagName("span")[0].getElementsByTagName("input")[0];
        this.functionDomElement = document.getElementById("function").firstChild as HTMLElement;
        this.recipientDomElement = document.getElementById("recipientSearch").getElementsByTagName("span")[0].getElementsByTagName("input")[0];

        if (this.selectedFunction == undefined || (this.supervisorLookupModel == "" || this.supervisorLookupModel == undefined)
            || (this.recipientLookupModel == undefined || this.recipientLookupModel == "")) {
            if (this.recipientLookupModel == undefined || this.recipientLookupModel == "" || this.recipientLookupModel.Email == undefined) {
                this.recipientDomElement.style.border = "1px solid #dc3545"
            }
            if (this.supervisorLookupModel == "" || this.supervisorLookupModel == undefined || this.supervisorLookupModel.Email == undefined) {
                this.supervisorDomElement.style.border = "1px solid #dc3545"
            }
            if (this.selectedFunction == undefined) {
                this.functionDomElement.style.border = "1px solid #dc3545"
            }

            this.messageService.add({ severity: 'error', summary: '', detail: "Mandatory fields cannot be empty" });
            return false;
        }
        return true;
    }

    //-----------------------------------------------
    // Call api to save the recipient
    //-----------------------------------------------
    private saveRecipientAndAddress(recipientModel: RecipientModel, addressModel: AddressModel) {
        this.recipientService.create(this.recipientModel, this.recipientType).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
            },
            () => {

                if (this.addAddressToProfileCheckbox) {
                    this.addressModel.IsActiveDirectoryAddress = true;
                    this.addressModel.Country = this.selectedCountry.Name;
                    this.addressModel.CountryId = this.selectedCountry.CountryId;
                    this.saveADAddress();
                }
                else {
                    console.log("Recipient Saved Successfuly");
                    this.messageService.add({ severity: 'success', summary: 'Recipient saved successfully', detail: '', life: 500 });
                    this.recipientModel.RecipientId = this.serviceResponse;
                    this.eventToReceiveNewCreatedRecipient.emit(this.recipientModel);
                    this.visible = false;
                    this.resetFields();
                }
            });
    }


    //---------------------------------------------------------
    // Call api to save the AD address of the recipient.
    //---------------------------------------------------------
    public saveADAddress() {
        this.addressService.createAddress(this.addressModel, this.recipientModel.Email).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
            },
            () => {
                console.log("Address added for selected recipient with address id = " + this.serviceResponse);
                this.messageService.add({ severity: 'success', summary: 'Recipient with Address saved successfully', detail: '', life: 500 });
                this.addressModel.AddressId = this.serviceResponse;
                this.eventToReceiveNewCreatedRecipient.emit(this.recipientModel);
                this.visible = false;
                this.resetFields();
            });
    }

    // -----------------------------------------------------
    // Method to set the address Model to save
    //------------------------------------------------------
    private setAddressModel() {
        this.addressModel.AddressLine1 = this.recipientLookupModel.StreetAddress;
        this.addressModel.AddressLine2 = this.recipientLookupModel.OfficeLocation;
        this.addressModel.AddressLine3 = this.recipientLookupModel.UsageLocation;
        this.addressModel.City = this.recipientLookupModel.City;
        this.addressModel.State = this.recipientLookupModel.State;
        this.addressModel.PostalCode = this.recipientLookupModel.PostalCode;
        this.addressModel.Mobile = this.recipientLookupModel.MobilePhone;
        this.addressModel.Country = this.selectedCountry.Name;
        this.addressModel.CountryId = this.selectedCountry.CountryId;
        this.addressModel.IsActiveDirectoryAddress = this.addAddressToProfileCheckbox;
    }


    //--------------------------------
    // close Add Recipient dialog pop up
    //--------------------------------
    public closeAddRecipientDialog() {
        this.eventToCloseAddRecipientPopUp.emit();
        this.resetFields();

    }

    //------------------------------------
    // To get the list of Countries
    //-----------------------------------
    public getCountryList() {
        if (this.countryList.length == 0) {
            this.countryService.getCountryList().subscribe(res => this.serviceResponse = res,
                error => {
                    this.messageService.add({ severity: 'error', summary: '', detail: error.error })
                },
                () => {
                    this.countryList = this.serviceResponse;
                })
        }
    }

    //------------------------------------
    // To get the list of Functions
    //-----------------------------------
    public getFunctionList() {
        if (this.functionList.length == 0) {
            this.functionService.getFunctionList().subscribe(res => this.serviceResponse = res,
                error => {
                    this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
                },
                () => {
                    this.functionList = this.serviceResponse;
                })
        }
    }

    //------------------------------------
    // action after selecting a recipient
    //------------------------------------
    public recipientOnSelect() {
        if (this.recipientDomElement)
            this.recipientDomElement.style.border = "1px solid #a6a6a6";
        this.recipientModel.Email = this.recipientLookupModel.Email;
        this.selectedCountry = this.countryList.filter(c => c.Name == this.recipientLookupModel.Country)[0];
        this.recipientSelected = true;
        this.setAddressModel();
    }

    //----------------------------------------------
    // To remove red border for mandatory field error
    //-----------------------------------------------
    public supervisorOnSelect() {
        if (this.supervisorDomElement)
            this.supervisorDomElement.style.border = "1px solid #a6a6a6";
    }

    //-------------------------------------------------
    // To remove red border for mandatory field error
    //-------------------------------------------------
    public functionChange() {
        if (this.functionDomElement)
            this.functionDomElement.style.border = "1px solid #a6a6a6";
    }

    //------------------------------------------------
    // To reset the fields on add new recipient dialog box
    //------------------------------------------------
    private resetFields() {
        this.selectedFunction = null;
        this.selectedCountry = null;
        this.recipientLookupModel = new UserLookUpModel;
        this.supervisorLookupModel = new UserLookUpModel;
        this.recipientModel = new RecipientModel;
        this.recipientSelected = false;
        this.addAddressToProfileCheckbox = false;
        if (this.recipientDomElement)
            this.recipientDomElement.style.border = "1px solid #a6a6a6";
        if (this.supervisorDomElement)
            this.supervisorDomElement.style.border = "1px solid #a6a6a6";
        if (this.functionDomElement)
            this.functionDomElement.style.border = "1px solid #a6a6a6";
    }
}

