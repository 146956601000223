import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE,MSAL_INTERCEPTOR_CONFIG, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from "@azure/msal-browser";
import { UserSearchAutocompleteModule } from 'sein-user-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as signalR from '@microsoft/signalr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgGridModule } from 'ag-grid-angular';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { DataViewModule } from 'primeng/dataview';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService } from 'primeng/api';



import { SharedModule } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { KeyFilterModule } from 'primeng/keyfilter';

import { StepsModule } from 'primeng/steps';
import { MenuModule } from 'primeng/menu';
import { MessageService } from 'primeng/api';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { CartService } from './services/cart-service/cart.service';
import { CarouselModule } from 'primeng/carousel';

import { HomeComponent } from './home/home.component';
import { RequestComponent } from './request/request/request.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingService } from './loading/res-loading.service';
import { httpSetHeaders } from './services/interceptor-service/httpSetHeaders.interceptor';
import { LoaderInterceptor } from './services/interceptor-service/loader.interceptor';
import { SearchComponent } from './search/search.component';
import { CartDetailsComponent } from './cart/cart-details.component';
import { ConfigurationSettings } from './configuration-settings';
import { UserComponent } from './user/user.component';
import { UnitOfMeasureComponent } from './unit-of-measure/unit-of-measure.component';
import { RequestManagementComponent } from './request-management/request-management.component';
import { AddressComponent } from './address/address.component';
import { MonitoringService } from './services/monitor.service';

import { AddInternalRecipientProfileComponent } from './recipient-profile/internal/add-internal-recipient-profile.component';

import { AddExternalRecipientProfileComponent } from './recipient-profile/external/add-external-recipient-profile.component';
import { AddRecipientProfileComponent } from './recipient-profile/add/add-recipient-profile.component';

import { AddRequesterAddressComponent } from './requester-address/add/add-requester-address.component';
import { AddInternalRequesterAddressComponent } from './requester-address/internal/add-internal-requester-address.component';
import { AddExternalRequesterAddressComponent } from './requester-address/external/add-external-requester-address.component';

import { AddShippingAddressComponent } from './shipping-address/add/add-shipping-address.component';
import { AddInternalShippingAddressComponent } from './shipping-address/internal/add-internal-shipping-address.component';
import { AddExternalShippingAddressComponent } from './shipping-address/external/add-external-shipping-address.component';
import { RecipientProfileComponent } from './recipient-profile/recipient-profile/recipient-profile.component';
import { InternalRecipientProfileComponent } from './recipient-profile/internal/internal-recipient-profile.component';
import { ExternalRecipientProfileComponent } from './recipient-profile/external/external-recipient-profile.component';
import { RequestDetailComponent } from './request/request-detail/request-detail.component';
import { RequestNotificationComponent } from './request/request-notification/request-notification.component';
import { AddChemicalDetailsComponent } from './request/add-chemical-details/add-chemical-details.component';
import { AddContainerComponent } from './request/add-container/add-container.component';
import { RequestItemAttachments } from './request/request-item-attachments/request-item-attachments.component';
import { RequesterInformationComponent } from './request/requester-information/requester-information.component';
import { ShippingAddressDetailsComponent } from './request/verify-and-submit/shipping-address-details/shipping-address-details.component';
import { RequesterDetailsComponent } from './request/verify-and-submit/requester-details/requester-details.component';
import { SampleDetailsComponent } from './request/verify-and-submit/sample-details/sample-details.component';
import { MyRequestsComponent } from './request-management/my-requests/my-requests.component';
import { FavouriteRequestsComponent } from './request-management/favourites/favourites.component';
import { CopyRequestComponent } from './request/copy-request/copy-request.component';
import { AdvancedSearchComponent } from './request-management/advanced-search/advanced-search.component';
import { InStockComponent } from './search/in-stock/in-stock.component';
import { GLPComponent } from './search/glp/glp.component';
import { P2PCartComponent } from './cart/P2P/p2p-cart.component';
import { ExceptionComponent } from './support/exception-log.component';
import { EmailLogComponent } from './support/email-log/email-log.component';
import { InStockCartComponent } from './cart/in-stock/in-stock-cart.component';
import { GlpCartComponent } from './cart/glp/glp-cart.component';
import { ForecastComponent } from './forecast/forecast.component';
import { DelegateComponent } from './delegate/delegate.component';
import { COMPOSITE_SEARCH_SETTINGS } from './services/environment-service/material-search-config';
import { CompositeSearchComponent } from './composite-search/composite-search.component';
import { TupleService } from './composite-search/tuple.service';
import { SiteService } from './services/site-service/site.service';
import { UserService } from './services/user-service/user.service';
import { RequesterInformationService } from './services/requester-information-service/requester-information.service';
import { GSSCOMMON_API_BASE_URL } from './services/gsscommon-api-client.service';
import { P2pRequesterDetailsComponent } from './request/verify-and-submit/requester-details/p2p-requester-details/p2p-requester-details.component';
import { AzureADService } from './services/azure-ad-service/azure-ad.service';
import { ApprovalComponent } from './approval/approval.component';
import { ApprovalService } from './services/approval-service/approval.service';
import { UnitOfMeasureService } from './services/unit-of-measure-service/unit-of-measure.service';
import { DecimalNumberDirective } from './directives/DecimalNumberDirective.directive';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AfterLoginComponent } from './after-login/after-login.component';
import { AfterLogoutComponent } from './after-logout/after-logout.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavigateComponent } from './navigate/navigate.component';
import { MaintenanceMessageComponent } from './maintenance-message/maintenance-message.component';
import { FARM_API_BASE_URL } from './services/farm-api-client.service';

@NgModule({
  declarations: [
    AccessDeniedComponent,
    AfterLoginComponent,
    AfterLogoutComponent,
    NavigateComponent,
    NoPermissionsComponent,
    PageNotFoundComponent,
    AddChemicalDetailsComponent,
    AddContainerComponent,
    AddExternalRecipientProfileComponent,
    AddExternalRequesterAddressComponent,
    AddExternalShippingAddressComponent,
    AddInternalRecipientProfileComponent,
    AddInternalRequesterAddressComponent,
    AddInternalShippingAddressComponent,
    AddRecipientProfileComponent,
    AddRequesterAddressComponent,
    AddressComponent,
    AddShippingAddressComponent,
    AdvancedSearchComponent,
    AppComponent,
    ApprovalComponent,
    CartDetailsComponent,
    CompositeSearchComponent,
    CopyRequestComponent,
    DecimalNumberDirective,
    DelegateComponent,
    EmailLogComponent,
    ExceptionComponent,
    ExternalRecipientProfileComponent,
    FavouriteRequestsComponent,
    ForecastComponent,
    GlpCartComponent,
    GLPComponent,
    HomeComponent,
    InStockCartComponent,
    InStockComponent,
    InternalRecipientProfileComponent,
    LoadingComponent,
    MaintenanceMessageComponent,
    MyRequestsComponent,
    P2PCartComponent,
    P2pRequesterDetailsComponent,
    RecipientProfileComponent,
    RequestComponent,
    RequestDetailComponent,
    RequesterDetailsComponent,
    RequesterInformationComponent,
    RequestItemAttachments,
    RequestManagementComponent,
    RequestNotificationComponent,
    RequestNotificationComponent,
    SampleDetailsComponent,
    SearchComponent,
    ShippingAddressDetailsComponent,
    UnitOfMeasureComponent,
    UserComponent
  ],
  imports: [
    AccordionModule,
    AgGridModule,
    AppRoutingModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    CommonModule,
    ConfirmDialogModule,
    DataViewModule,
    DialogModule,
    DynamicDialogModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    KeyFilterModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    MsalModule.forRoot(new PublicClientApplication(ConfigurationSettings.CurrentEnvironment.msal.config),
        ConfigurationSettings.CurrentEnvironment.msal.guard,
        ConfigurationSettings.CurrentEnvironment.msal.interceptor),
    MultiSelectModule,
    OverlayPanelModule,
    PanelModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ReactiveFormsModule,
    ResearchComponentsCoreModule.forRoot('CPSS'),
    ResearchComponentsMsalIntegrationModule,
    SelectButtonModule,
    SharedModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabViewModule,
    ToastModule,
    ToggleButtonModule,
    TooltipModule,
    UserSearchAutocompleteModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },
    { provide: COMPOSITE_SEARCH_SETTINGS, useValue: ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS },
    { provide: GSSCOMMON_API_BASE_URL, useValue: ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl },
    { provide: FARM_API_BASE_URL, useValue: ConfigurationSettings.FARM_API_BASE_URL },
    MsalService,
    MsalBroadcastService,
    LoadingService,
    MessageService,
    CartService,
    SiteService,
    RequesterInformationService,
    UserService,
    AzureADService,
    ApprovalService,
    MonitoringService,
    DialogService,
    TupleService,
    UnitOfMeasureService],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
