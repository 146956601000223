
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UntypedFormBuilder } from '@angular/forms';
import { CountryService } from '../../services/country-service/country.service';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RecipientAddressService } from '../../services/recipient-address/recipient-address.service';
import { UserLookUpModel } from '../../user-look-up/user-look-up.model';
import { AddressService } from '../../services/address-service/address.service';
import { AddRecipientProfileComponent } from '../add/add-recipient-profile.component';
import { ValidationService } from 'src/app/services/validation-service/validation-service';
import { constants } from 'src/app/constants/constants';

@Component({
    selector: 'add-external-recipient-profile',
    templateUrl: './add-external-recipient-profile.component.html',
    styleUrls: ['../add/add-recipient-profile.component.css'],

    providers: [RecipientService, MessageService, CountryService, AddressService, RecipientAddressService,
        ValidationService]
})

export class AddExternalRecipientProfileComponent extends AddRecipientProfileComponent implements OnInit {

    public internalContactLookupModel: UserLookUpModel;
    public userLookUpModelList: UserLookUpModel[];
    recipientDomElement: HTMLElement;
    internalContactDomElement: HTMLElement;
    emailDomElement: HTMLElement;
    legalNameDomElement: HTMLElement;
    contactNumberDomElement: HTMLElement;

    contentStyleObject: object = {
        'max-height': '75vh',
        'overflow': 'auto',
        'max-width': '780px',
        'min-width': '780px'
    }

    constructor(private formBuilder: UntypedFormBuilder, recipientService: RecipientService, countryService: CountryService,
        messageService: MessageService, public validationService: ValidationService
    ) {
        super(recipientService, messageService);
        this.recipientType = "External";
    }
    ngOnInit() {

    }

    //-----------------------------------------------
    // To save the recipient and address
    //-----------------------------------------------
    public save() {
        if (this.validateRequiredField() && this.validateInternalContact() && this.validateEmail()) {
            this.setRecipientModel();
            this.validateIfRecipientExists().then(res => {
                if (!res) {
                    console.log("Calling api to create a new recipient");
                    this.saveRecipient();
                    this.internalContactLookupModel = new UserLookUpModel();
                    document.getElementsByClassName('inValidMessage')[0].innerHTML = '';
                }
            })
        }
    }
    //-------------------------
    // To validate empty fields
    //-------------------------
    private validateRequiredField() {
        this.recipientDomElement = document.getElementById("name");
        this.emailDomElement = document.getElementById("email");
        this.legalNameDomElement = document.getElementById("legalName");
        this.contactNumberDomElement = document.getElementById("contactNumber");
        this.internalContactDomElement = document.getElementById("internalContactSearch").getElementsByTagName("span")[0].getElementsByTagName("input")[0];

        if ((this.internalContactLookupModel == null || this.internalContactLookupModel == undefined) || (this.recipientModel.ContactNumber == "" || this.recipientModel.ContactNumber == undefined)
            || (this.recipientModel.Email == "" || this.recipientModel.Email == undefined) || (this.recipientModel.LegalName == "" || this.recipientModel.LegalName == undefined)
            || this.recipientModel.FullName == "" || this.recipientModel.FullName == undefined) {
            if (this.internalContactLookupModel == null || this.internalContactLookupModel == undefined || this.internalContactLookupModel.Email == undefined) {
                this.internalContactDomElement.style.border = "1px solid #dc3545"
            }
            if (this.recipientModel.ContactNumber == "" || this.recipientModel.ContactNumber == undefined || this.recipientModel.ContactNumber.trim() == "") {
                this.contactNumberDomElement.style.border = "1px solid #dc3545"
            }
            if (this.recipientModel.Email == "" || this.recipientModel.Email == undefined || this.recipientModel.Email.trim() == "") {
                this.emailDomElement.style.border = "1px solid #dc3545"
            }
            if (this.recipientModel.FullName == "" || this.recipientModel.FullName == undefined || this.recipientModel.FullName.trim() == "") {
                this.recipientDomElement.style.border = "1px solid #dc3545"
            }
            if (this.recipientModel.LegalName == "" || this.recipientModel.LegalName == undefined || this.recipientModel.LegalName.trim() == "") {
                this.legalNameDomElement.style.border = "1px solid #dc3545"
            }
            this.messageService.add({ severity: 'error', summary: '', detail: "Mandatory fields cannot be empty" });
            return false;
        }
        return true;
    }

    //-------------------------------------------
    // To validate recipient model and supervisor
    //-------------------------------------------- 
    private validateInternalContact() {
        if (typeof this.internalContactLookupModel === 'string') {
            this.messageService.add({ severity: 'error', summary: '', detail: "Please select a valid Internal Contact" })
            return false;
        }
        return true;
    }

    //---------------------------------------------------
    // To Validate email
    //---------------------------------------------------
    private validateEmail() {
        var isValid = this.validationService.validateEmail(this.recipientModel.Email);
        var splitEmail = this.recipientModel.Email.split("@");

        if (!isValid) {
            this.messageService.add({ severity: 'error', summary: '', detail: "Please select a valid email" })
            return false;
        }
        else if (splitEmail[1].toLowerCase() === constants.CORTEVA_EMAIL_DOMAIN_NAME) {
            this.messageService.add({ severity: 'error', summary: '', detail: "External recipient cannot be associated to Corteva email id" })
            return false;
        }
        return true;
    }

    // -----------------------------------------------------
    // Method to set the recipient Model to save
    //------------------------------------------------------
    private setRecipientModel() {
        this.recipientModel.InternalContactName = this.internalContactLookupModel.DisplayName;
        this.recipientModel.InternalContactEmail = this.internalContactLookupModel.Email;
        this.recipientModel.IsActive = true;
    }

    //--------------------------------
    // close Add Recipient dialog pop up
    //--------------------------------
    public closeAddRecipientDialog() {
        this.resetFields();
        this.eventToCloseAddRecipientPopUp.emit();
    }

    //----------------------------------------------
    // To remove red border for mandatory field error
    //-----------------------------------------------
    public internalContactOnSelect() {
        if (this.internalContactDomElement)
            this.internalContactDomElement.style.border = "1px solid #a6a6a6";

    }

    //--------------------------------------------------------
    // To reset the fields on add new recipient dialog box
    //--------------------------------------------------------
    private resetFields() {
        this.internalContactLookupModel = new UserLookUpModel;
        this.recipientModel.Email = undefined;
        this.recipientModel.FullName = undefined;
        this.recipientModel.ContactNumber = undefined;
        this.recipientModel.LegalName = undefined;
        if (this.recipientDomElement)
            this.recipientDomElement.style.border = "1px solid #a6a6a6";
        if (this.internalContactDomElement)
            this.internalContactDomElement.style.border = "1px solid #a6a6a6";
        if (this.legalNameDomElement)
            this.legalNameDomElement.style.border = "1px solid #a6a6a6";
        if (this.emailDomElement)
            this.emailDomElement.style.border = "1px solid #a6a6a6";
        if (this.contactNumberDomElement)
            this.contactNumberDomElement.style.border = "1px solid #a6a6a6";

    }

    public removeRedBorder($event) {
        this.recipientDomElement = document.getElementById("name");
        this.emailDomElement = document.getElementById("email");
        this.legalNameDomElement = document.getElementById("legalName");
        this.contactNumberDomElement = document.getElementById("contactNumber");

        var id = $event.target.id;
        if (id == "name") {
            if (this.recipientModel.FullName) {
                if (this.recipientModel.FullName.trim() != "") {
                    this.recipientDomElement.style.border = "1px solid #a6a6a6"
                }
            }
        }
        else if (id == "email") {
            if (this.recipientModel.Email) {
                if (this.recipientModel.Email.trim() != "") {
                    this.emailDomElement.style.border = "1px solid #a6a6a6"
                }
            }
        }
        else if (id == "legalName") {
            if (this.recipientModel.LegalName) {
                if (this.recipientModel.LegalName.trim() != "") {
                    this.legalNameDomElement.style.border = "1px solid #a6a6a6"
                }
            }
        }
        else if (id == "contactNumber") {
            if (this.recipientModel.ContactNumber) {
                if (this.recipientModel.ContactNumber.trim() != "") {
                    this.contactNumberDomElement.style.border = "1px solid #a6a6a6"
                }
            }
        }
    }
}

