import { CompositeInventoryByLot, ReservationSearchInput, ReservationsClient, ReservationsSearchResults } from './../services/gsscommon-api-client.service';
import { Component, Inject, OnInit, Input, InputDecorator, EventEmitter, Output, OutputDecorator, ViewChild } from '@angular/core';
import { COMPOSITE_SEARCH_SETTINGS, MaterialSearchConfig } from '../services/environment-service/material-search-config';
import { CompositeInventoryClient, CompositeInventoryResult, FormulationClient, MaterialResult } from '../services/gsscommon-api-client.service';
import {CoformulantClient,CompoundClient} from '../services/gsscommon-api-client.service';
import { RequestType } from '../request-type/request-type.enum';
import { DataView } from 'primeng/dataview';
import { MessageService } from 'primeng/api';
import { TupleService } from './tuple.service';
import { AzureADService } from '../services/azure-ad-service/azure-ad.service';
import { UnitOfMeasureService } from '../services/unit-of-measure-service/unit-of-measure.service';
import { UnitOfMeasureModel } from '../unit-of-measure/unit-of-measure.model';
import { BioNexusClient } from '../services/gsscommon-api-client.service';
import { CartService } from '../services/cart-service/cart.service';
import { ConfigurationSettings } from '../configuration-settings';
import { CommonService } from '../services/common-service/common.service';
import { ReservationClient } from '../services/farm-api-client.service';

@Component({
  selector: 'app-composite-search',
  templateUrl: './composite-search.component.html',
  styleUrls: ['./composite-search.component.scss']
})
export class CompositeSearchComponent implements OnInit {

  @Output('markDuplicates') markDuplicates=  new EventEmitter<Array<MaterialResult>>();
  @Input('isGlpEligible') isGlpEligible: boolean = false;
  @Input() existingCartItemRequestType: string = '';
  @Output() AddCartItem =  new EventEmitter<MaterialResult>();
  @Output() ShowMessage = new EventEmitter<string>();
  @ViewChild('dv') dataView: DataView;
  inventoryUOM:string ='';
  displayName:string ='';
  materialType: string = "Coformulant";
  RequestTypeName:string = RequestType.InStock;
  disableRequestType:boolean = true;
  SelectedRepo:string = "";
  materialNameHeader:string;
  materialTypeId: string = "1";
  searchText: string;
  aiC: string;
  concept: string;
  composition: string;
  lotNumber: string;
  isGlp: boolean = false;
  showMore: boolean =false;
  showField: boolean = false;
  results: Array<MaterialResult> = [];
  displayDialog:boolean = false;
  displayNexusDialog:boolean = false;
  public alphaNumericRegEx = /^[a-zA-Z0-9\s\-]*$/;
  materialInfo:CompositeInventoryResult;
  cols:any=[];
  public showCartButton: boolean=false;
  uomOptions: UnitOfMeasureModel[];
  selectedUom: UnitOfMeasureModel;
  uom: string = "mg";
  selectedMaterialRepoName: any;
  selectedMaterialId: any;
  selectedMaterialHeader: any;
  p2pDisabled: boolean = false;
  cartMaterialType: string = '';
  cartItemList:any;
  isAdmin:boolean = false;
  displayApproverDialog:boolean = false;
  selectedInventoryType = "STOCK";
  inventoryList = [
    { name: "Stock", value: "STOCK" },
    { name: "GLP", value: "GLP", disabled:false },
    { name: "Both", value: "BOTH", disabled:false},
  ];
  inventoryBreakDown = {
    STOCK: { INDY: {}, FRANCE: {}, SGS: {}, SUB: {} },
    GLP: { INDY: {}, FRANCE: {}, SGS: {}, SUB: {} },
  };
  reservations : ReservationsSearchResults[] = [];
  inventoryTotalAvailable = 0;
  public euCenterFeature = ConfigurationSettings.CurrentEnvironment.featureFlags.euCenter;
  indyWarehouseName = "Indy";
  constructor(@Inject(COMPOSITE_SEARCH_SETTINGS)
  public compositeSearchSettings: MaterialSearchConfig,
  private unitOfMeasureService: UnitOfMeasureService,
   private coformulantClient: CoformulantClient,
   private formulationClient: FormulationClient,
   protected messageService: MessageService,
   private alchemistReservationsClient : ReservationsClient,
   protected compoundClient:CompoundClient,protected commonService:TupleService,
   private azureADService:AzureADService,
   private bioNexusClient:BioNexusClient,
   private compositeInventoryClient: CompositeInventoryClient,
   private cartService: CartService,
   private cmnService:CommonService,
   private reservationClient:ReservationClient,
   ) {
    console.log(compositeSearchSettings);
  }

  public get requestType(): typeof RequestType {
    return RequestType;
  }
  ngOnChanges()
  {
    this.UpdateDisableRequestType();
  }

  UpdateDisableRequestType()
  {
    this.disableRequestType= true;
    switch(this.existingCartItemRequestType){
      case RequestType.GLP :
        this.RequestTypeName = RequestType.GLP;
        break;
        case RequestType.PeerToPeer :
        this.RequestTypeName = RequestType.PeerToPeer;
        break;
        case RequestType.InStock :
        this.RequestTypeName = RequestType.InStock;
        break;
        default :

        this.RequestTypeName = RequestType.InStock;
        this.disableRequestType = false;
        break;
    }
  }
  ngOnInit(): void {
    console.log('test');
    console.log('isGlpEligible = ' + this.isGlpEligible);
    this.indyWarehouseName = this.euCenterFeature ? "Indianapolis" : "Indy";
    this.UpdateDisableRequestType();
    this.cols = [
      { field: 'lotId', header: 'Lot No' },
      { field: 'manufacturerLotNumber', header: 'Mfg Lot No' },
      { field: 'dateOfManufacture', header: 'Date of Mfg' },
      { field: 'inventoryType', header: 'Inventory Type' },
      { field: 'expirationDate', header: 'Exp Date' },
      { field: 'available', header: 'Available Qty' }
    ];
    this.getUnitOfMeasureListForAllRequestType();
    this.getCarts();
    this.isAdmin = this.cmnService.checkAdministrator();
  }

  isLotExist(lots:CompositeInventoryByLot[] = [], lotNumber, isGlp, warehouseName,reservationTypeId){
    let repos = ['FACTS', 'COFORM', 'BIONEXUS'];
   if(reservationTypeId){
    if(this.euCenterFeature){
      warehouseName = reservationTypeId == 2 ? "France" : this.indyWarehouseName;
    }else{
      warehouseName = reservationTypeId == 2 ? "SGS": this.indyWarehouseName;
    }
  }
    return lots.some(x=> ((this.selectedMaterialRepoName =="CORE" && x.lotId == lotNumber) || (repos.includes(this.selectedMaterialRepoName) && x.lotNumber == lotNumber))
                    && ((!isGlp && x.inventoryType.toLowerCase().includes('stock')) || (isGlp && x.inventoryType.toLowerCase().includes('glp')))
                    && (warehouseName == x.inventoryLocation));
  }

  breakDownInventory(materialInfo) {
    let indyStock = materialInfo.compositeInventoryByLot.filter(
      (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == this.indyWarehouseName
    );
    let indyGLP = materialInfo.compositeInventoryByLot.filter(
      (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == this.indyWarehouseName
    );

    let stockAvailable = 0;
    let glpAvailable = 0;
    let indyStockNoLotReservation = 0;
    let sgsStockNoLotReservation = 0;
    let franceStockNoLotReservation = 0;
    let indyGLPNoLotReservation = 0;
    let sgsGLPNoLotReservation = 0;
    let franceGLPNoLotReservation = 0;

    let indyStockPhysical = indyStock.reduce((f, x) => f + x.physical, 0);
    let indyStockReserved = indyStock.reduce((f, x) => f + x.reserved, 0);
    let indyStockAvailable = indyStock.reduce((f, x) => f + x.available, 0);

    let indyGLPPhysical = indyGLP.reduce((f, x) => f + x.physical, 0);
    let indyGLPReserved = indyGLP.reduce((f, x) => f + x.reserved, 0);
    let indyGLPAvailable = indyGLP.reduce((f, x) => f + x.available, 0);

    if (this.euCenterFeature) {
      let franceStock = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == "France"
      );
      let franceGLP = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == "France"
      );

      indyStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == this.indyWarehouseName).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      indyGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == this.indyWarehouseName).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      franceStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == 'France').reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      franceGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == 'France').reduce((a,r)=> a+ r.reservationAmountConverted ,0);

      let franceStockPhysical = franceStock.reduce((f, x) => f + x.physical, 0);
      let franceStockReserved = franceStock.reduce((f, x) => f + x.reserved, 0);
      let franceStockAvailable = franceStock.reduce(
        (f, x) => f + x.available,
        0
      );

      let franceGLPPhysical = franceGLP.reduce((f, x) => f + x.physical, 0);
      let franceGLPReserved = franceGLP.reduce((f, x) => f + x.reserved, 0);
      let franceGLPAvailable = franceGLP.reduce((f, x) => f + x.available, 0);

      this.inventoryBreakDown.STOCK.INDY = {
        physical: indyStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation,
        available: indyStockAvailable - indyStockNoLotReservation,
      };

      this.inventoryBreakDown.GLP.INDY = {
        physical: indyGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation,
        available: indyGLPAvailable - indyGLPNoLotReservation,
      };

      this.inventoryBreakDown.STOCK.FRANCE = {
        physical: franceStockPhysical,
        reserved: franceStockReserved + franceStockNoLotReservation,
        available: franceStockAvailable - franceStockNoLotReservation,
      };
      this.inventoryBreakDown.GLP.FRANCE = {
        physical: franceGLPPhysical,
        reserved: franceGLPReserved + franceGLPNoLotReservation,
        available: franceGLPAvailable - franceGLPNoLotReservation,
      };

      stockAvailable = indyStockAvailable - indyStockNoLotReservation + franceStockAvailable - franceStockNoLotReservation;
      glpAvailable = indyGLPAvailable - indyGLPNoLotReservation +  franceGLPAvailable - franceGLPNoLotReservation;

      this.inventoryBreakDown.STOCK.SUB = {
        physical: indyStockPhysical + franceStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation + franceStockReserved + franceStockNoLotReservation,
        available: stockAvailable,
      };
      this.inventoryBreakDown.GLP.SUB = {
        physical: indyGLPPhysical + franceGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation + franceGLPReserved + franceGLPNoLotReservation,
        available: glpAvailable,
      };

    } else {
      let sgsStock = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == "SGS"
      );
      let sgsGLP = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == "SGS"
      );

      indyStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 1).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      indyGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 1).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      sgsStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 2).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      sgsGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 2).reduce((a,r)=> a+ r.reservationAmountConverted ,0);

      let sgsStockPhysical = sgsStock.reduce((f, x) => f + x.physical, 0);
      let sgsStockReserved = sgsStock.reduce((f, x) => f + x.reserved, 0);
      let sgsStockAvailable = sgsStock.reduce((f, x) => f + x.available, 0);

      let sgsGLPPhysical = sgsGLP.reduce((f, x) => f + x.physical, 0);
      let sgsGLPReserved = sgsGLP.reduce((f, x) => f + x.reserved, 0);
      let sgsGLPAvailable = sgsGLP.reduce((f, x) => f + x.available, 0);

      this.inventoryBreakDown.STOCK.INDY = {
        physical: indyStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation,
        available: indyStockAvailable - indyStockNoLotReservation,
      };

      this.inventoryBreakDown.GLP.INDY = {
        physical: indyGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation,
        available: indyGLPAvailable - indyGLPNoLotReservation,
      };

      this.inventoryBreakDown.STOCK.SGS = {
        physical: sgsStockPhysical,
        reserved: sgsStockReserved + sgsStockNoLotReservation,
        available: sgsStockAvailable - sgsStockNoLotReservation,
      };
      this.inventoryBreakDown.GLP.SGS = {
        physical: sgsGLPPhysical,
        reserved: sgsGLPReserved + sgsGLPNoLotReservation,
        available: sgsGLPAvailable - sgsGLPNoLotReservation,
      };

      stockAvailable = indyStockAvailable - indyStockNoLotReservation + sgsStockAvailable - sgsStockNoLotReservation;
      glpAvailable = indyGLPAvailable - indyGLPNoLotReservation + sgsGLPAvailable - sgsGLPNoLotReservation;

      this.inventoryBreakDown.STOCK.SUB = {
        physical: indyStockPhysical + sgsStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation + sgsStockReserved + sgsStockNoLotReservation,
        available: stockAvailable,
      };
      this.inventoryBreakDown.GLP.SUB = {
        physical: indyGLPPhysical + sgsGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation + sgsGLPReserved + sgsGLPNoLotReservation,
        available: glpAvailable,
      };
    }
      this.inventoryTotalAvailable = stockAvailable + glpAvailable;
    //console.log(this.inventoryBreakDown);
  }

  GetExistingRequestType(cartRequestTypeId: number)
  {
    var requestType = this.requestType.InStock;
    switch(cartRequestTypeId)
    {
      case 1: requestType = this.requestType.InStock;
              break;
      case 2: requestType = this.requestType.GLP;
              break;
      case 3: requestType = this.requestType.Radioactive;
              break;
      case 4: requestType = this.requestType.PeerToPeer;
              break;
    }

    return requestType;
  }

  getCarts(){
     this.cartService.getCarts().subscribe(response => {
      this.cartItemList = response;
      let cartItemRequestType = this.cartItemList.length > 0 ? this.GetExistingRequestType(this.cartItemList[0].CartRequestTypeId) : this.requestType.InStock;

      if(cartItemRequestType == this.requestType.PeerToPeer)
      {
          if(this.cartItemList.some(x=>this.isP2PChemicalAllowedMaterial(x.MaterialSearchType, x.IsBioFormulation)))
          {
            this.cartMaterialType=this.commonService.configurations?.P2PChemical;
          }
          else if(this.cartItemList.some(x=> this.isP2PBiologicalAllowedMaterial(x.MaterialSearchType, x.IsBioFormulation)))
          {
            this.cartMaterialType=this.commonService.configurations?.P2PBiological;
          }
          else if(this.cartItemList.some(x=> this.isP2POtherRequestAllowedMaterial(x.MaterialSearchType)))
          {
            this.cartMaterialType=this.commonService.configurations?.P2POther;
          }
          else
          {
            this.cartMaterialType='';
          }
      }
      else
      {
        this.cartMaterialType='';
      }
    },
      error => {
        this.messageService.add({ severity: 'error', summary: "", detail: error.message });
      });
  }

  public removeDuplicates(originalUOM, prop) {
    var newUOM = [];
    var tempObject  = {};

    for(var i in originalUOM) {
      tempObject[originalUOM[i][prop]] = originalUOM[i];
    }

    for(i in tempObject) {
      newUOM.push(tempObject[i]);
    }
     return newUOM;
}

  public getUnitOfMeasureListForAllRequestType() {
    this.unitOfMeasureService.getUnitOfMeasureListForAllRequestType().subscribe(response => {
        this.uomOptions = response;
        this.uomOptions = this.removeDuplicates(this.uomOptions, "UnitOfMeasure")
        console.log("UOMs", this.uomOptions);
    });
  }

  public reconcileAndRefresh(){
    this.reservationClient.reconcileReservations(this.selectedMaterialRepoName,this.selectedMaterialId).subscribe(response => {
      this.getMaterialDetails(this.selectedMaterialRepoName,this.selectedMaterialId, this.uom, this.selectedMaterialHeader);
    },
      error => {
        console.log(error.message);
  });
  }

  downloadReconcileReport(){
    this.reservationClient.reconciliationReport(this.selectedMaterialRepoName,this.selectedMaterialId, this.uom, this.selectedMaterialHeader).subscribe(response => {
      var binaryData = [];
      binaryData.push(response);
      var blob = new Blob(binaryData, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = "ReconciliationReport.xlsx";
      a.click();
    },
      error => {
        console.log(error.message);
      });
  }

  OnUnitOfMeasureChange(obj:any)
  {
    this.uom = this.selectedUom.UnitOfMeasure;
    this.getMaterialDetails(this.selectedMaterialRepoName,this.selectedMaterialId, this.uom, this.selectedMaterialHeader);
  }
  private getProductName(item: MaterialResult): string {
    let res: string = "";
    switch (item.sourceSystem) {
      case 'COFORM':
        res = item.materialName;
        break;
      case 'CORE':
        res = item.commonName === null ? item.materialName : item.commonName + ' (' + item.materialName + ')';
        break;
      case 'FACTS':
        res = (item['preferredSynonym'] === null || item['preferredSynonym'] === undefined || item['preferredSynonym'] === '') ?
        item.materialName :
        item.materialName+' ('+ item['preferredSynonym'] + ')';
        break;
      case 'BIONEXUS':
          res = item.materialName;
        break;
      default:
        res = item.materialName;
        break;
    }
    return res;
  }

  private getMaterialDetailsBioNexus(materialId){
    // this.selectedMaterialRepoName = repoName;
    // this.selectedMaterialId = materialId;
    // this.selectedMaterialHeader = materialId;
    this.displayNexusDialog = true;
    this.materialNameHeader = materialId
  }


  private getMaterialDetails(repoName,materialId,uom,displayHeader = null){
    this.selectedInventoryType = this.RequestTypeName.toUpperCase();
    this.selectedMaterialRepoName = repoName;
    this.selectedMaterialId = materialId;
    this.selectedMaterialHeader = displayHeader;
    this.uom = uom;
    this.compositeInventoryClient.retrieveCompositeInventoryWithStorageLoc(repoName,materialId , uom, true)
    .subscribe(materilInfo => {
      this.materialInfo = materilInfo[0];
      let input = new ReservationSearchInput();
      input.productSourceSystem = repoName;
      input.productIdentifier = materialId;
      input.requestedUOM = uom;
      this.alchemistReservationsClient.search(input).subscribe(x=>{
        this.reservations = x;
        this.breakDownInventory(this.materialInfo);
     });
      const result  = this.materialInfo.compositeInventoryByLot?.filter(d=> d.available > 0 || d.physical > 0 || d.reserved > 0);
      this.materialInfo.compositeInventoryByLot = result;
      if(this.materialInfo.compositeInventoryByLot.length > 0)
      {
      if(repoName == 'CORE')
        {
            this.materialInfo.compositeInventoryByLot = this.materialInfo.compositeInventoryByLot.sort((a, b) => {
                return b.lotId - a.lotId;
             });
        }
      else
        {
          this.materialInfo.compositeInventoryByLot.sort((a, b) => (a.lotNumber > b.lotNumber) ? 1 : -1);
        }
    }
      this.materialNameHeader =displayHeader == null ?  materialId : displayHeader;
      this.selectedUom= this.uomOptions.find(uom => uom.UnitOfMeasure == this.uom);
      this.SelectedRepo = repoName;
      this.inventoryUOM = uom;
      this.displayDialog = true;
    });
  }
  searchLaneChanged() {
    this.results = [];
    this.searchText = '';
    this.aiC = '';
    this.concept = '';
    this.composition = '';
    this.lotNumber = '';
    if(this.RequestTypeName == this.requestType.PeerToPeer || this.RequestTypeName == this.requestType.InStock) {
      this.materialTypeId = "1";
    } else {
      this.materialTypeId = "7";
    }
    this.onTypeChange();
    this.selectedInventoryType = this.RequestTypeName.toUpperCase();
  }
  getSearchLabelText(): string {
    switch (this.materialType)
    {
      case this.commonService.configurations?.Coformulant:
      case this.commonService.configurations?.FieldInert:
        return this.commonService.configurations?.EnterTradeName;
      case this.commonService.configurations?.FACTSFormulationName:
        return this.commonService.configurations?.EnterFormulation;
      case this.commonService.configurations?.FACTSSynonym:
        return this.commonService.configurations?.EnterSynonym;
      case this.commonService.configurations?.CortevaTradeName:
        return this.commonService.configurations?.EnterCortevaTradeName;
      case this.commonService.configurations?.ThirdPartyTradeName:
        return this.commonService.configurations?.EnterPartyTradeName;
      case this.commonService.configurations?.FACTSFormulationNameGlp:
        return this.commonService.configurations?.EnterFormulationLot;
      case this.commonService.configurations?.COREMaterialId:
        return this.commonService.configurations?.EnterCoreMaterialId;
      case this.commonService.configurations?.CORESynonym:
        return this.commonService.configurations?.EnterSynonym;
      case this.commonService.configurations?.COREMaterialLotGlp:
        return this.commonService.configurations?.EnterCoreMaterialLotId;
      case this.commonService.configurations?.FACTTSNGlp:
        return this.commonService.configurations?.EnterTSNLot;

      case this.commonService.configurations?.TsnGlp:
        return this.commonService.configurations?.EnterTsn;
      case this.commonService.configurations?.P2PBiological:
        return this.commonService.configurations?.AddP2PBiological;
      case this.commonService.configurations?.P2POther:
        return this.commonService.configurations?.AddP2POther;
      case this.commonService.configurations?.BioMaterialLotId:
        return this.commonService.configurations?.EnterBioMaterialLotId;
      case this.commonService.configurations?.BioMaterialId:
        return this.commonService.configurations?.EnterBioMaterialId;
      case this.commonService.configurations?.BioNexusSynonymStock:
        return this.commonService.configurations?.EnterSynonym;
      case this.commonService.configurations?.BioNexusSynonymGLP:
        return this.commonService.configurations?.EnterSynonym;
      case this.commonService.configurations?.P2PChemical:
          return this.commonService.configurations?.AddP2PChemical;
    }
    return '';
  }
  onTypeChange() {
    var newMaterialType = '';
    this.showCartButton=false;
    switch (this.materialTypeId) {
      case '1': {
        newMaterialType = this.commonService.configurations?.Coformulant;
        break;
      }
      case '2': {
        newMaterialType = this.commonService.configurations?.FieldInert;
        break;
      }
      case '3': {
        newMaterialType = this.commonService.configurations?.FACTSFormulationName;
        break;
      }
      case '4': {
        newMaterialType = this.commonService.configurations?.FACTSSynonym;
        break;
      }
      case '5': {
        newMaterialType = this.commonService.configurations?.CortevaTradeName;
        break;
      }
      case '6': {
        newMaterialType = this.commonService.configurations?.ThirdPartyTradeName;
        break;
      }
      case '7': {
        newMaterialType = this.commonService.configurations?.FACTSFormulationNameGlp;
        break;
      }
      case '8': {
        newMaterialType = this.commonService.configurations?.COREMaterialId;
        break;
      }
      case '9': {
        newMaterialType = this.commonService.configurations?.CORESynonym;
        break;
      }
      case '10': {
        newMaterialType = this.commonService.configurations?.COREMaterialLotGlp;
        break;
      }
      case '12': {
        newMaterialType = this.commonService.configurations?.FACTTSNGlp;
        break;
      }
      case '11': {
        newMaterialType = this.commonService.configurations?.TsnGlp;
        break;
      }
      case '13': {
        newMaterialType = this.commonService.configurations?.P2PBiological;
        break;
      }
      case '14': {
        newMaterialType = this.commonService.configurations?.P2POther;
        break;
      }
      case '15': {
        newMaterialType = this.commonService.configurations?.BioMaterialLotId;
        break;
      }
      case '16': {
        newMaterialType = this.commonService.configurations?.BioNexusSynonymGLP;
        break;
      }
      case '17': {
        newMaterialType = this.commonService.configurations?.BioMaterialId;
        break;
      }
      case '18': {
        newMaterialType = this.commonService.configurations?.BioNexusSynonymStock;
        break;
      }
      case '19': {
        newMaterialType = this.commonService.configurations?.P2PChemical;
        break;
      }
    }
    if (newMaterialType !== this.materialType) {
      this.materialType = newMaterialType;
      this.resetValues();
    }
    if(this.isMaterialAllowedToAddToCart()){
      this.p2pDisabled=false;
    }else{
      this.p2pDisabled=true;
    }
    this.inventoryList.find(x=>x.value=="GLP").disabled =(this.materialType == this.commonService.configurations?.Coformulant || this.materialType == this.commonService.configurations?.FieldInert);
    this.inventoryList.find(x=>x.value=="BOTH").disabled =(this.materialType == this.commonService.configurations?.Coformulant || this.materialType == this.commonService.configurations?.FieldInert);
    this.inventoryList =[...this.inventoryList];
  }
  resetValues() {
    this.results = [];
    this.searchText = '';
    this.aiC = '';
    this.concept = '';
    this.composition = '';
    this.lotNumber = '';
  }
  getSearchCriteria(): string {
    switch (this.materialType)
    {
      case this.commonService.configurations?.Coformulant:
      case this.commonService.configurations?.FieldInert:
      case this.commonService.configurations?.CortevaTradeName:
        return this.searchText;
      case this.commonService.configurations?.ThirdPartyTradeName:
        return this.searchText;
      case this.commonService.configurations?.FACTSSynonym:
      case this.commonService.configurations?.COREMaterialId:
      case this.commonService.configurations?.CORESynonym:
      case this.commonService.configurations?.COREMaterialLotGlp:
      case this.commonService.configurations?.TsnGlp:
      case this.commonService.configurations?.P2PBiological:
      case this.commonService.configurations?.P2POther:
      case this.commonService.configurations?.P2PChemical:
        return this.searchText;
        case this.commonService.configurations?.FACTTSNGlp:
        return this.searchText;
      case this.commonService.configurations?.FACTSFormulationName:
        return this.aiC + "-" + this.concept + "-" + this.composition;
      case this.commonService.configurations?.FACTSFormulationNameGlp:
        {
        return this.aiC + "-" + this.concept + "-" + this.composition + "-" + this.lotNumber;
        }
      default:
        return this.searchText;
    }
    return '';
  }

  addToCart(result: MaterialResult) {
    if(result.approverEmail != null && result.approverEmail != undefined && result.approverEmail != ""){
      this.azureADService.GetUserDetails(result.approverEmail).subscribe(data => {
        if(data != null && data.length != 0){
          this.proceedForAddToCart(result);
        }
        else{
            this.displayApproverDialog = true;
        }
      });
    }
    else{
      this.proceedForAddToCart(result);
    }
  }

  proceedForAddToCart(result :MaterialResult){
    this.setP2PCartMaterialType(this.materialType, result.isBioFormulation);
    result["searchCriteria"] = this.getSearchCriteria();
    if(this.RequestTypeName == this.requestType.PeerToPeer)
    {
      result["cartItemType"] = "Stock-GLP"
      result["searchType"] = this.requestType.PeerToPeer;
    }
    result["materialSearchType"] = this.materialType;
    this.AddCartItem.emit(result);
  }

  GetUserDetailsFromAD(email:string)
  {

    this.azureADService.GetUserDetails(email).subscribe(data => {
      this.displayName = data[0]?.DisplayName;
      if(this.displayName == null || this.displayName == undefined)
      {
        this.displayName = email;
      }
    });

  }

  isP2PChemicalAllowedMaterial(materialType, isBioFormulation = false){
    let allowedMaterials =
                        [
                          this.commonService.configurations?.Coformulant,
                          this.commonService.configurations?.FieldInert,

                          this.commonService.configurations?.FACTSSynonym,
                          this.commonService.configurations?.CortevaTradeName,
                          this.commonService.configurations?.ThirdPartyTradeName,
                          this.commonService.configurations?.FACTTSNGlp,
                          this.commonService.configurations?.FACTSFormulationName,
                          this.commonService.configurations?.FACTSFormulationNameGlp,

                          this.commonService.configurations?.COREMaterialId,
                          this.commonService.configurations?.CORESynonym,
                          this.commonService.configurations?.COREMaterialLotGlp,
                          this.commonService.configurations?.TsnGlp,

                          this.commonService.configurations?.P2PChemical
                        ];

    return allowedMaterials.includes(materialType) && !isBioFormulation;
  }

  isP2PBiologicalAllowedMaterial(materialType, isBioFormulation=false){
    let allowedMaterials =
                        [
                          this.commonService.configurations?.BioMaterialLotId,
                          this.commonService.configurations?.BioNexusSynonymGLP,
                          this.commonService.configurations?.BioMaterialId,
                          this.commonService.configurations?.BioNexusSynonymStock,

                          this.commonService.configurations?.P2PBiological
                        ];

    let allowedFactsMaterials=
                        [
                          this.commonService.configurations?.FACTSSynonym,
                          this.commonService.configurations?.CortevaTradeName,
                          this.commonService.configurations?.ThirdPartyTradeName,
                          this.commonService.configurations?.FACTTSNGlp,
                          this.commonService.configurations?.FACTSFormulationName,
                          this.commonService.configurations?.FACTSFormulationNameGlp,
                        ];

    return allowedMaterials.includes(materialType) || (allowedFactsMaterials.includes(materialType) && isBioFormulation);
  }

  isP2POtherRequestAllowedMaterial(materialType){
    let allowedMaterials= [
                            this.commonService.configurations?.P2POther
                          ];

    return allowedMaterials.includes(materialType);
  }


  isMaterialAllowedToAddToCart(){
    switch(this.cartMaterialType){
      case this.commonService.configurations?.P2PChemical:
              return this.commonService.configurations?.P2PChemical == this.materialType;
      case this.commonService.configurations?.P2PBiological :
              return this.commonService.configurations?.P2PBiological == this.materialType;
      case this.commonService.configurations?.P2POther :
              return this.commonService.configurations?.P2POther == this.materialType;
      default :
          return true;
    }
  }

  setP2PCartMaterialType(materialType,isBioFormulation=false)
  {
    if(this.RequestTypeName == this.requestType.PeerToPeer && this.cartMaterialType=='')
    {
        if(this.isP2PChemicalAllowedMaterial(materialType,isBioFormulation))
        {
          this.cartMaterialType=this.commonService.configurations?.P2PChemical;
        }
        else if(this.isP2PBiologicalAllowedMaterial(materialType,isBioFormulation))
        {
          this.cartMaterialType=this.commonService.configurations?.P2PBiological;
        }
        else if(this.isP2POtherRequestAllowedMaterial(materialType))
        {
          this.cartMaterialType=this.commonService.configurations?.P2POther;
        }
        else
        {
          this.cartMaterialType='';
        }
    }
  }

  addToCartP2P() {
    this.setP2PCartMaterialType(this.materialType);
    var materialResult= new MaterialResult();
    materialResult["searchType"] = this.requestType.PeerToPeer;
    materialResult.materialName= this.getSearchCriteria();
    materialResult["materialSearchType"] = this.materialType;
    materialResult.materialId = null;
    this.AddCartItem.emit(materialResult);
    this.showCartButton = false;
    this.searchText = "";
  }

  public isBioResult(result){
    if((result.sourceSystem && result.sourceSystem.toLowerCase() === "bionexus")
        || (result.isBioFormulation && result.isBioFormulation === true)){
      return true;
    }
    return false;
  }

  search() {
    this.results = [];
    switch (this.materialType)
    {
      case this.commonService.configurations?.Coformulant:
        if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== '')
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmpty, detail: '' });
          return false;
        }
        this.coformulantClient.retrieveCoformulantsByName(this.searchText)
          .subscribe(a => {
            this.results = a;
            if(this.results){
              this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
            }
            this.isMaterialAllowedToAddToCartItems(this.results);
            this.markDuplicates.emit(this.results);
            this.isGlp =false;
            console.log(this.results);
          });
          break;
        case this.commonService.configurations?.FieldInert:
          if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== '')
          {
            this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmpty, detail: '' });
            return false;
          }
          this.coformulantClient.retrieveFieldInertsByName(this.searchText)
          .subscribe(a => {
            this.results = a;
            if(this.results){
              this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
            }
            this.isMaterialAllowedToAddToCartItems(this.results);
            this.markDuplicates.emit(this.results);
            this.isGlp =false;
            console.log(this.results);
          });
          break;
          case this.commonService.configurations?.FACTSFormulationName:
            if(this.aiC == undefined || this.aiC.length == 0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterAIC, detail: '' });
return false;
            }
            else if(this.concept == undefined || this.concept.length == 0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterConcept, detail: '' });
return false;
            }
            else if(this.composition == undefined || this.composition.length == 0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterComposition, detail: '' });
return false;
            }

            if(!(/[a-zA-Z0-9]/.test(this.aiC)) || this.aiC.length>10)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.AICAlphaNumeric, detail: '' });
              return false;

            }

          this.formulationClient.retrieveFormulationsByFormulationId(this.getSearchCriteria())
          .subscribe(a => {
            this.results = a;
            if(this.results){
              this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
            }
            this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
            this.isMaterialAllowedToAddToCartItems(this.results);
            this.markDuplicates.emit(this.results);
            console.log(this.results);
          });
          break;
          case this.commonService.configurations?.FACTSSynonym:
              if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 250)
              {
                this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '250'), detail: '' });
                return false;
              }
              this.formulationClient.retrieveFormulationsBySynonym(this.getSearchCriteria())
              .subscribe(a => {
                this.results = a;
                if(this.results){
                  this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
                }

                this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
                this.isMaterialAllowedToAddToCartItems(this.results);
                this.markDuplicates.emit(this.results);
                console.log(this.results);
              }, (error)=>{
                this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
              });
              break;
          case this.commonService.configurations?.ThirdPartyTradeName:
            if(this.getSearchCriteria() == undefined || this.getSearchCriteria().length > 100 ||  this.getSearchCriteria().length ==0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
              return false;
            }
            this.formulationClient.retrieveFormulationsByThirdPartyTradeName(this.getSearchCriteria())
            .subscribe(a => {
              this.results = a;
              if(this.results){
                this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
              }
              this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
              this.isMaterialAllowedToAddToCartItems(this.results);
              this.markDuplicates.emit(this.results);
              console.log(this.results);
            }, (error)=>{
              this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
            });
            break;

            case this.commonService.configurations?.CortevaTradeName:
            if(this.getSearchCriteria() == undefined || this.getSearchCriteria().length > 100 || this.getSearchCriteria().length ==0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
              return false;
            }
            this.formulationClient.retrieveFormulationsByCortevaTradeName(this.getSearchCriteria())
            .subscribe(a => {
              this.results = a;
              if(this.results){
                this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
              }
              this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
              this.isMaterialAllowedToAddToCartItems(this.results);
              this.markDuplicates.emit(this.results);
              console.log(this.results);
            }, (error)=>{
              this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
            });
            break;
            case this.commonService.configurations?.COREMaterialId:

              if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
              {
                this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
                return false;
              }
                this.compoundClient.retrieveCompoundByMaterialId(this.getSearchCriteria())
                .subscribe(a => {
                  this.results = a;
                  if(this.results){
                    this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
                  }
                  this.isMaterialAllowedToAddToCartItems(this.results);
                  this.markDuplicates.emit(this.results);
                  console.log(this.results);
                });

                break;
                case this.commonService.configurations?.CORESynonym:
                  if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 100)
                  {
                    this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
                    return false;
                  }
                  this.compoundClient.retrieveCompoundBySynonym(this.getSearchCriteria())
                  .subscribe(a => {
                    this.results = a;
                    if(this.results){
                      this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
                    }
                    this.isMaterialAllowedToAddToCartItems(this.results);
                    this.markDuplicates.emit(this.results);
                    console.log(this.results);
                  }, (error)=>{
                    this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
                  });

                    break;
                case this.commonService.configurations?.COREMaterialLotGlp:
                  if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 9)
                  {
                    this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '9'), detail: '' });
                    return false;
                  }

                    this.compoundClient.retrieveCompoundLotsByLotId(this.getSearchCriteria())
                    .subscribe(a => {
                      this.results = a;
                      if(this.results){
                        this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
                      }
                      if(this.results[0].approverEmail != null && this.results[0].approverEmail != undefined)
                        {
                          this.GetUserDetailsFromAD(this.results[0].approverEmail)
                        }
                      this.results.forEach(element => {
                         if(element.recertificationDate != null)
                         {
                         if(element.recertificationDate.getTimezoneOffset()/60 > 0)
                         {
                          element.recertificationDate.setHours(element.recertificationDate.getHours()+ (element.recertificationDate.getTimezoneOffset()/60) );
                         }
                         else
                         {
                          element.recertificationDate.setHours(element.recertificationDate.getHours()- (element.recertificationDate.getTimezoneOffset()/60) );
                         }

                         }
                         if(element.certificationDate != null)
                         {
                           if (element.certificationDate.getTimezoneOffset()/60 > 0)
                           {
                            element.certificationDate.setHours(element.certificationDate.getHours()+ (element.certificationDate.getTimezoneOffset()/60) );
                           }
                           else
                           {
                          element.certificationDate.setHours(element.certificationDate.getHours()- (element.certificationDate.getTimezoneOffset()/60) );
                           }
                         }
                      });
                      this.isMaterialAllowedToAddToCartItems(this.results);
                      this.markDuplicates.emit(this.results);
                      console.log(this.results);
                    });
              break;
      case this.commonService.configurations?.FACTSFormulationNameGlp:
        if(this.aiC == undefined || this.aiC.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterAIC, detail: '' });
return false;
        }
        else if(this.concept == undefined || this.concept.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterConcept, detail: '' });
return false;
        }
        else if(this.composition == undefined || this.composition.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterComposition, detail: '' });
return false;
        }
        else if(this.lotNumber == undefined || this.lotNumber.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterLot, detail: '' });
return false;
        }

        this.formulationClient.retreveConfidentialLots(this.getSearchCriteria(), undefined, undefined)
          .subscribe(a => {
            this.results = a;
            if(this.results){
              this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
            }
            this.isMaterialAllowedToAddToCartItems(this.results);
            this.markDuplicates.emit(this.results);
            console.log(this.results);
          });
        break;
        case this.commonService.configurations?.TsnGlp:
          if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 100)
          {
            this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
            return false;
          }

          this.compoundClient.retrieveCompoundByTSN(this.getSearchCriteria())
              .subscribe(a => {
                this.results = a;
                if(this.results){
                  this.results = a.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
                }
                if(this.results[0].approverEmail != null && this.results[0].approverEmail != undefined)
                {
                  this.GetUserDetailsFromAD(this.results[0].approverEmail)
                }
                this.isMaterialAllowedToAddToCartItems(this.results);
                this.markDuplicates.emit(this.results);
                console.log(this.results);
              }, (error)=>{
                this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
              });

            break;

        case this.commonService.configurations?.FACTTSNGlp:
          if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
              return false;
            }
            this.formulationClient.retrieveFormulationsByTSN(this.getSearchCriteria()).subscribe(x => {
              this.results = x;
              if(this.results){
                this.results = x.filter(result => result.status != 'DISCONTINUED' || (result.quantity && result.quantity > 0));
              }
              this.isMaterialAllowedToAddToCartItems(this.results);
              this.markDuplicates.emit(this.results);
              console.log(this.results);
            });
        break;
        // bionexus material search starts here
      case this.commonService.configurations?.BioMaterialId:
        if (this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
          return false;
        }
        this.bioNexusClient.retrieveBioNexusMaterialById(this.getSearchCriteria().toUpperCase()).subscribe((res) => {
          this.results = res;
          this.isMaterialAllowedToAddToCartItems(this.results);
          this.markDuplicates.emit(this.results);
          console.log(this.results);
        }, (error)=>{
          if(error.title){
            this.ShowMessage.emit(error.title);
          }
          else
            this.ShowMessage.emit(error);
        });
        break;

        case this.commonService.configurations?.BioMaterialLotId:
          if (this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
          {
            this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
            return false;
          }
          this.bioNexusClient.retrieveBioNexusLotByLotId(this.getSearchCriteria().toUpperCase()).subscribe((result) => {
            this.results = result.filter(a => a.isGLP == true);
            this.isMaterialAllowedToAddToCartItems(this.results);
            this.markDuplicates.emit(this.results);
          }, (error)=>{
            if(error.title){
              this.ShowMessage.emit(error.title);
            }
            else
              this.ShowMessage.emit(error);
          });
          break;
      case this.commonService.configurations?.BioNexusSynonymStock:
        if (this.getSearchCriteria() == undefined || this.getSearchCriteria() == '' || this.getSearchCriteria().length > 20)
        {
          this.messageService.add({severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: ''});
          return false;
        }
        this.bioNexusClient.retrieveBioNexusMaterialsBySynonymForStock(this.getSearchCriteria().toUpperCase()).subscribe(res => {
          this.results = res;
          this.isMaterialAllowedToAddToCartItems(this.results);
          this.markDuplicates.emit(this.results);
        }, (error)=>{
          if(error.title){
            this.ShowMessage.emit(error.title);
          }
          else
            this.ShowMessage.emit(error);
        });
        break;
      case this.commonService.configurations?.BioNexusSynonymGLP:
        if (this.getSearchCriteria() == undefined || this.getSearchCriteria() == '' || this.getSearchCriteria().length > 20)
        {
          this.messageService.add({severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: ''});
          return false;
        }
        this.bioNexusClient.retrieveBioNexusMaterialsBySynonymForGlp(this.getSearchCriteria().toUpperCase()).subscribe(res => {
          this.results = res;
          this.isMaterialAllowedToAddToCartItems(this.results);
          this.markDuplicates.emit(this.results);
        }, (error)=>{
          if(error.title){
            this.ShowMessage.emit(error.title);
          }
          else
            this.ShowMessage.emit(error);
        });
        break;
    }
    this.dataView.first = 0;
  }

  isMaterialAllowedToAddToCartItems(results = []){
      if(this.RequestTypeName==this.requestType.PeerToPeer){
          results.forEach(x=>{
              if(this.cartMaterialType==this.commonService.configurations?.P2PChemical){
                    x.disabled = !this.isP2PChemicalAllowedMaterial(this.materialType, x.isBioFormulation);
              }
              else if(this.cartMaterialType==this.commonService.configurations?.P2PBiological)
              {
                    x.disabled = !this.isP2PBiologicalAllowedMaterial(this.materialType, x.isBioFormulation);
              }
              else if(this.cartMaterialType==this.commonService.configurations?.P2POther){
                    x.disabled = !this.isP2POtherRequestAllowedMaterial(this.materialType);
              }
              else{
                    x.disabled = false;
              }
        });
      }
  }

  attributeValues(att: any) {
    let val: any = '';
    if (att.values.length === 0 || (att.values.length === 1 && att.values[0] === '')) {
      val = '';
    } else {
      (att.values).forEach((vl, ind) => {
        if (ind === (att.values).length - 1) {
          val += vl;
        } else {
          val += vl + ', '
        }
      });
    }
    // let parsed = Date.parse(val);
    // let isDate = (isNaN(val) && !isNaN(parsed) ? true : false);
    // if (isDate) {
    //   const date = moment(val).format('DD MMM YYYY');
    //   if (new Date(date).getFullYear() < 2100) {
    //     val = date;
    //   }
    // }
    return val;
  }

  RestrictUnwantedCharecters(e)
  {
      switch(this.materialType)
      {
        case this.commonService.configurations?.CortevaTradeName:
        case this.commonService.configurations?.ThirdPartyTradeName:
            if(e.key == "%" || e.key == "&")
              {
                return false;
              }
              break;
        case this.commonService.configurations?.COREMaterialId:
        case this.commonService.configurations?.COREMaterialLotGlp:
            if(this.searchText.length >= 9)
              {
                e.preventDefault();
                return false;
              }
            if(/^[0-9\s]*$/.test(e.key))
              {
                return true;
              }
            else
              {
                e.preventDefault();
                return false;
              }
            break;
        case this.commonService.configurations?.FACTTSNGlp:
                if(this.searchText.length >= 21)
                  {
                    e.preventDefault();
                    return false;
                  }

                  return true;
                break;
        case this.commonService.configurations?.P2PBiological:
        case this.commonService.configurations?.P2POther:
           if(this.alphaNumericRegEx.test(e.key)) {
             return true;
           }
           else{
            e.preventDefault();
            return false;
           }
      }
  }

  onMaterialNamePaste(e) {
    let clipboardData = e.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (!(this.alphaNumericRegEx.test(pastedText))) {
      e.preventDefault();
      return false;
    }
  }

  OnTextChanged(value)
  {
    if(this.searchText.length > 0)
    {
      this.showCartButton = true;
    }
    else{
      this.showCartButton = false;
    }

  }

  AllowAlphaNumeric(e)
  {
    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
      return true;
      } else {
          e.preventDefault();
          return false;
      }

  }

  AllowOnlyNumeric(e)
  {
    if (/^[0-9\s]*$/.test(e.key)) {
      return true;
      } else {
          e.preventDefault();
          return false;
      }

  }
}
