import { Component, Input, OnInit } from '@angular/core';
import { SelectItem, MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { CartDetailsComponent } from '../cart-details.component';
import { Chemical } from 'src/app/search/chemical';
import { CartService } from 'src/app/services/cart-service/cart.service';
import { RequestService } from 'src/app/services/request-service/request.service';
import { RequestManagementService } from 'src/app/services/request-management-service/request-management.service';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';
import { AppComponent } from 'src/app/app.component';
import { RegionService } from 'src/app/services/region-service/region-service';
import { CountryService } from 'src/app/services/country-service/country.service';

@Component({
    selector: 'app-instock-cart',
    templateUrl: './in-stock-cart.component.html',
    styleUrls: ['../cart-details.component.css'],
    providers: [Chemical, MessageService, CartService, ConfirmationService, RequestService, RequestManagementService, RequestItemService]
})
export class InStockCartComponent extends CartDetailsComponent implements OnInit {
    @Input() cartItemList: Chemical[] = [];

    constructor(messageService: MessageService,
        router: Router, cartService: CartService, appComponent: AppComponent,
        confirmationService: ConfirmationService, requestService: RequestService,
        requestManagementService: RequestManagementService, requestItemService: RequestItemService,regionService:RegionService,countryService:CountryService) {
        super(messageService, router, cartService, appComponent,
            confirmationService, requestService, requestManagementService, requestItemService,regionService,countryService);
    }

    ngOnInit() {
        // Call method to  get list of item added by use to the cart.
        this.cartService.getCarts().subscribe(response => {
            this.cartItemList = response;
            this.cartItemRequestType = this.cartItemList.length > 0 ? this.cartItemList[0].RequestTypeName : '';
            // To set no of item in header on page load/refresh of this page.
            this.appComponent.cartItemsCount = response.length;
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            });
    }



}
