<div class="col-12 resShadow">
  <div class="grid">
    <span class="col-2 columnLabel">Requester</span> <span
      class="col-3">{{requesterInformationModel.DisplayName}}</span>
  </div>
  <div class="grid">
    <span class="col-2 columnLabel">Email Id</span> <span class="col-3"> {{requesterInformationModel.Email}}
    </span>
  </div>
  <div class="grid">
    <span class="col-2 columnLabel">Contact Number</span> <span class="col-3">
      {{requesterInformationModel.ContactNumber}}
    </span>
  </div>
</div>