import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { SyncRequestClient } from 'ts-sync-request';
import { BehaviorSubject } from "rxjs";
import {tap} from 'rxjs/operators'
import { MsalService } from "@azure/msal-angular";
import { MessageService } from 'primeng/api';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';

@Injectable()
export class httpSetHeaders implements HttpInterceptor {
    private redirectToLogin = false;
    constructor(private auth: MsalService, private messageService: MessageService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('/functions')) {
            //to bypass token
        }
        // else if (request.url.includes('gsscommon-api')) {
        //     this.getTokenForGssCommon();
        //     request = request.clone({
        //         headers: new HttpHeaders({
        //             'Authorization': `Bearer ${localStorage["gsscommon_token"]}`
        //         })
        //     });
        // }
        else if (!request.url.includes('graph') && !(request.url.includes('attachments') && request.method == "POST")) {
            request = request.clone({
                headers: request.headers.set('Content-Type','application/json')
            });
            request = request.clone({
                headers: request.headers.set('loggedInUsername',this.auth.instance.getActiveAccount()?.username)
            });
        }
        else if (!request.url.includes('graph') && (request.url.includes('attachments') && request.method == "POST")) {
            request = request.clone({
                headers: request.headers.set('loggedInUsername',this.auth.instance.getActiveAccount()?.username)
            });
        }

        if (request.url.includes("login")) {
            this.redirectToLogin = false;
        }

        return next.handle(request).pipe( tap(() => {},
        (err: any) => {
        // if (err instanceof HttpErrorResponse) {
        //   if (!request.url.includes("login") && !request.url.includes("unauthorized") && err.status === 401 && !this.redirectToLogin) {
        //     this.redirectToLogin = true;
        //     this.messageService.add({ severity: 'error', summary: 'Session timeout', detail: 'Your session has timed out, redirecting to login.'});
        //     //this.router.navigate(['login']);
        //     this.auth.loginRedirect({scopes: [ConfigurationSettings.CLIENT_ID]});
        //   }
        // }
      }));
    }

    // public getTokenForGssCommon():any
    // {
    //     let otk = "Bearer "+localStorage['msal.idtoken'];
    //     if (localStorage["gsscommon_token"] === undefined || localStorage["gsscommon_token_acquired"] === undefined ||
    //     (((new Date()).getTime() - (new Date(localStorage["gsscommon_token_acquired"]).getTime()) > 3000000))) {
    //         let url = ConfigurationSettings.REST_API_URL + "/integration/token/gsscommon";
    //         let tk =  new SyncRequestClient()
    //                                             .addHeader("Authorization", otk)
    //                                             .addHeader('Content-Type', 'text/plain')
    //                                             .get(url);
                                               
    //         localStorage["gsscommon_token"] = tk['token'];
    //         localStorage["gsscommon_token_acquired"] = new Date();
    //         return 0;
    //     }
    // }
}