import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestNotificationService } from 'src/app/services/request-notification-service/request-notification.service';
import { MessageService } from 'primeng/api';
import { RequestRecipientModel } from 'src/app/request-recipient/request-recipient.model';
import { RequestService } from 'src/app/services/request-service/request.service';
import { RecipientModel } from 'src/app/recipient-profile/recipient-profile/recipient.model';
import { RequestModel } from 'src/app/request/request/request.model';
import { RequestNotificationModel } from 'src/app/request/request-notification/request-notification.model';
import { RequesterInformationModel } from 'src/app/request/requester-information/requester-information.model';
import { AddressModel } from 'src/app/address/address.model';

@Component({
  selector: 'app-p2p-requester-details',
  templateUrl: './p2p-requester-details.component.html',
  styleUrls: ['./p2p-requester-details.component.css'],
  providers: [ MessageService, RequestNotificationService]
})
export class P2pRequesterDetailsComponent implements OnInit {

  
  requestNotificationList: RequestNotificationModel[] = [];
  requestRecipientModel: RequestRecipientModel;
  @Input() requesterInformationModel?: RequesterInformationModel;
  
  @Input() requestModel?: RequestModel;
  
  public requesterAddress: AddressModel = {
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0
  };
;
  constructor(
    private messageService: MessageService,
    private requestService: RequestService,
    private requestNotificationService: RequestNotificationService) { }

  ngOnInit(): void {
this.requesterAddress = this.requesterInformationModel.RequesterAddress
this.getRequestRecipient();
this.getUsersToBeNotified();
  }

  public getRequestRecipient() { 
      this.requestService.getRequesterDetail(this.requesterInformationModel?.IsExternalAddress ? "External" : "Internal" ,this.requesterInformationModel?.Email).subscribe(response => this.requestRecipientModel = response,
        error => {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        })
    
  }
  //-----------------------------------
  // Gets the list of user notifications 
  //------------------------------------
  public getUsersToBeNotified() {
    this.requestNotificationService.getUsersToBeNotified(this.requestModel.RequestNumber).subscribe(response => {
      this.requestNotificationList = response;
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
      })
  }
}
