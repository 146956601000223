<h5>My Forecasts</h5>
<p-toast position="top-right"></p-toast>

<br />
<div class="grid">
    <div class="col-5 md:col-5">
        <span class="requester-label">Requester</span>
        <p-dropdown style="margin-left: 15px;" class="delegate requester-dropdown" [options]="requesters" [(ngModel)]="forecastRequester"
            optionLabel="DisplayName">
        </p-dropdown>
        <button style="margin-left:10px;" pButton type="button" label="Show" class="p-button-info" (click)="show()"></button>
    </div>
</div>
<br />
    <p-dataView emptyMessage="No records found" #dv layout="list" [paginator]="true" 
    [rows]="PAGE_SIZE" [value]="results" lazy="true"
    paginatorPosition="both"
    [totalRecords]="totalRecords" (onPage)="pageChanged($event)" 
    [hidden]="isHidden" pageLinks="5" 
    [alwaysShowPaginator]="false">
    <ng-template pTemplate="header">
        <div class="grid">
            <div class="col-4 md:col-4">
            <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By"
                (onChange)="sortChange($event)" styleClass="p-mb-2 p-mb-md-0">
            </p-dropdown>
        </div>
            <div class="col-4 md:col-4">
                <input style="float:center;" class="forecast" type="checkbox" [(ngModel)]="mineOnly" 
                    (click)="filterMyForecastOnCheckChange($event.target.checked)">&nbsp;
                <span>Requests with me as Forecaster only</span>
            </div>
            <div class="col-4 md:col-4">
                <span style="float:right;" class="p-input-icon-left p-mb-2 p-mb-md-0">
                <i class="pi pi-search"></i>
                <input type="search" pInputText placeholder="Filter By" [(ngModel)]="filterValue" (input)="filterChanged($event.target.value)">
            </span>
        </div>
    </div>
    </ng-template>
    <!-- </p-header> -->
    <ng-template let-result pTemplate="listItem">
        <div class="col-12 ui-dataview-content ui-widget-content">
                    <div style="width:100%;margin-left:0px;margin-top:0px;margin-bottom:3px;" class="grid resShadow" [ngClass]="result.HasExpired ? 'expiredItem':''">
                        <div class="col-fixed"
                            [ngClass]="result.RequestTypeName==requestType.GLP ? 'request-type-vertical-text-glp' : 'request-type-vertical-text-stock'">
                            {{result.RequestTypeName}}
                        </div>
                        <div class="col-10">
                            <div class="grid">
                                <div class="col-6">
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-left">Forecast ID</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.ForecastId}}
                                    </div>
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-left">Material Name</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.MaterialName}}
                                    </div>
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-left">Requester</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.Requesters}}
                                    </div>
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-left">Amount Forecasted</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.AmountForecasted + ' ' + result.Uom}}
                                    </div>
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-left">Amount Remaining </label>
                                        <span class="font-weight-bold">
                                        :
                                        </span>{{result.AmountRemaining + ' ' + result.Uom}}
                                    </div>
                                    <div class="col-12">
                                        <button id=show{{result.RequestLocationId}} class="showAll" pButton style="padding: 0px !important;"
                                            type="button" icon="pi pi-chevron-right" label="Show Sample Request History"
                                            [ngClass]="result.HasExpired ? 'button-transparent':''" 
                                            (click)="showDiv(result, true)" iconPos="right"></button>
                                        <button id=hide{{result.RequestLocationId}} class="hideAll" pButton 
                                            type="button" icon="pi pi-chevron-down" style="float:left;padding: 0px !important;display: none;" label="Hide Sample Request History"
                                            (click)="showDiv(result, false)" iconPos="right"></button>
                                    </div>
                                    <div class="grid" style="margin-top:20px;" id=div{{result.RequestLocationId}}>
                                        <div style="margin-left:15px;margin-top:-10px;" class="col-12" *ngFor="let history of result.SampleRequestHistory">
                                            {{history}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-right">Request Location ID</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.RequestLocationId}}
                                    </div>
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-right">Forecaster</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.Forecasters}}
                                    </div>
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-right">Country</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.Country }}
                                    </div>
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-right">Warehouse Loc.</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.InventoryLocation }}
                                    </div>                        
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-right">Material Approver</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.MaterialApprover }}
                                    </div>  
                                    <div class="col-12 padding-bottom-02em padding-top-02em">
                                        <label class="font-weight-bold lbl-right">Program No</label>
                                        <span class="font-weight-bold">
                                            :
                                        </span>{{result.ProgramNumber }}
                                    </div> 
                                    <div class="col-12 padding-bottom-02em padding-top-02em" *ngIf="result.InventoryOrderState != 'In Stock'" style="color: red;font-weight: bold;float: right;text-align: right;">
                                        <span>
                                        Inventory may not be available to fulfill this request at this time</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="add-to-cart-button-width">
                            <div class="col-12">
                                <button pButton type="button" icon="fa fa-hourglass" label="Expired"
                                    class="p-button-secondary button-expired float-right" *ngIf="result.HasExpired">
                                </button>
                                <button pButton type="button" icon="pi pi-shopping-cart" label="Add to Cart"
                                    (click)="addToCart(result)" class="p-button-success float-right"
                                    *ngIf="!result.IsAdded && !result.HasExpired">
                                </button>
                                <button pButton type="button" icon="pi pi-shopping-cart" label="Added to Cart"
                                    class="p-button-warning float-right margin-top-0 cursor-no-drop"
                                    *ngIf="result.IsAdded"></button></div>

                    </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="paginatorleft" let-state id="paginatorleft2">
        <span *ngIf="results && results.length > 0" class="ui-paginator-first" style="padding-left:10px;"> Total Items : {{totalRecords}}
          {{ totalRecords == results.length ? '' : '(Showing Items: '+ results.length + ')'}} </span>
      </ng-template>
      <ng-template pTemplate="paginatorright" id="paginatorright2" let-state>
        <span *ngIf="results && results.length > 0" class="ui-paginator-first" style="padding-right:10px;">{{(pageIndex * PAGE_SIZE) + 1}} -
          {{(results.length !== PAGE_SIZE ? totalRecords : (pageIndex+1) * PAGE_SIZE)}} of {{totalRecords}} Items</span>
      </ng-template>
</p-dataView>
