export class UserRequestTypeRoleModel {
    UserRequestTypeRoleId: number;
    RoleId: number;
    RoleName:string;   
    UserId: number;
    RequestTypeId: number;
    RequestTypeName:string;
}




