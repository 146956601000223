

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CountryModel } from '../../country/country.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class CountryService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  isQuestionnaireSaved: boolean= false;

  constructor(private _http: HttpClient) { }

  public createCountry(country: CountryModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/api/countrys"
    let body = JSON.stringify(country);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new Country"))
      );
  }

  public getCountryList(): any {
    let url = ConfigurationSettings.REST_API_URL + "/countries"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched Countrys")));
  }

  public async getCountryListAsync(): Promise<any> {
    let url = ConfigurationSettings.REST_API_URL + "/countries"
    return await this._http.get(url).pipe(
      tap(x => console.log("Fetched Countrys"))).toPromise();
  }

  public updateCountry(country: CountryModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/api/countrys"
    let body = JSON.stringify(country);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Updated Country"))
      );
  }

  public deleteCountry(Id: number): any {
    let url = ConfigurationSettings.REST_API_URL + "/api/countrys/" + Id;
    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted Country")));
  }

  public getShippingAddressCountries(): any {
    let url = ConfigurationSettings.REST_API_URL + "/shipping-addresses/countries"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched countries from shipping addresses")));
  }
}