
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestItemContainerModel } from './request-item-container.model';
import { RequestItemModel } from '../add-chemical-details/request-item.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ValidationService } from 'src/app/services/validation-service/validation-service';
import { UnitOfMeasureService } from 'src/app/services/unit-of-measure-service/unit-of-measure.service';
import { UOMConversionModel } from 'src/app/unit-of-measure/unit-of-measure.model';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-add-container',
  templateUrl: './add-container.component.html',
  styleUrls: ['./add-container.component.css'],
  providers: [MessageService, ConfirmationService, ValidationService]
})

export class AddContainerComponent implements OnInit {
  @Input() editRequestItemModel = new RequestItemModel();
  @Input() selectedUnitOfMeasure: any;
  @Output() UpdateRemainingAmount = new EventEmitter<any>();
  errorMessageNumberOfContainer: string;
  errorMessageAmountPerContainer: string;
  divForErrorMessageContainer: boolean = false;
  growlMsgs: ToastModule[] = [];
  toasterPositionStyleObject: object = {
    'marginTop': '-160px',
    'margin-left': '40px'
  }

  constructor(private messageService: MessageService,

    private confirmationService: ConfirmationService, public validationService: ValidationService, private unitOfMeasureService: UnitOfMeasureService
  ) {

  }

  ngOnInit() {
    if (this.editRequestItemModel.RequestItemComment.length == 0) {
      this.editRequestItemModel.RequestItemComment = [{
        Id: 0,
        CommentId: 0,
        RequestItemId: this.editRequestItemModel.RequestItemId,
        Comment: {
          Id: 0,
          Comment1: "",
          EnteredByUserId: 0,
          EnteredAt: "2021-03-08T23:11:39.54",
        }
      }]
    }

  }

  //-----------------------------------------------------------------
  // Method will be called when user click on add container button
  //----------------------------------------------------------------
  public addContainer(editRequestItemModel: RequestItemModel) {

    if (!this.validationForEmptyContainerFields(editRequestItemModel)) {
      return;
    };


    if (!editRequestItemModel.TotalRequestedQuantity) {
      editRequestItemModel.TotalRequestedQuantity = 0;
    }

    if (editRequestItemModel.FarmLocationId) {
      const uomItemConversion = {
        ProductIdentifier: this.editRequestItemModel.MaterialId,
        ProductSourceSystem: this.editRequestItemModel.RepoName,
        Amount: this.editRequestItemModel.AmountPerContainer,
        FromUnit: this.selectedUnitOfMeasure.UnitOfMeasure,
        ToUnit: this.editRequestItemModel.UnitOfMeasure
      };
      this.unitOfMeasureService.uomConvert(uomItemConversion).subscribe((response: UOMConversionModel) => {
        this.validateAndAdd(editRequestItemModel, response);
      },
        error => {
          this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
        })
    } else {
      this.validateAndAdd(editRequestItemModel);
    }
  }

  private validateAndAdd(editRequestItemModel: RequestItemModel, response?: UOMConversionModel) {
    var requestContainer = new RequestItemContainerModel();
    const requestedQuantity = ((editRequestItemModel?.ForecastId ? parseFloat(response.Amount.toString()) :
        parseFloat(editRequestItemModel.AmountPerContainer.toString())) * parseInt(editRequestItemModel.NumberOfContainer.toString()));

    const totalRequested = Number.parseFloat(this.editRequestItemModel.TotalRequestedQuantity.toString()) + requestedQuantity;

    if (editRequestItemModel.RequestLocationId > 0 && requestedQuantity > editRequestItemModel.AmountRemaining) {
      this.messageService.add({ key: "addContainerValidationMessage", severity: 'error', detail: 'Request quantity cannot exceed ' + editRequestItemModel.AmountRemaining.toString() });
      return;
    }
    this.editRequestItemModel.TotalRequestedQuantity = totalRequested;
    requestContainer.AmountPerContainer = parseFloat(editRequestItemModel.AmountPerContainer.toString());
    requestContainer.NumberOfContainer = editRequestItemModel.NumberOfContainer;
    requestContainer.SerialNumber = editRequestItemModel.RequestItemContainerList.length + 1;
    requestContainer.IsContainerDeleted = false;
    requestContainer.RequestItemContainerId = 0;
    editRequestItemModel.RequestItemContainerList.push(requestContainer);
    editRequestItemModel.AmountPerContainer = null;
    editRequestItemModel.NumberOfContainer = null;
    var removedQty= 0;
    editRequestItemModel.RequestItemContainerList.filter(x => x.RequestItemContainerId > 0 && x.IsContainerDeleted).forEach(y => {
      removedQty = removedQty +  (y.AmountPerContainer * y.NumberOfContainer);
    });
    var AddedQty = 0;
    editRequestItemModel.RequestItemContainerList.filter(x => x.RequestItemContainerId == 0 && !x.IsContainerDeleted).forEach(y => {
      AddedQty = AddedQty +  (y.AmountPerContainer * y.NumberOfContainer);
    });

    this.UpdateRemainingAmount.emit([removedQty,AddedQty])
  }



  //--------------------------------------------------
  // Validations on click of add button for containers
  //--------------------------------------------------
  public validationForEmptyContainerFields(editRequestItemModel: RequestItemModel): boolean {
    this.divForErrorMessageContainer = false;
    this.errorMessageNumberOfContainer = "";
    this.errorMessageAmountPerContainer = "";
    if (this.editRequestItemModel.UnitOfMeasure == null || this.editRequestItemModel.UnitOfMeasure == "") {

      this.messageService.add({ key: "addContainerValidationMessage", severity: 'error', detail: 'Please select a unit of measure' });
      return false;
    }

    if ((editRequestItemModel.NumberOfContainer == null || editRequestItemModel.NumberOfContainer.toString() == "") &&
      (editRequestItemModel.AmountPerContainer == null || editRequestItemModel.AmountPerContainer.toString() == "")) {
      this.divForErrorMessageContainer = true;
      this.errorMessageNumberOfContainer = "Please enter a value"
      this.errorMessageAmountPerContainer = "Please enter a value"
      return false;
    }
    else if ((editRequestItemModel.NumberOfContainer == null || editRequestItemModel.NumberOfContainer.toString() == "") ||
      (editRequestItemModel.AmountPerContainer == null || editRequestItemModel.AmountPerContainer.toString() == "")) {
      if (editRequestItemModel.NumberOfContainer == null || editRequestItemModel.NumberOfContainer.toString() == "") {
        this.divForErrorMessageContainer = true;
        this.errorMessageNumberOfContainer = "Please enter a value"
        if (editRequestItemModel.AmountPerContainer == 0 || editRequestItemModel.AmountPerContainer.toString() == "0") {
          this.errorMessageAmountPerContainer = "Invalid input"
        }

      }
      else if (editRequestItemModel.AmountPerContainer == null || editRequestItemModel.AmountPerContainer.toString() == "") {
        this.divForErrorMessageContainer = true;
        this.errorMessageAmountPerContainer = "Please enter a value"
        if (editRequestItemModel.NumberOfContainer == 0 || editRequestItemModel.NumberOfContainer.toString() == "0") {
          this.errorMessageNumberOfContainer = "Invalid input"
        }
      }
      return false;
    }
    else if ((editRequestItemModel.AmountPerContainer == 0 || editRequestItemModel.AmountPerContainer.toString() == "0") ||
      (editRequestItemModel.NumberOfContainer == 0 || editRequestItemModel.NumberOfContainer.toString() == "0")) {
      if ((editRequestItemModel.NumberOfContainer == 0 || editRequestItemModel.NumberOfContainer.toString() == "0") &&
        (editRequestItemModel.AmountPerContainer == 0 || editRequestItemModel.AmountPerContainer.toString() == "0")) {
        this.divForErrorMessageContainer = true;
        this.errorMessageNumberOfContainer = "Invalid input"
        this.errorMessageAmountPerContainer = "Invalid input"
        return false;
      }
      else if (editRequestItemModel.AmountPerContainer == 0 || editRequestItemModel.AmountPerContainer.toString() == "0") {
        this.divForErrorMessageContainer = true;
        this.errorMessageAmountPerContainer = "Invalid input"
      }
      else if (editRequestItemModel.NumberOfContainer == 0 || editRequestItemModel.NumberOfContainer.toString() == "0") {
        this.divForErrorMessageContainer = true;
        this.errorMessageNumberOfContainer = "Invalid input"
      }
      return false;
    }
    return true;
  }

  //-----------------------------------------------------------------
  // Method will be called when user click on remove container button
  //----------------------------------------------------------------
  public removeContainer(requestItemContainerModel: RequestItemContainerModel, requestItemContainerList: RequestItemContainerModel[]) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the selected container(s)?',
      header: 'Remove Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var index = 0;
        if (requestItemContainerModel.SerialNumber) {
          index = requestItemContainerList.findIndex(x => x.SerialNumber === requestItemContainerModel.SerialNumber);
        }
        else {
          index = requestItemContainerList.findIndex(x => x.RequestItemContainerId === requestItemContainerModel.RequestItemContainerId);
        }
        requestItemContainerModel.IsContainerDeleted = true;
        requestItemContainerList[index] = requestItemContainerModel;

        // Reset the serial no after the index of deleted item to the last item in the requestItemContainerList.
        while (index < requestItemContainerList.length) {
          requestItemContainerList[index].SerialNumber = index + 1;
          index++;
        }
        this.editRequestItemModel.TotalRequestedQuantity = Number.parseFloat(this.editRequestItemModel.TotalRequestedQuantity.toString()) - (parseFloat(requestItemContainerModel.AmountPerContainer.toString()) * parseInt(requestItemContainerModel.NumberOfContainer.toString()));
        var removedQty= 0;
    this.editRequestItemModel.RequestItemContainerList.filter(x => x.RequestItemContainerId > 0 && x.IsContainerDeleted).forEach(y => {
      removedQty = removedQty +  (y.AmountPerContainer * y.NumberOfContainer);
    });
    var AddedQty = 0;
    this.editRequestItemModel.RequestItemContainerList.filter(x => x.RequestItemContainerId == 0 && !x.IsContainerDeleted).forEach(y => {
      AddedQty = AddedQty +  (y.AmountPerContainer * y.NumberOfContainer);
    });

    this.UpdateRemainingAmount.emit([removedQty,AddedQty])
      },
      reject: () => {

      }
    });
  }
}
