import { ActiveIngredient} from "../services/gsscommon-api-client.service";

export class CartItemModel {
    CartId?: number;
    CartItemId?: number;
    RequestTypeId?: number;
    MaterialName?: string;
    RepoName?: string;
    Synonyms?: string;
    LotNumber?: string;
    RecertificationDate?: Date | string;
    AvailableAmount?: string;
    TsnNumber?: string;
    FormulationType: string;
    CreatedDate?: Date;
    CreatedBy?: number;
    Purity?: string;
    MaterialId?: string;
    ActiveIngredient?: ActiveIngredient[] | string;
    GfCode?: string;
    RequestLocationId?: number;
    ForecastId?: number;
    ForecastRequesterId?: number;
    FarmInventoryStatus: string;
    FarmInventoryLocation: string;
    WarehouseId: number;
    MaterialSearchType: string;
    ApproverName: string;
    ApproverEmail: string;
    IsBioFormulation: boolean;
    sourceSystem?: string;
    ingredientId?: string | undefined;
    materialIdInTargetSystem?: string | undefined;
    cartItemType?: string;

    requestItemAttributes?: RequestItemAttributeModel[];
}

export class RequestItemAttributeModel {
    public Id?: number;
    public RequestItemId?: number;
    public CartItemId?: number;
    public AttributeName?: string;
    public AttributeValue?: string;
    public IsLotLevelAttribute?: boolean;
}
