<p-toast position="top-right"></p-toast>

<p-dialog [contentStyle]="contentStyleObject" [draggable]="true" [modal]="true" [closeOnEscape]="false"
    class="copy-request-dialog" [(visible)]="visible" positionLeft=500 [closable]="false">
    <ng-template 
    pTemplate="header">
        <div class="grid" style="width: 100%;">
            <div class="col-10">
                <span class="dialog-header-text">Copy Request</span>
            </div>
            <div class="col-2">
                <i style="float:right;" class="pi pi-times close" (click)="close()"></i>
            </div>
        </div>
    </ng-template>
    <div class="grid div-header">
        <div class="col-12">
            Please choose information to copy
        </div>
    </div>
    <div class="grid">
        <div class="col-12 form-group">
            <p-checkbox label="Requester Details" binary="true" [(ngModel)]="copyRequestModel.RequesterDetails"
                name="requesterDetails" *ngIf="!copyRequester"></p-checkbox>
        </div>
        <div class="col-12 form-group">
            <p-checkbox label="Recipient Details" binary="true" [(ngModel)]="copyRequestModel.RecipientDetails"
                (onChange)="onChangeRecipientCheckbox()" name="recipientDetails"></p-checkbox>
        </div>
    </div>

    <div class="grid resShadow" style="margin-top:20px;">
        <div class="col-12 form-group div-chemical-details">
            <p-radioButton label="Sample List" value="Samples" [(ngModel)]="selectedCopyOption" name="chemicalDetails">
            </p-radioButton>
        </div>

        <div class="col-12 form-group">
            <p-radioButton label="Sample List with container details" [disabled]="radioButtonDisabled"
                value="SamplesWithContainers" [(ngModel)]="selectedCopyOption" name="chemicalDetails">
            </p-radioButton>
        </div>

        <div class="col-12 form-group">
            <p-radioButton label="Sample List with attachments" [(ngModel)]="selectedCopyOption"
                value="SamplesWithAttachments" name="chemicalDetails">
            </p-radioButton>
        </div>

        <div class="col-12 form-group">
            <p-radioButton label="Sample List with container details and attachments" [disabled]="radioButtonDisabled"
                value="SamplesWithContainersAndAttachments" [(ngModel)]="selectedCopyOption" name="chemicalDetails">
            </p-radioButton>

        </div>
        <div class="grid">
            <div class="col-6 div-no-of-copies"> # of Copies </div>
            <div class="col-6">
                <input (keypress)="validationService.numberValidation($event)" type="text"
                    [(ngModel)]="copyRequestModel.NumberOfCopies" class="form-control input-box"
                    name="noOfCopies" />
            </div>
        </div>
    </div>
    <p-footer>
        <div class="form-group dialog-footer">
            <button pButton type="button" (click)="createCopies()" class="p-button-success" pButton icon="pi pi-save"
                label="Create Copies">
            </button>
            <button pButton type="button" label="Reset" (click)="reset()">
            </button>
        </div>
    </p-footer>
</p-dialog>

<!-- confirmation dialog box to for close button -->
<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>