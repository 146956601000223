<div class="col-12">
    <p-radioButton name="recipientGroup" value="Internal" label="Internal Recipient Profile"
        [(ngModel)]="recipientOption">
    </p-radioButton>&nbsp;&nbsp;
    <p-radioButton name="recipientGroup" value="External" label="External Recipient Profile"
        [(ngModel)]="recipientOption">
    </p-radioButton>
</div>

<internal-recipient-profile *ngIf="recipientOption == 'Internal'" [recipientOption]="recipientOption"
    [results]="results">
</internal-recipient-profile>

<external-recipient-profile *ngIf="recipientOption == 'External'" [recipientOption]="recipientOption">
</external-recipient-profile>