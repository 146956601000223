import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './home.component.html',
    providers: []
})

export class HomeComponent implements OnInit {


    constructor() {

    }

    ngOnInit() {
        
    }
}
