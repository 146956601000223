import { Injectable } from "@angular/core";

@Injectable()
export class Chemical {
    CartItemId?: number;
    MaterialName: string;
    Synonyms: string;
    FormulationType: string;
    LotNumber: string;
    // This variable is true when user has already added this chemical to the user.
    IsAdded: boolean = false;

    RecertificationDate?: string
    AvailableAmount?: string
    TsnNumber?: string;
    Purity?: string;
    MaterialId?: string;
    RepoName?: string
    RequestTypeName?: string;
    CartRequestTypeId?: number;
    GfCode?: string;
    ActiveIngredient?: string;
    RequestLocationId?: number;
    FarmInventoryStatus: string;
    FarmInventoryLocation: string;
    WarehouseId: number;
    IsBioFormulation: boolean;
    // Below properties are of My Forecast
    ForecastId: number;
    Uom: string;
    Forecasters: string;
    Requesters: string;
    Country: string
    AmountForecasted: number;
    AmountRemaining: number;
    SampleRequestHistory: string;
    MaterialSearchType: string;
    ApproverName: string;
    ApproverEmail: string;
}
