import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { RequestManagementComponent } from '../request-management.component';
import { FilterService } from 'src/app/services/filter-service/filter.service';
import { Router } from '@angular/router';
import { RequestManagement } from '../request-management.enum';
import { FavouriteService } from 'src/app/services/favourite-service/favourite.service';
import { RequestManagementModel } from '../request-management.model';
import { RequestService } from 'src/app/services/request-service/request.service';
import { CommonService } from 'src/app/services/common-service/common.service';
import { RequestTypeModel } from 'src/app/request-type/request-type.model';
import { RequestTypeService } from 'src/app/services/request-type-service/request-type.service';
import { RequestType } from 'src/app/request-type/request-type.enum';
import { RequestStatusTypeModel } from 'src/app/request-status-type/request-status-type.model';

@Component({
    selector: 'app-favourite-requests',
    templateUrl: './favourites.component.html',
    styleUrls: ['../request-management.component.css'],
    providers: [MessageService, FilterService, FavouriteService, RequestService, RequestTypeService, ConfirmationService,CommonService]
})
export class FavouriteRequestsComponent extends RequestManagementComponent implements OnInit {

    requestStatusTypeList: RequestStatusTypeModel[] = [];
    selectedRequestStatusTypeModel: RequestStatusTypeModel[] = [];

    constructor(router: Router,
        messageService: MessageService,
        favouriteService: FavouriteService,
        filterService: FilterService,
        requestService: RequestService,
        confirmationService: ConfirmationService,
        commonService:CommonService,
        private requestTypeService: RequestTypeService) {
        super(router, filterService, requestService, confirmationService, favouriteService, messageService,commonService)
        this.requestOption = RequestManagement.Favourites;
    }

    ngOnInit() {
        this.filterColumns = [
            { Text: 'Recipient', Column: 'Recipient' },
            { Text: 'RequestStatus', Column: 'RequestStatus' },
            { Text: 'Year', Column: 'Year' },
            { Text: 'RequestTypeName', Column: 'RequestTypeName' },
            { Text: 'Copy', Column: 'IsCopy' }];

        this.getFavourites();
        this.getRequestStatusTypeList();
    }

    doNothing(e, index) {
        if (e) {
          e.stopPropagation();
        }
      }
      
    private getFavourites() {
        this.favouriteService.getFavourites(localStorage["msal.userId"]).subscribe(response => {
            this.requestManagementModelList = response;
            this.requestManagementModelList = this.requestManagementModelList.sort((a, b) => b.RequestId - a.RequestId);

            this.dataList = this.requestManagementModelList;
            this.totalRecordCount = this.requestManagementModelList.length;
        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            })
    }


    private getRequestStatusTypeList() {
        this.requestService.getRequestStatusTypes().subscribe(response => {
            this.requestStatusTypeList = response;
            this.selectedRequestStatusTypeModel = Array.from(this.requestStatusTypeList);
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        })
    }

    clearFilter(){
        this.selectedRequestStatusTypeModel = Array.from(this.requestStatusTypeList);
        super.clearFilter();
    }

    public favoriteClick(requestManagementModel: RequestManagementModel) {
        if (requestManagementModel.IsFavorite) {
            this.deletefavourite(requestManagementModel.FavoriteId).then(res => {
                if (res) {
                    this.messageService.add({ severity: 'success', summary: 'Request has been removed from your favorites list', detail: '' });
                    this.getFavourites();
                }
            });
        }
    }
    public getTitleStyle(requestManagementModel: RequestManagementModel) {
        switch (requestManagementModel.RequestStatus)
        {
          case  "Draft" :  return 'clsDraftTitleBar' ; break ;
          case  "Submitted" :  return 'clsSubmittedTitleBar' ; break ;
          case  "Ordered" :  return 'clsOrderedTitleBar' ; break ;
          case  "Cancelled" :  return 'clsCancelledTitleBar' ; break ;
          case "Material Approval": return 'clsMaterialApprovalTitleBar'; break;
          case  "Awaiting ITC" :  return 'clsAwaitingITCTitleBar' ; break ;
          case "ITC Rejected": return 'clsITCRejectedTitleBar'; break;
          case  "In Process" :  return 'clsInProcessTitleBar' ; break ;
          case "Shipped": return 'clsShippedTitleBar'; break;
        }
        return '';
      }

}
