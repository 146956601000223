 <div class="ui-g">
  <div class="grid padding-unset">
    <div class="col-4">
      <div class="col-12 resShadow recipientDetail min-height-230px">
        <div class="header-bar padding-8px">
          Recipient Details <span class="mandatory-field float-right"
            *ngIf="recipientModel.IsActive==false && requestModel.RequestStatus == 'Draft'">Inactive</span>
        </div>
        <div *ngIf="recipientModel.Email != undefined">
          <div class="grid shipmentType">
            <div class="col-5 columnLabel">Shipment Type</div>
            <div class="col-4 internal-bar" *ngIf="requestModel.ShippingOption == 'Internal'">
              {{requestModel.ShippingOption}}</div>
            <div class="col-4 external-bar" *ngIf="requestModel.ShippingOption == 'External'">
              {{requestModel.ShippingOption}}</div>
          </div>
          <div class="grid padding-unset">
            <div class="col-5 columnLabel">Recipient</div>
            <div class="col-7"> {{shippingAddress.PersonFullName}}</div>
          </div>
          <div class="grid padding-unset">
            <div class="col-5 columnLabel">Email Id</div>
            <div class="col-7"> {{recipientModel.Email}}</div>
          </div>
          <div class="grid padding-unset">
            <div class="col-5 columnLabel">Contact Number</div>
            <div class="col-7">
              <span *ngIf="requestModel.RequestStatus == 'Draft'"
                [innerHTML]="recipientModel.ContactNumber != '' ? recipientModel.ContactNumber : '<span  class=mandatory-field>Contact Required</span>'">
              </span>
              <span *ngIf="requestModel.RequestStatus != 'Draft'">
                {{ recipientModel.ContactNumber }}
              </span>
            </div>
          </div>
          <div *ngIf="requestModel.ShippingOption =='Internal'">
            <div class="grid padding-unset">
              <div class="col-5 columnLabel">Function</div>
              <div class="col-7"> {{recipientModel.Function}}</div>
            </div>
            <div class="grid padding-unset">
              <div class="col-5 columnLabel">Supervisor</div>
              <div class="col-7"> {{recipientModel.SupervisorName}}</div>
            </div>
          </div>
          <div *ngIf="requestModel.ShippingOption =='External'">
            <div class="grid padding-unset">
              <div class="col-5 columnLabel">Company Name</div>
              <div class="col-7"> {{recipientModel.LegalName}}</div>
            </div>
            <div class="grid padding-unset">
              <div class="col-5 columnLabel">Internal Contact</div>
              <div class="col-7"> {{recipientModel.InternalContactName}}</div>
            </div>
          </div>
        </div>
        <div class="col-12 text-align-center mandatory-field" *ngIf="recipientModel.Email == undefined">
          Shipping Details Not Selected
        </div>
      </div>
    </div>
    <div class=" col-3">
      <div class=" col-12 resShadow min-height-230px">
        <div class="col-12 padding-8px header-bar">
          Shipping Address
          <span class="mandatory-field float-right"
            *ngIf="requestModel.RequestStatus == 'Draft' && (shippingAddress.IsParentUpdated == true)">Address
            updated</span>
          <span class="mandatory-field float-right"
            *ngIf="requestModel.RequestStatus == 'Draft' && (shippingAddress.IsParentDeleted == true)">Address
            deleted</span>
        </div>
        <div class="col-12 selected-address" *ngIf="shippingAddress.PersonFullName != undefined">
          <div class="address-content">
            <span class="font-weight-bold">{{shippingAddress.PersonFullName}}</span>
            <div>{{shippingAddress.AddressLine1}}</div>
            <div>{{shippingAddress.AddressLine2}}</div>
            <div>{{shippingAddress.AddressLine3}}</div>
            <div *ngIf="shippingAddress.AddressId > 0">{{shippingAddress.City}},
              {{shippingAddress.State}} -
              {{shippingAddress.PostalCode}}</div>
            <div>{{shippingAddress.Country}}</div>
            <div *ngIf="shippingAddress.AddressId > 0">
              {{shippingAddress.Mobile}}</div>
              <div>{{shippingAddress.OrgName}}</div>
          </div>
        </div>
        <div  >
          <div class="col-12 text-align-center mandatory-field" *ngIf="requestModel.RequestTypeName =='P2P' && (shippingAddress.OrgName===undefined||shippingAddress.OrgName===null||shippingAddress.OrgName==='')">
            Shipping address is missing Organization Name          </div>
        </div>
        <div class="col-12 text-align-center mandatory-field" *ngIf="shippingAddress.PersonFullName == undefined">
          Shipping Address Not Selected
        </div>
        <div class="col-12 text-align-center mandatory-field" *ngIf="shippingAddress.Mobile===undefined||shippingAddress.Mobile===null||shippingAddress.Mobile===''">
          Shipping address is missing contact phone#
        </div>
      </div>
    </div>
    <div class="col-5" *ngIf="requestModel.RequestTypeName !='P2P'">
      <div class="col-12 resShadow min-height-230px">
        <div class="col-12 header-bar padding-8px">
          User(s) to be notified
        </div>

        <table class="width-100percent" *ngIf="requestNotificationList.length > 0">
          <tbody>
            <tr *ngFor="let requestNotification of requestNotificationList;" class="tblRequestNotification">
              <td class="line-height-20px tdName">
                {{requestNotification.FirstName}} {{requestNotification.LastName}}
                <br />
                <span class="font-italic">({{requestNotification.Email}})</span>
              </td>
              <td class="width-20px">
              </td>
              <td class="line-height-20px tdDescription">
                {{requestNotification.Description}}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-12 text-align-center" *ngIf="requestNotificationList.length == 0">
          No records found
        </div>
      </div>
    </div>
  </div>

</div>