<p-toast position="top-right"></p-toast>

<p-dialog class="recipientProfileDialog" [contentStyle]="contentStyleObject" [draggable]="true" [modal]="true"
    [closeOnEscape]="false" [(visible)]="visible" positionLeft=300 positionTop=40 [closable]="false">
    <p-header>
        <span class="dialog-header-text">New Recipient Profile</span>
    </p-header>

    <div class="col-12">
        <div class="grid">
            <div class="col-6">
                <div class="labelInternal font-weight-bold">
                    <span>*Name</span>
                </div>
                <div class="float-left">
                    <sein-user-search id="recipientSearch" [searchApiUrl]="restApiURL +'{searchWord}'"
                        [(ngModel)]="recipientLookupModel" [defaultUserList]="userLookUpModelList"
                        [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                        placeholder="Search by name or email address" (onSelect)="recipientOnSelect()">
                    </sein-user-search>
                </div>
            </div>
            <div class="col-6">
                <div class="labelInternal font-weight-bold">
                    <span>*Supervisor</span>
                </div>
                <div class="float-left">
                    <sein-user-search id="supervisorSearch" [searchApiUrl]="restApiURL +'{searchWord}'"
                        [(ngModel)]="supervisorLookupModel" [defaultUserList]="userLookUpModelList"
                        [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                        placeholder="Search by name or email address" (onSelect)="supervisorOnSelect()">
                    </sein-user-search>
                </div>
            </div>
        </div>

        <div class="grid">

            <div class="col-6">
                <div class="labelInternal">
                    <span>Email</span>
                </div>
                <div class="float-left">
                    <input type="text" [(ngModel)]="recipientModel.Email" pInputText maxlength="200" readonly="true">
                </div>
            </div>
            <div class="col-6">
                <div class="labelInternal font-weight-bold">
                    <span>*Function</span>
                </div>
                <div class="float-left">
                    <p-dropdown *ngIf="functionList.length"  id="function" [options]="functionList" appendTo="body" placeholder="Select Function"
                        [(ngModel)]="selectedFunction" optionLabel="Name" 
                        (onChange)="functionChange()">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="grid">
            <div class="col-6">
                <div class="labelInternal font-weight-bold">
                    <span>*Country</span>
                </div>
                <div class="float-left">
                    <p-dropdown *ngIf="countryList.length"  [options]="countryList" appendTo="body" placeholder="Select Country"
                        [(ngModel)]="selectedCountry" optionLabel="Name" >
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="col-2 labelInternal"></div>
            <div *ngIf="recipientSelected" class="col-10">
                <p-card>
                    <p-header>
                        <div class="p-card-header">
                            <span>AD Address</span>
                        </div>
                    </p-header>
                    <div>
                        <div>{{addressModel.AddressLine1}}</div>
                        <div>{{addressModel.AddressLine2}}</div>
                        <div>{{addressModel.AddressLine3}}</div>
                        <div>{{addressModel.City}}, {{addressModel.State}} -
                            {{addressModel.PostalCode}}</div>
                        <div>{{addressModel.Country}}</div>
                        <div><span>Phone Number : </span> {{addressModel.MobilePhone}}</div>
                    </div>
                    <p-footer>
                        <div class="p-card-footer">
                            <p-checkbox *ngIf="false" labelStyleClass="addAddressToProfileCheckbox" binary="true"
                                label="Add address to profile" [(ngModel)]="addAddressToProfileCheckbox"></p-checkbox>
                        </div>
                    </p-footer>
                </p-card>
            </div>
        </div>
    </div>

    <p-footer>
        <div class="dialog-footer">
            <button pButton type="button" class="p-button-success" pButton icon="pi pi-save" label="Save"
                (click)="save()">
            </button>
            <button pButton type="button" label="Close" (click)="closeAddRecipientDialog()"></button>
        </div>
    </p-footer>
</p-dialog>