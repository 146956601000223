
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { filter, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { RecipientModel } from 'src/app/recipient-profile/recipient-profile/recipient.model';

@Injectable()
export class RecipientService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private options = { headers: this.headers };

  constructor(private _http: HttpClient) { }

  public create(recipientModel: RecipientModel, recipientType: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + recipientType;
    let body = JSON.stringify(recipientModel);
    return this._http.post(
      url,
      body, this.options)
      .pipe(map((response: Response) => response, console.log(Response)));
  }

  public update(recipientModel: RecipientModel, recipientType: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + recipientType;
    let body = JSON.stringify(recipientModel);
    return this._http.put(
      url,
      body, this.options)
      .pipe(map((response: Response) => response, console.log(Response)));
  }

  public getRecipients(recipientType: string,email:string): any {
    let url = ConfigurationSettings.REST_API_URL + "/users/" + email + "/recipients/" + recipientType;
    return this._http.get(url)
      .pipe(map((response: Response) => response, console.log(Response)));
  }

  public getRecipient(email: string, recipientType: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + email + "/" + recipientType;
    return this._http.get(url)
      .pipe(map((response: Response) => response, console.log(Response)));
  }

  public async getRecipientAsync(email: string, recipientType: string): Promise<any> {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + email + "/" + recipientType;
    return await this._http.get(url)
      .pipe(map((response: Response) => response, console.log(Response))).toPromise();
  }

  public async getRecipientExistsAsync(email: string): Promise<any> {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + email;
    return await this._http.get(url)
      .pipe(map((response: Response) => response, console.log(Response))).toPromise();
  }

  public getRecipientExists(email: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + email;
    return this._http.get(url)
      .pipe(map((response: Response) => response, console.log(Response)));
  }

}