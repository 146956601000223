<p-toast position="top-right"></p-toast>


<p-dataView #dv layout="list" [paginator]="true" [rows]="10" [value]="results" [filterBy]="filterByList"
    paginatorPosition="both" [hidden]="isHidden" pageLinks="5" [sortField]="sortField" [sortOrder]="sortOrder"
    [alwaysShowPaginator]="true" styleClass="hover search">

    <!-- <p-header> -->
    <!-- Search Results -->
    <!-- <div class="ui-helper-clearfix">
            <div class="ui-g">
                <div class="col-12 md:col-8">
                    <div class="position-relative">
                        <input type="search" pInputText placeholder="Filter By"
                            (input)="dv.filter($event.target.value)">
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By"
                        (onChange)="sortChange($event)" [style]="{'min-width':'140px','float':'right'}">
                    </p-dropdown>
                </div>
            </div>
        </div> -->

    <ng-template pTemplate="header">
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By"
                (onChange)="sortChange($event)" styleClass="p-mb-2 p-mb-md-0"></p-dropdown>
            <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                <i class="pi pi-search"></i>
                <input type="search" pInputText placeholder="Filter By" (input)="dv.filter($event.target.value)">
            </span>

        </div>
    </ng-template>


    <!-- </p-header> -->
    <ng-template let-searchResults pTemplate="listItem">
        <div class="col-12 ui-dataview-content ui-widget-content">
            <div class="ui-g">
                <div class="col-12">
                    <div class="grid resShadow">
                        <div class="col-1 request-type-vertical-text-stock">
                            {{searchResults.RequestTypeName}}
                        </div>
                        <div class="col-10">
                            <div class="col-12 padding-bottom-02em">
                                <span class="font-weight-bold">
                                    Material Name :
                                </span>{{searchResults.MaterialName}}
                            </div>
                            <div class="col-12 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Formulation Type :
                                </span>{{searchResults.FormulationType == 'NULL' ? '' : searchResults.FormulationType }}
                            </div>
                            <div class="col-12 padding-top-02em">
                                <span class="font-weight-bold float-left">
                                    Synonyms :
                                </span>
                                <span class="firstSynonym" id=span{{searchResults.MaterialName}}>
                                    {{searchResults.Synonyms.replace("||",', ')}}</span>
                            </div>
                        </div>

                        <div class="add-to-cart-button-width">
                            <div class="col-12">
                                <button pButton type="button" icon="pi pi-shopping-cart" label="Add to Cart"
                                    (click)="addToCart(searchResults)" class="p-button-success float-right"
                                    *ngIf="!searchResults.IsAdded && isRequesterForSelectedRequestType">
                                </button>
                                <button pButton type="button" icon="pi pi-shopping-cart" label="Added to Cart"
                                    class="p-button-warning float-right margin-top-0 cursor-no-drop"
                                    *ngIf="searchResults.IsAdded && isRequesterForSelectedRequestType"></button></div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="paginatorleft" let-state>
        <span class="ui-paginator-first padding-left-10px"> Total Items : {{results.length}}
            {{ dv.totalRecords == results.length ? '' : '(Showing Items: '+ dv.totalRecords + ')'}}
        </span>
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
        <span
            class="ui-paginator-first padding-right-10px">{{ dv.totalRecords > 0 ? (state.page * state.rows) + 1 : 0 }}
            -
            {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page + 1)) }}
            of {{dv.totalRecords}} Items</span>
    </ng-template>


</p-dataView>