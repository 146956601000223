import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../../configuration-settings"
import { RequestAdvanceSearch } from 'src/app/request-management/advanced-search/request-advance-search.model';

@Injectable()
export class AdvancedSearchService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    requestAdvanceSearch:RequestAdvanceSearch ;
    constructor(private _http: HttpClient) { }



    public getAdvancedSearchResult(year: string, requestStatusId: number[], recipientEmail: string[]
        , requesterEmail: string[], requestCreatedBy: number[], countryId: number[], requestTypeId: number[]
        , sampleName: string,recipientType: string,requestNumber:string): any {
        
    this.requestAdvanceSearch = new RequestAdvanceSearch ();
    this.requestAdvanceSearch.CountryIds = countryId;
    this.requestAdvanceSearch.RecipientEmails = recipientEmail;
    this.requestAdvanceSearch.Year = year;
    this.requestAdvanceSearch.RecipientType = recipientType;
    this.requestAdvanceSearch.RequestStatusId = requestStatusId;
    this.requestAdvanceSearch.RequestCreatedByList = requestCreatedBy;
    this.requestAdvanceSearch.RequestTypeIds  = requestTypeId;
    this.requestAdvanceSearch.SampleName  = sampleName;
    this.requestAdvanceSearch.RequestNumber  = requestNumber;
    this.requestAdvanceSearch.RequesterEmails  = requesterEmail;

 
        let url = ConfigurationSettings.REST_API_URL + "/advance-search/requests";
        return this._http.post(url,this.requestAdvanceSearch,this.httpOptions).pipe(
            tap(x => console.log("Fetched Requests")));
    }

}