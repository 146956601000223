<p-toast position="top-right"></p-toast>

<div class="col-12 resShadow">
    <div class="grid external-label">
        <span class="lbl-82px font-weight-bold">*Recipient</span>
        <div class="col-9 recipient">
            <span class="ui-fluid">
                <sein-user-search [id]="'recipientSearch'" class="address" [searchApiUrl]="restApiURL +'{searchWord}'"
                    [(ngModel)]="userLookUpModel" [defaultUserList]="userLookUpModelList"
                    [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                    placeholder="Search by name or email address" (onSelect)="recipientOnSelect()"
                    suggestionTemplateLine2Label="Company Name" suggestionTemplateLine2Field="LegalName">
                </sein-user-search>
            </span>
        </div>

        <span class="lbl-113px">Internal Contact &nbsp;</span>
        <input id="internalContactOnShipmentPage" class="float-left font-size-12px read-only" type="text"
            [(ngModel)]="recipientModel.InternalContactName" pInputText maxlength="250" disabled="true">

        <span class="emailLabel">Email &nbsp;</span>
        <input id="emailOnShipmentPage" class="email read-only" type="text" [(ngModel)]="recipientModel.Email"
            pInputText maxlength="250" disabled="true">

        <div class="col addNewRecipient">
            <a class="float-right" href="javascript:void(0)" (click)="showAddRecipientDialog()">+ Add New
                Recipient</a>
        </div>

    </div>


    <div class="grid external-label">
        <span class="lbl-82px">Company Name &nbsp;</span>
        <input id="legalNameOnShipmentPage" class="legalName read-only" type="text"
            [(ngModel)]="recipientModel.LegalName" pInputText maxlength="250" disabled="true">

        <span class="lbl-113px">Contact Number &nbsp;</span>
        <input id="contactNumberOnShipmentPage" class="float-left font-size-12px read-only" type="text"
            [(ngModel)]="recipientModel.ContactNumber" pInputText maxlength="250" disabled="true">
    </div>

    <div class="col-12" *ngIf="recipientModel.RecipientId > 1 ">
        <hr>
    </div>
    <p-messages [(value)]="msgs" key="addressNotification" class="messages"></p-messages>

    <div class="col-12 spacing" [ngStyle]="!recipientModel.IsActive ? {'pointer-events':'none'}: ''">
        <div class="col-12 header-bar">
            <span> Shipping Address </span>
        </div>

        <div class="grid">
            <div class="col-3"> <span *ngIf="addressList.length > 0 " class="selectedAddressLabel">Selected Address
                </span>
            </div>
            <div class="col-7"> <span *ngIf="addressList.length > 0 " class="availableAddressLabel">Available
                    Addresses</span>
            </div>
            <div class="col-2"> <a class="addAddressLink" href="javascript:void(0)"
                    (click)="showAddAddressDialog(true)">+ Add
                    Address</a></div>
        </div>

        <div class="grid" *ngIf="addressList.length > 0 ">
            <div class=" col-3 ui-widget">
                <div class="col-3" class="padding-5-px">
                    <div class="selected-address" [@selectedAddressAnimation]=AddressSelectionState
                        (@selectedAddressAnimation.done)="handleTransitionDone( $event )">
                        <div class="address-content">
                            <span class="font-weight-bold">{{shippingAddress.PersonFullName}}</span>

                            <div>{{shippingAddress.AddressLine1}}</div>
                            <div>{{shippingAddress.AddressLine2}}</div>
                            <div>{{shippingAddress.AddressLine3}}</div>
                            <div *ngIf="shippingAddress.AddressId > 0">{{shippingAddress.City}},
                                {{shippingAddress.State}} -
                                {{shippingAddress.PostalCode}}</div>
                            <div>{{shippingAddress.Country}}</div>
                            <div *ngIf="shippingAddress.AddressId > 0"><span>Phone Number : </span>
                                {{shippingAddress.Mobile}}</div>
                                <div>{{shippingAddress.OrgName}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel col-9">

                <p-carousel #addressCarousel [value]="addressList" [page]="page" [numVisible]="3" [numScroll]="3">
                    <ng-template let-address pTemplate="item">

                        <div class="col-3" class="padding-5-px">
                            <div [ngStyle]="address.IsDefaultAddress ? {'background-color':'aliceblue'}:''"
                                class="available-address" (click)="setRequestShipment(address, addressCarousel)">
                                <p id="bg-text" *ngIf="address.IsDefaultAddress">Default</p>
                                <div class="address-content">
                                    <span class="font-weight-bold">{{address.PersonFullName}}</span>
                                    <div>{{address.AddressLine1}}</div>
                                    <div>{{address.AddressLine2}}</div>
                                    <div>{{address.AddressLine3}}</div>
                                    <div>{{address.City}}, {{address.State}} - {{address.PostalCode}}</div>
                                    <div>{{address.Country}}</div>
                                    <div><span>Phone Number : </span> {{address.Mobile}}</div>
                                    <div>{{address.OrgName}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>

    <!-- Address Pop up -->
    <app-address [visible]="showAddressDialog" [addressModel]="addressModel" [recipientEmail]="recipientModel.Email"
        (eventToReceiveNewCreatedAddress)="receiveNewCreatedAddress($event,true)" [recipientType]="shippingOption"
        (eventToCloseAddressPopUp)="onAddressPopUpClose($event)" *ngIf="showAddressDialog == true"></app-address>

    <add-external-recipient-profile [visible]="showRecipientDialog"
        (eventToReceiveNewCreatedRecipient)="newRecipient($event)"
        (eventToCloseAddRecipientPopUp)="onCloseAddRecipientPopUp()"></add-external-recipient-profile>

</div>

<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>