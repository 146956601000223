<p-toast position="top-right"></p-toast>

<div class="col-12 resShadow mainDiv requester">
  <div class="grid">
    <!-- Requester search section -->
    <span class="col-2 font-weight-bold">*Requester</span>
    <div class="col-3">
      <span class="ui-fluid">
        <sein-user-search [id]="'requesterSearch'" class="address" [searchApiUrl]="restApiURL +'{searchWord}'"
          [(ngModel)]="requester" [defaultUserList]="requesterList" [ngModelOptions]="{standalone: true}"
          appendTo="body" [multiple]="false" placeholder="Search by name or email address"
          (onSelect)="requesterOnSelect()">
        </sein-user-search>
      </span>
    </div>
  </div>

  <!-- Contact number section  -->
  <div class="grid">
    <span class="col-2 font-weight-bold">*Contact Number </span>
    <div class="col-3">
      <input type="text" class="form-control input-box" (keypress)="validationService.contactNumberValidation($event)"
        [(ngModel)]="requesterInformationModel.ContactNumber" size=20 pInputText maxlength="30">
    </div>
  </div>

  <!-- Email Id section -->
  <div class="grid">
    <span class="col-2">Email ID </span>
    <div class="col-3">{{requesterInformationModel.Email}}</div>
  </div>

  <div class="grid">
    <div class="col-11"></div>
    <div class="col-1">
      <button pButton type="submit" class="p-button-success float-right" pButton icon="pi pi-save" label="Save"
        (click)="saveRequesterDetails()"> </button>
    </div>
  </div>
</div>