<div class="ui-g">
    <div class="grid padding-unset">
      <div class="col-4">
        <div class="col-12 resShadow recipientDetail min-height-230px">
          <div class="header-bar padding-8px">
            Requester Details
          </div>
          <div *ngIf="requesterInformationModel?.Email != undefined">
            <div class="grid shipmentType">
              <div class="col-5 columnLabel">Requester Type</div>
              <div class="col-4 internal-bar" *ngIf="!requesterInformationModel?.IsExternalAddress">
                Internal</div>
              <div class="col-4 external-bar" *ngIf="requesterInformationModel?.IsExternalAddress">
                External</div>
            </div>
            <div class="grid padding-unset"> 
                <span class="col-5 columnLabel">Requester</span> <span
                  class="col-7">{{requesterInformationModel?.DisplayName}}</span>
              </div>
            <div class="grid padding-unset">
              <div class="col-5 columnLabel">Email Id</div>
              <div class="col-7"> {{requesterInformationModel?.Email}}</div>
            </div>
            <div class="grid padding-unset">
              <div class="col-5 columnLabel">Contact Number</div>
              <div class="col-7">
                <span *ngIf="requestModel?.RequestStatus == 'Draft'"
                  [innerHTML]="requesterInformationModel?.ContactNumber != '' ? requesterInformationModel?.ContactNumber : '<span  class=mandatory-field>Contact Required</span>'">
                </span>
                <span *ngIf="requestModel?.RequestStatus != 'Draft'">
                  {{ requesterInformationModel?.ContactNumber }}
                </span>
              </div>
            </div>
            <div *ngIf="!requesterInformationModel?.IsExternalAddress">
              <div class="grid padding-unset">
                <div class="col-5 columnLabel">Function</div>
                <div class="col-7"> {{requestRecipientModel?.Function}}</div>
              </div>
              <div class="grid padding-unset">
                <div class="col-5 columnLabel">Supervisor</div>
                <div class="col-7"> {{requestRecipientModel?.SupervisorName}}</div>
              </div>
            </div>
            <div *ngIf="requesterInformationModel?.IsExternalAddress">
              <div class="grid padding-unset">
                <div class="col-5 columnLabel">Company Name</div>
                <div class="col-7"> {{requestRecipientModel?.LegalName}}</div>
              </div>
              <div class="grid padding-unset">
                <div class="col-5 columnLabel">Internal Contact</div>
                <div class="col-7"> {{requestRecipientModel?.InternalContactName}}</div>
              </div>
            </div>
             
          </div>
          <div class="col-12 text-align-center mandatory-field" *ngIf="requesterInformationModel?.Email == undefined">
            Requester Details Not Selected
          </div>
        </div>
      </div>
      <div class=" col-3">
        <div class=" col-12 resShadow min-height-230px">
          <div class="col-12 padding-8px header-bar">
            Requester Address
            <span class="mandatory-field float-right"
              *ngIf="requestModel?.RequestStatus == 'Draft' && (requesterAddress?.IsParentUpdated == true)">Address
              updated</span>
            <span class="mandatory-field float-right"
              *ngIf="requestModel?.RequestStatus == 'Draft' && (requesterAddress?.IsParentDeleted == true)">Address
              deleted</span>
          </div>
          <div class="col-12 selected-address" *ngIf="requesterAddress?.PersonFullName != undefined">
            <div class="address-content">
              <span class="font-weight-bold">{{requesterAddress?.PersonFullName}}</span>
              <div>{{requesterAddress?.AddressLine1}}</div>
              <div>{{requesterAddress?.AddressLine2}}</div>
              <div>{{requesterAddress?.AddressLine3}}</div>
              <div *ngIf="requesterAddress?.AddressId > 0">{{requesterAddress?.City}},
                {{requesterAddress?.State}} -
                {{requesterAddress?.PostalCode}}</div>
              <div>{{requesterAddress?.Country}}</div>
              <div *ngIf="requesterAddress?.AddressId > 0">
                {{requesterAddress?.Mobile}}</div>
                <div>{{requesterAddress.OrgName}}</div>
                
            </div>
          </div>
          <div  >
            
          <div class="col-12 text-align-center mandatory-field" *ngIf="requesterAddress?.PersonFullName == undefined">
            Requester Address Not Selected
          </div>
          <div class="col-12 text-align-center mandatory-field" *ngIf="requesterAddress?.Mobile===undefined||requesterAddress?.Mobile===null||requesterAddress?.Mobile===''">
            Requester address is missing contact phone#
          </div>
        </div>
      </div>
    </div>
      <div class="col-5">
        <div class="col-12 resShadow min-height-230px">
          <div class="col-12 header-bar padding-8px">
            Interested Parties
          </div>
  
          <table class="width-100percent" *ngIf="requestNotificationList.length > 0">
            <tbody>
              <tr *ngFor="let requestNotification of requestNotificationList;" class="tblRequestNotification">
                <td class="line-height-20px tdName">
                  {{requestNotification.FirstName}} {{requestNotification.LastName}}
                  <br />
                  <span class="font-italic">({{requestNotification.Email}})</span>
                </td>
                <td class="width-20px">
                </td>
                <td class="line-height-20px tdDescription">
                  {{requestNotification.Description}}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-12 text-align-center" *ngIf="requestNotificationList.length == 0">
            No records found
          </div>
        </div>
      </div>
    
  
  </div>