<p-toast position="top-right"></p-toast>
<div>
    <div class="col-12 requestmanagement advanced-search">
        <div class="ui-g search-row">
            <div class="grid ui-search-content">
                <div class="col-3">
                    <div class="p-inputgroup float-left">
                        <span class="p-inputgroup-addon">Recipient
                            Type</span>
                        <p-dropdown [options]="recipientTypeList" [showClear]="true" placeholder="Select recipient type"
                            [(ngModel)]="selectedRecipientType" optionLabel="label"
                            (onChange)="recipientTypeChange()"> </p-dropdown>
                    </div>
                </div>
                <div class="col-3">
                    <div class="p-inputgroup float-left ">
                        <span class="p-inputgroup-addon">Recipient</span>

                        <p-multiSelect [options]="recipientList" defaultLabel="Select Recipient"
                            [(ngModel)]="selectedRecipient" optionLabel="DisplayName"
                            appendTo="body" dataKey="Email"
                            [ngClass]="{'ui-multiselect-defalut-label-font':selectedRecipient.length == 0}">
                        </p-multiSelect>

                    </div>
                </div>
                <div class="col-3">

                    <div class="p-inputgroup float-left ">
                        <span class="p-inputgroup-addon">Request Type</span>
                        <p-multiSelect [options]="requestTypeList" defaultLabel="Select request type"
                            [(ngModel)]="selectedRequestType" optionLabel="Name"
                            appendTo="body"
                            [ngClass]="{'ui-multiselect-defalut-label-font':selectedRequestType.length == 0}">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="col-3">

                    <div class="p-inputgroup float-left">
                        <span class="p-inputgroup-addon">Request Status</span>
                        <p-multiSelect [options]="requestStatusTypeList" 
                        defaultLabel="Select request status"
                            [(ngModel)]="selectedRequestStatusTypeModel" optionLabel="Code"
                            appendTo="body"
                            [ngClass]="{'ui-multiselect-defalut-label-font':selectedRequestStatusTypeModel.length == 0}">
                        </p-multiSelect>
                        
                    </div>
                </div>
            </div>
        </div>


        <div class="ui-g search-row">
            <div class="grid ui-search-content">

                <div class="col-3">
                    <div class="p-inputgroup float-left">
                        <span class="p-inputgroup-addon">Request Created By</span>
                        <p-multiSelect [options]="requestCreatedByList" defaultLabel="Select request created by"
                            [(ngModel)]="selectedRequestCreatedBy" optionLabel="DisplayName"
                            appendTo="body"
                            [ngClass]="{'ui-multiselect-defalut-label-font':selectedRequestCreatedBy.length == 0}">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="col-3">
                    <div class="p-inputgroup float-left ">
                        <span class="p-inputgroup-addon">Requester</span>
                        <p-multiSelect [options]="requesterList" defaultLabel="Select requester"
                            [(ngModel)]="selectedRequester" optionLabel="DisplayName"
                            appendTo="body"
                            [ngClass]="{'ui-multiselect-defalut-label-font':selectedRequester.length == 0}">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="col-3">

                    <div class="p-inputgroup float-left ">
                        <span class="p-inputgroup-addon">Country</span>
                        <p-multiSelect [options]="countryList" appendTo="body" defaultLabel="Select country"
                            [(ngModel)]="selectedCountry" optionLabel="Name" dataKey="CountryId"
                            [ngClass]="{'ui-multiselect-defalut-label-font':selectedCountry.length == 0}">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="col-3">

                    <div class="p-inputgroup float-left">
                        <span class="p-inputgroup-addon">Year</span>
                        <p-dropdown [options]="yearList" [(ngModel)]="selectedYear" optionLabel="label"
                            >
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>


        <div class="ui-g search-row">
            <div class="grid ui-search-content">

                <div class="col-3">
                    <div class="p-inputgroup float-left">
                        <span class="p-inputgroup-addon">Sample Name</span>
                        <input id="requestType" type="text" [(ngModel)]="sampleText" pInputText
                            placeholder="Enter sample name" maxlength="250">
                    </div>
                </div>
                <div class="col-3">
                    <div class="p-inputgroup float-left">
                        <span class="p-inputgroup-addon">Request Number</span>
                        <input id="requestNumber" type="text" [(ngModel)]="requestNumber" 
                            pInputText placeholder="Enter request number" maxlength="12"
                            (paste)="onRequestNumberPaste($event)"   
                            (keypress)="AllowAlphaNumericDash($event)" >
                    </div>
                </div>
                <div class="col-3">
                    <div class="p-inputgroup float-left search-button">
                        <button pButton type="button" label="Search" (click)="getSearchResult()"></button>
                    </div>
                </div>

            </div>
        </div>
        <p-dataView #dv [paginator]="true"  [rows]="12"  [value]="requestManagementModelList"  layout="grid"
             paginatorPosition="both" pageLinks="5" styleClass="hover">
            <ng-template let-requestManagementModel pTemplate="gridItem">
                <div class="col-3 sm:col-4 md:col-4 lg:col-3 requestmanagement">
                    <!-- <div class="ui-dataview-content"> -->
                    <div class="card">
                        <div class="col-12 card-background" [ngClass]="getTitleStyle(requestManagementModel)">
                            <span class="card-header-left">{{requestManagementModel.RequestNumber}}</span>
                            <span
                                class="copied">{{(requestManagementModel.IsCopy && requestManagementModel.RequestStatus == requestStatusType.Draft) ? this.copiedText : ''}}</span>
                            <span class="card-header-right"
                                *ngIf="checkRequester(requestManagementModel.RequestTypeName)">

                                <i [ngClass]="requestManagementModel.IsFavorite ? 'pi pi-star-fill' : 'pi pi-star'" style="margin-left:3px;"
                                    (click)="favoriteClick(requestManagementModel)"
                                    title="{{requestManagementModel.IsFavorite ? 'Remove favorite':'Set as favorite'}}"></i>
                            </span>
                        </div>
                        <div class="cursor-pointer"
                            (click)="showRequest(requestManagementModel.RequestNumber,requestManagementModel.RequestTypeName)">
                            <div class="requester">
                                Requester: {{requestManagementModel.Requester}}
                            </div>
                            <div class="requester">
                                Recipient: {{requestManagementModel.Recipient}}
                            </div>
                            <div class="requestCreatedOn">
                                Request Created On:
                                {{requestManagementModel.RequestCreatedOn | date: "dd-MMM-yyyy"}}
                            </div>
                            <div class="requester">
                                Order Coordinator: <a (click)="doNothing($event)" *ngIf="requestManagementModel.OrderCoordinatorName!==null&&requestManagementModel.OrderCoordinatorName!==''&&requestManagementModel.OrderCoordinatorEmail!==null" href="mailto:{{requestManagementModel.OrderCoordinatorEmail}}" title="{{requestManagementModel.OrderCoordinatorName}}">{{requestManagementModel.OrderCoordinatorName}}</a>
                            </div>
                            <div class="materials grid">
                                <div class="col-fixed" style="width: 68px;"><span class="form-label-header">Materials:</span></div>
                                <div class="col form-label-content" style="display: inline-block;padding-left: 0.0rem !important;">
                                    <ul style="word-wrap:break-word;width: 100%; overflow-y: auto;height: 72px;max-height: 72px; overflow-x: hidden;">
                                    <li *ngFor="let m of requestManagementModel.Materials; let i=index">{{m}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 card-footer-background" [ngClass]="getTitleStyle(requestManagementModel)">
                            <span class="card-header-left">{{requestManagementModel.RequestTypeName}}</span>
                            <label class="card-header-right">
                                <i *ngIf="requestManagementModel.OrderSplitInAlchemist" class="pi align-text-bottom">
                                    <img src="/assets/primeng/images/code-branch-solid.svg" style="width: 1em; height: 1em;">
                                </i>
                                {{requestManagementModel.RequestStatus}}</label>
                            <span class="card-header-right pr-1" *ngIf="requestManagementModel.IsRecipeRequest">
                                <i class="fa fa-flask" style="font-size: 1.3rem;"></i>
                            </span>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </ng-template>

            <ng-template pTemplate="paginatorleft" let-state>
                <span *ngIf="requestManagementModelList !=null" class="ui-paginator-first">
                    Total Items : {{totalRecordCount}}
                    {{ dv.totalRecords == totalRecordCount ? '' : '(Showing Items: '+ dv.totalRecords + ')'}}
                </span>
            </ng-template>
            <ng-template pTemplate="paginatorright" let-state>
                <span class="ui-paginator-first">{{ dv.totalRecords > 0 ? (state.page * state.rows) + 1 : 0 }}
                    -
                    {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page + 1)) }}
                    of {{dv.totalRecords}} Items</span>
            </ng-template>
        </p-dataView>
    </div>


</div>

<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>