import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigurationSettings } from 'src/app/configuration-settings';


@Injectable()
export class MenuService
{
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private options = { headers: this.headers };
  
    constructor(private _http: HttpClient) { }

    public getMenuByEmail(email:string):any
    {
        let url = ConfigurationSettings.REST_API_URL + "/users/" + email + "/menus";
        return this._http.get(url)
          .pipe(map((response: Response) => response, console.log(Response)));
    }
}