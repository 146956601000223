<p-toast position="top-right"></p-toast>
<div>
    <div class="col-12 requestmanagement advanced-search">
        <div class="ui-g filterDiv">
            <div class="ui-filter-content">

                <div class="p-inputgroup float-left">
                    <span class="p-inputgroup-addon">Recipient</span>
                    <input id="inputRecipientFilter" type="search" pInputText placeholder="Filter By Recipient"
                        (input)="filter('Recipient',$event.target.value, this.filterColumns,this.dataList)">
                </div>
                <div class="p-inputgroup float-left">
                    <span class="p-inputgroup-addon">Request Status</span>
                    <p-multiSelect [options]="requestStatusTypeList"
                    defaultLabel="Select request status"
                        [(ngModel)]="selectedRequestStatusTypeModel" optionLabel="Code"
                        appendTo="body"
                        (onChange)="filter('RequestStatus',selectedRequestStatusTypeModel, this.filterColumns,this.dataList)"
                        [ngClass]="{'ui-multiselect-defalut-label-font':selectedRequestStatusTypeModel.length == 0}">
                    </p-multiSelect>
                </div>
                <div class="p-inputgroup float-left">
                    <span class="p-inputgroup-addon">Year</span>
                    <input id="inputYearFilter" class="year" type="search" pInputText placeholder="Filter By Year"
                        (input)="filter('Year',$event.target.value, this.filterColumns,this.dataList)">
                </div>

                <div class="p-inputgroup float-left">
                    <span class="p-inputgroup-addon">Request Type</span>
                    <input id="inputRequestTypeNameFilter" type="search" pInputText placeholder="Filter By Request Type"
                        (input)="filter('RequestTypeName',$event.target.value, this.filterColumns,this.dataList)">
                </div>

                <div class="p-inputgroup p-inputgroup-addon ui-inputtext ui-corner-all float-left">
                    <input id="inputIsCopyFilter" class="copyCheckbox" type="checkbox"
                        (click)="filter('IsCopy',$event.target.checked, this.filterColumns,this.dataList)">
                    &nbsp; Show Copied Requests
                </div>

                <div class="clearAll">
                    <a style="cursor:pointer" (click)="clearFilter()">Clear All Filters</a>
                </div>

            </div>
        </div>

        <p-dataView #dv [paginator]="true"  [rows]="12"  [value]="requestManagementModelList"  layout="grid"
             paginatorPosition="both" pageLinks="5" styleClass="hover">
            <ng-template let-requestManagementModel pTemplate="gridItem">
                    <div class="col-3 sm:col-4 md:col-4 lg:col-3 requestmanagement">
                    <!-- <div class="ui-dataview-content"> -->
                    <div class="card">
                        <div class="col-12 card-background" [ngClass]="getTitleStyle(requestManagementModel)">
                            <span class="card-header-left">{{requestManagementModel.RequestNumber}}</span>
                            <span
                                class="copied">{{(requestManagementModel.IsCopy && requestManagementModel.RequestStatus == requestStatusType.Draft) ? this.copiedText : ''}}</span>

                            <span class="card-header-right"
                                *ngIf="checkRequester(requestManagementModel.RequestTypeName)">

                                <i class="pi pi-trash icon" title="Delete"
                                    *ngIf="requestManagementModel.RequestStatus == requestStatusType.Draft 
                                    || requestManagementModel.RequestStatus == requestStatusType.MaterialApproval
                                    || requestManagementModel.RequestStatus == requestStatusType.ITCRejected
                                    || requestManagementModel.RequestStatus == requestStatusType.AwaitingITC"
                                    (click)="deleteRequest(requestManagementModel.RequestNumber,requestManagementModel.RequestTypeName)"></i>

                                <i [ngClass]="requestManagementModel.IsFavorite ? 'pi pi-star-fill' : 'pi pi-star'" style="margin-left:3px;"
                                    (click)="favoriteClick(requestManagementModel)"
                                    title="{{requestManagementModel.IsFavorite ? 'Remove favorite':'Set as favorite'}}"></i>

                            </span>
                        </div>
                        <div class="cursor-pointer"
                            (click)="showRequest(requestManagementModel.RequestNumber,requestManagementModel.RequestTypeName)">
                            <div class="requester">
                                Requester: {{requestManagementModel.Requester}}
                            </div>
                            <div class="requester">
                                Recipient: {{requestManagementModel.Recipient}}
                            </div>
                            <div class="requestCreatedOn">
                                Request Created On:
                                {{requestManagementModel.RequestCreatedOn | date: "dd-MMM-yyyy"}}
                            </div>
                            <div class="requester">
                                Order Coordinator: <a (click)="doNothing($event)" *ngIf="requestManagementModel.OrderCoordinatorName!==null&&requestManagementModel.OrderCoordinatorName!==''&&requestManagementModel.OrderCoordinatorEmail!==null" href="mailto:{{requestManagementModel.OrderCoordinatorEmail}}" title="{{requestManagementModel.OrderCoordinatorName}}">{{requestManagementModel.OrderCoordinatorName}}</a>
                            </div>
                            <div class="materials grid">
                                <div class="col-fixed" style="width: 68px;"><span class="form-label-header">Materials:</span></div>
                                <div class="col form-label-content" style="display: inline-block;padding-left: 0.0rem !important;">
                                    <ul style="word-wrap:break-word;width: 100%; overflow-y: auto;height: 72px;max-height: 72px; overflow-x: hidden;">
                                    <li *ngFor="let m of requestManagementModel.Materials; let i=index">{{m}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 card-footer-background" [ngClass]="getTitleStyle(requestManagementModel)">
                            <span class="card-header-left">{{requestManagementModel.RequestTypeName}}</span>
                            <span class="card-header-right">
                                <i *ngIf="requestManagementModel.OrderSplitInAlchemist" class="pi align-text-bottom">
                                    <img src="/assets/primeng/images/code-branch-solid.svg" style="width: 1em; height: 1em;">
                                </i>
                                {{requestManagementModel.RequestStatus}}
                            </span>
                            <span class="card-header-right pr-1" *ngIf="requestManagementModel.IsRecipeRequest">
                                <i class="fa fa-flask" style="font-size: 1.3rem;"></i>
                            </span>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft" let-state>
                <span *ngIf="requestManagementModelList !=null" class="ui-paginator-first">
                    Total Items : {{totalRecordCount}}
                    {{ dv.totalRecords == totalRecordCount ? '' : '(Showing Items: '+ dv.totalRecords + ')'}}
                </span>
            </ng-template>
            <ng-template pTemplate="paginatorright" let-state>
                <span class="ui-paginator-first">{{ dv.totalRecords > 0 ? (state.page * state.rows) + 1 : 0 }}
                    -
                    {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page + 1)) }}
                    of {{dv.totalRecords}} Items</span>
            </ng-template>
        </p-dataView>
    </div>
</div>
<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>