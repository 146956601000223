import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestModel } from '../../request/request.model';
import { RequestNotificationService } from 'src/app/services/request-notification-service/request-notification.service';
import { RequestNotificationModel } from '../../request-notification/request-notification.model';
import { MessageService } from 'primeng/api';
import { RequestRecipientModel } from 'src/app/request-recipient/request-recipient.model';
import { RequestService } from 'src/app/services/request-service/request.service';
import { RecipientModel } from 'src/app/recipient-profile/recipient-profile/recipient.model';
import { AddressModel } from 'src/app/address/address.model';


@Component({
  selector: 'app-shipping-address-details',
  templateUrl: './shipping-address-details.component.html',
  styleUrls: ['./shipping-address-details.component.css'],
  providers: [RequestNotificationService, MessageService, RequestService]
})
export class ShippingAddressDetailsComponent implements OnInit {

  @Input() requestModel: RequestModel;
  @Output() eventToReturnRecipientModel = new EventEmitter<RecipientModel>();
  requestNotificationList: RequestNotificationModel[] = [];
  requestRecipientModel: RequestRecipientModel;
  public recipientModel: RecipientModel = { RecipientId: 0 };
  public serviceResponse: any;

  public shippingAddress: AddressModel = {
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0
  };

  constructor(private requestNotificationService: RequestNotificationService,
    private messageService: MessageService,
    private requestService: RequestService) {

  }

  ngOnInit() {
    this.getUsersToBeNotified();
    this.getRequestRecipient();
  }

  public getUsersToBeNotified() {
    this.requestNotificationService.getUsersToBeNotified(this.requestModel.RequestNumber).subscribe(response => {
      this.requestNotificationList = response;
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
      })
  }

  public getRequestRecipient() {
    if (this.requestModel.ShippingOption != "") {
      this.requestService.getRequestShippingDetail(this.requestModel.ShippingOption, this.requestModel.RequestNumber).subscribe(response => this.serviceResponse = response,
        error => {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        },
        () => {
          this.requestRecipientModel = this.serviceResponse;
          if (this.requestRecipientModel.Recipient.RecipientId > 0) {
            this.recipientModel = this.requestRecipientModel.Recipient;
            this.recipientModel.ContactNumber = this.recipientModel.ContactNumber == null ? '' : this.recipientModel.ContactNumber;
            this.shippingAddress = this.requestRecipientModel.ShipmentAddressModel;
            this.shippingAddress.PersonFullName = this.recipientModel.DisplayName;
            this.eventToReturnRecipientModel.emit(this.recipientModel);
          }
        })
    }
  }
}