

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../../configuration-settings"
import { FavouriteModel } from 'src/app/request-management/favourites/favourite.model';


@Injectable()
export class FavouriteService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public async create(requestNumber: string, favouriteModel: FavouriteModel): Promise<any> {
        let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/favorites";
        let body = JSON.stringify(favouriteModel);
        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new favourite"))
            ).toPromise();
    }

    public getFavourites(userId: bigint): any {
        let url = ConfigurationSettings.REST_API_URL + "/users/" + userId + "/requests/favorites";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Favourite Requests")));
    }

    public async delete(favoriteid: bigint): Promise<any> {
        let url = ConfigurationSettings.REST_API_URL + "/favorites/" + favoriteid;
        return this._http.delete(url).pipe(
            tap(x => console.log("Favourite Deleted"))).toPromise();
    }

}