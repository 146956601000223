import { DocumentType } from '../request-item-attachments/document-type.model';
export class RequestItemAttachmentModel{
    RequestItemAttachmentId? :number
    RequestItemId? :number
    FileName? :string
    File? :File
    Comment? :string
    CreatedDate? :Date
    CreatedBy? :number
    ModifiedDate? :Date
    ModifiedBy? :number
    DocumentTypeId:number;
    DocumentType:DocumentType;
}
