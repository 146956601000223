
import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { RequestNotificationModel } from './request-notification.model';
import { RequestNotificationService } from 'src/app/services/request-notification-service/request-notification.service';
import { RequestModel } from '../request/request.model';
import { UserLookUpModel } from 'src/app/user-look-up/user-look-up.model';
import { ConfigurationSettings } from 'src/app/configuration-settings';



@Component({
  selector: 'app-request-notification',
  templateUrl: './request-notification.component.html',
  styleUrls: ['./request-notification.component.css'],
  providers: [RequestNotificationService, ConfirmationService, MessageService, RequestNotificationService]
})

export class RequestNotificationComponent implements OnInit {
  @Input() requestModel: RequestModel;
  @Input() notificationHeader: String;
  restApiURL: string;

  selectedDescription: string;

  requestNotificationList: RequestNotificationModel[] = [];
  requestNotification: RequestNotificationModel;
  errorMessageforUserToBeNotified: string;
  divforErrorMessageNotification: boolean = false;

  notifyUserList: UserLookUpModel[] = [];
  notifyUser: UserLookUpModel;



  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private requestNotificationService: RequestNotificationService) { }

  ngOnInit() {
    this.getUsersToBeNotified();
    this.restApiURL = ConfigurationSettings.REST_API_URL + "/azure-ad/users/search?includeDistributionLists=true&searchString=";
    this.notifyUser = new UserLookUpModel;
    console.log('notification header is ' + this.notificationHeader);
  }

  //-----------------------------------
  // Gets the list of user notifications 
  //------------------------------------
  public getUsersToBeNotified() {
    this.requestNotificationService.getUsersToBeNotified(this.requestModel.RequestNumber).subscribe(response => {
      this.requestNotificationList = response;
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
      })
  }

  //--------------------------------------------------
  // Adds a user to be notified along with description
  //--------------------------------------------------
  public addUserToNotify() {
    if (!this.validationForAddUserToBeNotified()) {
      return;
    };
    var requestNotification = new RequestNotificationModel;
    requestNotification.Description = this.selectedDescription;
    requestNotification.Email = this.notifyUser.Email;
    requestNotification.FirstName = this.notifyUser.FirstName;
    requestNotification.MiddleName = this.notifyUser.MiddleName;
    requestNotification.LastName = this.notifyUser.LastName;
    requestNotification.RequestId = this.requestModel.RequestId;
    requestNotification.IsRequestNotificationDeleted = false;
    this.requestNotificationService.addUserToBeNotified(this.requestModel.RequestNumber, requestNotification).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'User to be notified has been added', detail: '', life: 500 });
      this.getUsersToBeNotified();
      this.selectedDescription = "";
      this.ngOnInit();
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      })
  }

  //---------------------------------------------------------
  // Validations on click of add button for user notification
  //---------------------------------------------------------

  public validationForAddUserToBeNotified(): boolean {
    this.divforErrorMessageNotification = false;
    this.errorMessageforUserToBeNotified = "";
    if (this.notifyUser == null || this.notifyUser == undefined || this.notifyUser.toString() == "" || this.notifyUser.DisplayName == undefined) {
      this.divforErrorMessageNotification = true;
      this.errorMessageforUserToBeNotified = "Please select a user"
      return false;
    }
    return true;
  }

  //----------------------------------------------------
  // Removes a user to be notified along with description
  //-----------------------------------------------------
  public removeUserToBeNotified(requestNotification: RequestNotificationModel) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the user?',
      header: 'Remove Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: "removeUserConfirmDialog",
      accept: () => {
        this.requestNotificationService.removeUserToBeNotified(this.requestModel.RequestNumber, requestNotification.RequestNotificationId).subscribe(response => {
          this.getUsersToBeNotified();
          this.messageService.add({ severity: 'success', summary: 'User to be notified has been removed', detail: '', life: 500 });
        },
          error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while removing' });
          })
      },
      reject: () => {

      }
    });

  }
}

