<p-toast position="top-right"></p-toast>
<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>

<p-dialog [contentStyle]="contentStyleObject"  [(visible)]="showSuggestedAddress" [modal]="true" [closeOnEscape]="false">
  <p-header>
    <span class="dialog-header-text">Suggested Address</span>
  </p-header>
  <div class="row d-flex align-items-center px-3">
    <i class="pi pi-exclamation-circle" style="font-size: 3rem !important;color: #0072ce !important;"></i>
    <label class="mx-3">USPS recommends the following address:</label>    
  </div>
  <div class="row">
    <div class="col-6 ui-widget">
      <div class="col-6" class="padding-5-px">
        <div class="selected-address" >
          <div class="address-content">
            <span class="font-weight-bold">Entered Address:</span>
            <div>{{addressModel.AddressLine1}}</div>
            <div>{{addressModel.AddressLine2}}</div>
            <div>{{addressModel.AddressLine3}}</div>
            <div>{{addressModel.City}}, {{addressModel.State}} -
              {{addressModel.PostalCode}}</div>
            <div><a style="text-decoration: underline;" class="cursor-pointer" (click)="closePopUp()">Edit Address</a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 ui-widget">
      <div class="col-6" class="padding-5-px">
        <div class="available-address">
          <div class="address-content">
            <span class="font-weight-bold">Suggested Address:</span>
            <div>{{validatedAddress.AddressLine1}}</div>
            <div>{{validatedAddress.AddressLine2}}</div>
            <div>{{validatedAddress.AddressLine3}}</div>
            <div>{{validatedAddress.City}}, {{validatedAddress.State}} -
              {{validatedAddress.PostalCode}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <p-footer>
    <div class="form-group dialog-footer">
      <a style="text-decoration: underline;" class="cursor-pointer mr-4" (click)="UseEnteredAddress()">Use Entered Address</a>
      <button pButton type="button" class="p-button-primary" pButton icon="pi pi-check" label="Accept USPS Address" (click)="AcceptUSPSAddress()"></button>
    </div>
  </p-footer>
</p-dialog>

<form [formGroup]="addressForm" (ngSubmit)="saveAddress()" class="formStyle">
  <p-dialog [contentStyle]="contentStyleObject" [draggable]="true" [modal]="true" [closeOnEscape]="false"
    class="addressDialog" [(visible)]="visible" positionLeft=500 [closable]="false">

    <p-header>
      <span class="dialog-header-text" *ngIf="addressModel.AddressId == 0">New Address</span>
      <span class="dialog-header-text" *ngIf="addressModel.AddressId > 0">Edit Address</span>
    </p-header>
    <div class="col-12" *ngIf="DisplayCommanError()">
      <div class="col-12 invalid-feedback">
        
    {{ errorMessage }}
  </div>
    </div>
    <div class="col-12">
      <div class="grid p-align-start">
        <span class="lbl font-weight-bold">*Country</span>
        <div class="dropdownCSS">
          <p-dropdown formControlName="country" class="form-control" appendTo="body"
            [ngClass]="{ 'is-invalid': submitted && f.country.errors }" [filter]="true" [options]="countryList"
            placeholder="Select Country" [(ngModel)]="selectedCountry" optionLabel="Name" 
            dataKey="CountryId" [disabled]="disableNonEditableField" (onChange)="GetStateAndCityFromZip()">
          </p-dropdown>
          
          <!-- To Show Required field validation message for country-->
          <span *ngIf="submitted && f.country.errors && f.country.errors.required" class="col-12 invalid-feedback">
            Country is required
          </span>
        </div>
      </div>
      <div class="grid p-align-start">
        <span class="lbl font-weight-bold">*Address Line 1</span>
        <div>
          <input type="text" formControlName="addressLine1" class="input-box"
            [ngClass]="{ 'is-invalid': ( submitted && f.addressLine1.errors) || errorMessage.indexOf('Address') !== -1 }" [(ngModel)]="addressModel.AddressLine1"
            size=50 pInputText maxlength="100" (focus)="clearErroMessage('Address')">
          <!-- To Show Required field validation message for Address Line 1 -->
          <span *ngIf="submitted && f.addressLine1.errors && f.addressLine1.errors.required"
            class="col-12 invalid-feedback">
            Address Line 1 is required
          </span>
          <span *ngIf="errorMessage.indexOf('Address') !== -1"
            class="col-12 invalid-feedback">
            {{errorMessage}}
          </span>
        </div>
      </div>

      <div class="grid p-align-start">
        <span class="lbl">Address Line 2</span>
        <input type="text" formControlName="addressLine2" [(ngModel)]="addressModel.AddressLine2" class="input-box"
          size=50 pInputText maxlength="200">
      </div>

      <div class="grid p-align-start">
        <span class="lbl">Address Line 3</span>
        <input type="text" formControlName="addressLine3" [(ngModel)]="addressModel.AddressLine3" class="input-box"
          size=50 pInputText maxlength="200">
      </div>
      

      <div class="grid p-align-start">
        <span class="lbl font-weight-bold">*Postal/Zip Code</span>
        <div>
          <input type="text" formControlName="zipCode" class="input-box"
            [ngClass]="{ 'is-invalid': (submitted && f.zipCode.errors) || errorMessage.indexOf('Zip') !== -1 }" [(ngModel)]="addressModel.PostalCode" size=20
            pInputText maxlength="10" (focus)="clearErroMessage('Zip')" (change)="GetStateAndCityFromZip()">
          <!-- To Show Required field validation message for zip code -->
          <span *ngIf="submitted && f.zipCode.errors && f.zipCode.errors.required" class="col-12 invalid-feedback">
            Zip Code is required
          </span>
          <span *ngIf="errorMessage.indexOf('Zip') !== -1"
            class="col-12 invalid-feedback">
            {{errorMessage}}
          </span>
        </div>
      </div>

      <div class="grid p-align-start">
        <span class="lbl font-weight-bold">*City</span>
        <div>
          <input type="text" formControlName="city" class="input-box"
            [ngClass]="{ 'is-invalid': (submitted && f.city.errors ) || errorMessage.indexOf('City') !== -1 }" [(ngModel)]="addressModel.City" size=20 pInputText
            maxlength="50" (focus)="clearErroMessage('City')">
          <!-- To Show Required field validation message for City -->
          <span *ngIf="submitted && f.city.errors && f.city.errors.required" class="col-12 invalid-feedback">
            City is required
          </span>

          <span *ngIf="errorMessage.indexOf('City') !== -1"
            class="col-12 invalid-feedback">
            {{errorMessage}}
          </span>
        </div>
      </div>
 
      <div class="grid p-align-start">
        <span *ngIf="!isStateRequired" class="lbl">State/Province</span>
        <span *ngIf="isStateRequired" class="lbl font-weight-bold">*State/Province</span>
        <div>
          <input type="text" formControlName="state" class="input-box"
            [ngClass]="{ 'is-invalid': (submitted && f.state.errors) || errorMessage.indexOf('State') !== -1 }" [(ngModel)]="addressModel.State" size=20
            pInputText maxlength="50" (focus)="clearErroMessage('State')">
            <span *ngIf="isStateRequired && submitted && f.state.errors" class="col-12 invalid-feedback">
              State is required
            </span>
            <span *ngIf="errorMessage.indexOf('State') !== -1"
            class="col-12 invalid-feedback">
            {{errorMessage}}
          </span>
        </div>
      </div>
      <div class="grid p-align-start" >
        <span class="lbl font-weight-bold">*Company</span>
        <div>
          <input type="text" formControlName="company" class="input-box"
            [ngClass]="{ 'is-invalid': submitted && f.company.errors }" [(ngModel)]="addressModel.OrgName" size=50
            pInputText maxlength="50" (keypress)="RestrictUnwantedCharecters($event)">
          <!-- To Show Required field validation message for zip codecompany -->
          <span *ngIf="submitted && f.company.errors && f.company.errors.required" class="col-12 invalid-feedback">
            Company is required
          </span>
        </div>
      </div>
      <div class="grid p-align-start">
        <span class="lbl font-weight-bold">*Contact Number</span>
        <div>
          <input type="text" (keypress)="validationService.contactNumberValidation($event)"
            formControlName="contactNumber" class="input-box"
            [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" [(ngModel)]="addressModel.Mobile" size=20
            pInputText maxlength="30" [attr.disabled]="disableNonEditableField?'disabled':null">
          <!-- To Show Required field validation message for Contact No -->
          <span *ngIf="submitted && f.contactNumber.errors && f.contactNumber.errors.required"
            class="col-12 invalid-feedback">
            Contact Number is required
          </span>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="form-group dialog-footer">
        <button pButton type="submit" class="p-button-success" pButton icon="pi pi-save" label="Save"
          *ngIf="addressModel.AddressId == 0" [disabled]="!addressForm.valid"></button>
        <button pButton type="submit" class="p-button-success" pButton icon="pi pi-save" label="Update"
          *ngIf="addressModel.AddressId > 0" [disabled]="!addressForm.valid"></button>
        <button pButton type="button" label="Close" (click)="closeAddressDialog()"></button>
      </div>
    </p-footer>
  </p-dialog>
</form>
