<div class="grid">
    <div class="md:col-3"><h4 style="font-weight: bold;">Approval</h4></div>
</div>

<div *ngIf="materialApprovalModel">
  <p-dataView #dv [value]="materialApprovalModel" id="materialApprovalView" [paginator]="true" [rows]="PAGE_SIZE" [lazy]="true"
    [totalRecords]="totalCount" pageLinkSize="3" (onPage)="pageChanged($event)" paginatorPosition="bottom" layout="list"
    styleClass="hover">

    <ng-template pTemplate="header">
<div class="grid" style="width: 100%;color:black">
    <div class="col-1 md:col-1 header-column" style="text-align: left;">
      <label style="font-weight: bold;text-align: center;">Material ID</label>
    </div>
    <div class="col-1 md:col-1 header-column" style="text-align: left;">
      <label style="font-weight: bold;text-align: center;">Request Number</label>
    </div>
    <div class="col-1 md:col-1 header-column" style="text-align: left;">
        <label style="font-weight: bold;text-align: center;">Lot Number/ID</label>
      </div>
      <div class="col-1 md:col-1 quantity header-column" style="text-align: right;">
      <label style="font-weight: bold;text-align: center;">Quantity</label>
    </div>
    <div class="col-1 md:col-1 header-column" style="text-align: left;" >
        <label style="font-weight: bold;text-align: center;">Requester</label>
    </div>
    <div class="col-1 md:col-1 header-column" style="text-align: left;">
        <label style="font-weight: bold;text-align: center;">Recipient</label>
      </div>
    <div class="col-2 md:col-2 header-column" style="text-align: left;" *ngIf="isAdmin">
        <label style="font-weight: bold;text-align: center;">Approver</label>
      </div>
    <div class="col-2 md:col-2 header-column" style="text-align: left;" >
      <label style="font-weight: bold;text-align: center;">Destinations</label>
    </div>
    <div class="col-2 md:col-2 header-column" style="text-align: left;">
      <label style="font-weight: bold;text-align: center;">Actions</label>
    </div>
</div>
</ng-template>
<ng-template let-requestItem pTemplate="listItem" let-i="rowIndex">
    <div class="grid" [ngStyle]="{'width': '100%','min-height':'40px','margin-left':'0px','margin-bottom':'5px','background-color':(i%2==0 ? 'white' : 'lightgray'),'color':(i%2==0 ? 'black' : 'black')}">
        <div class="col-1 md:col-1 col-value"  style="text-align: left;">
          <div><a [routerLink]="['/request-types/'+requestItem.RequestType + '/request/' + requestItem.RequestNumber]" target="_blank" style="text-decoration: underline">{{requestItem.Material}}</a></div>
        </div>
        <div class="col-1 md:col-1 col-value" style="text-align: left;">
          <div><a [routerLink]="['/request-types/'+requestItem.RequestType + '/request/' + requestItem.RequestNumber]" target="_blank" style="text-decoration: underline">{{requestItem.RequestNumber}}</a></div>
        </div>
        <div class="col-1 md:col-1 col-value" style="text-align: left;">
          <div>{{requestItem.LotNumber}}</div>
        </div>
        <div class="col-1 md:col-1 col-value quantity"  style="text-align: right;">
          <div>{{requestItem.Quantity | number : '1.3-3'}} {{requestItem.Uom}}</div>
        </div>
        <div class="col-1 md:col-1 col-value" style="text-align: left;">
            <a href="mailto:{{requestItem.RequesterEmail}}">
              {{requestItem.Requester}}
           </a>
        </div>
        <div class="col-1 md:col-1 col-value" style="text-align: left;">
            <div>{{requestItem.Recipient}}</div>
        </div>
        <div class="col-2 md:col-2 col-value" style="text-align: left;" *ngIf="isAdmin">
          <div>
            <a href="mailto:{{requestItem.ApproverEmail}}">
               {{requestItem.ApproverName ?? requestItem.ApproverEmail}}
            </a>
        </div>
        </div>
        <div class="col-2 md:col-2 col-value" style="text-align: left;">
            <div>{{requestItem.Destination}}</div>
        </div>
        <div class="col-2 md:col-2">
            <div class="grid">
                <div class="col-6 md:col-6">
                    <button pButton type="button" label="Reject" (click)="rejectItem(requestItem.RequestItemId)"
                    style="margin-left:-10px;margin-top:2px;background-color: #d33f49 !important;color:white;border:none"
                    ></button>
                </div>
                  <div class="col-6 md:col-6" style="margin-left:-60px;">
                    <button pButton type="button" label="Approve" (click)="approveRequestItem(requestItem.RequestItemId)"
                    style="margin-left:3px;margin-top:2px;background-color:#0072ce;color:white;border:none"></button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template pTemplate="paginatorleft" let-state id="paginatorleft2">
  <span class="ui-paginator-first" style="padding-left:10px;"> Total Items : {{totalCount}}
    {{ totalCount == materialApprovalModel.length ? '' : '(Showing Items: '+ materialApprovalModel.length + ')'}} </span>
</ng-template>
<ng-template pTemplate="paginatorright" id="paginatorright2" let-state>
  <span class="ui-paginator-first" style="padding-right:10px;">{{ totalCount > 0 ? ((pageIndex - 1) * PAGE_SIZE) + 1 : ''}}{{totalCount > 0 ? '-' : '' }}
    {{(materialApprovalModel.length !== PAGE_SIZE ? totalCount : pageIndex * PAGE_SIZE)}} of {{totalCount}} Items</span>
</ng-template>
</p-dataView>
</div>

<p-dialog id="dialogApprove" [resizable]="false"  [(visible)]="confirmationMessageDialog"
[styleClass]="'clsApproveDialog'" [modal]="true" [closable]="false" (onHide)="onHide()">
<ng-template pTemplate="header" style="height:45px;">
    <div class="grid" style="width: 100%">
      <div class="col-11"><span style="font-weight:bold;">Confirmation Message</span></div>
      <div class="col-1">
        <button pButton type="button" icon="pi pi-times" style="float: right;margin-right:-30px;background-color:#007ad9 !important;border: none;" (click)="confirmationMessageDialog = false"></button>
      </div>
    </div>
  </ng-template>
  <div class="grid">
  <div class="md:col-12">
    <div class="grid">
      <i class="pi pi-exclamation-triangle" style="font-size: 3rem !important;color: #0072ce !important;"></i><label
        style="margin-top: 9px;">Are you sure you want to approve this request?</label>
    </div>
    <div *ngIf="isAdmin && !isApprover" class="form-group grid" style="margin-top: 25px;">
      <div>Comment</div>
      <textarea id="w3review" name="w3review" rows="4" cols="50" style="width: 100%;" [(ngModel)]="approvalComment" (keyup)="onCommentChange()"></textarea>
    </div>
  </div>
</div>
<p-footer>
  <div class="footer">
    <div style="padding-left: 3px;"></div>
    <div>
      <p-checkbox *ngIf="isApprover" id="chkShowApproveDialog" style="padding-left: 5px;" name="chkShowApproveDialog" [(ngModel)]="dontShowConfirmation" binary="true"></p-checkbox>
      <label *ngIf="isApprover" style="padding-left: 3px;margin-top: 3px;" for="dontShowConfirmation">Don't show this message again</label>
    </div>
    <div style="padding-left: 23px;"></div>
    <div >
      <button style="background-color: #0072ce !important;color:white" type="button" pButton icon="pi pi-times"
        id="btnReject" (click)="confirmationMessageDialog = false" label="Cancel"></button>
      <button type="button" style="margin-left: 10px;background-color: #0072ce !important;color:white" pButton
        icon="pi pi-check" id="btnReturnForReview" (click)="approveItem()" label="Approve" [disabled]="!isValid"></button>
    </div>
  </div>
</p-footer>
</p-dialog>
<p-dialog id="dialogRejectComment" [resizable]="false"[(visible)]="showRejectCommentDialog"
[styleClass]="'clsDialog'" [closable]="false"
 [modal]="true">
 <ng-template pTemplate="header">
    <div class="grid" style="width: 100%;margin-top:0px !important;">
      <div class="col-11"><span style="font-weight:bold;">Reject/Review Request</span></div>
      <div class="col-1" >
        <button pButton type="button" icon="pi pi-times" style="float: right;margin-right:-30px;background-color:#007ad9 !important;border: none;" (click)="showRejectCommentDialog = false"></button>
      </div>
  </div>
</ng-template>
  <div class="grid">
    <div class="md:col-12"><h6 style="font-weight: bold;">*Please select a reject reason</h6></div>
    </div>
    <div class="grid" style="margin-top:5px;">
      <div class="md:col-12">
          <p-dropdown [style]="{'minWidth':'300px'}"  id="ddrOrderBy" [options]="rejectReasons" optionLabel="name"
          [(ngModel)]="selectedRejectReason" (ngModelChange)="onChangeSelectedRejectReason()" >
          </p-dropdown>
      </div>
    </div>
    <div class="grid" style="margin-top:5px;">
      <div class="col-11">
        <textarea placeholder="*Add comments for reject here.." name="commentText" [value]="rejectComment" (input)="this.rejectComment=$event.target.value;" style="height: 200px;overflow-y: scroll;border-width: 1px;;" id="txtComment" [rows]="10" [cols]="95"></textarea>
      </div>
    </div>
  <div class="grid">
    <div class="md:col-6"></div>
    <div class="md:col-6 text-right" style="padding-right: 20px;" >
      <button type="button" style="background-color: #0072ce !important;color:white" pButton icon="pi pi-refresh" id="btnReturnForReview" [disabled]="!rejectComment"   (click)="ReturnForReview()" label="Return for Review"></button>
      <button style="margin-left: 10px;background-color: #0072ce !important;color:white" type="button" pButton icon="pi pi-times" id="btnReject" (click)="RejectRequestWithComment();" label="Reject" [disabled]="rejectComment===null||rejectComment===undefined||rejectComment===''"  ></button>
    </div>
  </div>
</p-dialog>
