import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class AzureADService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public getSupervisor(email): any {
        let url = ConfigurationSettings.REST_API_URL + "/azure-ad/users/" + email + "/manager"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Supervisor")));
    }

    public GetUserDetails(email) :any
    {
        let url = ConfigurationSettings.REST_API_URL + "/azure-ad/users/search?searchString=" + email;
        return this._http.get(url).pipe(
             tap(x => console.log("Fetched User details")));
    }
     
}