import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SearchComponent } from '../search.component';
import { ChemicalService } from 'src/app/services/chemical-service/chemical.service';
import { CartService } from 'src/app/services/cart-service/cart.service';
import { AppComponent } from '../..//app.component';
import { CartItemModel } from 'src/app/cart/cart-item.model';
import { SaveCartModel } from 'src/app/cart/save-cart.model';
import { RequestTypeService } from 'src/app/services/request-type-service/request-type.service';
import { CommonService } from 'src/app/services/common-service/common.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';


@Component({
    selector: 'app-glp',
    templateUrl: './glp.component.html',
    styleUrls: ['../search.component.css'],
    providers: [MessageService]
})
export class GLPComponent extends SearchComponent implements OnInit {
    showField: boolean = false;
    constructor(protected router: Router,chemicalService: ChemicalService, messageService: MessageService, userService:UserService,
        cartService: CartService, appComponent: AppComponent, requestTypeService: RequestTypeService, commonService: CommonService) {
        super(router,chemicalService, messageService,userService, cartService, appComponent, requestTypeService, commonService);
    }

    defaultRecertificationDate: string = '0001-01-01T00:00:00';

    ngOnInit() {
        this.isHidden = true;
        this.filterByList = "MaterialName,Synonyms,FormulationType";
        this.sortOptions = [
            { label: 'Material Name', value: 'MaterialName' },
            { label: 'Synonyms', value: 'Synonyms' },
            { label: 'Formulation Type', value: 'FormulationType' }
        ];
    }

    public async addToCart(item) {
        if (this.existingCartItemRequestType != '' &&
            this.existingCartItemRequestType != item.RequestTypeName) {
            this.messageService.add({ severity: 'error', summary: 'Cart can contain only one type of samples at a time', detail: '' });
            return;
        }
        var cartModel = new CartItemModel();
        var saveCartModel = new SaveCartModel();

        cartModel.MaterialName = item.MaterialName;
        cartModel.Synonyms = item.Synonyms;
        // These variable value will come in future.
        cartModel.RequestTypeId = item.RequestTypeId;
        cartModel.LotNumber = item.LotNumber;
        cartModel.RecertificationDate = item.RecertificationDate;
        cartModel.AvailableAmount = item.AvailableAmount;
        cartModel.TsnNumber = item.TsnNumber;
        cartModel.Purity = item.Purity;
        cartModel.ActiveIngredient = item.ActiveIngredient;
        cartModel.GfCode = item.GfCode;
        cartModel.MaterialId = item.MaterialId;
        saveCartModel.CartItem = cartModel;
        saveCartModel.SearchString = this.searchText;

        let res = await this.createCart(saveCartModel);

        if (res) {
            item.IsAdded = true;
            this.messageService.add({ severity: 'success', summary: item.MaterialName + ' added successfully', detail: '', life: 500 });
            // Increase no. of items in the cart.
            this.appComponent.cartItemsCount = this.appComponent.cartItemsCount + 1;
        }
    }


}