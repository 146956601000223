export class DelegateModel {
    RequesterDelegatorId?: number;
    RequesterId?: number;
    DelegatorId?: number;
    RequestTypeId?: number;
    RequesterName?: string;   
    DelegatorName?: string;  
    Supervisor?: string;
    Function?: string;
    Email?: string;
    RequesterEmail?: string;
    Country?: string;
    RequestTypeName?: string;
}