
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { SiteModel } from "./site.model"
import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class SiteService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }



    public getSitesList(): any {
        let url = ConfigurationSettings.REST_API_URL + "/sites"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Sites")));
    }
}