
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../../configuration-settings"


@Injectable()
export class RequestTypeService {
    constructor(private _http: HttpClient) { }

    //-------------------------------------------------------------
    // Method to get Request Types from backend api
    //-------------------------------------------------------------  
    public getRequestTypes(): any {

        let url = ConfigurationSettings.REST_API_URL + "/requesttypes";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched requesttypes")));
    }

    getRequestTypesByEmail(email:string): any {
        let url = ConfigurationSettings.REST_API_URL + "/users/" + email +  "/requesttypes";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched requesttypes for logged in user")));
    }

}