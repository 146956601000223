<div>
    <h5>Exception Log</h5>
</div>
<div>
    <p-table #dt [columns]="exceptionLogTableHeaders" [value]="exceptionLogList" [paginator]="true" [rows]="10"
        selectionMode="multiple" [(selection)]="selectedException" [reorderableColumns]="true"
        [resizableColumns]="true">
        <ng-template pTemplate="caption">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText size="50" placeholder="Global Filter"
                    (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto;float:right">
            </span>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [ngStyle]="{'display': col.display,'text-align':'center','width': col.width}" pReorderableColumn
                    pResizableColumn>
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th *ngFor="let col of columns" [ngStyle]="{'text-align':'center'}">
                    <input pInputText type="text" [ngStyle]="filterColumnWidth"
                        (input)="dt.filter($event.target.value, col.field, col.filterMatchMode='contains')">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngStyle]="{'display': col.display}">

                    <span *ngIf="col.field == 'CreatedDate'">{{rowData[col.field] | date: "dd-MMM-yyyy"}} </span>
                    <span *ngIf="col.field != 'CreatedDate'">{{rowData[col.field]}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>