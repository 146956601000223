<div>
    <h5>Delegate</h5>
</div>
<div>
    <p-table #dt [columns]="delegateTableHeaders" [value]="delegates" [paginator]="true" [rows]="10"
        [(selection)]="selectedUsers" [reorderableColumns]="true" [resizableColumns]="true">
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Global Filter"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto;float:right">
                </span>
                <button pButton type="button" label="Add new" (click)="AddnewItem()" icon="pi pi-plus"
                    *ngIf="(isAdmin || isRequester)"></button>


            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [ngStyle]="{'display': col.display,'text-align':'center','width':col.width}" pReorderableColumn
                    pResizableColumn>
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th></th>
            </tr>
            <tr>
                <th *ngFor="let col of columns">
                    <input pInputText type="text" [ngStyle]="filterColumnWidth"
                        (input)="dt.filter($event.target.value, col.field, col.filterMatchMode='contains')">
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngStyle]="{'display': col.display,'text-align':'center'}">
                    {{rowData[col.field]}}
                </td>
                <td style="text-align:center">
                    <button type="button" pButton icon="pi pi-pencil" (click)="edit(rowData)"
                        *ngIf="isAdmin || isRequester"></button>
                    <button type="button" pButton icon="pi pi-trash" (click)=delete(rowData) style="margin-left:5px"
                        *ngIf="(isAdmin || isRequester)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>



<p-dialog class="userDialog" header="{{dialogHeaderText}}" [contentStyle]="contentStyleObject" [draggable]="true"
    [modal]="true" [closeOnEscape]="false" [(visible)]="displayDialog" positionLeft=300 positionTop=40
    [closable]="false">

    <div class="col-12" style="min-height:170px;">
        <div class="grid">
            <div class="col-6">
                <div class="grid">
                    <div class="col-3">
                        <span class="label font-weight-bold">*Requester</span>

                    </div>
                    <div class="col">
                        <p-dropdown class="delegate" id="requester" [options]="requesters" appendTo="body"
                            [(ngModel)]="selectedRequester" optionLabel="DisplayName" 
                            placeholder="Select Requester" (onChange)="requesterOnChange()" [disabled] = "isEditable" [filter]="true"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="grid">
                    <div class="col-3">
                        <span class="label">Request Type</span>

                    </div>
                    <div class="col">
                        <p-dropdown class="delegate" id="requestType" [options]="requestTypeList"
                            [(ngModel)]="selectedRequestType" optionLabel="Name"
                            (onChange)="requestTypeOnChange()" [filter]="true">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-6">
                <div class="grid">
                    <div class="col-3">
                        <span class="label font-weight-bold">*Delegate</span>


                    </div>
                    <div class="col">
                        <p-dropdown class="delegate" id="delegator" [options]="delegators" appendTo="body"
                            [(ngModel)]="selectedDelegator" optionLabel="DisplayName" 
                            placeholder="Select Delegate" (onChange)="delegatorOnChange()" [disabled] = "isEditable" [filter]="true"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="grid">
                    <div class="col-3">
                        <span class="label">Function</span>
                    </div>
                    <div class="col">
                        {{ selectedFunction }}
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div class="space">

    </div>
    <p-footer>
        <button type="button" class="p-button-success" pButton icon="pi pi-check-square" (click)="save()"
            label="Delegate" *ngIf="!isEditable"></button>
        <button type="button" class="p-button-success" pButton icon="pi pi-save" (click)="update()" *ngIf="isEditable"
            label="Update"></button>
        <!--This delete moved into grid column beside edit button.-->
        <button type="button" pButton icon="pi pi-times" (click)="cancel()" label="Cancel"></button>
    </p-footer>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [closable]="false" acceptLabel="OK" rejectLabel="Cancel"></p-confirmDialog>