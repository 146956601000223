import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnitOfMeasureService } from '../services/unit-of-measure-service/unit-of-measure.service';
import { UnitOfMeasureModel } from '../unit-of-measure/unit-of-measure.model';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RequestTypeService } from '../services/request-type-service/request-type.service';
import { RequestTypeModel } from '../request-type/request-type.model';
import { UntypedFormBuilder } from '@angular/forms';
import { CommonService } from '../services/common-service/common.service';



@Component({
    selector: 'unit-of-measure',
    templateUrl: './unit-of-measure.component.html',
    styleUrls: ['./unit-of-measure.component.css'],
    providers: [MessageService, UnitOfMeasureService, ConfirmationService, RequestTypeService,CommonService]

})

export class UnitOfMeasureComponent implements OnInit {
    growlMsgs: ToastModule[] = [];

    requestTypeList: RequestTypeModel[];
    selectedRequestType: RequestTypeModel;

    unitOfMeasureListForAllRequestType: UnitOfMeasureModel[]; // This is a collection which contains all UnitOfOfmeasures for all requestType.
    mappedUnitOfMeasureListWithRequestType: UnitOfMeasureModel[]; // This collection will contain the list of mapped UnitOfMeasure for a requestType.
    unMappedUnitOfMeasureListWithRequestType: UnitOfMeasureModel[]; // This collection will contain the list of unmapped UnitOfMeasure for a requestType.
    selectedUnitOfMeasuresToMapWithRequestType: UnitOfMeasureModel[];
    unitOfMeasureList: UnitOfMeasureModel[]; // This collection contains list of UnitOfMeasure only.


    isUnitOfMeasureNotMappedWithRequestType: Boolean;
    isRequestTypeSelected: Boolean;
    isUnitOfMeasureSelected: Boolean;
    disableMappingButton: boolean = true;
    selectedUnitOfMeasure: string;
    selectedDescription: string;
    isUnitOfMeasureList: boolean;
    isMultiSelectDisabled: boolean = true;
    isAdminstrator: boolean = false;




    constructor(private activatedroute: ActivatedRoute, private messageService: MessageService,
        private unitOfMeasureService: UnitOfMeasureService, private requestTypeService: RequestTypeService,
        private confirmationService: ConfirmationService, private formBuilder: UntypedFormBuilder,private commonService:CommonService) { }


    ngOnInit() {
        this.checkAdminstratorRole();
        this.isUnitOfMeasureNotMappedWithRequestType = true;
        this.isRequestTypeSelected = true;
        this.isUnitOfMeasureSelected = true;

        this.getRequestTypes();
        this.getUnitOfMeasures();
        this.getUnitOfMeasureListForAllRequestType();
    }

    //-----------------------------------------------------------------
    //  To get the list of request type
    //----------------------------------------------------------------
    public getRequestTypes() {
        this.requestTypeService.getRequestTypes().subscribe(response => {
            this.requestTypeList = response;
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            })
    }

    //-----------------------------------------------------------------
    //  To get the list of measure unit.
    //----------------------------------------------------------------
    public getUnitOfMeasures() {
        this.unitOfMeasureService.getUnitOfMeasureList().subscribe(response => {
            this.unitOfMeasureList = response;
            this.isUnitOfMeasureList = this.unitOfMeasureList.length == 0 ? true : false;
            this.unMappedUnitOfMeasureListWithRequestType = response;

            this.unMappedUnitOfMeasureListWithRequestType = [];
            this.getUnitOfMeasuresByRequestTypeId(this.selectedRequestType.RequestTypeId);
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            })
    }

    //-----------------------------------------------------------------
    //  To get the list of measure unit by request type id.
    //----------------------------------------------------------------
    public getUnitOfMeasuresByRequestTypeId(requestTypeId: number) {
        this.unitOfMeasureService.getUnitOfMeasures(requestTypeId).subscribe(response => {
            this.mappedUnitOfMeasureListWithRequestType = response;
            this.getUnMappedUnitOfMeasuresWithRequestType();
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            })
    }

    //-----------------------------------------------------------------
    //  To get the list of unit of measure for all chemical type
    //-----------------------------------------------------------------
    public getUnitOfMeasureListForAllRequestType() {
        this.unitOfMeasureService.getUnitOfMeasureListForAllRequestType().subscribe(response => {
            this.unitOfMeasureListForAllRequestType = response;
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            })
    }

    //-----------------------------------------------------------------
    //  To save mapping of unit of measure and chemical type
    //-----------------------------------------------------------------
    public SaveUnitOfMeasureMappingWithRequestType(requestTypeId: Number, unitofmeasureIdList: Number[]) {
        this.unitOfMeasureService.createMappingUnitOfMeasureWithRequestType(requestTypeId, unitofmeasureIdList).subscribe(response => {
            var res = response;
            this.selectedUnitOfMeasuresToMapWithRequestType = [];
            this.getUnitOfMeasureListForAllRequestType();
            this.getUnitOfMeasures();
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message + ' ' + error.detail });
            })
    }

    //------------------------------------------------------------------------------
    //  To update status of those UnitOfMeasure with are mapped with chemical type
    //------------------------------------------------------------------------------
    public updateUnitOfMeasure(unitOfMeasureModel: UnitOfMeasureModel) {
        this.unitOfMeasureService.updateUnitOfMeasure(unitOfMeasureModel).subscribe(response => {
            var res = response;
            this.getUnitOfMeasureListForAllRequestType();
            this.getUnitOfMeasures();
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            })
    }

    //-------------------------------------------------------------------------------------------------------------------
    //  To get the list of un mapped measure unit by request type id which will be bind with UnitOfMeasure dropdown.
    //-------------------------------------------------------------------------------------------------------------------
    public getUnMappedUnitOfMeasuresWithRequestType() {
        this.isRequestTypeSelected = true;
        if (this.mappedUnitOfMeasureListWithRequestType != null || this.mappedUnitOfMeasureListWithRequestType != undefined) {
            this.unMappedUnitOfMeasureListWithRequestType = [];
            this.unitOfMeasureList.forEach(element => {
                let mappedUnitOfMeasure = this.mappedUnitOfMeasureListWithRequestType.find(r => r.UnitOfMeasureId == element.UnitOfMeasureId);
                if (mappedUnitOfMeasure == null || mappedUnitOfMeasure == undefined) {
                    this.unMappedUnitOfMeasureListWithRequestType.push(element);
                }
            });
        }
        else {
            this.getUnitOfMeasures();
        }
    }

    //--------------------------------------------------------
    //  To filter the list of measure unit by chemical type id 
    //---------------------------------------------------------
    public filterUnitOfMeasureListForAllRequestType(requestTypeId: number) {
        var filteredRecords = this.unitOfMeasureListForAllRequestType.filter(r => r.RequestTypeId == requestTypeId);
        this.isUnitOfMeasureNotMappedWithRequestType = filteredRecords.length == 0 ? true : false;
        return filteredRecords;
    }


    //-----------------------------------------------------------------
    //  To get the list of mapped measure unit by request type id.
    //----------------------------------------------------------------
    public onRequestTypeChange(selectedRequestType: RequestTypeModel) {
        this.isRequestTypeSelected = true;;
        this.isUnitOfMeasureSelected = true;
        this.isMultiSelectDisabled = false;
        this.unMappedUnitOfMeasureListWithRequestType = [];
        this.getUnitOfMeasuresByRequestTypeId(selectedRequestType.RequestTypeId);
    }

    public onMeasureUnitChange(selectedUnitOfMeasuresToMapWithRequestType) {
        this.isRequestTypeSelected = true;;
        this.isUnitOfMeasureSelected = true;
    }

    public onMappingSave() {
        this.isRequestTypeSelected = true;;
        this.isUnitOfMeasureSelected = true;

        if (this.selectedRequestType == null) {
            this.isRequestTypeSelected = false;
            this.messageService.add({ severity: 'error', summary: "", detail: "Please select a chemical type" });
            return;
        }
        if (this.selectedUnitOfMeasuresToMapWithRequestType == null || this.selectedUnitOfMeasuresToMapWithRequestType.length == 0) {
            this.isUnitOfMeasureSelected = false;
            this.messageService.add({ severity: 'error', summary: "", detail: "Please select a unit of measure" });
            return;
        }
        let mappedUnitOfMeasureIds: Number[] = [];
        this.selectedUnitOfMeasuresToMapWithRequestType.forEach(element => {
            let findUnitOfMeasureId = mappedUnitOfMeasureIds.find(r => r == element.UnitOfMeasureId);
            if ((findUnitOfMeasureId == null || findUnitOfMeasureId == undefined)) {
                ;
                mappedUnitOfMeasureIds.push(element.UnitOfMeasureId);
            }
        });

        var result = this.SaveUnitOfMeasureMappingWithRequestType(this.selectedRequestType.RequestTypeId, mappedUnitOfMeasureIds);
        this.selectedRequestType = null;
    }

    //-----------------------------
    //  To add new unit of measure
    //-----------------------------
    public addNewUnitOfMeasure() {
        if (this.selectedUnitOfMeasure == "" || this.selectedUnitOfMeasure == null) {
            this.messageService.add({ severity: 'error', summary: "", detail: "Please enter unit of measure" });
            return;
        }
        if (this.selectedDescription == "" || this.selectedDescription == null) {
            this.messageService.add({ severity: 'error', summary: "", detail: "Please enter description" });
            return;
        }
        var addUnitOfMeasureModel = new UnitOfMeasureModel();
        addUnitOfMeasureModel.UnitOfMeasure = this.selectedUnitOfMeasure;
        addUnitOfMeasureModel.Description = this.selectedDescription;
        this.unitOfMeasureService.createUnitOfMeasure(addUnitOfMeasureModel).subscribe(response => {
            this.getUnitOfMeasures();
            this.selectedUnitOfMeasure = "";
            this.selectedDescription = "";
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            })
    }

    //--------------------------------------------------------------
    //  on click of unmapped unit of measure in multiselect dropdown
    //--------------------------------------------------------------
    clickEvent() {
        if (this.selectedRequestType == null) {
            this.messageService.add({ severity: 'error', summary: "", detail: "Please select a chemical type" });
            return false;
        }
        if (this.unMappedUnitOfMeasureListWithRequestType == null || this.unMappedUnitOfMeasureListWithRequestType.length == 0) {
            this.messageService.add({ severity: 'error', summary: "", detail: "No unmapped unit of measure left" });
            return false;
        }
    }

    private checkAdminstratorRole()
    {
      this.isAdminstrator = this.commonService.checkAdministrator();
      return this.isAdminstrator;
    }

}

