

export class RequestNotificationModel {

    RequestNotificationId?: number;
    RequestId?: number;
    Email?: string;
    FirstName: string;
    LastName: string;
    MiddleName: string;
    Description?: string;
    DisplayName?: string;
    IsRequestNotificationDeleted: boolean;
    SerialNumber?: number;
}