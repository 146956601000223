
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { filter, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ConfigurationSettings } from 'src/app/configuration-settings';


@Injectable()
export class ChemicalService {

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private options = { headers: this.headers };

    constructor(private _http: HttpClient) { }

    //-------------------------------------------------------------
    // Method to get chemicals from backend api
    //-------------------------------------------------------------
    public getChemicals(requestType, searchText): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/search/chemicals?criteria=" + encodeURIComponent(searchText)
        return this._http.get(url)
            .pipe(map((response: Response) => response, console.log(Response)));
    }

}