import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CartService } from '../services/cart-service/cart.service';
import { SaveCartModel } from '../cart/save-cart.model'
import { AppComponent } from '../app.component';
import { RequestType } from '../request-type/request-type.enum';
import { ForecastModel } from './forecast.model';
import { ForecastService } from '../services/forecast-service/forecast.service';
import { CartItemModel } from '../cart/cart-item.model';
import { CountryService } from '../services/country-service/country.service';
import { RegionService } from '../services/region-service/region-service';
import { CountryModel } from '../country/country.model';
import { RegionModel } from '../region/region.model';
import { DelegateService } from '../services/delegate-service/delegate.service';
import { DelegateModel } from '../delegate/delegate.model';
import { UserModel } from '../user/user.model';
import { DataView } from 'primeng/dataview';
import { ForecastRequestModel } from './forecast-request.model';
import { ForecastResponseModel } from './forecast-response.model';


@Component({
    selector: 'forecast',
    templateUrl: './forecast.component.html',
    styleUrls: ['./forecast.component.css'],
    providers: [ForecastService, MessageService, CartService, CountryService, RegionService, DelegateService]
})
export class ForecastComponent implements OnInit {
    results: ForecastModel[] = [];
    public isHidden = false;
    protected DEFAULT_GLOBAL_AREA = 'DEFAULT GLOBAL AREA';
    protected filterByList: string;
    growlMsgs: ToastModule[] = [];

    //These variables are used for sorting
    protected sortOptions: SelectItem[];
    sortKey: string;
    sortField: string;
    sortOrder: number;
    mineOnly: boolean = false;
    hideHideAllButton: boolean = true;
    existingCartItemRequestType: string = '';
    filterValue: string = '';
    PAGE_SIZE: number = 5;
    sortDrowDownStyle: object;
    isForecast: boolean;
    serviceResponse: any;
    countries: CountryModel[];
    regions: RegionModel[];
    cartItemList: any[];
    message: string;
    requesters: UserModel[];
    forecastRequester: UserModel;
    @ViewChild('dv') dataView: DataView;
    totalRecords: number = 0;
    pageCount: number = 0;
    pageIndex: number = 0;
    showHistory : boolean = false;
    requestHistory: string[];
    constructor(private forecastService: ForecastService, private messageService: MessageService,
        private cartService: CartService, private appComponent: AppComponent,
        private countryService: CountryService, private regionService: RegionService,
        private delegateService: DelegateService) {
    }

    ngOnInit() {
        this.filterByList = "MaterialName,Forecasters,Requesters,Country,ForecastId,RequestLocationId,MaterialApprover,ProgramNumber";
        this.sortOptions = [
            { label: 'Country', value: 'Country' },
            { label: 'Forecaster', value: 'Forecasters' },
            { label: 'Forecast ID', value: 'ForecastId' },
            { label: 'Material Name', value: 'MaterialName' },
            { label: 'Program No', value: 'ProgramNumber' },
            { label: 'Requester', value: 'Requesters' },
            { label: 'Request Location ID', value: 'RequestLocationId' }
        ];

        this.sortDrowDownStyle = { 'min-width': '140px', 'float': 'right' };
        this.cartService.getCarts().subscribe(response => {
            this.appComponent.cartItemsCount = response.length;
            this.cartItemList = response;
            this.isForecast = (response.length > 0 && response.find(c => c.RequestLocationId > 0)) ? true : false;
            this.existingCartItemRequestType = (response.length > 0 ? response[0].RequestTypeName : '');
        },
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
            });
        this.getPaginatedForecasts();
//        this.getForecasts(this.forecastRequester != undefined ? this.forecastRequester.Email : localStorage["loggedInUsername"]);
        this.getRegions();
        this.getCountries();
        this.getRequesters();
    }

        //--------------------------------------
    // Method to get chemicals from API
    //--------------------------------------
    private getPaginatedForecasts() {

        let req = new ForecastRequestModel();
        req.sortOrder = this.sortField;
        req.isASC = true;
        req.pageSize = this.PAGE_SIZE;
        req.pageIndex = this.pageIndex;
        req.filterValue = this.filterValue;
        req.delegatorEmail = localStorage["loggedInUsername"];
        req.requesterEmail = this.forecastRequester != undefined ? this.forecastRequester.Email : localStorage["loggedInUsername"];

        if (this.mineOnly) {
            req.forecasterEmail = localStorage["msal.displayName"];
        } else {
            req.forecasterEmail = '';
        }
        this.results = [];
        this.forecastService.getPaginatedForecasts(req).subscribe(response => {
            if (this.filterValue == response.FilterValue &&
            this.sortField == response.SortOrder &&
            (!this.mineOnly || response.ForecasterEmail == localStorage["msal.displayName"])) {
                this.dataView.value = [];
                this.results = response.Forecasts;
                this.totalRecords = response.TotalCount;
                this.pageCount = response.PageCount;
                if (this.pageIndex == 0) {
                    this.dataView.first = 0;
                }
            }
        },
        error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
                this.ngOnInit();
        })
    }



    //--------------------------------------
    // Method to get chemicals from API
    //--------------------------------------
    private getForecasts(email: string) {
        this.results = [];
        this.forecastService.getForecasts(email).subscribe(response => {
            this.results = response;
            this.results.sort((a, b) => {
              return Number(a.HasExpired) - Number(b.HasExpired);
            });
        },
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
                this.ngOnInit();
            })
    }

    pageChanged(event: any) {
        this.pageIndex = (event.first / this.PAGE_SIZE);
        this.getPaginatedForecasts();
      }

    public show() {
        this.pageIndex = 0;
        this.getPaginatedForecasts();
        this.dataView.first = 0;
    }

    public async addToCart(item: ForecastModel) {
        this.message = '';
        if (!this.isForecast && this.existingCartItemRequestType != '') {
            this.messageService.add({ severity: 'error', summary: 'Cart cannot contain forecasted and unforecasted items at the same time', detail: '' });
            return;
        }
        if (this.existingCartItemRequestType != '' &&
            this.existingCartItemRequestType != item.RequestTypeName) {
            this.messageService.add({ severity: 'error', summary: this.message != '' ? this.message : 'Cart can contain only one type of samples at a time', detail: '' });
            return;
        }
        var cartModel = new CartItemModel();
        var saveCartModel = new SaveCartModel();

        cartModel.RequestTypeId = item["RequestTypeId"];
        cartModel.MaterialName = item.MaterialName;
        cartModel.MaterialId = item.MaterialId;
        cartModel.RepoName = item.RepoName;
        cartModel.Synonyms = item.Synonyms;
        cartModel.LotNumber = item.LotNumber;
        cartModel.ApproverEmail = item.ApproverEmail;
        cartModel.ApproverName = item.ApproverName;
        cartModel.FarmInventoryStatus = item.InventoryOrderState;
        cartModel.FarmInventoryLocation = item.InventoryLocation;
        cartModel.WarehouseId = item.WarehouseId;
        cartModel.RequestLocationId = item.RequestLocationId;
        cartModel.ForecastRequesterId = this.forecastRequester.UserId;
        cartModel.ForecastId = item.ForecastId;
        saveCartModel.CartItem = cartModel;
        saveCartModel.SearchString = '';
        saveCartModel.CartRequestTypeId = item["RequestTypeId"];

        let res = await this.createCart(item.RequestTypeName, saveCartModel);

        if (res) {
            item.IsAdded = true;
            this.messageService.add({ severity: 'success', summary: item.MaterialName + ' added successfully', detail: '', life: 500 });
            // Increase no. of items in the cart.
            this.appComponent.cartItemsCount = this.appComponent.cartItemsCount + 1;
            this.ngOnInit();
        }
    }

    //---------------------------------------------------------------------
    // This method add item to the cart and save it to the database.
    //---------------------------------------------------------------------
    public createCart(requestTypeName, saveCartModel: SaveCartModel): Promise<boolean> {
        return this.cartService.createCart(requestTypeName, saveCartModel).then(response => {
            return true;
        })
            .catch(error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
                return false;
            });

    }


    //--------------------------------------------
    // Filter the result
    //--------------------------------------------
    public setFilter(event, value) {
        if (event.checked) {
            this.filterByList = value;
        }
    }

    //------------------------------------------------
    //To sort the search result by selected column
    //------------------------------------------------
    public sortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
        this.pageIndex = 0;
        this.getPaginatedForecasts();
        this.dataView.first = 0;
    }

    //---------------------------------------------------------
    // To show the Hide All button and display all the synonyms
    //---------------------------------------------------------
    showDiv(forecast, isShow) {
        if(isShow && (forecast.SampleRequestHistory == null || forecast.SampleRequestHistory == '' || forecast.SampleRequestHistory == undefined)){
            this.forecastService.getForecastSampleRequestHistory(forecast.RequestLocationId).subscribe((response)=>{
                console.log(response);
                forecast.SampleRequestHistory = response;
            },
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error'
                , detail: error.message });
            });
        }
        var showButton = document.getElementById("show" + forecast.RequestLocationId);
        var hideButton = document.getElementById("hide" + forecast.RequestLocationId)
        var spanId = document.getElementById("div" + forecast.RequestLocationId);
        if(isShow){
            spanId.style.display = "block";
            showButton.style.display = "none";
            hideButton.style.display = "inline-flex";
        }
        else{
            spanId.style.display = "none";
            showButton.style.display = "inline-flex";
            hideButton.style.display = "none";
        }
    }

    //---------------------------------------------------------
    // To show the Show All button and hide all the synonyms
    //---------------------------------------------------------
    hideDiv(synonymdivId) {
        var synonymDiv = document.getElementById(synonymdivId);
        var showButton = document.getElementById("show" + synonymdivId);
        var hideButton = document.getElementById("hide" + synonymdivId);
        var spanId = document.getElementById("span" + synonymdivId);
        spanId.style.display = "block";
        synonymDiv.style.display = "none";
        showButton.style.display = "block";
        hideButton.style.display = "none";

    }

    public get requestType(): typeof RequestType {
        return RequestType;
    }

    public canAddItemToCart(item): boolean {
        var existingCartList = this.cartItemList.filter(c => c.RequestLocationId > 0);
        if (this.appComponent.cartItemsCount == 0 || existingCartList.length == 0) {
            return true;
        }

//          KAT-1078 relaxed the following rule
//        if (existingCartList.find(c => c.ForecastRequesterId != this.forecastRequester.UserId)) {
//            this.message = "Forecasted samples of multiple requesters cannot be added to the cart";
//            return false;
//        }

        // if another cart item already has this country, we are good
        if (existingCartList.find(c => c.Country == item.Country) ||
            item.Country.toUpperCase() === this.DEFAULT_GLOBAL_AREA) {
            return true;
        }
        else {
                // if provided item is a region and cart contains DGA and no other specific country
                // that is good
                if (item.IsRegion) {
                    // only one non-default region can exist
                    if (existingCartList.find(c => c.IsRegion && c.Country.toUpperCase() !== this.DEFAULT_GLOBAL_AREA)) {
                        this.message = "Cart cannot have more than 1 non-default regions";
                        return false;
                    }

                    // if a country exists, its region should match this region
                    var cartCountries = existingCartList.find(c => !c.IsRegion);
                    if (cartCountries) {
                        var cartCountry = this.countries.filter(c => c.Name == cartCountries.Country);
                        var cartRegion = this.regions.filter(c => c.RegionId == cartCountry[0].RegionId);
                        if (cartRegion[0].Description === item.Country) {
                            return true;
                        } else {
                            this.message = "Cart cannot include a region where an existing country does not belong.";
                            return false;
                        }
                    }
                    else {
                        return true;
                    }
                }
                else {
                    if (existingCartList.find(c => c.Country.toUpperCase() === item.Country.toUpperCase())) {
                        return true;
                    }
                    if (existingCartList.find(c => !c.IsRegion && c.Country.toUpperCase() !== item.Country.toUpperCase())) {
                        this.message = "Cart cannot contain sample forecasts from different country/regions";
                        return false;
                    }

                    // get region of the provided item.
                    var selectedCountry = this.countries.filter(c => c.Name == item.Country);
                    if (selectedCountry.length > 0) {
                        var selectedRegion = this.regions.filter(c => c.RegionId == selectedCountry[0].RegionId)
                        if (selectedRegion.length > 0) {
                            // selected item's region and region in a existing cart are the same
                            // OR either one of the regions is DEFAULT GLOBAL AREA
                            let nonDefaultRegions = existingCartList.find(c => c.IsRegion && c.Country.toUpperCase() !== this.DEFAULT_GLOBAL_AREA)
                            if (nonDefaultRegions && nonDefaultRegions.Country.toUpperCase() === selectedRegion[0].Description.toUpperCase())
                            {
                                return true;
                            }

                            if (existingCartList.find(c => c.Country.toUpperCase() === this.DEFAULT_GLOBAL_AREA ||
                                selectedRegion[0].Description.toUpperCase() === this.DEFAULT_GLOBAL_AREA ||
                                c.Country == selectedRegion[0].Description)) {
                                return true;
                            }
                        }
                    }
                }
            }
            this.message = "Cart cannot contain sample forecasts from different country/regions";
            return false;
    }
    public getRegions() {
        if (this.regions == undefined) {
            this.regionService.getRegions().subscribe(response => this.regions = response,
                error => {
                    this.messageService.add({ severity: 'error', summary: '', detail: error.error });
                },
                () => {
                })
        }
    }

    public getCountries() {
        if (this.countries == undefined) {
            this.countryService.getCountryList().subscribe(res => this.serviceResponse = res,
                error => {
                    this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
                },
                () => {
                    this.countries = this.serviceResponse;
                })
        }
    }

    public getRequesters() {
        this.delegateService.getRequesters().subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            },
            () => {
                this.requesters = this.serviceResponse;
                this.forecastRequester = this.forecastRequester == undefined ? this.requesters[0] : this.forecastRequester;
            });
    }

    public filterChanged(v) {
        if ((this.filterValue != null && this.filterValue.length > 2) || this.filterValue == '') {
            this.pageIndex = 0;
            this.getPaginatedForecasts();
        }
    }

    public filterMyForecastOnCheckChange(checked) {
        this.mineOnly = checked;
        this.pageIndex = 0;
        this.getPaginatedForecasts();
        this.dataView.first = 0;
    }

}

