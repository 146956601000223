

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { UserModel } from '../../user/user.model';
import { Observable } from 'rxjs';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class UserService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private _http: HttpClient) { }

  public createUser(user: UserModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/users"
    let body = JSON.stringify(user);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new User"))
      );
  }

  public getUsers(): any {
    let url = ConfigurationSettings.REST_API_URL + "/users"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched Users")));
  }

  public getUser(email: String): Observable<UserModel> {
    let url = ConfigurationSettings.REST_API_URL + "/users/email?email="+email;
    return this._http.get<UserModel>(url).pipe(
      tap(x => console.log("Fetched User", x)));
  }

  public updateUser(user: UserModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/users"
    let body = JSON.stringify(user);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Updated User"))
      );
  }

  public updateSupervisor(): any {
    let url = ConfigurationSettings.REST_API_URL + "/users/supervisors"
    return this._http.put(
      url,
      null, this.httpOptions).pipe(
        tap(x => console.log("Supervisors updated successfully"))
      );
  }

  public UpdateMaterialApprover(userEmail: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/users/UpdateMaterialApprover?emailId="+userEmail;    
    return this._http.put(
      url,
      null, this.httpOptions).pipe(
        tap(x => console.log("Update material approver for user"))
      );
  }
  public deleteUser(Id: number): any {
    let url = ConfigurationSettings.REST_API_URL + "/users?userId=" + Id;
    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted User")));
  }
}