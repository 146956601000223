import { Component, OnInit, Input } from '@angular/core';
import { RequestModel } from '../../request/request.model';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { RequestItemAttachmentService } from 'src/app/services/request-item-attachment-service/request-item-attachment.service';
import { ToastModule } from 'primeng/toast';
import { DocumentType } from '../../request-item-attachments/document-type.model';
import { RequestStatusType } from 'src/app/request-status-type/request-status-type.enum';
import { RequestItemModel } from '../../add-chemical-details/request-item.model';
import { CommonService } from 'src/app/services/common-service/common.service';
import { ConfigurationSettings } from 'src/app/configuration-settings';


@Component({
  selector: 'app-sample-details',
  templateUrl: './sample-details.component.html',
  styleUrls: ['./sample-details.component.css'],
  providers: [RequestItemService, MessageService, DatePipe,RequestItemAttachmentService]
})
export class SampleDetailsComponent implements OnInit {
  growlMsgs: ToastModule[] = [];
  @Input() requestModel: RequestModel;
  documentTypes: DocumentType[] = [];
  validateQuantity:boolean;
  isPermitDocNotInUploadedDocList: boolean[] = [];
  isBioMaterial = false;
  public euCenterFeature = ConfigurationSettings.CurrentEnvironment.featureFlags.euCenter;
  isAdministrator: boolean = false;

  constructor(private requestItemService: RequestItemService, private commonService: CommonService
    , private messageService: MessageService, public datepipe: DatePipe,private requestItemAttachmentService: RequestItemAttachmentService) {

  }
  ngOnInit() {
   // this.getRequestItemByRequestNumber();
   console.log(this.requestModel);
   this.isAdministrator = this.commonService.checkAdministrator();
   if (this.requestModel !== undefined) {
    this.requestModel.RequestItemList.map(item => {
      if (this.isBioMaterialType(item) === true) {
        this.isBioMaterial = true;
      }
    })
   }
   this.validateQuantity =  this.requestModel.RequestStatus == RequestStatusType.Draft || this.requestModel.RequestStatus == RequestStatusType.AwaitingITC || this.requestModel.RequestStatus == RequestStatusType.MaterialApproval;
    this.requestItemAttachmentService.GetDocumentTypeList().subscribe((response) => {
      this.documentTypes = response;

      // validate permit doc uploaded or not
      let docTypePermitObj;
      this.requestItemAttachmentService.GetDocumentTypeList().subscribe((response) => {
        docTypePermitObj = response.filter(x => x.Name === 'Permit')[0];
        
        this.requestModel.RequestItemList.map(item => {
          if (item.IsPermitRequired === true) {
            const isPermitFound = item.RequestItemAttachmentList.filter(x => x.DocumentTypeId === docTypePermitObj.Id);
            if (isPermitFound.length === 0) {
              this.isPermitDocNotInUploadedDocList.push(true);
            } else {
              this.isPermitDocNotInUploadedDocList.push(false);
            }
          } else {
            this.isPermitDocNotInUploadedDocList.push(false);
          }
        });
      });

    });
  }

  //--------------------------------------------------------
  // Call method to  get list of item added by use to the cart.
  //------------------------------------------------------------
  private getRequestItemByRequestNumber() {
    this.requestItemService.getRequestItems(this.requestModel.RequestTypeName, this.requestModel.RequestNumber).subscribe(response => {
      this.requestModel.RequestItemList = response;
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      })
  }

  public GetAttachmentType(documentType:number)
  {
    return this.documentTypes.filter(x => x.Id == documentType)[0]?.Name;
  }
  public downloadAttachment(requestId: number, requestItemId: number, attachmentId: number, filename: string) {
    this.requestItemAttachmentService.downloadFile(requestId, requestItemId, attachmentId).subscribe(response => {
      var binaryData = [];
      binaryData.push(response);
      var blob = new Blob(binaryData, { type: "application/octet-stream" });
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.click();
    },
      error => {
        this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
      });
  }
  //---------------------------------------------
  // NeedByDate should be greater than today
  //---------------------------------------------
  public validateNeedByDate(needBydate: Date) {
    let format = 'yyyy-MM-dd';
    let today = this.datepipe.transform(new Date().getTime(), format);
    if (this.datepipe.transform(needBydate, format) <= today) {
      return false;
    }
    return true;
  }
   CheckSampleComments(comment:String)
  {
    if(comment == null || comment == undefined || comment == "")
    {
      return false;
    }
    return true;
  }

  rejectedComments(commentArry: any) {
    let commentWithRejection = commentArry.filter(x => x.Comment.CommentType === 'ApprovalRejected' ||  x.Comment.CommentType === 'ApprovalGranted')[0];
    return commentWithRejection?.Comment?.Comment1;
  }

  isBioMaterialType(item: RequestItemModel): boolean {
    const isBioItem = (item.MaterialSearchType === 'BioMaterialLotId' || item.MaterialSearchType === 'BioMaterialId'
    || item.MaterialSearchType === 'BioNexusSynonymStock' || item.MaterialSearchType === 'BioNexusSynonymGLP' || item.MaterialSearchType === 'P2PBiological'
    || item.MaterialSearchType === 'AddP2PBiological' || item.IsBioFormulation == true) ? true : false;

    return isBioItem;
}
}
