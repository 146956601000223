import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MsalService } from "@azure/msal-angular";
import { Subscription } from "rxjs";
import { NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { SecurityService } from './services/security-service/security.service';
import { MonitoringService } from './services/monitor.service';
import { ConfigurationSettings } from './configuration-settings';
import { environment } from '../environments/environment';
import { UserModel } from './user/user.model';
import { MenuModel } from './menu/menu.model';
import { MenuService } from './services/menu-service/menu.service';
import { LoadingService } from './loading/res-loading.service';
import { MenuModule } from 'primeng/menu';
import { CommonService } from './services/common-service/common.service';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService, AuthStatus } from '@corteva-research/ngx-components-core';
import { MsalBroadcastService } from "@azure/msal-angular";
import { ConfirmationService, SelectItem, MessageService } from "primeng/api";
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, mergeMap, pairwise, takeUntil } from 'rxjs/operators';
import * as signalR from "@microsoft/signalr";
import { LogLevel } from 'msal';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [SecurityService, MenuService, CommonService, MessageService]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscription: Subscription;
  public username: string;
  public userModel: UserModel;
  public showMenuOnAuthentication: object = { 'visibility': 'hidden' };

  public cartItemsCount: number = 0;
  public appVersion: string = environment.appVersion;

  growlMsgs: ToastModule[] = [];
  currentEnvironment = ConfigurationSettings.CURRENT_ENVIRONMENT_NAME;
  isDisabled: true;

  skipSearchPage: boolean = false;

  public showMenu: boolean = false;
  public menus: MenuModel[] = [];
  adminMenus: MenuModel[];
  nonAdminMenus: MenuModel[];
  supportMenus: MenuModel[];
  public exists: boolean = false;
  public isRequester: boolean = false;
  public additionalRoutes = ['request-types/Stock/request', 'request-types/GLP/request', 'request-types/P2P/request']
  private readonly _destroying$ = new Subject<void>();
  public connection: signalR.HubConnection;
  public theMessage: string;
  public displayMaintenanceMessage: boolean = false;


  public previousRouteURL = '';
  constructor(private broadcastService: MsalBroadcastService, private authService: MsalService
    , private router: Router, private securityService: SecurityService,
    private monitoringService: MonitoringService,
    private primengConfig: PrimeNGConfig, private messageService: MessageService,
    private loadingService: LoadingService, private menuService: MenuService, private commonService: CommonService) {
    //    this.router.navigate(['404']);
    this.router.events.subscribe((event) => {
      if (((event instanceof NavigationStart)
        // && event.url != '/unauthorized'
        && event.url != '/home'
        // && event.url != '/search'
        && event.url != '/cart'
        && event.url != '/code'
        && event.url != '/access-denied')
        || ((event instanceof NavigationEnd) && event.urlAfterRedirects == '/search')
      ) {

        // Code to clear all local storage and Session Storage on logout click.
        if (event.url == "/after-logout") {
          this.menus = [];
          this.showMenu = false;
          localStorage.clear();
        }

        if (localStorage.getItem("msal.menus") != null && localStorage.getItem("msal.menus") != "") {
          this.getMenusFromlocalStorage();
        }

        if (this.menus != null && this.menus.length > 0) {
          if (event.url == "/") {
            this.router.navigate(["./search"]);
          }
          else {
            this.exists = this.menus.some(menu => event.url.replace('/', '').indexOf(menu.ActionURL) === 0 || this.additionalRoutes.some(a => event.url.replace('/', '').indexOf(a) === 0));
            if (!this.exists)
              this.router.navigate(["./access-denied"]);
          }
        }
        else {
          if (localStorage["msal.user.validated"] == "true" || localStorage["msal.user.validated"] == undefined) {
            this.router.navigate(["./home"]);
          }
          else {
            this.router.navigate(["./access-denied"]);
          }
        }

        // Show loading indicator
      }
    });
  }

  ngOnInit() {
    // This if case is handling page refresh after login success. 
    // msal.user.validated (user define storage)-> Prefix name msal is given because it was not clear by logout.
    // onve msal is given this storage is cleaned by logout. 
    //  this.router.navigate(['404']);
    console.log('ngoninit');

    this.primengConfig.ripple = true;
    this.initSignalR();
    let currentUrl = window.location.href;
    if (currentUrl.indexOf('step') > 0 || (currentUrl.indexOf('/request-types/') > 0 && currentUrl.indexOf('/request/') > 0)) {
      this.skipSearchPage = true;
    }
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        filter(() => { return this.authService.instance.getAllAccounts().length > 0; }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (localStorage["msal.user.validated"] === undefined || localStorage["msal.user.validated"] === null || localStorage["msal.user.validated"] === 'false') {
          this.username = this.authService.instance.getActiveAccount()?.username;
          localStorage.setItem("loggedInUsername", this.username);
          if (!(this.username == undefined || this.username == "guest" || this.username == "")) {
            this.validateUser();
          }
          else {
            console.log("username is either undefined or guest");
            localStorage["msal.user.validated"] = "false";
            this.showMenuOnAuthentication = { 'visibility': 'hidden' };
          }
        }
      });

    if (localStorage.getItem("msal.account.keys")) {
      this.getMenus();
      this.showMenuOnAuthentication = { 'visibility': 'visible' };
      if (localStorage.getItem("msal.role") != null) {
        this.isRequester = this.commonService.checkRequester();
      }
    }
    else {
      // this.broadcastService.subscribe("msal:loginFailure", () => {
      //   localStorage["msal.user.validated"] = "false";
      //   this.showMenuOnAuthentication = { 'visibility': 'hidden' };
      // });
    }

    if (localStorage.getItem("msal.menus") != null && localStorage.getItem("msal.menus") != "") {
      this.getMenusFromlocalStorage();
    }
    else {
      this.getMenus();
    }

    if (localStorage["msal.user.validated"] == "true") {
      this.setPreviousRouteURL();
    }

  }
  public ngAfterViewInit(): void {
    const el = document.querySelector("res-app-settings-options res-navigation-group");
    el.removeAttribute("hidden");
  }
  //-------------------------------------------
  //To set the previous URL when route change.
  //-------------------------------------------
  public setPreviousRouteURL() {

    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
        this.previousRouteURL = e[0].urlAfterRedirects;
        console.log(e[0].urlAfterRedirects); // previous url
      });
  }
  public initSignalR() {
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(ConfigurationSettings.CurrentEnvironment.ApiPath + "/hub")
      .build();

    this.connection.start().then(function () {
      console.log('SignalR Connected!');
    });

    this.connection.on("SendGreeting", (message: string) => {
      this.theMessage = message;
      this.displayMaintenanceMessage = true;
    });
    this.connection.on("NewUserWelcome", (message: string) => {
      this.messageService.add({ severity: 'success', summary: '', detail: message });
    });
  }
  public closeMaintenanceMessage() {
    this.theMessage = "";
    this.displayMaintenanceMessage = false;
  }

  ngOnDestroy() {
    localStorage.removeItem("loggedInUsername");
    localStorage.removeItem("loggedInUserRoles");
    localStorage["msal.user.validated"] = "false";
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  //--------------------------------------------
  // Method to redirect to Cart Page.
  //--------------------------------------------
  public showCartDetailsPage() {
    this.router.navigateByUrl("/cart");
  }

  //----------------------------------------------------------------------
  // Check the user in database and show the menu bar if available in it.
  //----------------------------------------------------------------------
  public validateUser() {
    console.log("Validating User : " + this.username);
    localStorage.setItem("loggedInUsername", this.username);

    this.securityService.validateUser(this.username)
      .subscribe(data => this.userModel = data,
        error => {
          console.log("Error in Validating User : " + error);
          this.showMenuOnAuthentication = { 'visibility': 'hidden' };
          // This session storage is used to show the menu itme when user refresh the page.
          // false means hide the menu. 
          localStorage["msal.user.validated"] = "false";

          this.menus = [];
          localStorage.removeItem('msal.menus');
          this.showMenu = false;
          this.router.navigate(['./access-denied']);
          //this.authService.loginRedirect();
          //          if (window.location.host.toLowerCase().indexOf('login') < 0)
          //            this.router.navigate(['./login']);
        },
        async () => {
          var result = <boolean>await this.getMenus();

          if (result) {
            localStorage["msal.userId"] = this.userModel.UserId;
            localStorage.setItem("msal.role", JSON.stringify(this.userModel.UserRequestTypeRoleList));
            localStorage["msal.displayName"] = this.userModel.FirstName + ' ' + this.userModel.LastName;
            console.log("Current Logged in Username : " + this.username);
            this.showMenuOnAuthentication = { 'visibility': 'visible' };

            // This session storage is used to show the menu itme when user refresh the page.
            // true means show the menu. 
            localStorage["msal.user.validated"] = "true";
            localStorage.removeItem("gsscommon_token_acquired");  // invalidate gss common token
            this.isRequester = this.commonService.checkRequester();
            if (!this.skipSearchPage) {
              this.router.navigate(["search"]);
            }
          }
        });
  }

  public getMenus() {
    return new Promise((resolve) => {
      this.menuService.getMenuByEmail(localStorage.getItem("loggedInUsername"))
        .subscribe(data => {
          this.menus = data;
        },
          error => {
            this.showMenu = false;
            this.showMenuOnAuthentication = { 'visibility': 'hidden' }
            this.loadingService.clearMessage();
            //this.authService.loginRedirect();
            resolve(false);
          },
          () => {
            if (this.menus.length > 0) {
              this.getGroupedMenus();
              localStorage.setItem("msal.menus", JSON.stringify(this.menus));
            }
            this.loadingService.clearMessage();
            resolve(true);
          });
    });
  }

  public getMenusFromlocalStorage() {
    this.menus = JSON.parse(localStorage.getItem("msal.menus")) as MenuModel[];

    this.getGroupedMenus();

  }

  private getGroupedMenus() {
    var adminMenu = this.menus.find(x => x.Name == 'Administration');
    if (adminMenu != undefined) {
      this.adminMenus = this.menus.filter(x => x.MenuId == adminMenu.MenuId || x.ParentMenuId == adminMenu.MenuId);
    }
    var supportMenu = this.menus.find(x => x.Name == 'Support');
    if (supportMenu != undefined) {
      this.supportMenus = this.menus.filter(x => x.MenuId == supportMenu.MenuId || x.ParentMenuId == supportMenu.MenuId);
    }

    if (adminMenu != undefined && supportMenu != undefined) {
      this.nonAdminMenus = this.menus.filter(x => x.MenuId != adminMenu.MenuId && x.ParentMenuId != adminMenu.MenuId
        && x.MenuId != supportMenu.MenuId && x.ParentMenuId != supportMenu.MenuId);
    }
    else if (adminMenu != undefined) {
      this.nonAdminMenus = this.menus.filter(x => x.MenuId != adminMenu.MenuId && x.ParentMenuId != adminMenu.MenuId);
    }
    else if (supportMenu != undefined) {
      this.nonAdminMenus = this.menus.filter(x => x.MenuId != supportMenu.MenuId && x.ParentMenuId != supportMenu.MenuId);
    }
  }

}
