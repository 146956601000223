import { AddressModel } from '../../address/address.model';
export class RequesterInformationModel {
 
    RequesterInformationId? : number;
    RequestId? :number
    DisplayName? : string;
    Email? : string;
    ContactNumber? : string;
    RequesterAddressId?: number;
    RequesterAddress?: AddressModel;
    IsExternalAddress?: boolean;
    DeliveryDate?: Date;
    SiteName?: string;
}
