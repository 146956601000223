export class UserLookUpModel {
   
    UserName? : string
    FirstName? : string
    MiddleName? : string
    LastName ? : string
    DisplayName ? : string
    Email? : string 
    JobTitle? : string
    MobilePhone? : string
    OfficeLocation? : string
    City? : string
    State? : string
    Country? : string
    CompanyName? : string
    Department? : string
    StreetAddress? : string
    UsageLocation? : string
    PostalCode? : string
}






