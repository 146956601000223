<p-toast position="top-right"></p-toast>
<h5> Request Management </h5>

<div class="col-12">
    <p-radioButton name="requestManagementGroup" value="Mine" label="My Requests" [(ngModel)]="requestOption">
    </p-radioButton>&nbsp;&nbsp;
    <p-radioButton name="requestManagementGroup" value="Favourites" label="My Favorites" [(ngModel)]="requestOption">
    </p-radioButton>&nbsp;&nbsp;
    <p-radioButton name="requestManagementGroup" value="AdvancedSearch" label="Advanced Search"
        [(ngModel)]="requestOption">
    </p-radioButton>
</div>

<app-my-requests *ngIf="requestOption == 'Mine'" [requestOption]="requestOption"></app-my-requests>
<app-favourite-requests *ngIf="requestOption == 'Favourites'" [requestOption]="requestOption"></app-favourite-requests>
<app-advanced-search *ngIf="requestOption == 'AdvancedSearch'"></app-advanced-search>