import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AddressService } from '../../services/address-service/address.service'
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RequestService } from '../../services/request-service/request.service';
import { trigger, state, style, animate, transition, keyframes, AnimationEvent } from '@angular/animations';
import { AddShippingAddressComponent } from '../add/add-shipping-address.component';
import { CountryService } from 'src/app/services/country-service/country.service';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';



@Component({
  selector: 'add-internal-shipping-address',
  templateUrl: './add-internal-shipping-address.component.html',
  styleUrls: ['../add/add-shipping-address.component.css'],
  providers: [AddressService, ConfirmationService, MessageService, RecipientService
    , RequestService],
  animations: [
    trigger('selectedAddressAnimation', [
      state('selected', style({
      })),
      transition('initial => selected', animate('1000ms', keyframes([
        style({ backgroundColor: 'white', offset: 0 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(0.1)', offset: 0.5 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(1)', offset: 1 })
      ])))
    ])
  ]
})

export class AddInternalShippingAddressComponent extends AddShippingAddressComponent implements OnInit {

  public msgs: any[];
  constructor(recipientService: RecipientService, messageService: MessageService,
    confirmationService: ConfirmationService, addressService: AddressService,
    requestService: RequestService, countryService: CountryService,
    requestItemService: RequestItemService
  ) {
    super(recipientService, messageService, confirmationService, addressService, requestService, countryService, requestItemService);


  }

  ngOnInit() {
    this.getRequestRecipient(this.requestModel.RequestNumber);
  }

  //----------------------------------------------------------
  // Fire when user select user from recipient search text bxo
  //-----------------------------------------------------------
  public recipientOnSelect() {
    this.validateSelectedRecipient();
    this.onCloseAddRecipientPopUp();

  }

  public newRecipient($event) {
    this.userLookUpModel = $event;
    this.recipientOnSelect()
  }

}
