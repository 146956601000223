import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { AddressService } from '../../services/address-service/address.service';
import { FilterService } from '../../services/filter-service/filter.service';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RecipientAddressService } from '../../services/recipient-address/recipient-address.service';
import { FunctionService } from '../../services/function-service/function.service';
import { RecipientProfileComponent } from '../recipient-profile/recipient-profile.component';
import { RecipientModel } from '../recipient-profile/recipient.model';
import { UserLookUpModel } from 'src/app/user-look-up/user-look-up.model';
import { ValidationService } from 'src/app/services/validation-service/validation-service';
import { constants } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common-service/common.service';

@Component({
    selector: 'external-recipient-profile',
    templateUrl: './external-recipient-profile.component.html',
    styleUrls: ['../recipient-profile/recipient-profile.component.css'],
    providers: [MessageService, ConfirmationService, RecipientService, AddressService, FilterService, RecipientAddressService, FunctionService, ValidationService,CommonService]
})
export class ExternalRecipientProfileComponent extends RecipientProfileComponent implements OnInit {

    internalContactLookupModel: UserLookUpModel;
   
    constructor(router: Router, _formBuilder: UntypedFormBuilder,
        confirmationService: ConfirmationService,
        messageService: MessageService, recipientService: RecipientService,
        addressService: AddressService, filterService: FilterService,
        recipientAddressService: RecipientAddressService,functionService: FunctionService,
        commonSerive:CommonService,
        private validationService: ValidationService
    ) {

        super(router, _formBuilder, confirmationService, messageService, recipientService,
            addressService, filterService, recipientAddressService,commonSerive)
        this.recipientOption = 'External';
        this.isAdminstrator = this.commonService.checkAdministrator();     
    }

    ngOnInit() {
        this.filterColumns = [{ 'Text': 'Name', 'Column': 'DisplayName' },
        { 'Text': 'Country', 'Column': 'Country' },
        { 'Text': 'Company Name', 'Column': 'LegalName' },
        { 'Text': 'Internal Contact', 'Column': 'InternalContactName' },
        { 'Text': 'Show Profiles without Address', 'Column': 'Address' }
        ];
        this.canEditRecipientProfile();       
        this.getRecipientProfiles(this.recipientOption);
    }

    //----------------------------------------------
    // Method to save Internal Contact on Edit
    //-----------------------------------------------
    public internalContactOnSelect(recipient: RecipientModel) {
        recipient.InternalContactName = this.internalContactLookupModel.DisplayName;
        recipient.InternalContactEmail = this.internalContactLookupModel.Email;

        this.recipientService.update(recipient, this.recipientOption).subscribe(res => {
            this.enableDisableEditControl('divEditInternalContact' + recipient.RecipientId,
                'editInternalContact' + recipient.RecipientId,
                'divEditInternalContactIcon' + recipient.RecipientId,
                'divEditInternalContactCancelIcon' + recipient.RecipientId, 'block');
            this.messageService.add({ severity: 'success', summary: 'Internal Contact updated successfully', detail: '', life: 500 });

        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating' });
            }
        );
    }


    //-------------------------------------------------------------------------------
    // Method to enable editable sein-user search control to select Supervisor
    //-------------------------------------------------------------------------------
    public editInternalContactButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditInternalContact' + recipient.RecipientId,
            'editInternalContact' + recipient.RecipientId,
            'divEditInternalContactIcon' + recipient.RecipientId,
            'divEditInternalContactCancelIcon' + recipient.RecipientId, 'none');
        this.internalContactLookupModel = this.setRecipientObject(recipient);
    }

    //-------------------------------------------------------------------------------
    // Method to cancel edit control
    //-------------------------------------------------------------------------------
    public editInternalContactCancelButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditInternalContact' + recipient.RecipientId,
            'editInternalContact' + recipient.RecipientId,
            'divEditInternalContactIcon' + recipient.RecipientId,
            'divEditInternalContactCancelIcon' + recipient.RecipientId, 'block');
    }

    //----------------------------------------------
    // Method to save LegalName on Edit
    //-----------------------------------------------
    public legalNameOnBlur(recipient: RecipientModel) {
        this.recipientService.update(recipient, this.recipientOption).subscribe(res => {
            this.enableDisableEditControl('divEditLegalName' + recipient.RecipientId,
                'editLegalName' + recipient.RecipientId,
                'divEditLegalNameIcon' + recipient.RecipientId,
                'divEditLegalNameCancelIcon' + recipient.RecipientId, 'block');
            this.messageService.add({ severity: 'success', summary: 'Company Name updated successfully', detail: '', life: 500 });

        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating' });
            }
        );
    }

    //-------------------------------------------------------------------------------
    // Method to enable editable control to provide LegalName
    //-------------------------------------------------------------------------------
    public editLegalNameButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditLegalName' + recipient.RecipientId,
            'editLegalName' + recipient.RecipientId,
            'divEditLegalNameIcon' + recipient.RecipientId,
            'divEditLegalNameCancelIcon' + recipient.RecipientId, 'none');
    }

    //-------------------------------------------------------------------------------
    // Method to cancel edit control
    //-------------------------------------------------------------------------------
    public editLegalNameCancelButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditLegalName' + recipient.RecipientId,
            'editLegalName' + recipient.RecipientId,
            'divEditLegalNameIcon' + recipient.RecipientId,
            'divEditLegalNameCancelIcon' + recipient.RecipientId, 'block');
    }

    //-------------------------------------------------------------------------------
    // Method to enable editable control to provide Email
    //-------------------------------------------------------------------------------
    public editEmailButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditEmail' + recipient.RecipientId,
            'editEmail' + recipient.RecipientId,
            'divEditEmailIcon' + recipient.RecipientId,
            'divEditEmailCancelIcon' + recipient.RecipientId, 'none');
    }

    //-------------------------------------------------------------------------------
    // Method to cancel edit control
    //-------------------------------------------------------------------------------
    public editEmailCancelButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditEmail' + recipient.RecipientId,
            'editEmail' + recipient.RecipientId,
            'divEditEmailIcon' + recipient.RecipientId,
            'divEditEmailCancelIcon' + recipient.RecipientId, 'block');
    }


    //----------------------------------------------
    // Method to save Email on Edit
    //-----------------------------------------------
    public emailOnBlur(recipient: RecipientModel) {
        if (this.validationService.validateEmail(recipient.Email)) {
            var splitEmail = recipient.Email.split("@");
            if (splitEmail[1].toLowerCase() != constants.CORTEVA_EMAIL_DOMAIN_NAME) {
                this.recipientService.getRecipientExists(recipient.Email).subscribe(res => {
                    this.serviceResponse = res;
                    var existingRecipient = this.serviceResponse;
                    if (existingRecipient.RecipientId == 0) {
                        this.recipientService.update(recipient, this.recipientOption).subscribe(res => {
                            this.enableDisableEditControl('divEditEmail' + recipient.RecipientId,
                                'editEmail' + recipient.RecipientId,
                                'divEditEmailIcon' + recipient.RecipientId,
                                'divEditEmailCancelIcon' + recipient.RecipientId, 'block');
                            this.messageService.add({ severity: 'success', summary: 'Email updated successfully', detail: '', life: 500 });

                        },
                            error => {
                                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating' });
                            }
                        );
                    }
                    else {
                        this.messageService.add({ severity: 'error', summary: '', detail: 'Recipient Email already exists!' });
                    }
                },
                    error => {
                        this.messageService.add({ severity: 'error', summary: '', detail: error });
                    });
            }
            else {
                {
                    this.messageService.add({ severity: 'error', summary: '', detail: "External recipient cannot be associated to Corteva email id" })
                }
            }
        }
        else {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Please provide valid Email' });
        }
    }

    //----------------------------------------------------------------------
    // Method to filter records based on provided column and its value
    //----------------------------------------------------------------------
    public filter(column, value, filterColumns: any[], results: RecipientModel[]) {
        this.results = this.filterService.filter(column, value, filterColumns, results, false);
        this.showWithoutAddressOnChange();
    }

    public clearFilter() {
        this.filterColumns.forEach(element => {
            var obj = document.getElementById('input' + element.Column + 'Filter');
            (<HTMLInputElement>obj).value = '';
        });

        (<HTMLInputElement>document.getElementById('inputAddressFilter')).checked = false;

        this.results = this.filterService.filter('', '', this.filterColumns, this.dataList, true);
    }

    public showWithoutAddressOnChange() {
        let callFilter = false;
        const checked = (document.getElementById('inputAddressFilter') as HTMLInputElement).checked;
        if (checked) {
            this.results = this.dataList.filter(c => c.Country == '');
        }
        else {
            this.results = this.dataList;
        }

        this.filterColumns.forEach(element => {
            const obj = document.getElementById('input' + element.Column + 'Filter');
            if ((obj as HTMLInputElement).value != '') {
                callFilter = true;
                return;
            }
        });

        if (callFilter) {
            this.results = this.filterService.filter('', '', this.filterColumns, this.results, false);
        }
    }
    
    public canEditRecipientProfile()
    {
        this.isAdminstrator = this.commonService.checkAdministrator(); 
//        let isInternalContact = this.selectedRecipientModel !== null && this.selectedRecipientModel !== undefined && localStorage.getItem("loggedInUsername") == this.selectedRecipientModel.InternalContactEmail;
//        this.isRecipientProfileEditable = isInternalContact || this.isAdminstrator ? true : false ;
        this.isRecipientProfileEditable = true;
        return true;

    }
}

