import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingService } from '../../loading/res-loading.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    // This variable is used to show loader when muuliple http call are made.
    private totalRequests = 0;
    constructor(public loadingService: LoadingService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes('graph')) {
            this.totalRequests++;
            this.loadingService.setMessage('Loading...');
            return next.handle(req).pipe(
                finalize(() => {
                    this.totalRequests--;
                    if (this.totalRequests === 0) {
                        this.loadingService.clearMessage()
                    }
                })
            );
        }

        return next.handle(req).pipe(
            finalize(() => { })
        );
    }
}