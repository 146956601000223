import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserService } from '../services/user-service/user.service';
import { UserModel } from '../user/user.model';
import { constants } from '../constants/constants'
import { CommonService } from '../services/common-service/common.service';
import { RequestTypeService } from '../services/request-type-service/request-type.service';
import { RequestTypeModel } from '../request-type/request-type.model';
import { RequestType } from '../request-type/request-type.enum';
import { FunctionModel } from '../function/function.model';
import { DelegateModel } from './delegate.model';
import { DelegateService } from '../services/delegate-service/delegate.service';

@Component({
    selector: 'delegate',
    templateUrl: './delegate.component.html',
    styleUrls: ['./delegate.component.css'],
    providers: [UserService, MessageService, CommonService, RequestTypeService, ConfirmationService, DelegateService]
})
export class DelegateComponent implements OnInit {

    delegateTableHeaders: any[];
    requesters: any[];
    forecastRequester: any;
    users: UserModel[];
    selectedUsers: UserModel[];
    selectedRequester: UserModel;
    requestTypeList: RequestTypeModel[];
    selectedRequestType: RequestTypeModel;
    delegators: any[];
    selectedDelegator: UserModel;
    selectedFunction: string;
    user = new UserModel;
    displayDialog: boolean = false;
    isEditable: boolean = false;
    dialogHeaderText: any = "Map Delegator";
    editUser: any;
    requesterDomElement: HTMLElement;
    requestTypeDomElement: HTMLElement;
    delegatorDomElement: HTMLElement;
    contentStyleObject: object = {
        'max-height': '75vh',
        'overflow': 'auto',
        'max-width': '730px',
        'min-width': '730px'
    }

    delegate = new DelegateModel();
    delegates: DelegateModel[];
    serviceResponse: any;
    selectedDelegate: DelegateModel;
    filterColumnWidth: object = { 'width': '100%' };
    isRequester: boolean = false;
    isAdmin: boolean = false;
    isSupport: boolean = false;


    constructor(private confirmationService: ConfirmationService, private userService: UserService, private messageService: MessageService, private commonService: CommonService,
        private requestTypeService: RequestTypeService, private delegateService: DelegateService) { }
    ngOnInit() {

        this.isRequester = this.commonService.checkRequester();
        this.isAdmin = this.commonService.checkAdministrator();
        this.isSupport = this.commonService.checkSupport();

        this.getDelegators();
        this.delegateTableHeaders = [
            { field: 'RequesterName', header: ' Requester', display: 'table-cell', width: '12%' },
            { field: 'DelegatorName', header: ' Delegate', display: 'table-cell', width: '12%' },
            { field: 'RequestTypeName', header: ' Request Type', display: 'table-cell', width: '9%' },
            { field: 'Function', header: ' Function', display: 'table-cell', width: '9%' },
            { field: 'Supervisor', header: ' Supervisor', display: 'table-cell', width: '12%' },
            { field: 'Email', header: ' Email Id', display: 'table-cell', width: '16%' },
            { field: 'ContactNumber', header: ' Contact Number', display: 'table-cell', width: '11%' },
            { field: 'Country', header: ' Country', display: 'table-cell', width: '11%' }
        ]
    }

    AddnewItem() {
        this.getUsers();
        this.user = {};
        this.displayDialog = true;
        this.isEditable = false;
        this.dialogHeaderText = "Map Delegator";
        this.selectedRequester = undefined;
        this.requestTypeList = [];
        this.selectedFunction = undefined;
        this.selectedDelegator = undefined;
    }

    private getUsers() {
        this.userService.getUsers().subscribe(response => this.users = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            },
            () => {

                this.users.forEach(s => (
                    s.DisplayName = s.FirstName.concat(' ', s.LastName),
                    s.RoleName = s.UserRequestTypeRoleList.filter(u => u.UserId == s.UserId).map(m => m.RoleName).
                        //To get distinct item in array.
                        // arryItem = > item in array, itemIndex=> item index, arry=> array reference
                        filter((arryItem, itemIndex, arry) => arry.indexOf(arryItem) == itemIndex).join(', '),
                    s.RequestTypeName = s.UserRequestTypeRoleList.filter(u => u.UserId == s.UserId).
                        map(m => m.RequestTypeName).filter((arryItem, itemIndex, arry) => arry.indexOf(arryItem) == itemIndex).join(', ')
                ));
                this.users.sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : -1);
                this.getRequesters();
            })
    }


    private getRequesters() {
        this.requesters = this.users.filter(c => c.UserRequestTypeRoleList.find(x => x.RoleName.toLowerCase() == constants.REQUESTER_ROLE));
        this.selectedRequester = this.requesters.filter(c => c.Email == localStorage["loggedInUsername"])[0];
        if (this.isEditable) {
            this.selectedRequester = this.users.find(s => s.UserId == this.selectedDelegate.RequesterId);
        }
        else if (this.isRequester && !this.isAdmin && !this.isSupport) {
            this.requesters = this.users.filter(c => c.Email == localStorage["loggedInUsername"]);
            this.selectedRequester = this.requesters[0];
        }
        this.getRequestTypeList();
        
    }

    private getRequestTypeList() {
        this.requestTypeService.getRequestTypesByEmail(this.selectedRequester.Email).subscribe(response => {
            this.requestTypeList = response;
            if (this.requestTypeList.find(a => a.Name === 'P2P') === undefined) {
                let rtp2p = new RequestTypeModel();
                rtp2p.Name = 'P2P';
                rtp2p.RequestTypeId = 4;
                this.requestTypeList.push(rtp2p);
            }
            this.requestTypeList.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
            this.requestTypeList = this.requestTypeList.filter(s => s.Name == RequestType.InStock || s.Name == RequestType.GLP || s.Name == RequestType.PeerToPeer);

            if (!this.isEditable) {
                this.selectedRequestType = this.requestTypeList[0];
            }
            else {
                this.selectedRequestType = this.requestTypeList.find(s => s.RequestTypeId == this.selectedDelegate.RequestTypeId);
            }
            this.delegators = this.users.filter(c => this.selectedRequestType.Name === 'P2P' || c.UserRequestTypeRoleList.find(x => x.RoleName.toLowerCase() == constants.REQUESTER_ROLE) && c.UserRequestTypeRoleList.find(x => x.RequestTypeName == this.selectedRequestType.Name));
            if (this.isEditable) {
                this.selectedDelegator = this.delegators.find(s => s.UserId == this.selectedDelegate.DelegatorId);
                this.selectedFunction = this.selectedDelegate.Function;
            }

        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.error });
            })
    }

    public requesterOnChange() {

        if (this.requesterDomElement) {
            this.requesterDomElement.style.border = "1px solid #a6a6a6";
        }
        if (this.requestTypeDomElement) {
            this.requestTypeDomElement.style.border = "1px solid #a6a6a6"
        }
        this.getRequestTypeList();
    }

    public requestTypeOnChange() {
        this.delegators = this.users.filter(c => this.selectedRequestType.Name === 'P2P' || c.UserRequestTypeRoleList.find(x => x.RoleName.toLowerCase() == constants.REQUESTER_ROLE) && c.UserRequestTypeRoleList.find(x => x.RequestTypeName == this.selectedRequestType.Name));
    }

    public delegatorOnChange() {
        if (this.delegatorDomElement) {
            this.delegatorDomElement.style.border = "1px solid #a6a6a6";
        }
        this.selectedFunction = this.selectedDelegator.FunctionName;
    }

    public compareObject(obj: any, prop: string) {
        return (obj[prop] != '' || obj[prop] != null) ? true : false;
    }

    public compareObjects(objToCompare: any, objWithCompare: any, prop: any) {
        if (typeof (objToCompare[prop]) != 'object') {
            return (objToCompare[prop] !== objWithCompare[prop]) ? true : false;
        }
        else if (typeof (objToCompare[prop]) == 'object') {
            if (objToCompare[prop].length != objWithCompare[prop].length) {
                return true;
            }
            else {
                for (let i = 0; i < objToCompare[prop].length; i++) {
                    for (let pr in objToCompare[prop][i]) {
                        if (this.compareObjects(objToCompare[prop][i], objWithCompare[prop][i], pr)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    cancel() {
        var hasChanges: boolean = false;

        if (this.editUser == undefined) {
            for (let prop in this.selectedRequester) {
                if (this.compareObject(this.selectedRequester, prop)) {
                    hasChanges = true;
                    break;
                }
            }
        }
        else {
            for (let prop in this.user) {
                if (this.compareObjects(this.editUser, this.selectedRequester, prop)) {
                    hasChanges = true;
                    break;
                }
            }
        }

        if (hasChanges) {
            this.confirmationService.confirm({
                message: 'Are you sure you want to cancel?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.user = {};
                    this.ngOnInit();
                    this.resetFields();
                    this.displayDialog = false;
                },
                reject: () => {

                }
            });
        }
        else {
            this.user = {}
            this.resetFields();
            this.displayDialog = false;
        }
    }

    resetFields() {
        this.requesterDomElement = document.getElementById("requester").firstChild as HTMLElement;
        this.requestTypeDomElement = document.getElementById("requestType").firstChild as HTMLElement;
        this.delegatorDomElement = document.getElementById("delegator").firstChild as HTMLElement;
        this.requesterDomElement.style.border = "1px solid #a6a6a6";
        this.requestTypeDomElement.style.border = "1px solid #a6a6a6";
        this.delegatorDomElement.style.border = "1px solid #a6a6a6";
    }

    validateMandatoryControls() {
        this.requesterDomElement = document.getElementById("requester").firstChild as HTMLElement;
        this.requestTypeDomElement = document.getElementById("requestType").firstChild as HTMLElement;
        this.delegatorDomElement = document.getElementById("delegator").firstChild as HTMLElement;

        if (this.selectedRequester == undefined || this.selectedDelegator == undefined ||
            this.selectedRequestType == undefined) {

            if (this.selectedRequester == undefined) {
                this.requesterDomElement.style.border = "1px solid #dc3545"
            }
            if (this.selectedDelegator == undefined) {
                this.delegatorDomElement.style.border = "1px solid #dc3545"
            }
            if (this.selectedRequestType == undefined) {
                this.requestTypeDomElement.style.border = "1px solid #dc3545"
            }

            this.messageService.add({ severity: 'error', summary: '', detail: "Mandatory fields cannot be empty" });
            return false;
        }
        return true;
    }

    save() {
        if (this.validateMandatoryControls()) {
            console.log(this.delegate);
            this.delegate = {
                RequesterId: this.selectedRequester.UserId,
                DelegatorId: this.selectedDelegator.UserId,
                RequestTypeId: this.selectedRequestType.RequestTypeId
            }
            this.delegateService.createDelegate(this.delegate).subscribe(res => this.serviceResponse = res,
                error => {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
                },
                () => {
                    this.messageService.add({ severity: 'success', summary: '', detail: 'Requester-Delegate association created' });
                    this.displayDialog = false;
                    this.delegate = {};
                    this.ngOnInit();
                });
        }
    }

    private getDelegators() {
        this.delegateService.getDelegators().subscribe(response => this.delegates = response,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });

            },
            () => {

                if (this.isRequester && !this.isAdmin && !this.isSupport) {
                    this.delegates = this.delegates.filter(x => x.RequesterName == localStorage["msal.displayName"]);
                }
            })
    }

    delete(data) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete the Delegator?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                console.log(data);
                this.selectedDelegate = data;
                this.deleteDelegate();
            },
            reject: () => {

            }
        });
    }

    private deleteDelegate() {
        this.delegateService.deleteRequesterDelegator(this.selectedDelegate.RequesterDelegatorId).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Delegate deleted successfully' })
                this.ngOnInit();
            });
    }

    edit(data) {
        this.isEditable = true;
        this.getUsers();
        this.selectedDelegate = data;
        console.log(this.selectedDelegate);
        this.delegate = this.selectedDelegate;
        this.displayDialog = true;
        this.dialogHeaderText = "Edit";
    }

    update() {
        this.selectedDelegate.RequestTypeId = this.selectedRequestType.RequestTypeId;
        this.delegateService.updateDelegate(this.selectedDelegate).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.displayDialog = false;
                this.selectedDelegate = {};
                this.isEditable = false;
                this.ngOnInit();
            });
    }
}