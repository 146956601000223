

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';


import { ConfigurationSettings } from "../../configuration-settings"
import { RequestItemModel } from 'src/app/request/add-chemical-details/request-item.model';

@Injectable()
export class RequestItemAttachmentService {

    constructor(private _http: HttpClient) { }


    public saveRequestItemAttachment(files: any[], requestitem: RequestItemModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/items/" + requestitem.RequestItemId + "/attachments"
        let formData: FormData = new FormData();
        for (let file of files) {
            formData.append(file.DocumentTypeId +"^" + file.Comment, file, file.name); // Document type and comment are passed as name 
            
        }

        return this._http.post(
            url,
            formData).pipe(
                tap(x => console.log("Attached new file"))
            );
        //this.httpOptions
    }

    public downloadFile(requestId: number, requestItemId: number, attachmentId: number) {
        return this._http.get(ConfigurationSettings.REST_API_URL + "/requests/" + requestId + "/items/" + requestItemId + "/attachments/" + attachmentId, { responseType: 'blob' });
    }

    //-------------------------------------------------
    // To get attached file with request.
    //-------------------------------------------------
    public getRequestItemAttachmentList(requestId: Number, requestItemId: Number): any {

        let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestId + "/items/" + requestItemId + "/attachments";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched RequestItemAttachments")));
    }

    //-------------------------------------------------
    // To get document type list
    //-------------------------------------------------
    public GetDocumentTypeList(): any {

        let url = ConfigurationSettings.REST_API_URL + "/requests/DocumentTypes";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Document Type list")));
    }

    //------------------------------
    // To delete attachment from request
    //-------------------------------
    public deleteAttachment(requestId: number, requestItemId: number, requestItemAttachmentId: number): any {

        let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestId + "/items/" + requestItemId + "/attachments/" + requestItemAttachmentId;
        return this._http.delete(url).pipe(
            tap(x => console.log("Deleted Attachment")));
    }

      //-------------------------------------------------
    // To get packed with choices list
    //-------------------------------------------------
    public getPackedWithChoicesList(): any {

        let url = ConfigurationSettings.REST_API_URL + "/requests/PackedWithChoices";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Packed With Choices list")));
    }
}