import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ConfigurationSettings } from "../.././configuration-settings"


@Injectable()
export class SecurityService {

    constructor(private _http: HttpClient) { }

    /* Validating User */
    public validateUser(username: string): any {
        return this._http.get(ConfigurationSettings.REST_API_URL + "/authentication/user?username=" + username)
            .pipe(map(response => response));
    }
}