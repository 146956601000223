export enum RequestStatusType {
    Draft = 'Draft',
    Submitted = 'Submitted',
    Ordered = 'Ordered',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
    MaterialApproval = 'Material Approval',
    ITCRejected = 'ITC Rejected',
    AwaitingITC= 'Awaiting ITC',
    InProcess = 'In Process',
    Shipped = 'Shipped',
  }