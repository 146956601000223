import { Component, OnInit, ViewChild } from '@angular/core';
import {  DataView } from 'primeng/dataview';
import { ApprovalService } from '../services/approval-service/approval.service';
import { ApproveRequestModel } from '../services/approval-service/approve-request-model';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { UserService } from '../services/user-service/user.service';
import { UserModel } from '../user/user.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isValid: boolean = true; 
  approvalComment: string = '';
  materialApprovalModel:any=[];
  confirmationMessageDialog:boolean= false;
  dontShowConfirmation:boolean = false;
   totalCount: number;
   pageIndex: number=1;
   PAGE_SIZE: number = 25;
   selectedRejectReason: string;
   targetRequestItemId:number;
  rejectComment: string;
  rejectReasons: Array<any> = [
    {name: 'Select a reason for rejection', code: 0},
    {name: 'Inappropriate destinations', code: 1} , 
    {name: 'Material cannot be exported', code: 2}
  ,{name: 'Incorrect amount requested', code: 3}, 
  {name: 'Missing permit / Wrong document', code: 4}];
  showRejectCommentDialog: boolean;
   @ViewChild('dv') dataView: DataView;
  constructor(protected router: Router,public approvalService:ApprovalService, private readonly userService: UserService) { }

  ngOnInit() {
    this.GetRequestItem();
    this.userService.getUser(localStorage["loggedInUsername"]).pipe(
      take(1)
    ).subscribe(user=>{this.isAdmin = user.UserRequestTypeRoleList.some(urt=>urt.RoleId==2);});
  }

  pageChanged(event: any) {
    this.pageIndex = (event.first / this.PAGE_SIZE) + 1;
    this.GetRequestItem(false);
  }
  GetRequestItem(setCount:boolean = true)
  {
    this.approvalService.getMaterialApprovalRequestItems(localStorage["loggedInUsername"], this.pageIndex-1, this.PAGE_SIZE).subscribe(data => {
      this.materialApprovalModel = data.Approvals;
      
      if (setCount ) {
        this.pageIndex = 1;
        this.totalCount = data.TotalCount;
        this.dataView.first = 0;
      }
      console.log(data);
   });
  }

  rejectItem(requestItemId: number) {
    this.targetRequestItemId = requestItemId;
    this.rejectComment = '';
    this.selectedRejectReason = '';
    this.showRejectCommentDialog = true;
  }

  CloseApproveConfirmation() {
    this.confirmationMessageDialog = false;
  }

  approveRequestItem(requestItemId: number){
    const requestItem = this.materialApprovalModel.filter(mp=>mp.RequestItemId == requestItemId)?.[0];
    this.isApprover = localStorage["loggedInUsername"] == requestItem.ApproverEmail;
    if(this.isAdmin && !this.isApprover){
      this.isValid = this.approvalComment.length > 0;
    }
    else{
      this.isValid = true;
    }

    this.targetRequestItemId = requestItemId;
    var dontShowApproverDlg = sessionStorage.getItem("dontShowConfirmation");

    if(!this.isApprover){
      this.confirmationMessageDialog = true;
    }
    else{
      if(dontShowApproverDlg != 'hide'){
        this.confirmationMessageDialog = true;
      }
      else
      {
        this.approveItem();
      }
    }
  }

  
  approveItem() {
    var dontShowApproverDlg = sessionStorage.getItem("dontShowConfirmation");
    if(dontShowApproverDlg != 'hide') {
      sessionStorage.setItem("dontShowConfirmation", this.dontShowConfirmation ? 'hide' : 'show');
    }
    let approval = new ApproveRequestModel();
    approval.approvalTimeStamp = new Date();
    approval.approvedByUserId = localStorage["msal.userId"];
    approval.isApproved = true;
    approval.comment = this.approvalComment;
    approval.requestItemId =this.targetRequestItemId;
    approval.commentType = 'ApprovalGranted';
    this.confirmationMessageDialog = false;
    this.UpdateApproval(approval);
  }

  ReturnForReview() {
    this.showRejectCommentDialog = false;
    let approval = new ApproveRequestModel();
    approval.approvalTimeStamp = new Date();
    approval.approvedByUserId = localStorage["msal.userId"];
    approval.comment = this.rejectComment;
    approval.requestItemId = this.targetRequestItemId;
    this.approvalService.returnForReview(approval)
    .subscribe(data => {
      this.GetRequestItem();
      console.log("success");
  },
  error => {
    console.log(error);
  });
  }

  RejectRequestWithComment() {
    this.showRejectCommentDialog = false;
    let approval = new ApproveRequestModel();
    approval.approvalTimeStamp = new Date();
    approval.approvedByUserId =localStorage["msal.userId"];
    approval.isApproved = false;
    approval.comment = this.rejectComment;
    approval.requestItemId = this.targetRequestItemId;
    approval.commentType = 'ApprovalRejected'
    this.UpdateApproval(approval);
  }

  UpdateApproval(approveRequestModel:ApproveRequestModel)
  {
    this.approvalService.updateApproval(approveRequestModel).subscribe(x => {
      this.GetRequestItem();
      this.approvalComment='';
    });
  }

  openRequest(requestItem: any) {
    
  }

  onChangeSelectedRejectReason(): void {
    this.rejectComment = this.selectedRejectReason['code'] == 0 ? '' : this.selectedRejectReason['name'];
  }

  onCommentChange(){
    this.isValid = this.isAdmin && !this.isApprover && this.approvalComment.length > 0;
  }

  onHide(){
    this.approvalComment='';
  }

}
