import { Component, OnInit, Input } from '@angular/core';
import { RequestModel } from '../../request/request.model';
import { RequesterInformationModel } from '../../requester-information/requester-information.model';



@Component({
  selector: 'app-requester-details',
  templateUrl: './requester-details.component.html',
  styleUrls: ['./requester-details.component.css'],
  providers: []
})
export class RequesterDetailsComponent implements OnInit {

  @Input() requesterInformationModel?: RequesterInformationModel;
  @Input() requestModel?: RequestModel
  constructor() {

  }

  ngOnInit() {
    this.requesterInformationModel = this.requestModel.RequesterInformationModel

  }
}