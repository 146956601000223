import { EnvironmentDefinition } from "./environment-defination.interface"
import { InteractionType, BrowserCacheLocation } from "@azure/msal-browser";

/* 
    This is the class where i can define the key and its value 
    and it will transform the value of the key based on environment.

    --> If you want to add a new key and its value in the below section 
        then first you define that key in the EnvironmentDefinition interface. 
*/
export class Environments {

    public static environments: EnvironmentDefinition[] = [
        {
            name: 'LOCAL',
            clientID: "6c532256-e670-4e57-bc85-3949fb1b4b62",
            hosts: ['localhost'],
            appInsightsKey: 'efc628f7-b99f-4d24-94f7-02e82b5cc965',
            ApiPath: 'https://localhost:44390',
            UIPath: 'http://localhost:4200',
            farmApiPath:'https://localhost:44393',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CPSampleShopBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "LclSession"
            },
            msal: {
                config: {
                    auth: {
                        clientId: "6c532256-e670-4e57-bc85-3949fb1b4b62",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "http://localhost:4200/",
                        postLogoutRedirectUri: "http://localhost:4200/#/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://localhost:44390', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://localhost:44393', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://gsscommon-api-dev.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]
                            
                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess"]
                      }
                }
            },
            featureFlags: {
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://forecasting-dev.azurewebsites.net/',
                coreUrl: 'https://cp-compoundutilities-dev.phibred.com/',
                raceUrl:'https://raced.phibred.com/Home/Index',
                hostAppApiUrl: 'https://cpsampleshop-dev.azurewebsites.net',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-dev.azurewebsites.net',
                pageLength: 5,
                showCartButton: true,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'DV',
            clientID: "6c532256-e670-4e57-bc85-3949fb1b4b62",
            hosts: ['dev'],
            appInsightsKey: 'efc628f7-b99f-4d24-94f7-02e82b5cc965',
            ApiPath: 'https://cpsampleshopApi-dev.se.research.corteva.com',
            UIPath: 'https://cpsampleshop-dev.se.research.corteva.com',
            farmApiPath:'https://farmApi-dev.se.research.corteva.com',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CPSampleShopBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "DvSession"
            },
            msal: {
                config: {
                    auth: {
                        clientId: "6c532256-e670-4e57-bc85-3949fb1b4b62",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://cpsampleshop-dev.se.research.corteva.com/",
                        postLogoutRedirectUri: "https://cpsampleshop-dev.se.research.corteva.com/#/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://cpsampleshopApi-dev.se.research.corteva.com', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://farmApi-dev.se.research.corteva.com', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://gsscommon-api-dev.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]
                            
                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess"]
                      }
                }
            },
            featureFlags: {
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://forecasting-dev.azurewebsites.net/',
                coreUrl: 'https://cp-compoundutilities-dev.phibred.com/',
                raceUrl:'https://raced.phibred.com/Home/Index',
                hostAppApiUrl: 'https://cpsampleshopApi-dev.se.research.corteva.com',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-dev.azurewebsites.net',
                pageLength: 5,
                showCartButton: true,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'QA',
            clientID: "6c532256-e670-4e57-bc85-3949fb1b4b62",
            hosts: ['qa'],
            appInsightsKey: 'e96457da-e523-4012-90f7-8186b0456d93',
            ApiPath: 'https://cpsampleshopApi-qa.se.research.corteva.com',
            UIPath: 'https://cpsampleshop-qa.se.research.corteva.com',
            farmApiPath:'https://farmApi-qa.se.research.corteva.com',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CPSampleShopBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "QASession"
            },
            msal: {
                config: {
                    auth: {
                        clientId: "6c532256-e670-4e57-bc85-3949fb1b4b62",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://cpsampleshop-qa.se.research.corteva.com/",
                        postLogoutRedirectUri: "https://cpsampleshop-qa.se.research.corteva.com/#/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://cpsampleshopApi-qa.se.research.corteva.com', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://farmApi-qa.se.research.corteva.com', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://gsscommon-api-qa.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]
                            
                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess"]
                      }
                }
            },
            featureFlags: {
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://facts.qa.research.corteva.com/',
                coreUrl: 'https://cp-compoundutilities-test.phibred.com/',
                raceUrl:'https://raceq.phibred.com/Home/Index',
                hostAppApiUrl: 'https://cpsampleshopApi-qa.se.research.corteva.com',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-qa.azurewebsites.net',
                pageLength: 5,
                showCartButton: true,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'UAT',
            clientID: "6c532256-e670-4e57-bc85-3949fb1b4b62",
            hosts: ['uat'],
            appInsightsKey: '79079d8e-9875-47ec-af2d-a7afd85d10e3',
            ApiPath: 'https://cpsampleshopApi-uat.se.research.corteva.com',
            UIPath: 'https://cpsampleshop-uat.se.research.corteva.com',
            farmApiPath:'https://farmApi-uat.se.research.corteva.com',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CPSampleShopBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "QASession"
            },
            msal: {
                config: {
                    auth: {
                        clientId: "6c532256-e670-4e57-bc85-3949fb1b4b62",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://cpsampleshop-uat.se.research.corteva.com/",
                        postLogoutRedirectUri: "https://cpsampleshop-uat.se.research.corteva.com/#/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://cpsampleshopApi-uat.se.research.corteva.com', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://farmApi-uat.se.research.corteva.com', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://gsscommon-api-uat.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]
                            
                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess"]
                      }
                }
            },
            featureFlags: {
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://facts.qa.research.corteva.com/',
                coreUrl: 'https://cp-compoundutilities-test.phibred.com/',
                raceUrl:'https://raceq.phibred.com/Home/Index',
                hostAppApiUrl: 'https://cpsampleshopApi-uat.se.research.corteva.com',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-uat.azurewebsites.net',
                pageLength: 5,
                showCartButton: true,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'PROD',
            clientID: "f0fb150c-2892-4c16-9215-808f583a6eee",
            hosts: ['prod', 'cpsampleshop.'],
            appInsightsKey: '2a783470-06ec-4fe7-bc69-23736e12bad7',
            ApiPath: 'https://cpsampleshopApi.se.research.corteva.com',
            UIPath: 'https://cpsampleshop.se.research.corteva.com',
            farmApiPath:'https://farmApi.se.research.corteva.com',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CPSampleShopBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "PdSession"
            },
            msal: {
                config: {
                    auth: {
                        clientId: "f0fb150c-2892-4c16-9215-808f583a6eee",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://cpsampleshop.se.research.corteva.com/",
                        postLogoutRedirectUri: "https://cpsampleshop.se.research.corteva.com/#/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://cpsampleshopApi.se.research.corteva.com', ['api://f0fb150c-2892-4c16-9215-808f583a6eee/ReadAccess']],
                        ['https://farmApi.se.research.corteva.com', ['api://gssforecasting-prod/Api.Write', 'api://gssforecasting-prod/Api.Read']],
                        ['https://gsscommon-api-prod.azurewebsites.net/', ['api://gsscommon-prod/API.FullAccess']]
                            
                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://f0fb150c-2892-4c16-9215-808f583a6eee/ReadAccess"]
                      }
                }
            },
            featureFlags: {
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://facts.research.corteva.com/',
                coreUrl: 'https://cp-compoundutilities.phibred.com/',
                raceUrl:'https://race.phibred.com/Home/Index',
                hostAppApiUrl: 'https://cpsampleshopApi-prod.azurewebsites.net',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-prod.azurewebsites.net',
                pageLength: 5,
                showCartButton: true,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        }
    ];
}