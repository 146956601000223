import { RequestItemContainerModel } from '../add-container/request-item-container.model';
import { RequestItemAttachmentModel } from '../request-item-attachments/request-item-attachments.model';


export class RequestItemModel {

    RequestItemId?: number
    RequestId?: number
    RequestTypeId?: number
    ChemicalName?: string
    MaterialId: string;
    MaterialSearchType: string;
    RepoName?: string
    Synonyms?: string
    Country?: string
    LotNumber?: string
    RecertificationDate?: string
    AmountAvailable?: string
    TSNNumber?: string
    FormulationType?: string
    FarmLocationId?:number;
    ForecastId?:number;
    RequestNumber: string;
    ShipNow: boolean = false;
    NeedByDate: Date;
    Priority: string;
    SendInvoiceBefore: boolean = false;
    SDS: boolean = false;
    COO: boolean = false;
    CoA: boolean = false;
    OtherDoc: boolean = true;
    OtherDocText: string;

    UnitOfMeasureId: string;
    UnitOfMeasure: string;
    TotalRequestedQuantity: number = 0;
    NumberOfContainer: number;
    AmountPerContainer: number;
    ContainerLabel: string;
    Display: boolean = false;
    RequestItemContainerList: RequestItemContainerModel[] = [];
    RequestItemAttachmentList: RequestItemAttachmentModel[] = [];
    RequestItemComment: any = [];
    AmountForecasted: number;
    AmountRemaining: number;
    RequestLocationId: number = 0;
    IsReachCompliant: boolean=false;
    FarmInventoryStatus: string;
    FarmInventoryLocation: string;
    IsApproved?: boolean;
    ApprovedAt?: Date;
    ApproverName: string;
    ApproverEmail: string;
    isCustomLabel: boolean = false;
    IsUserDefinedContainerLabel?:boolean=false;
    requestedContainerLabel: string =  "";
    PackedWithChoiceId?: number;
    PackedWithChoiceName: string =  "";
    CustomsValue: number = 0;
    IsBioFormulation: boolean;
    IsPermitAvailable?: boolean;
    IsPermitRequired?: boolean;
}