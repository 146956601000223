import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AddressService } from '../../services/address-service/address.service'
import { ConfigurationSettings } from '../../configuration-settings';
import { UserLookUpModel } from '../../user-look-up/user-look-up.model';
import { AddressModel } from '../../address/address.model';
import { CountryModel } from '../../country/country.model';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RequestService } from '../../services/request-service/request.service';
import { RequestShipmentModel } from './request-shipment.model';
import { ToastModule } from 'primeng/toast';
import { RequestModel } from '../../request/request/request.model';
import { RequestRecipientModel } from '../../request-recipient/request-recipient.model';
import { RecipientModel } from '../../recipient-profile/recipient-profile/recipient.model';
import { trigger, state, style, animate, transition, keyframes, AnimationEvent } from '@angular/animations';
import { constants } from '../../constants/constants'
import { CountryService } from 'src/app/services/country-service/country.service';
import { RegionModel } from 'src/app/region/region.model';
import { RequestItemModel } from 'src/app/request/add-chemical-details/request-item.model';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';


@Component({
  selector: 'add-shipping-address',
  templateUrl: './add-shipping-address.component.html',
  styleUrls: ['./add-shipping-address.component.css'],
  providers: [AddressService, ConfirmationService, MessageService, RecipientService
    , RequestService, CountryService],

  animations: [
    trigger('selectedAddressAnimation', [
      state('selected', style({
      })),
      transition('initial => selected', animate('1000ms', keyframes([
        style({ backgroundColor: 'white', offset: 0 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(0.1)', offset: 0.5 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(1)', offset: 1 })
      ])))
    ])
  ]
})

export class AddShippingAddressComponent implements OnInit {

  @Input() shippingOption: string = '';
  @Input() requestModel: RequestModel;

  public growlMsgs: ToastModule[] = [];
  public serviceResponse: any;
  public restApiURL: string;

  public userLookUpModelList: UserLookUpModel[] = [];
  public userLookUpModel: UserLookUpModel;
  public addressModel: AddressModel = {
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0,
    OrgName : ''
  };
  public addressList: AddressModel[] = [];

  public showAddressDialog: boolean = false;
  public selectedCountry: CountryModel;
  public recipientModel: RecipientModel = { RecipientId: 0 };
  public requestRecipientModel: RequestRecipientModel;
  public page: number = null;
  public shippingAddress: AddressModel = {
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0,
    OrgName : ''
  };
  public region: RegionModel;
  public showCarousel = true;

  public AddressSelectionState: string = 'initial'; /// To track if the new address is selected
  showRecipientDialog: boolean = false;
  submitted:boolean = false;

  constructor(private recipientService: RecipientService, protected messageService: MessageService,
    protected confirmationService: ConfirmationService, private addressService: AddressService,
    private requestService: RequestService, protected countryService: CountryService,
    private requestItemService: RequestItemService,
  ) {

    this.messageService.clear('addressNotification');
    this.restApiURL = ConfigurationSettings.REST_API_URL + "/azure-ad/users/search?searchString=";

  }

  ngOnInit() {
    this.shippingOption = this.requestModel.ShippingOption != '' ? this.requestModel.ShippingOption : "Internal";
  }

  public handleTransitionDone(event: AnimationEvent): void {
    if (this.AddressSelectionState !== "initial") {
      this.AddressSelectionState = "initial";
    }
  }

  //---------------------------------------------------------------
  // This method will returns
  //  1. Recipient for the request
  //  2. Shipping address of the request
  //  3. All the available addresses of the recipient
  //--------------------------------------------------------------- 
  public getRequestRecipient(requestNumber: string) {
    this.requestService.getRequestShippingDetail(this.shippingOption, requestNumber).subscribe(response => this.serviceResponse = response,
      error => {
        this.showCarousel = true;
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.showCarousel = true;
        this.requestRecipientModel = this.serviceResponse;
        if (this.requestRecipientModel.Recipient.RecipientId > 0) {
          this.recipientModel = this.requestRecipientModel.Recipient;
          this.userLookUpModel = this.setRecipientObject(this.recipientModel);
          if (!this.recipientModel.IsActive && this.requestModel.RequestStatus == 'Draft') {
            this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "Selected Recipient profile has been disabled. Please select another recipient", detail: '' });
          }
          this.addressList = [];
          this.getAddressList(this.requestRecipientModel.RecipientAddressList);
          this.shippingAddress = this.requestRecipientModel.ShipmentAddressModel;
          this.shippingAddress.PersonFullName = this.userLookUpModel.DisplayName;
          var requestStatus = this.requestModel.RequestStatus;
          // Show message when status is in Draft.
          if (this.shippingAddress.IsParentUpdated && this.requestModel.RequestStatus == 'Draft') {
            this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "The selected address has been modified. Please select the correct address", detail: '' });
          }

          if (this.shippingAddress.IsParentDeleted && this.requestModel.RequestStatus == 'Draft') {
            this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "The selected address has been deleted. Please select another address", detail: '' });
          }
        }
      })
  }

  //---------------------------------------------------------------------
  // To set the properties of UserLookModel and return the object.
  //---------------------------------------------------------------------
  private setRecipientObject(recipientModel: RecipientModel): UserLookUpModel {
    var recipient = {
      FirstName: recipientModel.FirstName,
      LastName: recipientModel.LastName,
      DisplayName: recipientModel.FullName == undefined ? recipientModel.FirstName + ' ' + recipientModel.LastName : recipientModel.FullName,
      Email: recipientModel.Email,

      LegalName: recipientModel.LegalName,
      InternalContactName: recipientModel.InternalContactName,
      ContactNumber: recipientModel.ContactNumber,

    }
    return recipient;
  }

  //---------------------------------------------------------------------
  // Method to push the addresses to AddressList and return the list.
  //---------------------------------------------------------------------
  private getAddressList(addresses: AddressModel[]) {
    this.addressList = [];
    addresses.forEach((value) => {
      var addressModel = JSON.parse(JSON.stringify(value));
      
      addressModel.PersonFullName = this.userLookUpModel.DisplayName;
      if(addressModel.OrgName != null && addressModel.OrgName != undefined)
            this.addressList.push(addressModel);
       
    });
  }




  public validateSelectedRecipient() {
    this.recipientService.getRecipient(this.userLookUpModel.Email, this.shippingOption).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        if (this.userLookUpModel.Email != this.recipientModel.Email) {
          this.recipientModel = this.serviceResponse;
          if (this.recipientModel.RecipientId == 0) {
            this.messageService.add({ severity: 'error', summary: "Selected Recipient profile does not exist. Please click Add", detail: '' });

          }
          else {
            if (!this.recipientModel.IsActive && this.recipientModel.RecipientId != 0 && this.requestModel.RequestStatus == 'Draft') {
              this.messageService.add({ severity: 'error', summary: "Selected Recipient profile has been disabled. Please select another recipient", detail: '' });
              this.addressList = [];
            }
            else {
              this.messageService.clear('addressNotification');
              this.getRequestShippingDetailForRecipient();
            }
          }
        }
      })
  }

  public getRegionForCountry(countryId: number): Promise<any> {
    return this.countryService.getCountryListAsync().then(res => {
      this.serviceResponse = res
      this.region = this.serviceResponse.filter(c => c.CountryId == countryId);
    })
      .catch(error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
      });

  }
  //------------------------------------------
  // To save selected recipient
  //-------------------------------------------
  public getRequestShippingDetailForRecipient() {


    this.requestService.getRequestShippingDetailForRecipient(this.shippingOption, this.requestModel.RequestNumber, this.recipientModel.Email).subscribe(response => this.serviceResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.requestRecipientModel = this.serviceResponse;
        this.shippingAddress = this.requestRecipientModel.ShipmentAddressModel;
        this.getAddressList(this.requestRecipientModel.RecipientAddressList);

        if (this.addressList.length > 0) {
          var defaultAddressModel = new AddressModel();
          defaultAddressModel = this.addressList.filter(s => s.IsDefaultAddress)[0];
          if (defaultAddressModel) {
            // Set default address as shipping address for  the request.
            this.setRequestShipment(defaultAddressModel, null);
          }

        }
        else {
          this.messageService.add({ key: 'addressNotification', severity: 'info', summary: "Please add address to continue", detail: '' });
        }
      })

  }

  //-----------------------------------------------------------------------
  // To set the selected address as shipping address for selected request
  //----------------------------------------------------------------------- 
  public async setRequestShipment(address: AddressModel, addressCarousel) {
    
    if(this.submitted)
    {
      return false;
    }
    // if (this.requestModel.IsForecastRequest && !this.requestModel.IsRegion && this.requestModel.CountryId != address.CountryId) {
    //   this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "Samples were forecasted for " + this.requestModel.CountryName + ". Please select the correct country", detail: '' })
    //   return;
    // }
    else if (this.requestModel.IsForecastRequest && this.requestModel.IsRegion && this.requestModel.CountryName.toUpperCase() !== 'DEFAULT GLOBAL AREA') {
      // var region = await this.getRegionForCountry(address.CountryId);
      // if (this.requestModel.CountryId != this.region[0].RegionId) {
      //   this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "Samples were forecasted for " + this.requestModel.CountryName + ". Please select the correct country", detail: '' })
      //   return;
      // }
    }
    console.log("AddressSelectionState value on click: " + this.AddressSelectionState);

    this.messageService.clear('addressNotification');

    this.AddressSelectionState = 'selected';

    console.log("AddressSelectionState after setting to selected: " + this.AddressSelectionState);

    var requestShipmentModel = new RequestShipmentModel();
    requestShipmentModel.RequestId = this.requestModel.RequestId;
    requestShipmentModel.AddressId = address.AddressId;
    requestShipmentModel.RecipientId = this.recipientModel.RecipientId;
    this.submitted = true;
    this.requestService.addShippingAddress(this.requestModel.RequestNumber, requestShipmentModel).subscribe(response => {
      this.submitted = false;
      this.messageService.add({ severity: 'success', summary: 'Shipping Address Saved for Request', detail: '', life: 500 });
      // make laebl null on address country change only
      if (address.CountryId !== this.shippingAddress.CountryId) {
        let isUpdateLabel = false;
        this.requestModel.RequestItemList.map(item => {
          if (item.IsUserDefinedContainerLabel !== true && 
              item.MaterialId !== null && item.RepoName !== null &&
              item.MaterialId !== undefined && item.RepoName !== undefined &&
              item.MaterialId !== '' && item.RepoName !== '') {
            item.ContainerLabel =  null;
            isUpdateLabel = true;
          } 
        });
        if (isUpdateLabel)
          this.updateRequestItems();
      }
      this.shippingAddress = address;
      this.requestModel.ShippingOption = this.shippingOption;
    },
      error => {
        this.submitted = false;
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      });

    if (addressCarousel) {
      addressCarousel.onDotClick(MouseEvent, 0);
    }
  }

  updateRequestItems() {
    this.requestItemService.createRequestItem(this.requestModel.RequestTypeName, this.requestModel.RequestItemList).subscribe(response => {
        console.log(response);
    });
  }

  //--------------------------------
  // Open address dialog pop up 
  //--------------------------------
  public showAddAddressDialog(assignValueFromRecipient: boolean) {
    this.addressModel.RecipientId = this.recipientModel.RecipientId;
    this.addressModel.AddressId = 0;

    if (assignValueFromRecipient && this.requestRecipientModel.RecipientAddressList.length > 0) {
      this.addressModel.CountryId = this.requestRecipientModel.RecipientAddressList[0].CountryId;
      this.addressModel.Country = this.requestRecipientModel.RecipientAddressList[0].Country;
      this.addressModel.Mobile = this.recipientModel.ContactNumber;
    }
    else if (assignValueFromRecipient) {
      this.addressModel.Mobile = this.recipientModel.ContactNumber;
    }
    else {
      this.addressModel.CountryId = 0;
      this.addressModel.Country = "";
      this.addressModel.Mobile = "";
    }

    this.showAddressDialog = true;
  }

  //--------------------------------------------------------
  // This method will receive the newly created address
  // and set to the array list
  //-------------------------------------------------------
  public receiveNewCreatedAddress(addressModel: AddressModel, assignedCountryToRecipient: boolean) {
    this.messageService.add({ severity: 'success', summary: 'Address saved successfully', detail: '', life: 500 });
    this.showAddressDialog = false;
    this.showCarousel = false;
    addressModel.PersonFullName = this.userLookUpModel.DisplayName;
    this.addressList.unshift(addressModel);
    this.messageService.clear('addressNotification');
    // In  case of external recipient country of first address becomes country of the recipient.
    if (assignedCountryToRecipient == true && this.addressList.length == 1) {
      this.requestRecipientModel.RecipientAddressList = this.addressList;
    }
    //this.getRequestRecipient(this.requestModel.RequestNumber)
    this.showCarousel = true;
    this.getRequestShippingDetailForRecipient();
    this.setRequestShipment(addressModel, null);
  }
  //--------------------------------------
  // This method will close address up.
  //--------------------------------------
  public onAddressPopUpClose() {
    this.showAddressDialog = false;
    console.log("close  pop up called");
    //this.getRequestRecipient(this.requestModel.RequestNumber)
    //this.getRequestShippingDetailForRecipient();
    this.messageService.clear('addressNotification');
  }

  //--------------------------------
  // Open Add Recipient dialog pop up 
  //--------------------------------
  public showAddRecipientDialog() {
    this.showRecipientDialog = true;
  }

  //--------------------------------------
  // This method will close recipient pop up.
  //--------------------------------------
  public onCloseAddRecipientPopUp() {
    this.showRecipientDialog = false;
  }
}
