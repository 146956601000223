
import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ExceptionService } from '../services/exceptionlog-service/exceptionlog.service';

@Component({
    selector: 'app-exception-log',
    templateUrl: './exception-log.component.html',
    styleUrls: ['./exception-log.component.css'],
    providers: [MessageService, ExceptionService]
})

export class ExceptionComponent implements OnInit {

    exceptionLogTableHeaders: any[]

    exceptionLogList: any[];

    selectedException: any;
    serviceResponse: any;

    sortOptions: SelectItem[];
    sortKey: string;
    sortField: string;
    sortOrder: number;
    filterColumnWidth: object = { 'width': '100%' };

    constructor(private messageService: MessageService, private exceptionService: ExceptionService) { }

    ngOnInit() {
        this.getExceptions();

        this.exceptionLogTableHeaders = [
            { field: 'CreatedDate', header: ' Exception DateTime', display: 'table-cell', width: '20%' },
            { field: 'CustomMessage', header: ' Exception', display: 'table-cell', width: '20%' },
            { field: 'ExceptionText', header: ' Stack Trace', display: 'table-cell', width: '60%' }
        ]
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }


    private getExceptions() {
        this.exceptionService.getExceptions().subscribe(response => this.exceptionLogList = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            },
            () => {
            });
    }
}

