import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService, SelectItem } from 'primeng/api';
import { AddressService } from '../../services/address-service/address.service'
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RequestService } from '../../services/request-service/request.service';
import { trigger, state, style, animate, transition, keyframes, AnimationEvent } from '@angular/animations';
import { AddRequesterAddressComponent } from '../add/add-requester-address.component';
import { CountryService } from 'src/app/services/country-service/country.service';
import { SiteModel } from '../../services/site-service/site.model';
import { SiteService } from '../../services/site-service/site.service';
import { RequesterInformationService } from '../../services/requester-information-service/requester-information.service';
import { UserService } from '../../services/user-service/user.service';



@Component({
  selector: 'add-internal-requester-address',
  templateUrl: './add-internal-requester-address.component.html',
  styleUrls: ['../add/add-requester-address.component.css'],
  providers: [AddressService, ConfirmationService, MessageService, RecipientService
    , RequestService],
  animations: [
    trigger('selectedAddressAnimation', [
      state('selected', style({
      })),
      transition('initial => selected', animate('1000ms', keyframes([
        style({ backgroundColor: 'white', offset: 0 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(0.1)', offset: 0.5 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(1)', offset: 1 })
      ])))
    ])
  ]
})

export class AddInternalRequesterAddressComponent extends AddRequesterAddressComponent implements OnInit {
  public selectedDeliveryDate: Date | any;
  public msgs: any[];
  minimumDate: Date ;
  constructor(recipientService: RecipientService, messageService: MessageService,
    confirmationService: ConfirmationService, addressService: AddressService,
    requestService: RequestService, countryService: CountryService,
    siteService: SiteService, requesterInformationService: RequesterInformationService,
    protected userService: UserService
  ) {
    super(recipientService, messageService, confirmationService, addressService, requestService, 
      countryService, siteService,requesterInformationService, userService);


  }

  ngOnInit() {
  //  this.getRequestRecipient(this.requestModel.RequestNumber);
    this.getSites();
    this.getUserLookupModel();
    this.minimumDate = new Date();
    this.requesterInformationModel.DeliveryDate = new Date(this.requesterInformationModel.DeliveryDate);
  }

  //----------------------------------------------------------
  // Fire when user select user from recipient search text bxo
  //-----------------------------------------------------------
  public requesterOnSelect() {
    this.validateSelectedRecipient();
    this.onCloseAddRecipientPopUp();

  }

  public newRecipient($event) {
    this.userLookUpModel = $event;
    this.requesterOnSelect();
  }

}
