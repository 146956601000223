
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CopyRequestModel } from './copy-request.model';
import { ValidationService } from 'src/app/services/validation-service/validation-service';
import { RequestService } from 'src/app/services/request-service/request.service';
import { CopyRequestOptions } from './copy-request-options-enum';


@Component({
  selector: 'app-copy-request',
  templateUrl: './copy-request.component.html',
  styleUrls: ['./copy-request.component.css'],
  providers: [ConfirmationService, MessageService, ValidationService, RequestService]
})

export class CopyRequestComponent implements OnInit {


  @Input() visible: boolean;
  @Input() requestNumber: string;
  @Input() requestType: string;
  @Input() copyRequester: boolean;
  @Output() eventToClosePopUp = new EventEmitter<CopyRequestModel>();
  radioButtonDisabled: boolean;
  copyRequestModel: CopyRequestModel;
  submitted:boolean = false;
  contentStyleObject: object = {
    'width': '40vw',
    'max-height': '75vh',
    'overflow': 'auto',
  }
  selectedCopyOption: string = '';
  constructor(private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, public validationService: ValidationService,
    private requestService: RequestService
  ) {

  }

  ngOnInit() {
    this.copyRequestModel = {
      RequesterDetails: false, RecipientDetails: false, Samples: false,
      Containers: false, Attachments: false,NumberOfCopies: 1
    };    
  }

  public onChangeRecipientCheckbox() {

    if (this.selectedCopyOption == CopyRequestOptions.SamplesWithContainers || this.selectedCopyOption == CopyRequestOptions.SamplesWithContainersAndAttachments) {
      this.selectedCopyOption = '';
    }

    if (this.copyRequestModel.RecipientDetails) {
      this.radioButtonDisabled = true;
    }
    else {
      this.radioButtonDisabled = false;
    }
  }

  public createCopies() {    
    if(this.submitted)
    {
      return false;
    }
    this.selectedCopyOption == CopyRequestOptions.Samples && (this.copyRequestModel.Samples = true);
    this.selectedCopyOption == CopyRequestOptions.SamplesWithContainers && (this.copyRequestModel.Samples = this.copyRequestModel.Containers = true);
    this.selectedCopyOption == CopyRequestOptions.SamplesWithAttachments && (this.copyRequestModel.Samples = this.copyRequestModel.Attachments = true);
    this.selectedCopyOption == CopyRequestOptions.SamplesWithContainersAndAttachments && (this.copyRequestModel.Samples = this.copyRequestModel.Containers = this.copyRequestModel.Attachments = true);
   
    if (this.copyRequestModel.RecipientDetails == false && this.copyRequestModel.RequesterDetails == false && this.selectedCopyOption == '') {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please select details to copy" });
    }
    else if (this.copyRequestModel.NumberOfCopies == 0 || this.copyRequestModel.NumberOfCopies.toString() == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please enter the number of copies" });
    }
    else if (this.maxNoOfCopiesExceedsLimit()) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Max 5 copies allowed" });
    }
    else {
      // call api
      this.submitted = true;
      let serviceResponse: string;
      this.requestService.copyRequest(this.requestNumber, this.copyRequestModel,this.requestType).subscribe
        (res => serviceResponse = res,
          error => {
            this.submitted = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
          },
          () => {
            this.submitted = false;
            this.messageService.add({ severity: 'success', summary: serviceResponse, detail: '' });
            this.reset();
            this.eventToClosePopUp.emit(this.copyRequestModel);
          })
    }
  }

  public close() {
    this.confirmationService.confirm({
      message: 'The selected options will be lost. Are you sure you want to close the form?',
      header: 'Close Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.reset();
        this.eventToClosePopUp.emit(this.copyRequestModel);
      },
      reject: () => {
      }
    });
  }

  public reset() {
    this.selectedCopyOption = '';
    this.copyRequestModel = {
      RequesterDetails: false,
      RecipientDetails: false,
      Samples: false,
      Containers: false,
      Attachments: false,
      NumberOfCopies: 1
    };
    this.radioButtonDisabled = false;
  }


  public maxNoOfCopiesExceedsLimit() {
    const maxCopies = 5;
    if (this.copyRequestModel.NumberOfCopies > maxCopies) {
      this.copyRequestModel.NumberOfCopies = 1;
      return true;
    }
    return false;
  }

}


