
import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { EmailLogService } from 'src/app/services/emaillog-service/emaillog.service';

@Component({
    selector: 'app-email-log',
    templateUrl: './email-log.component.html',
    styleUrls: ['./email-log.component.css'],
    providers: [MessageService, EmailLogService]
})

export class EmailLogComponent implements OnInit {

    public emailLogTableHeaders: any[]

    public emailLogList: any[];
    public selectedEmailLog: any[];

    public selectedException: any;
    public serviceResponse: any;

    public sortOptions: SelectItem[];
    public sortKey: string;
    public sortField: string;
    public sortOrder: number;

    constructor(public  messageService: MessageService, public  emailLogService: EmailLogService) { }

    ngOnInit() {
        this.getEmailLogs();

        this.emailLogTableHeaders = [
            { field: 'RequestId', header: ' Request Number', display: 'table-cell', width: '10%' },
            { field: 'ToRecipients', header: ' To', display: 'table-cell', width: '13%' },
            { field: 'CcRecipients', header: ' CC', display: 'table-cell', width: '12%' },
            { field: 'Subject', header: ' Subject', display: 'table-cell', width: '20%' },
            { field: 'Body', header: ' Body', display: 'table-cell', width: '30%' },
            { field: 'IsSent', header: ' Mail Sent', display: 'table-cell', width: '5%' },
            { field: 'CreatedDate', header: ' Created Date', display: 'table-cell', width: '10%' }
        ]
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }


    private getEmailLogs() {
        this.emailLogService.getEmailLogs().subscribe(response => this.emailLogList = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            },
            () => {
            });
    }
}

