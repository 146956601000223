<p-toast key="addContainerValidationMessage" position="center" [style]="toasterPositionStyleObject" [baseZIndex]="5000"
  sticky="true"></p-toast>

<div class="col-12 container-section">
  <div class="col-space"></div>

  <div class="col-11 font-size container-section-padding-right">
    <table class="tableWidth">
      <thead>
        <tr>
          <td class="col-3 tableHeader text-align-right"># Containers</td>
          <td class="col-5 tableHeader text-align-right">Amount Per Container</td>
          <td class="col-4 tableHeader text-align-right">
          </td>
        </tr>

        <tr>
          <td class="col-3 text-align-right"><input (keypress)="validationService.numberValidation($event)"
              maxlength="3" minlength="1" type="text" [(ngModel)]="editRequestItemModel.NumberOfContainer" /></td>
          <td class="col-5 text-align-right"><input id="amountPerContainer"
              (keypress)="validationService.numberValidationWithDecimal($event)" maxlength="8" minlength="1" type="text"
              [(ngModel)]="editRequestItemModel.AmountPerContainer" /></td>
          <td class="col-4 text-align-right">
            <button pButton type="button" label="Add" class="ui-button-info" icon="pi pi-plus"
              (click)="addContainer(editRequestItemModel)"></button>
          </td>
        </tr>
        <tr *ngIf="errorMessageNumberOfContainer">
          <td colspan="3">
            <p class="col-12 invalid-feedback validation-message">{{errorMessageNumberOfContainer}}</p>
          </td>
        </tr>
        <tr *ngIf="errorMessageAmountPerContainer">
          <td colspan="3">
            <p class="col-12 invalid-feedback validation-message">{{errorMessageAmountPerContainer}}</p>
          </td>
        </tr>
        <!-- <tr *ngIf="divForErrorMessageContainer" class="col-12 invalid-feedback"> -->
          <!-- <td class="col-4 text-align-right validation-message">
            {{errorMessageNumberOfContainer}}
          </td>
          <td class="col-5 text-align-right validation-message">
            {{errorMessageAmountPerContainer}}
          </td>
          <td class="col-3 tableHeader text-align-right">
          </td> -->
        <!-- </tr> -->
      </thead>
      <tbody> 
        <tr class="line" *ngFor="let requestItemContainer of editRequestItemModel.RequestItemContainerList;">
          <td *ngIf="requestItemContainer.IsContainerDeleted == false" class="col-3 container-value-td">
            {{requestItemContainer.NumberOfContainer}}
          </td>
          <td *ngIf="requestItemContainer.IsContainerDeleted == false" class="col-5 amount-value-td">
            {{requestItemContainer.AmountPerContainer | number : '1.2-4'}}&nbsp;&nbsp;{{selectedUnitOfMeasure.UnitOfMeasure}}
          </td>
          <td *ngIf="requestItemContainer.IsContainerDeleted == false" class="col-4 remove-td">
            <i label="Remove" class="pi pi-times"
              (click)="removeContainer(requestItemContainer,editRequestItemModel.RequestItemContainerList)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="col-space"></div>
<div class="col-space"></div>
<div class="col-12 container-section">
  <div class="col-space"></div>

  <div class="col-11 font-size "> <div>
    <!-- Add Comments-->
    <span class="uomLabel font-weight-bold">
     {{editRequestItemModel.MaterialSearchType == 'P2PBiological' || editRequestItemModel.MaterialSearchType == 'P2POther' ? '*Sample Comments:' : 'Sample Comments:' }}
    </span>
    <div>
      <textarea style="width: 100%;resize:none;height: unset !important;" id="txtRequestComment" [rows]="4" [cols]="120" 
      placeholder="{{editRequestItemModel.MaterialSearchType == 'P2PBiological' || editRequestItemModel.MaterialSearchType == 'P2POther' ? 'Special Comment/Special Instructions' : '' }}"
      maxlength="2000" pInputTextarea  [(ngModel)] = "editRequestItemModel.RequestItemComment[0].Comment.Comment1"></textarea>
    </div>
    </div>
  </div>
<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" appendTo="body"
  [closable]="false">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
    <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>