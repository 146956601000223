import { Injectable } from '@angular/core';

@Injectable()

export class ValidationService {

    constructor() { }

    //--------------------------------------
    // Validations for Contact Number field
    //--------------------------------------
    public contactNumberValidation(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 47 && charCode < 58) {
            return true;
        }
        else if (charCode == 32 || charCode == 40 || charCode == 41 || charCode == 43 || charCode == 45) {
            return true;
        }
        return false;
    }

    //-----------------------------------
    // Validations for only number input
    //-----------------------------------
    public numberValidation(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    //-------------------------------------------------
    // Validations for only number input along with decimal
    //-----------------------------------------------------
    public numberValidationWithDecimal(event): boolean {
        const pattern = /^(\d{1,4}|\d{1,4}\.\d{1,4})$/;
        let amount = (document.getElementById("amountPerContainer") as HTMLInputElement).value;
        amount += event.key;
        let splitArray = amount.split(".");
        if (splitArray[1] == "")
            amount += "0";
        if (!pattern.test(amount)) {
            return false;
        }
        return true;
    }

    //-----------------------------------
    // Validations for valid email
    //-----------------------------------
    public validateEmail(email: string): boolean {
        var mailFormat = /^\w+([\.-]?\w+)*@[a-zA-Z0-9\-_]+([\.]?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,})+$/;
        if (email.match(mailFormat)) {
            return true;
        }
        else {
            return false;
        }
    }

}