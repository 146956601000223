export class RequestAdvanceSearch {
    Year?:string  ;
    RequestStatusId?:number[]  ;
    RecipientType?:string  ;
    RecipientEmails?:string[]  ;
    RequesterEmails?:string[]  ;
    RequestCreatedByList?:number[]  ;
    CountryIds?:number[]  ;
    RequestTypeIds?:number[]  ;
    SampleName?:string  ;
    RequestNumber?:string;
}
