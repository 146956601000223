export class ForecastRequestModel {
    filterValue: string;
    requesterEmail: string;
    delegatorEmail: string;
    forecasterEmail: string;
    sortOrder: string;
    isASC: boolean;
    pageIndex: number;
    pageSize: number;

}