<h5>Cart Details - Peer to Peer</h5>

<p-dataView #dv [paginator]="true"  [alwaysShowPaginator]="false" [rows]="10"  [value]="cartItemList"  layout="list"
     paginatorPosition="both" pageLinks="5" styleClass="hover cart">

    <ng-template let-cartItem pTemplate="listItem">
        <div class="col-12 ui-dataview-content ui-widget-content" *ngIf="cartItem.RequestTypeName=='P2P'">
            <div class="ui-g">
                <div class="col-12">
                    <div class="grid resShadow">
                        <div class="col-fixed request-type-vertical-text-p2p">
                            {{cartItem.RequestTypeName}}
                        </div>
                        <div class="col-9">
                            <div class="grid">
                                <div class="col-12">
                                    <span class="font-weight-bold">
                                        Material Name :
                                    </span>{{cartItem.MaterialName}}
                                </div>  
                                <div class="col-12" *ngIf="isBioMaterialType(cartItem)">
                                    <span class="font-weight-bold float-left">
                                        Synonyms :
                                    </span>
                                    <span class="firstSynonym" id=span{{cartItem.TsnNumber}}>
                                        {{cartItem.Synonyms.split('||').join('; ')}}
                                    </span>
                                </div> 
                            </div>                        
                        </div>                       
                        
                        <div class="col add-to-cart-button-width">

                            <div class="col-12"><button pButton type="button" icon="pi pi-shopping-cart"
                                    label="Remove from Cart" class="p-button-danger float-right removeButton"
                                    (click)="removeFromCart(cartItem)"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 ui-dataview-content ui-widget-content" *ngIf="cartItem.RequestLocationId == 0 && cartItem.RequestTypeName=='GLP'">
            <!-- <div class="ui-g"> -->
            <div class="col-12">
                <div class="grid resShadow">
                    <div class="col-fixed request-type-vertical-text-p2p">
                        P2P
                    </div>
                    <div class="col-9">
                        <div class="grid">
                            <div class="col-6">
                                <span class="font-weight-bold">
                                    Material Name :
                                </span>{{cartItem.MaterialName}}
                            </div>
                            <div class="col-6" *ngIf="!isBioMaterialType(cartItem)">
                                <span class="font-weight-bold">
                                    Lot Number :
                                </span>{{cartItem.LotNumber == 'NULL' ? '' : cartItem.LotNumber }}
                            </div>
                            <div class="col-6" *ngIf="!isBioMaterialType(cartItem)">
                                <span class="font-weight-bold">
                                    Recertification Date :
                                </span>{{cartItem.RecertificationDate == defaultRecertificationDate ? '' : cartItem.RecertificationDate | date: "dd-MMM-yyyy" }}
                            </div>
                            <div class="col-6" *ngIf="showField">
                                <span class="font-weight-bold">
                                    TSN Number :
                                </span>{{cartItem.TsnNumber == 'NULL' ? '' : cartItem.TsnNumber }}
                            </div>
                            <div class="col-6" *ngIf="showField">
                                <span class="font-weight-bold">
                                    Purity :
                                </span>{{cartItem.Purity == 'NULL' ? '' : cartItem.Purity }}
                            </div>
                            <div class="col-6" *ngIf="!isBioMaterialType(cartItem)">
                                <span class="font-weight-bold">
                                    Available Amount :
                                </span>{{cartItem.AvailableAmount == 'NULL' ? '' : cartItem.AvailableAmount | number : '1.3-3'  }} 
                                {{cartItem.AvailableAmount == 'NULL' ? '' : cartItem.UOM }} 
                                                        
                            </div>
                            <div class="col-6" *ngIf="!isBioMaterialType(cartItem)">
                                <span class="font-weight-bold">
                                    GF-Code :
                                </span>{{cartItem.GfCode == 'NULL' ? '' : cartItem.GfCode }}
                            </div>
                            <div class="col-6" *ngIf="!isBioMaterialType(cartItem)">
                                <span class="font-weight-bold">
                                    Active Ingredient :
                                </span>{{cartItem.ActiveIngredient == 'NULL' ? '' : cartItem.ActiveIngredient }}
                            </div>
                            <div class="col-6" *ngIf="!isBioMaterialType(cartItem)">
                                <span class="font-weight-bold">
                                    Material ID :
                                </span>{{cartItem.MaterialId == 'NULL' ? '' : cartItem.MaterialId }}
                            </div>
                            <div class="col-12">
                                <span class="font-weight-bold float-left">
                                    Synonyms :
                                </span>
                                <span class="firstSynonym" id=span{{cartItem.TsnNumber}}>
                                    {{cartItem.Synonyms.split('||').join('; ')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col add-to-cart-button-width">

                        <div class="col-12"><button pButton type="button" icon="pi pi-shopping-cart"
                                label="Remove from Cart" class="p-button-danger float-right removeButton"
                                (click)="removeFromCart(cartItem)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div class="col-12 ui-dataview-content ui-widget-content" *ngIf="cartItem.RequestLocationId > 0 && cartItem.RequestTypeName=='GLP'">
            <!-- <div class="ui-g"> -->
            <div class="col-12">
                <div class="grid resShadow">
                    <div class="col-fixed request-type-vertical-text-p2p">
                        P2P
                    </div>
                    <div class="col-9">
                        <div class="grid">
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Forecast ID :
                                </span>{{cartItem.ForecastId}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Request Location ID :
                                </span>{{cartItem.RequestLocationId}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Material Name :
                                </span>{{cartItem.MaterialName}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Forecaster :
                                </span>{{cartItem.Forecasters}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Requester :
                                </span>{{cartItem.Requesters}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Country :
                                </span>{{cartItem.Country }}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Amount Forecasted :
                                </span>{{cartItem.AmountForecasted == 'NULL' ? '' : cartItem.AmountForecasted | number:'1.3-3'}} 
                                {{cartItem.AmountForecasted == 'NULL' ? '' : cartItem.UOM }}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Amount Remaining :
                                </span>{{cartItem.AmountRemaining == 'NULL' ? '' : cartItem.AmountRemaining | number:'1.3-3' }}
                                {{cartItem.AmountRemaining == 'NULL' ? '' : cartItem.UOM }}
                            </div>
                            <div class="col-12 padding-top-02em">
                                <div class="col-10">
                                    <span class="font-weight-bold float-left">
                                        Sample Request History :
                                    </span>
                                    <span class="firstSynonym" id=span{{cartItem.RequestLocationId}}>
                                        {{cartItem.SampleRequestHistory != '' ? cartItem.SampleRequestHistory.split("|",1) : ''}}</span>

                                     <button id=show{{cartItem.RequestLocationId}} class="showAll"
                                        *ngIf="cartItem.SampleRequestHistory.split('|').length > 1" pButton type="button"
                                        icon="pi pi-chevron-right" label=" . . . Show All"
                                        (click)="showDiv(cartItem.RequestLocationId)" iconPos="right"></button>
                                    <button id=hide{{cartItem.RequestLocationId}} class="hideAll"
                                        *ngIf="cartItem.SampleRequestHistory.split('|').length > 1" pButton type="button"
                                        icon="pi pi-chevron-down" label="Hide All"
                                        (click)="hideDiv(cartItem.RequestLocationId)" iconPos="right"
                                        [style.display]="hideHideAllButton?'none':'inherit'"></button>

                                    <div id={{cartItem.RequestLocationId}} class="moreSynonyms display-none">
                                    </div>
                                </div>

                            </div>
                       
                        </div>
                    </div>

                    <div class="col add-to-cart-button-width">

                        <div class="col-12"><button pButton type="button" icon="pi pi-shopping-cart"
                                label="Remove from Cart" class="p-button-danger float-right removeButton"
                                (click)="removeFromCart(cartItem)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>


        <div class="col-12 ui-dataview-content ui-widget-content" *ngIf="cartItem.RequestLocationId == 0 && cartItem.RequestTypeName=='Stock'">
            <div class="ui-g">
                <div class="col-12">
                    <div class="grid resShadow">
                        <div class="col-fixed request-type-vertical-text-p2p">
                            P2P
                        </div>
                        <div class="col-9">
                            <div class="col-12">
                                <span class="font-weight-bold">
                                    Material Name :
                                </span>{{cartItem.MaterialName}}
                            </div>
                            <div class="col-12">
                                <span class="font-weight-bold float-left">
                                    Synonyms :
                                </span>
                                <span class="firstSynonym" id=span{{cartItem.MaterialName}}>
                                    {{cartItem.Synonyms.split('||').join('; ')}}</span>
                            </div>
                        </div>
                        <div class="col add-to-cart-button-width">

                            <div class="col-12"><button pButton type="button" icon="pi pi-shopping-cart"
                                    label="Remove from Cart" class="p-button-danger float-right removeButton"
                                    (click)="removeFromCart(cartItem)"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 ui-dataview-content ui-widget-content" *ngIf="cartItem.RequestLocationId > 0 && cartItem.RequestTypeName=='Stock'">
            <!-- <div class="ui-g"> -->
            <div class="col-12">
                <div class="grid resShadow">
                    <div class="col-fixed request-type-vertical-text-p2p">
                        P2P
                    </div>
                    <div class="col-9">
                        <div class="grid">
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Forecast ID :
                                </span>{{cartItem.ForecastId}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Request Location ID :
                                </span>{{cartItem.RequestLocationId}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Material Name :
                                </span>{{cartItem.MaterialName}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Forecaster :
                                </span>{{cartItem.Forecasters}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Requester :
                                </span>{{cartItem.Requesters}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Country :
                                </span>{{cartItem.Country }}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Amount Forecasted :
                                </span>{{cartItem.AmountForecasted == 'NULL' ? '' : cartItem.AmountForecasted + ' ' +cartItem.UOM}}
                            </div>
                            <div class="col-6 padding-bottom-02em padding-top-02em">
                                <span class="font-weight-bold">
                                    Amount Remaining :
                                </span>{{cartItem.AmountRemaining == 'NULL' ? '' : cartItem.AmountRemaining + ' ' +cartItem.UOM}}
                            </div>
                            <div class="col-12 padding-top-02em">
                                <div class="col-10">
                                    <span class="font-weight-bold float-left">
                                        Sample Request History :
                                    </span>
                                    <span class="firstSynonym" id=span{{cartItem.RequestLocationId}}>
                                        {{cartItem.SampleRequestHistory != '' ? cartItem.SampleRequestHistory.split("|",1) : ''}}</span>

                                     <button id=show{{cartItem.RequestLocationId}} class="showAll"
                                        *ngIf="cartItem.SampleRequestHistory.split('|').length > 1" pButton type="button"
                                        icon="pi pi-chevron-right" label=" . . . Show All"
                                        (click)="showDiv(cartItem.RequestLocationId)" iconPos="right"></button>
                                    <button id=hide{{cartItem.RequestLocationId}} class="hideAll"
                                        *ngIf="cartItem.SampleRequestHistory.split('|').length > 1" pButton type="button"
                                        icon="pi pi-chevron-down" label="Hide All"
                                        (click)="hideDiv(cartItem.RequestLocationId)" iconPos="right"
                                        [style.display]="hideHideAllButton?'none':'inherit'"></button>

                                    <div id={{cartItem.RequestLocationId}} class="moreSynonyms display-none">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col add-to-cart-button-width">

                        <div class="col-12"><button pButton type="button" icon="pi pi-shopping-cart"
                                label="Remove from Cart" class="p-button-danger float-right removeButton"
                                (click)="removeFromCart(cartItem)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>

    </ng-template>

    <ng-template pTemplate="paginatorleft" let-state>
        <span *ngIf="cartItemList !=null" class="ui-paginator-first"> Total Items :
            {{cartItemList.length}}
            {{ dv.totalRecords == cartItemList.length ? '' : '(Showing Items: '+ dv.totalRecords + ')'}} </span>
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
        <span class="ui-paginator-first">{{ dv.totalRecords > 0 ? (state.page * state.rows) + 1 : 0 }}
            -
            {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page + 1)) }}
            of {{dv.totalRecords}} Items</span>
    </ng-template>
</p-dataView>

<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>
<p-toast position="top-right"></p-toast>
