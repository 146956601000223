
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { UserModel } from './user.model';
import { UserService } from '../services/user-service/user.service'
import { ConfigurationSettings } from '../configuration-settings';
import { UserLookUpModel } from '../user-look-up/user-look-up.model';
import { FunctionModel } from '../function/function.model';
import { CountryModel } from '../country/country.model';
import { FunctionService } from '../services/function-service/function.service';
import { CountryService } from '../services/country-service/country.service';
import { RequestTypeModel } from '../request-type/request-type.model';
import { RequestTypeService } from '../services/request-type-service/request-type.service';
import { RoleModel } from '../role/role.model';
import { RoleService } from '../services/role-service/role.service';
import { RegionModel } from '../region/region.model';
import { RegionService } from '../services/region-service/region-service';
import { AzureADService } from '../services/azure-ad-service/azure-ad.service';
import { UserRequestTypeRoleModel } from '../user-request-type-role/user-request-type-role.model';
import { Role } from '../role/role.enum';
import { CommonService } from '../services/common-service/common.service';
import { RequestType } from '../request-type/request-type.enum';



@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css'],
    providers: [UserService, ConfirmationService, MessageService, FunctionService, CountryService,
        RequestTypeService, RoleService, RegionService, AzureADService, CommonService]
})

export class UserComponent implements OnInit {

    public restApiURL: string = '';
    public userTableHeaders: any[]

    public users: UserModel[];
    public user = new UserModel();
    public editUser: any;
    public selectedUser: UserModel;
    public selectedUsers: UserModel[];
    public serviceResponse: any;
    public isEditable: boolean = false
    public dialogHeaderText: any = "Add new";
    private NAME_NOT_AVAILABLE  = 'Not available';
    public userLookupModel: UserLookUpModel;
    public userLookUpModelList: UserLookUpModel[] = [];
    public selectedFunction: FunctionModel;
    public functionList: FunctionModel[] = [];
    public selectedCountry: CountryModel;
    public countryList: CountryModel[] = [];
    public requestTypeList: RequestTypeModel[] = [];
    public roles: RoleModel[] = [];
    public contentStyleObject: object = {
        'max-height': '75vh',
        'overflow': 'auto',
        'max-width': '730px',
        'min-width': '730px'
    }

    //Property to display dialog
    public displayDialog: boolean = false;
    public sortOptions: SelectItem[];
    public sortKey: string;
    public sortField: string;
    public sortOrder: number;

    public countryDomElement: HTMLElement;
    public functionDomElement: HTMLElement;
    public usernameDomElement: HTMLElement;
    public roleRequestTypeList: UserRequestTypeRoleModel[] = [];
    public filterColumnWidth: object = { 'width': '100%' };
    public userReadonly: boolean = false;
    public isAdminstrator: boolean = false;
    public disableNonEditableField = false;

    constructor(public  userService: UserService, public  confirmationService: ConfirmationService, public  messageService: MessageService,
        public  functionService: FunctionService, public  countryService: CountryService,
        public  requestTypeService: RequestTypeService, public  roleService: RoleService,
        public  regionService: RegionService, public  azureADService: AzureADService, public  commonService: CommonService) { }

    ngOnInit() {
        this.checkAdminstratorRole();
        //this.updateSupervisor();
        this.getUsers();
        this.getRequestTypeList();
        this.getRoles();
        this.userTableHeaders = [
            { field: 'DisplayName', header: ' Name', display: 'table-cell', width: '10%' },
            { field: 'SupervisorName', header: ' Supervisor', display: 'table-cell', width: '10%' },
            { field: 'Email', header: ' Email', display: 'table-cell', width: '15%' },
            { field: 'FunctionName', header: ' Function', display: 'table-cell', width: '10%' },
            { field: 'RegionName', header: ' Region', display: 'table-cell', width: '8%' },
            { field: 'CountryName', header: ' Country', display: 'table-cell', width: '7%' },
            { field: 'RoleName', header: ' Role', display: 'table-cell', width: '10%' },
            { field: 'IsApprover', header: ' Material Approver', display: 'table-cell', width: '15%' },
            { field: 'RequestTypeName', header: ' Request Type', display: 'table-cell', width: '10%' }
        ]
        this.restApiURL = ConfigurationSettings.REST_API_URL + "/azure-ad/users/search?searchString=";
        this.userLookupModel = new UserLookUpModel;
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    AddnewItem() {
        this.user.UserRequestTypeRoleList = [];
        this.user = {};
        this.isEditable = false;
        this.dialogHeaderText = "Add new";
        this.selectedUser = undefined;
        this.user.OptInEmailNotification = true;
        this.user.IsEnabled = true;
        this.getFunctionList();
        this.getCountryList();
        this.roleRequestTypeList = [];
        this.userLookupModel = undefined;
        this.selectedCountry = undefined;
        this.selectedFunction = undefined;
        this.userReadonly = false;
        this.displayDialog = true;
        var requesterRole = this.roles.find(c => c.Name == "Requester");
        var stockId = this.requestTypeList.find(c=>c.Name == "Stock");
        this.roleClick(true,requesterRole.RoleId, stockId.RequestTypeId);
    }

    userOnSelect() {
        if (this.usernameDomElement)
            this.usernameDomElement.style.border = "1px solid #a6a6a6";
        this.user.UserName = this.userLookupModel.UserName;
        this.user.FirstName = this.userLookupModel.FirstName;
        this.user.LastName = this.userLookupModel.LastName;
        this.getSupervisor(this.userLookupModel.Email);
        this.user.Email = this.userLookupModel.Email;
        this.user.IsEnabled = true;
    }

    updateSupervisor() {
        this.userService.updateSupervisor().subscribe(response => this.serviceResponse = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
            },
            () => {

            });
    }

    getSupervisor(email: string) {
        this.azureADService.getSupervisor(email).subscribe(response => this.serviceResponse = response,
            error => {
                this.user.SupervisorName = this.NAME_NOT_AVAILABLE;
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
            },
            () => {
                this.user.SupervisorName = this.serviceResponse.DisplayName;
                if (this.user.SupervisorName == null || this.user.SupervisorName == undefined || this.user.SupervisorName.length == 0)
                    this.user.SupervisorName = this.NAME_NOT_AVAILABLE;
                if (this.user.SupervisorName != null)
                    document.getElementById("supervisor").style.border = "1px solid #a6a6a6";
            });
    }
    getFunctionList() {

        this.functionService.getFunctionList().subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            },
            () => {
                this.functionList = this.serviceResponse;
                    this.selectedFunction = this.functionList.find(c => c.FunctionId == this.user.FunctionId);
            })

    }

    getCountryList() {

        this.countryService.getCountryList().subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            },
            () => {
                this.countryList = this.serviceResponse;
                    this.selectedCountry = this.countryList.find(c => c.CountryId == this.user.CountryId);
            })

    }

    getRequestTypeList() {
        this.requestTypeService.getRequestTypes().subscribe(response => {
            this.requestTypeList = response;
            this.requestTypeList = this.requestTypeList.filter(a => a.Name != 'P2P'  && a.Name !== 'Radioactive');
        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.error });
            },
            () => {

            })
    }
    getRoles() {
        this.roleService.getRoles().subscribe(response => this.roles = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.error });
            },
            () => {
            })
    }

    roleClick(checked, roleId, requestTypeId) {
        var roleRequestType = this.roleRequestTypeList.find(c => c.RoleId == roleId && c.RequestTypeId == requestTypeId);
        if (roleRequestType == undefined && checked) {
            var selectedRole = this.roles.find(c => c.RoleId == roleId);
            if (selectedRole.Name == Role.Administrator || selectedRole.Name == Role.Support) {
                this.requestTypeList.forEach(element => {
                    var userRequestTypeRole = new UserRequestTypeRoleModel();
                    userRequestTypeRole.RoleId = roleId;
                    userRequestTypeRole.RequestTypeId = element.RequestTypeId;

                    this.roleRequestTypeList.push(userRequestTypeRole);
                });
            }
            else {
                var userRequestTypeRole = new UserRequestTypeRoleModel();
                userRequestTypeRole.RoleId = roleId;
                userRequestTypeRole.RequestTypeId = requestTypeId;

                this.roleRequestTypeList.push(userRequestTypeRole);
                if(this.requestTypeList.filter(x => x.RequestTypeId == requestTypeId)[0].Name == "GLP" )
                {
                    if(this.roleRequestTypeList.filter(x => x.RoleId == roleId && x.RequestTypeId == this.requestTypeList.filter(x => x.Name == "Stock")[0].RequestTypeId).length == 0)
                    {
                    var userRequestTypeRoleStock = new UserRequestTypeRoleModel();
                    userRequestTypeRoleStock.RoleId = roleId;
                    userRequestTypeRoleStock.RequestTypeId = this.requestTypeList.filter(x => x.Name == "Stock")[0].RequestTypeId;
                    this.roleRequestTypeList.push(userRequestTypeRoleStock);
                    }
                }
            }
        }
        else {
            var selectedRole = this.roles.find(c => c.RoleId == roleId);
            if (selectedRole.Name == Role.Administrator || selectedRole.Name == Role.Support) {
                this.roleRequestTypeList = this.roleRequestTypeList.filter(c => c.RoleId != roleId);
            }
            else {
                
                if(requestTypeId == this.requestTypeList.filter(x => x.Name == "Stock")[0].RequestTypeId && this.roleRequestTypeList.filter(x => x.RoleId == roleId &&  x.RequestTypeId == this.requestTypeList.filter(x => x.Name == "GLP")[0].RequestTypeId).length > 0)
                {
                    this.messageService.add({ severity: 'error', summary: '', detail: "As GLP request capability is enabled cannot remove stock request capability" });
return false;
                }
                this.roleRequestTypeList.splice(this.roleRequestTypeList.indexOf(roleRequestType), 1);
            }
        }
        this.user.UserRequestTypeRoleList = this.roleRequestTypeList;
    }

    functionChange() {
        if (this.functionDomElement)
            this.functionDomElement.style.border = "1px solid #a6a6a6";
        this.user.FunctionId = this.selectedFunction.FunctionId;
    }


    countryChange() {
        if (this.countryDomElement)
            this.countryDomElement.style.border = "1px solid #a6a6a6";
        this.user.CountryId = this.selectedCountry.CountryId;
    }

    Save() {

        if (this.validateMandatoryControls() && this.validateRoles()) {
            this.user.UserRequestTypeRoleList = this.roleRequestTypeList;
            console.log(this.user);
            this.InsertUser();
            this.displayDialog = false;
            this.user = {};
        }
    }

    validateMandatoryControls() {
        this.countryDomElement = document.getElementById("country").firstChild as HTMLElement;
        this.functionDomElement = document.getElementById("function").firstChild as HTMLElement;
        this.usernameDomElement = document.getElementById("username").getElementsByTagName("span")[0].getElementsByTagName("input")[0];

        if (this.selectedFunction == undefined ||
            this.selectedCountry == undefined || (this.userLookupModel == null || this.userLookupModel == undefined || this.user.SupervisorName == null)) {
            if (this.userLookupModel == undefined || this.userLookupModel == null || this.userLookupModel.Email == undefined) {
                this.usernameDomElement.style.border = "1px solid #dc3545"
            }
            if (this.selectedCountry == undefined) {
                this.countryDomElement.style.border = "1px solid #dc3545"
            }
            if (this.selectedFunction == undefined) {
                this.functionDomElement.style.border = "1px solid #dc3545"
            }
            if (this.user.SupervisorName == null) {
                document.getElementById("supervisor").style.border = "1px solid #dc3545";
            }

            this.messageService.add({ severity: 'error', summary: '', detail: "Mandatory fields cannot be empty" });
            return false;
        }
        return true;
    }

    validateRoles() {
        if (this.roleRequestTypeList.length == 0) {
            this.messageService.add({ severity: 'error', summary: '', detail: "Please assign a role to the user" });
            return false;
        }
        return true;
    }

    resetFields() {
        this.countryDomElement = document.getElementById("country").firstChild as HTMLElement;
        this.functionDomElement = document.getElementById("function").firstChild as HTMLElement;
        this.usernameDomElement = document.getElementById("username").getElementsByTagName("span")[0].getElementsByTagName("input")[0];

        this.countryDomElement.style.border = "1px solid #a6a6a6";
        this.functionDomElement.style.border = "1px solid #a6a6a6";
        this.usernameDomElement.style.border = "1px solid #a6a6a6";
    }

    Edit(data) {
        this.selectedUser = data;
        console.log(this.selectedUser);
        this.editUser = new UserModel();
        this.editUser = JSON.parse(JSON.stringify(data));
        this.user = this.selectedUser;

        this.userLookupModel = this.selectedUser;
        // Pulling Supervisor always from AD as incase if that is updated we will get it.
        this.getSupervisor(this.userLookupModel.Email);
        this.roleRequestTypeList = this.user.UserRequestTypeRoleList;
//        this.selectedFunction = undefined;
//        this.selectedCountry = undefined;
        this.getFunctionList();
        this.getCountryList();
        this.displayDialog = true;
        this.isEditable = true;
        this.dialogHeaderText = "Edit";
        this.userReadonly = true;
    }

    Delete(data) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                console.log(data);
                this.user = data;
                this.DeleteUser();
                this.user = {}
            },
            reject: () => {

            }
        });
    }

    Update() {
        if (this.validateMandatoryControls() && this.validateRoles()) {
            this.user = this.selectedUser;
            this.user.UserRequestTypeRoleList = this.roleRequestTypeList;

            this.UpdateUser();
            this.displayDialog = false;
            this.user = {};
        }
    }

    public compareObject(obj: any, prop: string) {
        return (obj[prop] != '' || obj[prop] != null) ? true : false;
    }

    public compareObjects(objToCompare: any, objWithCompare: any, prop: any) {
        if (typeof (objToCompare[prop]) != 'object') {
            return (objToCompare[prop] !== objWithCompare[prop]) ? true : false;
        }
        else if (typeof (objToCompare[prop]) === 'object' && objToCompare[prop] !== null && objToCompare[prop] !== undefined) {
            if (objToCompare[prop].length != objWithCompare[prop].length) {
                return true;
            }
            else {
                for (let i = 0; i < objToCompare[prop].length; i++) {
                    for (let pr in objToCompare[prop][i]) {
                        if (this.compareObjects(objToCompare[prop][i], objWithCompare[prop][i], pr)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    Cancel() {
        var hasChanges: boolean = false;

        if (this.editUser == undefined) {
            for (let prop in this.user) {
                if (this.compareObject(this.user, prop)) {
                    hasChanges = true;
                    break;
                }
            }
        }
        else {
            for (let prop in this.user) {
                if (this.compareObjects(this.editUser, this.user, prop)) {
                    hasChanges = true;
                    break;
                }
            }
        }

        if (hasChanges) {
            this.confirmationService.confirm({
                message: 'Are you sure you want to cancel the UserRole assignment?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.user = {};
                    this.ngOnInit();
                    this.resetFields();
                    this.displayDialog = false;
                },
                reject: () => {

                }
            });
        }
        else {
            this.user = {};
            this.ngOnInit();
            this.resetFields();
            this.displayDialog = false;
        }
    }

    private InsertUser() {
        this.userService.createUser(this.user).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'User-Role saved successfully' })
                this.ngOnInit();
            });
    }

    private getUsers() {
        this.userService.getUsers().subscribe(response => this.users = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
            },
            () => {
                this.users.forEach(s => (
                    s.DisplayName = s.FirstName.concat(' ', s.LastName),
                    s.RoleName = s.UserRequestTypeRoleList.filter(u => u.UserId == s.UserId).map(m => m.RoleName).
                        //To get distinct item in array.
                        // arryItem = > item in array, itemIndex=> item index, arry=> array reference
                        filter((arryItem, itemIndex, arry) => arry.indexOf(arryItem) == itemIndex).join(', '),
                    s.RequestTypeName = s.UserRequestTypeRoleList.filter(u => u.UserId == s.UserId && u.RequestTypeName !== 'P2P' && u.RequestTypeName !== 'Radioactive').
                        map(m => m.RequestTypeName).filter((arryItem, itemIndex, arry) => arry.indexOf(arryItem) == itemIndex).join(', ')
                ));
            })
    }

    private UpdateUser() {
        this.userService.updateUser(this.user).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating. Contact Administrator' })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    private DeleteUser() {
        this.userService.deleteUser(this.user.UserId).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while deleting. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Deletion successful' })
                this.ngOnInit();
            });
    }

    private isRoleAssigned(roleId, requestTypeId, userRequestTypeRoleList) {
        if (userRequestTypeRoleList) {
            return userRequestTypeRoleList.find(c => c.RoleId == roleId && c.RequestTypeId == requestTypeId) ? true : false;
        } else {
            return false;
        }
    }

    private checkAdminstratorRole() {
        this.isAdminstrator = this.commonService.checkAdministrator();
        return this.isAdminstrator;
    }

    public get requestType(): typeof RequestType {
        return RequestType;
      }

}

