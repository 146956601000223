<div class="col-12 padding-unset" style="margin-top: 5px;margin-bottom:10px;">
   
    <div class="grid p-justify-end" style="margin-bottom:15px;" [ngClass]="isAdminstrator ? '' : 'space-when-add-recipient-button-hidden'">
        <div class="col-10"></div>
        <div class="col-2">
            <button style="float:right;" pButton type="button" icon="pi pi-plus" label="Add New Recipient Profile" class="p-button-info"
                (click)="showAddRecipientDialog()" *ngIf="isAdminstrator"></button>
        </div>
    </div>


    <div class="userprofile">
        <!-- <div class="col-12"> -->
        <div class="padding-unset"
            [ngStyle]="{'font-size':'12px','background-color':'aliceblue','padding':'unset','height':'29px'}">

            <div class="p-inputgroup float-left">
                <span class="p-inputgroup-addon">Name</span>
                <input id="inputDisplayNameFilter" class="filter-input" type="search" pInputText
                    placeholder="Filter By Name"
                    (input)="filter('DisplayName',$event.target.value, this.filterColumns,this.dataList)">
            </div>

            <div class="p-inputgroup float-left">
                <span class="p-inputgroup-addon">Country</span>
                <input id="inputCountryFilter" class="filter-input" type="search" pInputText
                    placeholder="Filter By Country"
                    (input)="filter('Country',$event.target.value, this.filterColumns,this.dataList)">
            </div>

            <div class="p-inputgroup float-left">
                <span class="p-inputgroup-addon">Company Name</span>
                <input id="inputLegalNameFilter" class="legalNameFilter" type="search" pInputText
                    placeholder="Filter By Company"
                    (input)="filter('LegalName',$event.target.value, this.filterColumns,this.dataList)">
            </div>

            <div class="p-inputgroup float-left">
                <span class="p-inputgroup-addon">Internal Contact</span>
                <input id="inputInternalContactNameFilter" class="contactNameFilter" type="search" pInputText
                    placeholder="Filter By Internal Contact"
                    (input)="filter('InternalContactName',$event.target.value, this.filterColumns,this.dataList)">
            </div>

            <div class="p-inputgroup p-inputgroup-addon p-inputtext ui-corner-all float-left">
                <input id="inputAddressFilter" class="withoutAddress" type="checkbox"
                    (click)="showWithoutAddressOnChange()">
                &nbsp; Profiles without Address
            </div>

            <div class="clearAll">
                <a  style="cursor:pointer" [routerLink]="" (click)="clearFilter()">Clear All Filters</a>
            </div>

        </div>
        <!-- </div> -->
    </div>
    <!--PrimeNg DataView -->
    <p-dataView #dv class="recipientProfile" layout="list" [paginator]="true" [rows]="10" [value]="results"
        paginatorPosition="both" [hidden]="isHidden" pageLinks="5" [alwaysShowPaginator]="true" styleClass="hover">

        <ng-template let-searchResults pTemplate="listItem">
            <!-- <div class="ui-dataview-content ui-widget-content"> -->
            <div class="col-12">
                <div class="grid resShadow recipientDiv"
                    [ngClass]="!searchResults.IsActive ? 'recipient-disable-row' : ''">
                    <div class="col-12">
                        <div class="grid">
                            <div class="col-11">
                                <div class="grid">
                                    <div class="col-4">
                                        <span class="lbl-115px">Name </span>
                                        {{searchResults.FullName}}
                                    </div>

                                    <div class="col-4">
                                        <span class="lbl-115px">Email </span>
                                        <span class="float-left" id="{{'editEmail'+searchResults.RecipientId}}">
                                            {{searchResults.Email}}</span>

                                        <input id="{{'divEditEmail'+searchResults.RecipientId}}"
                                            class="display-none float-left" type="text"
                                            [(ngModel)]="searchResults.Email" pInputText maxlength="250"
                                            (blur)="emailOnBlur(searchResults)">

                                        <i id="{{'divEditEmailIcon'+searchResults.RecipientId}}"
                                            (click)="editEmailButtonClick(searchResults)" class="pi pi-pencil edit" *ngIf = "isRecipientProfileEditable"></i>

                                        <i id="{{'divEditEmailCancelIcon'+searchResults.RecipientId}}"
                                            (click)="editEmailCancelButtonClick(searchResults)"
                                            class="pi pi-times editCancel" *ngIf = "isRecipientProfileEditable"></i>
                                    </div>

                                    <div class="col-4">
                                        <span class="lbl-115px">Country </span>
                                        {{searchResults.Country}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">
                                <span
                                    class="font-weight-bold">{{searchResults.IsActive ? 'Enabled' : 'Disabled'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="grid">
                            <div class="col-11">
                                <div class="grid">
                                    <div class="col-4">
                                        <span class="lbl-115px">Internal Contact &nbsp;</span>
                                        <span class="float-left"
                                            id="{{'editInternalContact'+searchResults.RecipientId}}">
                                            {{searchResults.InternalContactName}}</span>

                                        <div id="{{'divEditInternalContact'+searchResults.RecipientId}}"
                                            class="display-none float-left">
                                            <sein-user-search [id]="supervisorSearch"
                                                [searchApiUrl]="restApiURL +'{searchWord}'"
                                                [(ngModel)]="internalContactLookupModel"
                                                [defaultUserList]="userLookUpModelList"
                                                [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                                                placeholder="Search by name or email address"
                                                (onSelect)="internalContactOnSelect(searchResults)">
                                            </sein-user-search>
                                        </div>

                                        <i id="{{'divEditInternalContactIcon'+searchResults.RecipientId}}"
                                            (click)="editInternalContactButtonClick(searchResults)"
                                            class="pi pi-pencil edit" *ngIf = "isRecipientProfileEditable"></i>
                                        <i id="{{'divEditInternalContactCancelIcon'+searchResults.RecipientId}}"
                                            (click)="editInternalContactCancelButtonClick(searchResults)"
                                            class="pi pi-times editCancel" *ngIf = "isRecipientProfileEditable"></i>

                                    </div>
                                    <div class="col-4">
                                        <span class="lbl-115px">Company Name &nbsp;</span>
                                        <span class="float-left" id="{{'editLegalName'+searchResults.RecipientId}}">
                                            {{searchResults.LegalName}} </span>

                                        <input id="{{'divEditLegalName'+searchResults.RecipientId}}"
                                            class="display-none float-left" type="text"
                                            [(ngModel)]="searchResults.LegalName" pInputText maxlength="250"
                                            (blur)="legalNameOnBlur(searchResults)">

                                        <i id="{{'divEditLegalNameIcon'+searchResults.RecipientId}}"
                                            (click)="editLegalNameButtonClick(searchResults)"
                                            class="pi pi-pencil edit" *ngIf = "isRecipientProfileEditable"></i>

                                        <i id="{{'divEditLegalNameCancelIcon'+searchResults.RecipientId}}"
                                            (click)="editLegalNameCancelButtonClick(searchResults)"
                                            class="pi pi-times editCancel" *ngIf = "isRecipientProfileEditable"></i>
                                    </div>
                                    <div class="col-4">
                                        <span class="lbl-115px">Contact Number &nbsp;</span>
                                        <span class="float-left" id="{{'editContact'+searchResults.RecipientId}}">
                                            {{searchResults.ContactNumber}}</span>

                                        <input id="{{'divEditContact'+searchResults.RecipientId}}" type="text"
                                            class="form-control input-box display-none float-left"
                                            (keypress)="validationService.contactNumberValidation($event)"
                                            [(ngModel)]="searchResults.ContactNumber" size=20 pInputText maxlength="30"
                                            (blur)="onBlurContactNumber(searchResults)">

                                        <i id="{{'divEditContactIcon'+searchResults.RecipientId}}"
                                            (click)="editContactButtonClick(searchResults)"
                                            class="pi pi-pencil edit" *ngIf = "isRecipientProfileEditable"></i>
                                        <i id="{{'divEditContactCancelIcon'+searchResults.RecipientId}}"
                                            (click)="editContactCancelButtonClick(searchResults)"
                                            class="pi pi-times editCancel" *ngIf = "isRecipientProfileEditable"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">
                                <p-inputSwitch [(ngModel)]="searchResults.IsActive"
                                    (onChange)="changeRecipientProfileStatus($event,searchResults)"
                                    [ngStyle]="{'pointer-events':'visible'}" title="Enable/Disable" *ngIf="isAdminstrator"></p-inputSwitch>
                            </div>
                        </div>
                    </div>
                    <div
                        [ngClass]="searchResults.IsActive && searchResults.ShowAddressBar ? 'col-12 header-bar' : 'col-12'">
                        <a class="addresses" [routerLink]=""
                            (click)="showRecipientAddresses(searchResults, searchResults.FullName);canEditRecipientProfile();">Addresses
                            <i [ngStyle]="{'vertical-align': 'middle'}"
                                [ngClass]="searchResults.ShowAddressBar?'pi pi-caret-up' : 'pi pi-caret-down'"></i></a>

                    </div>
                    <div class="col-12 display-none" id="div{{searchResults.Email}}">
                        <div class="divCarousel addressBackground recipient-carousel">
                            <p-carousel #addressCarousel [value]="searchResults.AddressList" [numScroll]="4" [numVisible]="4">
                                <ng-template let-address pTemplate="item">
                                    <div class="col-12 addAddressCarousel" *ngIf="address.PersonFullName == 'Add'">
                                        <div class="addAddress">
                                            <a href="javascript:void(0)"
                                                (click)="showAddAddressDialog(searchResults,true)"
                                                class="addAddressAnchor" *ngIf = "isRecipientProfileEditable">+ Add Address</a>

                                                <!-- <a href="javascript:void(0)"
                                                class="addAddressAnchor block-element-action" *ngIf = "!isRecipientProfileEditable">+ Add Address</a> -->
                                        </div>
                                    </div>
                                    <div class="col addressCarousel" *ngIf="address.PersonFullName != 'Add'">
                                        <div class="shippingAddress address">

                                            <div class="addressBlock">
                                                <span class="font-weight-bold">{{address.PersonFullName}}</span>
                                                <div class="float-right">
                                                    <i class="pi pi-pencil edit-address-button"
                                                        (click)="showEditAddressDialog(address,searchResults.Email,searchResults.FullName)"
                                                        title="Edit" *ngIf = "isRecipientProfileEditable"></i>
                                                </div>
                                                <div>{{address.AddressLine1}}</div>
                                                <div>{{address.AddressLine2}}</div>
                                                <div>{{address.AddressLine3}}</div>
                                                <div>{{address.City}}, {{address.State}} - {{address.PostalCode}}
                                                </div>
                                                <div>{{address.Country}}</div>
                                                <div><span>Phone Number : </span> {{address.Mobile}}</div>
                                                <div>{{address.OrgName}}</div>
                                            </div>
                                            <div class="grid p-justify-between addressBlockFooter">
                                                <div class="col-12"><span *ngIf="address.ModifiedBy!==undefined&&address.ModifiedBy!==null&&address.ModifiedBy!==''" style="font-size:10px !important;">Modifed by {{address.ModifiedBy}} at {{address.ModifiedAt | date: 'd-MMM-y h:mm:ss a'}}</span></div>
                                                <div class="col-12">
                                                    <p-checkbox inputId="{{'chk'+ address.AddressId}}"
                                                        name="{{searchResults.Email}}" [(ngModel)]="address.IsDefaultAddress" binary="true"
                                                        (onChange)="setAsDefaultOnChange(searchResults,address)"
                                                        label="Set as Default" *ngIf="canEditRecipientProfile(searchResults.Email)">
                                                    </p-checkbox>

                                                    <button pButton type="button" icon="pi pi-times" label="Remove"
                                                        (click)="removeAddress(searchResults,address.AddressId,false)"
                                                        class="p-button-danger removeButton float-right" *ngIf="isRecipientProfileEditable">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>
                            </p-carousel>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </ng-template>

        <ng-template pTemplate="paginatorleft" let-state>
            <span *ngIf="results != null" class="ui-paginator-first">
                Total Items : {{totalRecordCount}}
                {{ dv.totalRecords == totalRecordCount ? '' : '(Showing Items: '+ dv.totalRecords + ')'}}
            </span>
        </ng-template>
        <ng-template pTemplate="paginatorright" let-state>
            <span class="ui-paginator-first">
                {{ dv.totalRecords > 0 ? (state.page * state.rows) + 1 : 0 }}
                -
                {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page + 1)) }}
                of {{dv.totalRecords}} Items</span>
        </ng-template>


    </p-dataView>
    <!--PrimeNg DataView -->
    <p-toast position="top-right"></p-toast>

    <!-- Address Pop up -->
    <app-address [visible]="showAddressDialog" [addressModel]="addressModel" [recipientEmail]="recipientEmail"
        [recipientType]="recipientOption"
        (eventToReceiveNewCreatedAddress)="receiveAddressModelFromAddressComponent($event,true)"
        (eventToCloseAddressPopUp)="onAddressPopUpClose($event)" *ngIf="showAddressDialog == true"></app-address>

    <add-external-recipient-profile [visible]="showRecipientDialog"
        (eventToReceiveNewCreatedRecipient)="receiveNewCreatedRecipient()"
        (eventToCloseAddRecipientPopUp)="onCloseAddRecipientPopUp()"></add-external-recipient-profile>

    <p-confirmDialog #removeAddressConfirmDialog key="removeAddressConfirmDialog" header="Confirmation"
        icon="pi pi-exclamation-triangle" [closable]="false">
        <p-footer>
            <button type="button" pButton icon="pi pi-check" label="OK"
                (click)="removeAddressConfirmDialog.accept()"></button>
            <button type="button" pButton icon="pi pi-times" label="Cancel"
                (click)="removeAddressConfirmDialog.reject()"></button>
        </p-footer>
    </p-confirmDialog>

    <p-confirmDialog #changeProfileStatusConfirmDialog key="changeProfileStatusConfirmDialog" header="Confirmation"
        icon="pi pi-exclamation-triangle" [closable]="false">
        <p-footer>
            <button type="button" pButton icon="pi pi-check" label="OK"
                (click)="changeProfileStatusConfirmDialog.accept()"></button>
            <button type="button" pButton icon="pi pi-times" label="Cancel"
                (click)="changeProfileStatusConfirmDialog.reject()"></button>
        </p-footer>
    </p-confirmDialog>

</div>