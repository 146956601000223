import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';
import { UnitOfMeasureService } from 'src/app/services/unit-of-measure-service/unit-of-measure.service';
import { RequestModel } from '../request/request.model';
import { UnitOfMeasureModel } from 'src/app/unit-of-measure/unit-of-measure.model';
import { RequestItemModel } from './request-item.model';
import { AppComponent } from 'src/app/app.component';
import { Priority } from './priority.enum';
import { RequestItemPriorityModel } from './request-item-priority.model';
import { RequestItemDocumentEUPModel } from './request-item-document-eup.model';
import { RequestItemAttachmentService } from 'src/app/services/request-item-attachment-service/request-item-attachment.service';
import { PackedWithChoices } from '../request-item-attachments/packed-with-choices.model';
import { tap } from 'rxjs/operators';
import { CountryService } from 'src/app/services/country-service/country.service';
import { UoMConversionModel } from './uom-conversion.model';
import { ActivatedRoute } from '@angular/router';
import { Chemical } from 'src/app/search/chemical';
import { RequestService } from 'src/app/services/request-service/request.service';
import { RequestRecipientModel } from 'src/app/request-recipient/request-recipient.model';
import { RecipientModel } from 'src/app/recipient-profile/recipient-profile/recipient.model';
import { CountryLabel, RaceLabelsClient, RaceResponseModel } from 'src/app/services/gsscommon-api-client.service';
import { CommonService } from 'src/app/services/common-service/common.service';
import { AddressModel } from 'src/app/address/address.model';

@Component({
  selector: 'app-chemical-details',
  templateUrl: './add-chemical-details.component.html',
  styleUrls: ['./add-chemical-details.component.css'],

  providers: [RequestItemService, MessageService, UnitOfMeasureService, ConfirmationService, RequestItemAttachmentService]
})

export class AddChemicalDetailsComponent implements OnInit, DoCheck {
  @Input() requestModel: RequestModel;
  uploadedFiles: any[] = [];
  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  requestNumber: string;
  requestTypeName: string;
  materialSearchType: string;
  selectedLabel: string = "";
  initialRemainingAmount:number;
  growlMsgs: ToastModule[] = [];

  unitOfMeasureList: UnitOfMeasureModel[];
  containerLabelList: any[] = [];
  saveRequestItemModelList: RequestItemModel[];
  selectedUnitOfMeasure: any;
  otherDocTextBoxStyle: object; // To set the style
  isQuestionaireTermsChecked: boolean = false;
  editRequestItemModel = new RequestItemModel();
  synonymsList: any;
  serviceResponse: any;
  showAddShippingStep: boolean = false;
  ShipNowStyle: object;
  defaultDate = new Date("1-jan-1970");
  minNeedByDate = new Date();
  packedWithChoices: PackedWithChoices[] = [];
  showQuestionnaireDialog: boolean = false;
  questionnairesForm: UntypedFormGroup;
  private biologicalQuestionnaire: any;
  PackedWithChoice: PackedWithChoices;
  isDisplayOthers: boolean;
  countries: any;
  filteredCountriesSingle: any[];
  public alphaNumSpaces: RegExp = /^[A-Za-z0-9 ]+$/;
  isPermitContainerShowForP2P = false;
  requestRecipientModel: RequestRecipientModel;
  ShipmentAddressModel: AddressModel;
  raceResponse : RaceResponseModel;
  constructor(private _formBuilder: UntypedFormBuilder, private messageService: MessageService,
    private requestItemService: RequestItemService, private appComponent: AppComponent,
    private unitOfMeasureService: UnitOfMeasureService, private confirmationService: ConfirmationService,
    private requestItemAttachmentService: RequestItemAttachmentService, private formBuilder: UntypedFormBuilder,
    private countryService: CountryService, private route: ActivatedRoute,private requestService : RequestService,
    private raceLabelClient: RaceLabelsClient, private commonService:CommonService
    ) {

  }

  onOriginBlurValidation(event, fcName) {
    var selectedOrigin = event.target.value;
    let index = this.countries.findIndex(cntry => cntry.Name == selectedOrigin);
    if (index == -1) {
      this.questionnairesForm.controls[fcName].setValue("");
    }
  }


  get disableSaveBn(): boolean {
    var flag = false;
    if (!this.editRequestItemModel?.ContainerLabel || this.editRequestItemModel.isCustomLabel) {
      if (this.editRequestItemModel.isCustomLabel) {
        flag = !(this.editRequestItemModel?.requestedContainerLabel?.length > 0)
      }
    }
    return flag;
  }

  ngOnInit() {
    this.isPermitContainerShowForP2P = (this.route.snapshot.params.requesttype === 'P2P') ? true : false;
    this.requestNumber = this.requestModel.RequestNumber;
    this.requestTypeName = this.requestModel.RequestTypeName;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.questionnairesForm = this.formBuilder.group({});
    this.getAllCountries();
}

  ngDoCheck() {

  }

  public getRequestRecipient(requestNumber: string) {
    this.requestService.getRequestShippingDetail(this.requestModel.ShippingOption, requestNumber).subscribe(response => this.serviceResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.requestRecipientModel=this.serviceResponse;
        if (this.requestRecipientModel.ShipmentAddressModel) {
          this.ShipmentAddressModel = this.requestRecipientModel.ShipmentAddressModel;
          let coutryName = this.ShipmentAddressModel.Country.toLowerCase();
          let material = this.editRequestItemModel.ChemicalName.split(' ')[0];
          this.raceLabelClient.retrieveLabelsForCompositionByCountry(material,this.getCountryCode(coutryName),this.ShipmentAddressModel.Country).subscribe(resp=>{
            this.raceResponse = resp;
            let countryLabels = this.raceResponse.countryLabels.find((x:CountryLabel)=>x.country.toLowerCase() == coutryName);
            if(countryLabels){
              let lables=countryLabels.labels || [];

              for (let i = lables.length-1; i >= 0; i--) {
                if(this.containerLabelList.map(x=>x.label).indexOf(lables[i]) === -1)
                {
                  var item = { label: '', value: '',isRaceLabel : true };
                  item.label = lables[i];
                  item.value = lables[i];
                  this.containerLabelList.unshift(item);
                }
              }
              this.editRequestItemModel.ContainerLabel = this.selectedLabel;
            }
            //console.log("labels",this.containerLabelList);
          })
        }
      })
  }



  //--------------------------------------------------------
  // Call method to  get list of item added by use to the cart.
  //------------------------------------------------------------
  private getRequestItemByRequestNumber(requestTypeName: string, requestNumber: string) {
    this.requestItemService.getRequestItems(requestTypeName, requestNumber).subscribe(response => {
      this.requestModel.RequestItemList = response;
      // When user comes from cart details page. ALL the item from the cart are removed
      // So make the No of item in the cart 0.
      if (this.appComponent.previousRouteURL == '/cart') {
        this.appComponent.cartItemsCount = 0;
      }

    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      })
  }

  private getPackedWithChoicesList() {
    this.requestItemAttachmentService.getPackedWithChoicesList().subscribe((response) => {
      this.packedWithChoices = response;
    })
  }

  UpdateRemainingAmount(removedQty)
  {
    if(this.initialRemainingAmount < 0)
    {
      this.editRequestItemModel.AmountRemaining = removedQty[0] + this.initialRemainingAmount ;
    }
    else
    {
    this.editRequestItemModel.AmountRemaining =  this.initialRemainingAmount - removedQty[0];
    }
    this.editRequestItemModel.AmountRemaining = this.editRequestItemModel.AmountRemaining - removedQty[1] ;
  }

  //-----------------------------------------------------------------
  // Open a popup on each row to enter the detaiis and save.
  //----------------------------------------------------------------
  public showDialog(requestItem) {
    this.uploadedFiles = [];
    this.messageService.clear();
    this.getMeasureUnitList(this.requestModel.RequestTypeId, requestItem);
    this.editRequestItemModel = new RequestItemModel();
    this.editRequestItemModel = JSON.parse(JSON.stringify(requestItem));
    this.selectedLabel = this.editRequestItemModel.ContainerLabel;
    this.handleOtherDocTextBoxVisibility(this.editRequestItemModel.OtherDoc);
    if (this.editRequestItemModel.NeedByDate == this.defaultDate || this.editRequestItemModel.NeedByDate == null) {
      this.editRequestItemModel.NeedByDate = null;
    }
    else {
      this.editRequestItemModel.NeedByDate = new Date(requestItem.NeedByDate);
    }
    requestItem.OtherDocText = this.editRequestItemModel.OtherDocText;
    this.editRequestItemModel.Priority = this.editRequestItemModel.Priority == null ? Priority.Standard : this.editRequestItemModel.Priority;
    this.editRequestItemModel.COO = this.editRequestItemModel.COO == null ? false : this.editRequestItemModel.COO
    this.editRequestItemModel.OtherDocText = this.editRequestItemModel.OtherDocText == null ? "" : this.editRequestItemModel.OtherDocText;
    this.editRequestItemModel.ShipNow = this.editRequestItemModel.ShipNow == null ? false : this.editRequestItemModel.ShipNow;
    this.editRequestItemModel.isCustomLabel = this.editRequestItemModel.IsUserDefinedContainerLabel && this.editRequestItemModel.IsUserDefinedContainerLabel ? true : false;
    if (this.editRequestItemModel.isCustomLabel) {
      this.editRequestItemModel.requestedContainerLabel = this.editRequestItemModel.ContainerLabel;
    }
    this.getSynonyms(requestItem);
    this.materialSearchType = requestItem.MaterialSearchType;
    this.PackedWithChoice = {
      Id: requestItem.PackedWithChoiceId,
      Name: requestItem.PackedWithChoiceName
    }
    this.getPackedWithChoicesList();
    this.initialRemainingAmount = this.editRequestItemModel.AmountRemaining;
    if(this.editRequestItemModel.RepoName === "FACTS")
    {
        this.getRequestRecipient(this.requestNumber);
    }
  }

  //-----------------------------------------------------------------
  // Close the pop up
  //----------------------------------------------------------------
  public closeDialog(requestItem) {
    var hasChanges: boolean = false;
    var propertiesNeedToSkip = ['Display', 'RequestItemContainerList', 'RequestItemAttachmentList', 'ApplyPriorityToAllSamples'];
    //Set default values to requestItem to compare with Edit request item
    if (requestItem.UnitOfMeasureId !== undefined && requestItem.UnitOfMeasureId == null && requestItem.UnitOfMeasure != "") {
      requestItem.UnitOfMeasureId = this.unitOfMeasureList.find(r => r.UnitOfMeasure.toLowerCase() == requestItem.UnitOfMeasure.toLowerCase()).UnitOfMeasureId;
    }
    if (requestItem.RequestItemComment.length == 0) {
      requestItem.RequestItemComment = [{
        Id: 0,
        CommentId: 0,
        RequestItemId: requestItem.RequestItemId,
        Comment: {
          Id: 0,
          Comment1: "",
          EnteredByUserId: 0,
          EnteredAt: "2021-03-08T23:11:39.54",
        }
      }]
    }
    var currentPriority = requestItem.Priority;
    var currentCOO = requestItem.COO;
    var currentOtherDocText = requestItem.OtherDocText;
    var currentShipNow = requestItem.ShipNow;
    requestItem.Priority = requestItem.Priority == null ? Priority.Standard : requestItem.Priority;
    requestItem.COO = requestItem.COO == null ? false : requestItem.COO
    requestItem.OtherDocText = requestItem.OtherDocText == null ? "" : requestItem.OtherDocText;
    requestItem.ShipNow = requestItem.ShipNow == null ? false : requestItem.ShipNow;


    for (let prop in requestItem) {

      if (propertiesNeedToSkip.findIndex(x => x == prop) < 0) {
        if (this.compareObject(requestItem, prop)) {
          hasChanges = true;
          break;
        }
      }
    }
    // Below two lines are for add container.
    if (hasChanges == false) {
      var newlyAddedContainer = this.editRequestItemModel.RequestItemContainerList.filter(s => s.RequestItemContainerId == 0);
      var deletedContainer = this.editRequestItemModel.RequestItemContainerList.filter(s => s.IsContainerDeleted == true);
      if (newlyAddedContainer.length > 0 || deletedContainer.length > 0) {
        hasChanges = true;
      }
    }
    // for attachment comment.
    if (hasChanges == false) {
      if (this.editRequestItemModel.RequestItemAttachmentList.length != requestItem.RequestItemAttachmentList.length) {
        hasChanges = true;
      }
      this.editRequestItemModel.RequestItemAttachmentList.forEach((element) => {
        var requestItemAttachment = requestItem.RequestItemAttachmentList.filter(s => s.FileName == element.FileName);
        if (requestItemAttachment.length > 0) {
          if (requestItemAttachment[0].Comment !== element.Comment) {
            hasChanges = true;
          }
          else if (requestItemAttachment[0].DocumentTypeId !== element.DocumentTypeId) {
            if (requestItemAttachment[0].DocumentTypeId != null && element.DocumentType.Name != "Other") {
              hasChanges = true;
            }
          }
        }
      });
    }

    //for custom label.
    if (hasChanges == false) {
      if (this.editRequestItemModel.isCustomLabel) {
        hasChanges = this.editRequestItemModel.ContainerLabel != this.editRequestItemModel.requestedContainerLabel;
      }
    }

    //for packed with choices
    if (hasChanges == false) {
      if (this.PackedWithChoice.Id !== this.editRequestItemModel.PackedWithChoiceId) {
        hasChanges = true;
      }
    }

    if (hasChanges) {
      this.confirmationService.confirm({
        message: 'Do you want to save the newly entered data?',
        header: 'Close Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.saveRequestItemDetails(requestItem);
        },
        reject: () => {
          //Reset to previous values after compare
          requestItem.Priority = currentPriority;
          requestItem.COO = currentCOO;
          requestItem.OtherDocText = currentOtherDocText;
          requestItem.ShipNow = currentShipNow;
          requestItem.Display = false;
        }
      });
    }
    else {
      //Reset to previous values after compare
      requestItem.Priority = currentPriority;
      requestItem.COO = currentCOO;
      requestItem.OtherDocText = currentOtherDocText;
      requestItem.ShipNow = currentShipNow;
      requestItem.Display = false;
    }
  }
  // Returns true if the user has changed the value in the pop up form.
  public compareObject(obj: any, prop: string) {
    if (prop == 'NeedByDate') {
      // Because of some date format we are handling comparision differently.
      // Just convesring values to its string.
      return new Date(this.editRequestItemModel[prop]).toDateString() !== new Date(obj[prop]).toDateString();
    }
    else if (prop == 'RequestItemComment') {
      if (this.editRequestItemModel.RequestItemComment[0].Comment.Comment1 === obj.RequestItemComment[0].Comment.Comment1) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      if (this.editRequestItemModel[prop] == "" && obj[prop] == null) {
        return false;
      }
      return this.editRequestItemModel[prop] !== obj[prop];
    }
    //return this.editRequestItemModel[prop] !== obj[prop];
  }
  //-----------------------------------------------------------------
  //  To get the list of measure unit.
  //----------------------------------------------------------------
  public getMeasureUnitList(requestTypeId, requestItem) {
    this.unitOfMeasureService.getUnitOfMeasures(requestTypeId).subscribe(response => {
      this.unitOfMeasureList = response;
      requestItem.Display = true;
      if (requestItem.RequestLocationId == 0) {
        let uom = this.unitOfMeasureList.find(r => r.UnitOfMeasureId == requestItem.UnitOfMeasureId);
        if(uom){
          this.selectedUnitOfMeasure = uom;
        }else{
          this.selectedUnitOfMeasure = this.unitOfMeasureList.find(r => r.UnitOfMeasure == 'g');
        }
        this.editRequestItemModel.UnitOfMeasureId = this.selectedUnitOfMeasure.UnitOfMeasureId;
        this.editRequestItemModel.UnitOfMeasure = this.selectedUnitOfMeasure.UnitOfMeasure;
      }
      else {
        this.selectedUnitOfMeasure = this.unitOfMeasureList.find(r => r.UnitOfMeasure.toLowerCase() == requestItem.UnitOfMeasure.toLowerCase());
        this.editRequestItemModel.UnitOfMeasureId = this.selectedUnitOfMeasure.UnitOfMeasureId;
        this.editRequestItemModel.UnitOfMeasure = this.selectedUnitOfMeasure.UnitOfMeasure;
      }
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      })
  }
  //-----------------------------------------------------------------
  //  To get the synonyms
  //----------------------------------------------------------------
  public getSynonyms(requestItem: any) {
    this.requestItemService.getSynonyms(requestItem.RequestItemId, this.requestTypeName).subscribe(response => {
      this.synonymsList = response;
      this.containerLabelList = [];
      // Add user enter synonyms into defined synonyms list for chameical.
      // this.containerLabelList.push({ label: requestItem.ContainerLabel, value: requestItem.ContainerLabel });
      //Filter synonymsList to get synomyms of selected chemical.

      if (this.synonymsList.length > 0) {
        var result = this.synonymsList.filter(
          s => s.MaterialName == requestItem.ChemicalName
        )[0].SynonymList;
        // Add item in chemicalSynonyms in the format of label and value.
        result.forEach((e) => {
          var item = { label: '', value: '' };
          item.label = e;
          item.value = e;
          this.containerLabelList.push(item);
        })

      }



      if (this.containerLabelList.length > 0) {
        //sorting Label length
        this.containerLabelList.sort((a, b) => a.label.length - b.label.length);
      }
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      })
  }
  //---------------------------------------
  // Fires when user change measure unit
  //---------------------------------------
  public onMeasureUnitChange(selectedUnitOfMeasure) {
    if (this.editRequestItemModel.FarmLocationId) {
      const uomItemConversion = {
        ProductIdentifier: this.editRequestItemModel.MaterialId,
        ProductSourceSystem: this.editRequestItemModel.RepoName,
        Amount: 1,
        FromUnit: this.editRequestItemModel.UnitOfMeasure,
        ToUnit: this.selectedUnitOfMeasure.UnitOfMeasure,
      };
      this.editRequestItemModel.UnitOfMeasure = selectedUnitOfMeasure.UnitOfMeasure;
      this.editRequestItemModel.UnitOfMeasureId = selectedUnitOfMeasure.UnitOfMeasureId;
      this.unitOfMeasureService.uomConvert(uomItemConversion).subscribe((response: UoMConversionModel) => {
        this.editRequestItemModel.TotalRequestedQuantity = +((this.editRequestItemModel.TotalRequestedQuantity * response.Amount));
        this.editRequestItemModel.AmountForecasted = +((response.Amount * this.editRequestItemModel.AmountForecasted));
        this.editRequestItemModel.AmountRemaining = +((response.Amount * this.editRequestItemModel.AmountRemaining));
        this.initialRemainingAmount = response.Amount * this.initialRemainingAmount;
        this.editRequestItemModel.RequestItemContainerList.forEach(container => {
          container.AmountPerContainer = +((container.AmountPerContainer * response.Amount));
        });
      })
    }
    else {
      this.editRequestItemModel.UnitOfMeasure = selectedUnitOfMeasure.UnitOfMeasure;
      this.editRequestItemModel.UnitOfMeasureId = selectedUnitOfMeasure.UnitOfMeasureId;
    }
  }
  //----------------------------------------------------------
  // Fires when user click on save button to save the data.
  //----------------------------------------------------------
  public saveRequestItemDetails(requestItemModel: RequestItemModel) {
    if (this.requestTypeName == 'P2P') {
      if (requestItemModel.CustomsValue !== undefined && requestItemModel.CustomsValue >= 1) {
        this.messageService.clear();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please enter value greater or equal to 1" });
        return;
      }
    }
    this.saveRequestItemModelList = [];
    this.messageService.clear('fileUploadValidation');
    this.messageService.clear('fileUploadSummary');
    if (this.editRequestItemModel.NeedByDate) {
      this.editRequestItemModel.NeedByDate = new Date(this.editRequestItemModel.NeedByDate.toString() + "UTC");
    }
    this.editRequestItemModel.IsUserDefinedContainerLabel = this.editRequestItemModel.isCustomLabel;
    if (this.editRequestItemModel.isCustomLabel) {
      this.editRequestItemModel.ContainerLabel = this.editRequestItemModel.requestedContainerLabel;
    }
    if (this.PackedWithChoice) {
      this.editRequestItemModel.PackedWithChoiceId = this.PackedWithChoice.Id;
    }
    this.saveRequestItemModelList.push(this.editRequestItemModel);
    //this.requestItemAttachmentService.saveRequestItemAttachment(this.uploadedFiles, this.editRequestItemModel).subscribe(() => {
    // this.requestItemAttachmentService.saveRequestItemAttachment(this.uploadedFiles,this.editRequestItemModel).subscribe(() => {});
    this.requestItemService.createRequestItem(this.requestTypeName, this.saveRequestItemModelList).subscribe(response => {
      requestItemModel.Display = false;

      if (this.uploadedFiles.length > 0) {
        this.uploadedFiles.forEach(element => {
          var comment = this.editRequestItemModel.RequestItemAttachmentList.filter(x => x.FileName == element.name)[0].Comment;
          element.Comment = comment == null ? "n/a" : comment;
        });
        this.requestItemAttachmentService.saveRequestItemAttachment(this.uploadedFiles, this.editRequestItemModel).subscribe(() => {
          this.getRequestItemByRequestNumber(this.requestTypeName, this.requestNumber);
          this.uploadedFiles = [];
          this.messageService.add({ severity: 'success', summary: 'Details for ' + requestItemModel.ChemicalName + ' added successfully', detail: '', life: 500 });
        },
          error => {
            this.messageService.add({ severity: 'error', summary: '', detail: "Attachment not received please try again" });
          });
      }
      else {
        this.messageService.add({ severity: 'success', summary: 'Details for ' + requestItemModel.ChemicalName + ' added successfully', detail: '', life: 500 });
        this.getRequestItemByRequestNumber(this.requestTypeName, this.requestNumber);
      }
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.message });
      })
  }

  // Handle click of Other
  public setOtherDocTextBoxVisibility(setting) {
    this.otherDocTextBoxStyle = {
      'display': setting
    }
  }

  //---------------------------------
  // Handle click of Other
  //---------------------------------
  public handleOtherDocTextBoxVisibility(checked) {
    if (checked) {
      this.setOtherDocTextBoxVisibility('block');
    }
    else {
      this.setOtherDocTextBoxVisibility('none');
      this.editRequestItemModel.OtherDocText = null;
    }
  }

  //------------------------------------
  // Remove item form request.
  //------------------------------------
  public removeRequestItem(requestItemModel: RequestItemModel) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the selected material?',
      header: 'Remove Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.requestItemService.deleteRequestItem(this.requestTypeName, requestItemModel.RequestItemId).subscribe(res => this.serviceResponse = res,
          error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.message });
          },
          () => {
            this.messageService.add({ severity: 'success', summary: '', detail: requestItemModel.ChemicalName + ' removed from the Request', life: 500 });
            this.getRequestItemByRequestNumber(this.requestTypeName, this.requestNumber);
          });
      },
      reject: () => {

      }
    });
  }

  public GetUploadedFiles(files: any) {
    for (let file of files) {
      this.uploadedFiles.push(file);
    }

  }
  public DeleteFile(fileName: string) {
    this.uploadedFiles.forEach((element, index) => {
      if (element.name == fileName) this.uploadedFiles.splice(index, 1);
    });
  }

  public UpdateDocumentType(documentTypeDetails: any) {
    this.uploadedFiles.filter(x => x.name == documentTypeDetails.fileName)[0].DocumentTypeId = documentTypeDetails.documentTypeId;

  }
  public priorityApplyToAllSamples() {

    //let requestItemPriorityModel: RequestItemPriorityModel = { RequestId: this.requestModel.RequestId, Priority: this.editRequestItemModel.Priority, ShipNow: this.editRequestItemModel.ShipNow, NeedByDate: new Date(this.editRequestItemModel.NeedByDate.toString() + "UTC") }
    let requestItemPriorityModel: RequestItemPriorityModel = { RequestId: this.requestModel.RequestId, Priority: this.editRequestItemModel.Priority, ShipNow: this.editRequestItemModel.ShipNow, NeedByDate: null }
    this.requestItemService.applyPriorityToAllSamples(this.requestTypeName, requestItemPriorityModel).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.message });
      },
      () => {
        this.messageService.add({ severity: 'success', summary: 'selection applied for all samples', detail: '', life: 500 });
      });

  }


  public applyDocumentEUPToAllSamples() {
    let requestItemDocumentEUPModel: RequestItemDocumentEUPModel = { RequestId: this.requestModel.RequestId, SendInvoiceBefore: this.editRequestItemModel.SendInvoiceBefore, SDS: this.editRequestItemModel.SDS, COO: this.editRequestItemModel.COO, CoA: this.editRequestItemModel.CoA, OtherDoc: this.editRequestItemModel.OtherDoc, OtherDocText: this.editRequestItemModel.OtherDocText }
    this.requestItemService.applyDocumentEUPToAllSamples(this.requestTypeName, requestItemDocumentEUPModel).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.message });
      },
      () => {
        this.messageService.add({ severity: 'success', summary: 'selection applied for all samples', detail: '', life: 500 });
      });
  }

  public showQuestionnaireModal() {
    // if(this.biologicalQuestionnaire) {
    //   this.buildQuestionnaireForm();
    // } else {
    this.getBiologicalQuestionnaire(this.requestNumber)

    // }
  }

  getAllCountries() {
    if (this.countries == undefined || this.countries == null) {
      this.countryService.getCountryList().subscribe(res => this.serviceResponse = res,
        error => {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
        },
        () => {
          this.countries = this.serviceResponse;
        })
    }
  }

  getCountryCode(name:String){
    let countryCode='';
    if (this.countries && name) {
      let country = this.countries.find(x=>x.Name == name.toUpperCase());
      if(country){
        countryCode=country.Code;
      }
    }
    return countryCode;
  }

  filterCountrySingle(event) {
    let query = event.query;
    this.filteredCountriesSingle = this.filterCountry(query, this.countries);
  }

  filterCountry(query, countries: any[]): any[] {
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.Name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    return filtered;
  }

  private isNumeric(val): boolean {
    return /^-?\d+$/.test(val);
  }

  saveQuetionnaire() {
    this.showQuestionnaireDialog = false;
    const response = [];
    var responseString = "";
    Object.keys(this.questionnairesForm.value).forEach(key => {
      if (this.isNumeric(key)) {
        const value = this.questionnairesForm.value[key];
        if (responseString !== "")
          responseString = responseString.concat("\n")
        const questionnaireQuestion = this.biologicalQuestionnaire.find(x => x.QuestionId == key);
        if (questionnaireQuestion.QuestionType.QuestionType1 === 'MultipleChoice') {
          let localChoiceName = typeof value === 'object' ? value.ChoiceName?.toString() :
            questionnaireQuestion.QuestionChoices.find(choice => choice.QuestionChoiceId == value)?.ChoiceName;
          if (localChoiceName == "Other (explain)") {
            questionnaireQuestion.OthersValue = this.questionnairesForm.controls["others_" + key].value;
          }
        }
        //questionnaireQuestion.OthersValue = this.questionnairesForm.controls["others_" + key].value;
        responseString = responseString.concat(questionnaireQuestion.QuestionCaption, "\n", this.getAnswerForSave(questionnaireQuestion, value))  //, "\n", questionnaireQuestion.OthersValue

        response.push({
          questionId: Number(key),
          response: typeof value === 'object' ? (questionnaireQuestion.QuestionCaption == "Origin" ? value.Name.toString() : value.QuestionChoiceId?.toString()) : value.toString(),
          AdditionalResponse: questionnaireQuestion.OthersValue
        })
      }
    })
    var payload = {
      responseString: responseString,
      responses: response
    };

    this.requestItemService.saveQuestionnaire(payload, this.requestNumber).subscribe();


  }

  private getBiologicalQuestionnaire(requestId) {
    this.requestItemService.getBiologicalQuestionnaire(requestId).pipe(
      tap(res => {
        this.biologicalQuestionnaire = res;
        this.biologicalQuestionnaire.forEach(question => {
          if (question.QuestionType.QuestionType1 === 'MultipleChoice') {

            question.QuestionChoices.sort((a, b) => {
              if (a.ChoiceName.toUpperCase() < b.ChoiceName.toUpperCase()) {
                return -1;
              }
              else if (a.ChoiceName.toUpperCase() > b.ChoiceName.toUpperCase()) {
                return 1;
              }
              return 0;
            });
          }
        });
        this.buildQuestionnaireForm();


      })
    ).subscribe()

  }

  private buildQuestionnaireForm() {
    this.questionnairesForm = this.formBuilder.group({});
    this.biologicalQuestionnaire.forEach(question => {
      const value = question?.RequestItemQuestionResponses[0]?.Response;
      this.questionnairesForm.addControl(question.QuestionId, this.formBuilder.control(this.getAnswer(question), Validators.required))
      if (question.QuestionCaption == "Origin" && value != null && value != undefined && value != "" && value.length > 0) {

        let ctry = this.countries.find(ft => ft.Name.toLowerCase() === value.toLowerCase());
        if (ctry != undefined && ctry != null)
          (<UntypedFormControl>this.questionnairesForm.controls[question.QuestionId]).setValue(ctry, { onlySelf: true });
      }
      this.questionChhoicesChange(question, null);
    });
    this.showQuestionnaireDialog = true;
  }

  private getAnswer(question) {
    const value = question?.RequestItemQuestionResponses[0]?.Response;
    const questionType = question.QuestionType.QuestionType1;
    if (value) {
      if (questionType === 'YesNo' || questionType === 'Boolean') {
        return JSON.parse(value);
      }

      if (questionType === 'MultipleChoice') {
        return question.QuestionChoices.find(choice => choice.QuestionChoiceId == value);
      }


      return value;
    }
    if (question.QuestionType.QuestionType1 === 'YesNo' || question.QuestionType.QuestionType1 === 'Boolean') {
      return false;
    }
    return '';
  }


  private getAnswerForSave(question, value) {
    // const value = question?.RequestItemQuestionResponses[0]?.Response;
    const questionType = question.QuestionType.QuestionType1;
    if (value) {
      if (questionType === 'YesNo' || questionType === 'Boolean') {
        return JSON.parse(value);
      }

      if (questionType === 'MultipleChoice') {
        return typeof value === 'object' ? value.ChoiceName?.toString() :
          question.QuestionChoices.find(choice => choice.QuestionChoiceId == value)?.ChoiceName;
      }
      if (question.QuestionCaption == "Origin") {
        return typeof value === 'object' ? value.Name?.toString() :
          this.countries.find(cntry => cntry.Name == value)?.Name;
      }

      return value;
    }
    if (question.QuestionType.QuestionType1 === 'YesNo' || question.QuestionType.QuestionType1 === 'Boolean') {
      return false;
    }
    return '';
  }



  questionChhoicesChange(questions, event) {
    // Other (explain)

    if (questions.QuestionType.QuestionType1 === 'MultipleChoice') {
      var answer = this.getAnswer(questions);
      const localChoiceName = typeof answer === 'object' ? answer.ChoiceName?.toString() :
        answer;
      if ((event != null && event.value.ChoiceName == "Other (explain)") || (localChoiceName == "Other (explain)" && event == null)) {

        questions.IsOthersSelected = true;
        questions.OthersValue = questions?.RequestItemQuestionResponses[0]?.AdditionalResponse;
        questions.FCName = "others_" + questions.QuestionId;
        this.questionnairesForm.addControl("others_" + questions.QuestionId, this.formBuilder.control(questions.OthersValue, Validators.required));

      }
      else {
        questions.IsOthersSelected = false;
        this.questionnairesForm.removeControl("others_" + questions.QuestionId);
      }
    }
  }

  onSelectPermit(permitState: string, item: RequestItemModel){
    console.log(permitState);
    switch(permitState){
      case 'NotRequired':
        this.editRequestItemModel.IsPermitRequired = false;
        this.editRequestItemModel.IsPermitAvailable = null;
        this.requestModel.RequestItemList.filter(x => x.RequestItemId === item.RequestItemId)[0].IsPermitRequired = false;
        this.requestModel.RequestItemList.filter(x => x.RequestItemId === item.RequestItemId)[0].IsPermitAvailable = null;
        break;

      case 'Required':
        this.editRequestItemModel.IsPermitRequired = true;
        this.requestModel.RequestItemList.filter(x => x.RequestItemId === item.RequestItemId)[0].IsPermitRequired = true;
        break;

      case 'permitNotAvailable':
        this.editRequestItemModel.IsPermitAvailable = false;
        this.requestModel.RequestItemList.filter(x => x.RequestItemId === item.RequestItemId)[0].IsPermitAvailable = false;
        break;

      case 'permitAvailable':
        this.editRequestItemModel.IsPermitAvailable = true;
        this.requestModel.RequestItemList.filter(x => x.RequestItemId === item.RequestItemId)[0].IsPermitAvailable = true;
        break;
    }
  }

  isBioMaterialType(item: RequestItemModel): boolean {
    const isBioItem = (item.MaterialSearchType === 'BioMaterialLotId' || item.MaterialSearchType === 'BioMaterialId' || item.IsBioFormulation
    || item.MaterialSearchType === 'BioNexusSynonymStock' || item.MaterialSearchType === 'BioNexusSynonymGLP' || item.MaterialSearchType === 'P2PBiological'
    || item.MaterialSearchType === 'AddP2PBiological') ? true : false;

    return isBioItem;
}

isSaveBtnDisableByPermit(requestItem: RequestItemModel) {
  if ((requestItem.IsPermitAvailable !== null && requestItem.IsPermitAvailable === false) ||
  (requestItem.IsPermitRequired === true && requestItem.IsPermitAvailable === null)){
    return true;
  }
}
}
