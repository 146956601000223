import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { RequestService } from 'src/app/services/request-service/request.service';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';
import { RequestModel } from '../request/request.model';
import { AppComponent } from 'src/app/app.component';


@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.css'],
  providers: [MessageService, RequestService, RequestItemService]
})
export class RequestDetailComponent implements OnInit {

  @Input() requestNumber: string = '';
  @Input() requestType: string = '';
  @Output() eventToReturnRequestModelToParentComponent = new EventEmitter<RequestModel>();

  // To avoid the error on page load for variable RequestItemList assigning the variable with its variable.
  requestModel: RequestModel = { RequestId: 0, RequestCreatedOn: '', RequestNumber: '', RequestItemList: [], RequestStatus: '', RequestCreatedBy: '', RequesterInformationModel: {} };
  serviceResponse: any;
  constructor(private requestService: RequestService,
    private requestItemService: RequestItemService,
    private appComponent: AppComponent, private activatedroute: ActivatedRoute, private messageService: MessageService) {

  }

  ngOnInit() {
    //To get request number passed from card detail page.
    this.getRequestDetails(this.requestNumber,this.requestType)
  }

  //----------------------------------------
  // To get the request by request number
  //---------------------------------------
  private getRequestDetails(requestNumber: string,requestType:string) {
    this.requestService.getRequestDetail(requestNumber,requestType).subscribe(response => this.serviceResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.requestModel = this.serviceResponse;
        console.log('response',this.requestModel)
        this.getRequestItemByRequestNumber(this.requestModel.RequestTypeName, this.requestNumber);
      })
  }

  //-------------------------------------------------------- 
  // Call method to  get list of item added by use to the cart.
  //------------------------------------------------------------
  private getRequestItemByRequestNumber(requestTypeName: string, requestNumber: string) {
    this.requestItemService.getRequestItems(requestTypeName, requestNumber).subscribe(response => {
      this.requestModel.RequestItemList = response;
      console.log('response',this.requestModel)
      // When user comes from cart details page. ALL the item from the cart are removed
      // So make the No of item in the cart 0.
      if (this.appComponent.previousRouteURL == '/cart') {
        this.appComponent.cartItemsCount = 0;
      }
      this.eventToReturnRequestModelToParentComponent.emit(this.requestModel);
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      })
  }
}