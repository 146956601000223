import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { filter, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { tap } from 'rxjs/operators';
import { RecipientAddressModel } from 'src/app/recipient-profile/recipient-address/recipient-address.model';

@Injectable()
export class RecipientAddressService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private _http: HttpClient) { }

  public addRecipientAndADAddress(recipientAddressModel: RecipientAddressModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipient-with-address"
    let body = JSON.stringify(recipientAddressModel);
    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Added recipient and Address to Database"))
      );
  }

  public delete(recipientId, addressId): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipient/" + recipientId + "/address/" + addressId + "/recipientaddress"

    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted Recipient Address")));
  }

  public update(recipientAddressModel: RecipientAddressModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipientaddress"
    let body = JSON.stringify(recipientAddressModel);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Updated Recipient Address"))
      );
  }

}
