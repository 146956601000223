

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { RequestModel } from '../../request/request/request.model';

import { ConfigurationSettings } from "../.././configuration-settings"
import { RequestShipmentModel } from 'src/app/shipping-address/add/request-shipment.model';
import { CartItemModel } from 'src/app/cart/cart-item.model';

@Injectable()
export class RequestService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private _http: HttpClient) { }

  public createRequest(CartItem: CartItemModel[], requestType: string): any {

    let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/requests";
    // As API accept CartItemList thats why casting it from
    // CartModel List to CartItemList.
    let body = JSON.stringify({ CartItemList: CartItem });
    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new Request"))
      );
  }

  public getRequestDetail(requestNumber: string, requestType: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/requests/" + requestNumber;
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched Requests")));
  }

  public updateRequest(request: RequestModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/requests"
    let body = JSON.stringify(request);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Request Updated"))
      );
  }

  public deleteRequest(requestNumber: string, requestType: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/requests/" + requestNumber;
    return this._http.delete(url).pipe(
      tap(x => console.log("Request Deleted")));
  }

  //-------------------------------------------------
  // To make selected address as shipping address
  //-------------------------------------------------
  public addShippingAddress(requestNumber: string, requestShipmentModel: RequestShipmentModel): any {

    let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/addresses"
    let body = JSON.stringify(requestShipmentModel);
    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Shipping Address for Request is added"))
      );
  }

  //-------------------------------------------------
  // To get addresses and recipient of the request
  //-------------------------------------------------
  public getRequestShippingDetail(recipientType: string, requestNumber: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/shippingdetail?recipientType=" + recipientType;
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched request shipping details for request number  " + requestNumber)));
  }
  public getRequesterDetail(recipientType: string, emailId: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/requests/" + emailId + "/reqesterDetails?recipientType=" + recipientType;
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched request shipping details for request number  " + emailId)));
  }
  public async getRequestShippingDetailAsync(recipientType: string, requestNumber: string): Promise<any> {
    let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/shippingdetail?recipientType=" + recipientType;
    return await this._http.get(url).pipe(
      tap(x => console.log("Fetched request shipping details for request number  " + requestNumber))).toPromise();
  }

  //---------------------------------------------------------
  // To get addresses and shipping address of the request.
  //---------------------------------------------------------
  public getRequestShippingDetailForRecipient(recipientType: string, requestNumber: string, recipientEmail: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/shippingdetail?recipientType=" + recipientType + "&recipientEmail=" + recipientEmail;
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched request shipping details for request number  " + requestNumber)));
  }

  //-------------------------------------------------
  // To get addresses and recipient of the request
  //-------------------------------------------------
  public getRequestStatusTypes(): any {
    let url = ConfigurationSettings.REST_API_URL + "/requeststatustypes/";
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched all request status types")));
  }

  public updateRequestStatus(requestNumber, requestStatusModel, requestType): any {
    let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/requests/" + requestNumber + "/request-statuses";
    let body = JSON.stringify(requestStatusModel);
    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new Request"))
      );
  }

  public cancelRequest(requestNumber): any {
    let url = ConfigurationSettings.REST_API_URL + "/CancelRequest/" + requestNumber ;
    let body = JSON.stringify("");
    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Request has been cancelled"))
      );
  }

  public ValidateCancelRequest(orderId): any {    
    let url = ConfigurationSettings.REST_API_URL + "/ValidateCancelRequest/" + orderId;

    let body = JSON.stringify("");
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched alchemist order info.")));
  }

  public copyRequest(requestNumber, copyRequestModel, requesType): any {
    let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requesType + "/requests/" + requestNumber + "/copy";
    let body = JSON.stringify(copyRequestModel);
    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Request copied"))
      );
  }

  public getRequestCreatedByList(): any {
    let url = ConfigurationSettings.REST_API_URL + "/requests/creators"
    return this._http.get(url).pipe(
      tap(x => console.log("Get list of all the user who created request")));
  }
//-------------------------------------------------
  // To save comment of the request
  //-------------------------------------------------
  public saveRequestComment(commentModel, requestNumber): any {
    
    let url = ConfigurationSettings.REST_API_URL + "/SaveRequestComment/" + requestNumber ;
    let body = JSON.stringify(commentModel);
    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Request has been saved succesfully"))
      );
  }

  //-------------------------------------------------
  // To get comment  of the request
  //-------------------------------------------------
  public getRequestComment(requestNumber): any {
    let url = ConfigurationSettings.REST_API_URL + "/GetRequestComment/" + requestNumber ;
    return this._http.get(
      url).pipe(
        map((res: any) => (res === null || res === undefined) ? '' : res.Comment),
        tap(x => console.log("RequestComment"))
      );
  }

}