

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../.././configuration-settings"
import { RequesterInformationModel } from 'src/app/request/requester-information/requester-information.model';



@Injectable()
export class RequesterInformationService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }


    //-------------------------------------------------
    // To Update the requester information
    //-------------------------------------------------- 
    public updateRequesterInformation(requesterinformation: RequesterInformationModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/requester-information"
        let body = JSON.stringify(requesterinformation);
        return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Requester Information Updated"))
            );
    }

    public getRequesterList(): any {
        let url = ConfigurationSettings.REST_API_URL + "/requests/requesters"
        return this._http.get(url).pipe(
            tap(x => console.log("Get requester list")));
    }
    public async getRequesterAsync(requestId:number):  Promise<any> {
        let url = ConfigurationSettings.REST_API_URL + "/requests/requester/"+requestId;
        return await this._http.get(url).pipe(
            tap(x => console.log("Get requester list"))).toPromise();
    }

}