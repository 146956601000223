import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RequestComponent } from './request/request/request.component';
import { CartDetailsComponent } from './cart/cart-details.component';
import { SearchComponent } from './search/search.component';
import { UserComponent } from './user/user.component';
import { UnitOfMeasureComponent } from './unit-of-measure/unit-of-measure.component';
import { RequestManagementComponent } from './request-management/request-management.component';
import { RecipientProfileComponent } from './recipient-profile/recipient-profile/recipient-profile.component';
import { ExceptionComponent } from './support/exception-log.component';
import { EmailLogComponent } from './support/email-log/email-log.component';
import { ForecastComponent } from './forecast/forecast.component';
import { DelegateComponent } from './delegate/delegate.component';
import { ApprovalComponent } from './approval/approval.component';
import { ToastModule } from 'primeng/toast';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AfterLoginComponent } from './after-login/after-login.component';
import { AfterLogoutComponent } from './after-logout/after-logout.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavigateComponent } from './navigate/navigate.component';
import { MaintenanceMessageComponent } from './maintenance-message/maintenance-message.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'request-types/:requesttype/request/:requestnumber', component: RequestComponent, canActivate: [MsalGuard] },
  //{ path: 'request/:requestnumber/step/:activestep', component: RequestComponent, canActivate: [MsalGuard] },
  { path: 'request-types/:requesttype/request/:requestnumber/step/:activestep', component: RequestComponent, canActivate: [MsalGuard] },
  { path: 'person', component: RequestComponent, canActivate: [MsalGuard] },
  { path: 'cart', component: CartDetailsComponent, canActivate: [MsalGuard] },
  { path: 'search', component: SearchComponent, canActivate: [MsalGuard] },
  { path: 'user', component: UserComponent, canActivate: [MsalGuard] },
  { path: 'unitofmeasure', component: UnitOfMeasureComponent, canActivate: [MsalGuard] },
  { path: 'requestmanagement', component: RequestManagementComponent, canActivate: [MsalGuard] },
  { path: 'recipientprofile', component: RecipientProfileComponent, canActivate: [MsalGuard] },
  { path: 'exceptionlog', component: ExceptionComponent, canActivate: [MsalGuard] },
  { path: 'emaillog', component: EmailLogComponent, canActivate: [MsalGuard] },
  { path: 'myforecasts', component: ForecastComponent, canActivate: [MsalGuard] },
  { path: 'delegate', component: DelegateComponent, canActivate: [MsalGuard] },
  { path: 'approval', component: ApprovalComponent, canActivate: [MsalGuard] },
  { path: 'maintenancemessage', component: MaintenanceMessageComponent, canActivate: [MsalGuard]},
  { path: 'after-login', component: AfterLoginComponent }, // needed for MSAL exchange code for token(s); Don't add authguard - will cause errors;
  { path: 'after-logout', component: AfterLogoutComponent },
  { path: 'no-permissions', component: NoPermissionsComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: 'navigate', component: NavigateComponent },
  //Below route is used to redirect the user on login page when given route in URL is empty.(http://localhost:4200/)
  { path: '', redirectTo: '/search', pathMatch: 'full' },
  //Below route is used to redirect the user on login page when given route in URL is not defined in application routes.(http://localhost:4200/**)
  { path: '**', redirectTo: '/search', pathMatch: 'full' },
];

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, ToastModule, RouterModule.forRoot(routes, 
    { useHash: true,
      relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload',
    initialNavigation: isInIframe() ? 'disabled' : undefined})],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export function isInIframe() {
  return window !== window.parent && !window.opener;
}
