export class UnitOfMeasureModel {
    UnitOfMeasureId?: number
    UnitOfMeasure?: string
    Description?: string
    CreatedDate?: Date
    CreatedBy?: number
    ModifiedDate?: Date
    ModifiedBy?: number
    RequestTypeId?: number
    IsActive?: Boolean
}


export class UOMConversionModel {
    Amount?: number
    Abbreviation?: string
    AmountWithUnit?: string
}

export class UOMItemConversionModel {
    ProductSourceSystem: string
    ProductIdentifier: string
    Amount: any
    FromUnit: string
    ToUnit: string
}
