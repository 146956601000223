<div class="ui-g">
    <div class="col-12 padding-unset">
        <div *ngFor="let requestItem of this.requestModel.RequestItemList; let index  = index;">
            <!-- <div class="ui-dataview-content ui-widget-content"> -->
            <div class="col-12 resShadow bottomMargin">
                <div class="grid" [ngClass]="{'approved': requestItem.IsApproved === true, 'rejected': requestItem.IsApproved === false, ' chemical-header': requestItem.IsApproved === null}">
                    <div class="col-3 text-align-left">
                        <span class="font-weight-bold"> {{requestItem.ChemicalName}} </span>

                        <br />
                        <span class="font-style-italic" [ngClass]="{'mandatory-field': requestItem.ContainerLabel === undefined||requestItem.ContainerLabel===null||requestItem.ContainerLabel.trim()===''}">{{requestItem.ContainerLabel !== undefined&&requestItem.ContainerLabel!==null&&requestItem.ContainerLabel.trim()!=='' ? requestItem.ContainerLabel : 'No label selected'}}</span>
                    </div>
                    <div class="col-3 text-align-left">
                        <span class="font-weight-bold"> {{requestItem.LotNumber}} </span>
                    </div>
                   
                    <div class="col-6 rightDiv">
                        <span style="float: left;" *ngIf="requestModel.RequestTypeName === 'P2P' && requestItem.IsPermitRequired && isPermitDocNotInUploadedDocList[index] === true">
                            <span class="span-data-missing">
                                Permit required and not available
                            </span>
                        </span>
                        <span *ngIf="validateQuantity && requestModel.RequestTypeName != 'P2P' 
                                    && (requestModel.IsIndyAligned || euCenterFeature)
                                    && requestItem.RequestLocationId == 0  && requestItem.MaterialSearchType != 'P2POther' 
                                    && requestItem.MaterialSearchType != 'P2PBiological' 
                                    && requestItem.AmountRemaining < requestItem.TotalRequestedQuantity" 
                                [ngClass]="{ 'mandatory-field': !isAdministrator, 'mandatory-field-exception-only': isAdministrator}">
                                Not enough material to fulfill this request, please modify your order or place a forecast in FARM 
                        </span>

                        <span class="dialog-header-total-quantity"
                        [ngClass]="{'quatity_approved':  requestItem.IsApproved === true, 'quatity_rejected':  requestItem.IsApproved === false}">
                            {{requestItem.TotalRequestedQuantity | number }} {{requestItem.UnitOfMeasure}}
                        </span>
                       
                        <div class="col-12">
                            <!-- Commented as per KAT-404 Need by date is future implementation -->
                            <!--                             <span>
                                <span class="font-weight-bold">Ship Now</span> :
                                {{requestItem.ReadyToBeShipped == true?'Yes':'No'}}
                            </span> |
                            <span>

                                <span class="font-weight-bold">Need By Date</span> :
                                <span *ngIf="requestItem.NeedByDate != undefined">
                                    {{requestItem.NeedByDate | date :  "dd-MMM-y"}} <span
                                        *ngIf="!validateNeedByDate(requestItem.NeedByDate)" class="mandatory-field">Date
                                        should be greater than today</span>
                                </span>
                                <span *ngIf="requestItem.NeedByDate == undefined" class="mandatory-field">Date not
                                    selected</span>

                            </span> | -->
                           
                            <span>
                                <span class="font-weight-bold">Priority</span> :
                                <span>
                                    <button *ngIf="requestItem.Priority == 'Standard'" pButton type="button"
                                        label="Standard"
                                        [ngClass]="{'p-button-success green-button':  requestItem.IsApproved === null, 'p-button-info':  requestItem.IsApproved === true, 'p-button-warning':  requestItem.IsApproved === false}"></button>

                                    <button *ngIf="requestItem.Priority == 'Urgent'" pButton type="button"
                                        label="Urgent"
                                        [ngClass]="{'p-button-danger red-button':  requestItem.IsApproved === null, 'p-button-warning':  requestItem.IsApproved === false, 'p-button-info':  requestItem.IsApproved === true}"></button>
                                </span>

                            </span>

                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12 bottomMargin padding-left-unset">
                        <div class="grid col-12">
                        <div class="col-4 header-bar padding-8px"><span>Attachments</span></div>
                        <div class="col-6 header-bar padding-8px"><span>Description </span></div>
                        <div class="col-2 header-bar padding-8px"><span>Attachment Type</span></div>
                    </div>
                        <div class="grid col-12 padding-bottom-unset" hidden=true>
                            <div class="col-6 padding-bottom-unset">
                                <p-checkbox readonly="true" binary="true" [(ngModel)]="requestItem.SendInvoiceBefore">
                                </p-checkbox>
                                &nbsp;&nbsp;Invoice
                                <br />
                                <p-checkbox readonly="true" binary="true" [(ngModel)]="requestItem.SDS"></p-checkbox>
                                &nbsp;&nbsp;SDS
                            </div>
                            <div class="col-6 padding-bottom-unset">
                                <p-checkbox readonly="true" binary="true" [(ngModel)]="requestItem.COO"></p-checkbox>
                                &nbsp;&nbsp;COO
                                <br />
                                <p-checkbox readonly="true" binary="true" [(ngModel)]="requestItem.CoA"></p-checkbox>
                                &nbsp;&nbsp;CoA
                            </div>
                        </div>
                        <div class="col-12 padding-top-unset" hidden=true>
                            <p-checkbox readonly="true" id="OtherDocCheckBox" binary="true"
                                [(ngModel)]="requestItem.OtherDoc">
                            </p-checkbox>
                            &nbsp;&nbsp;Other&nbsp;&nbsp;
                            <textarea *ngIf="requestItem.OtherDoc" class="vertical-align-middle"
                                [(ngModel)]="requestItem.OtherDocText" maxlength="150" autoresize="true" rows="1"
                                readonly="true"></textarea>
                        </div>
                        <div class="col-12">

                            <div class="grid attached-files-list"
    *ngFor="let requestItemAttachment of requestItem.RequestItemAttachmentList;">

    <div class="col-4" [attr.title]="requestItemAttachment.FileName"><i class="pi pi-download download-file-link"
        (click)="downloadAttachment(requestItem.RequestId,requestItemAttachment.RequestItemId, requestItemAttachment.RequestItemAttachmentId,requestItemAttachment.FileName)"></i>
       {{(requestItemAttachment.FileName.length>50)? (requestItemAttachment.FileName | slice:0:50)+'..':(requestItemAttachment.FileName) }}
    </div>

    <div class="col-6 comment">
        <span>{{requestItemAttachment.Comment}}</span>

    </div>
    <div class="col-2 comment">
        <span>{{GetAttachmentType(requestItemAttachment.DocumentTypeId)}}</span>

    </div>

  </div>

                        </div>

                    </div>
                </div>
                <div class="grid">
                    <div class="col-12 bottomMargin padding-right-unset">
                        <div class="grid header-bar container-grid">
                            <div class="col-1 containerHeader"># Containers
                            </div>
                            <div class="col-2 containerHeader">Amount Per Container
                                &nbsp; </div>
                            <div class="containerHeader"  [ngClass]="{'col-4':requestModel.RequestTypeName === 'P2P', 'col-3': requestModel.RequestTypeName !== 'P2P'}"> Sample Comments
                                &nbsp; </div>
                            <div class="col-1 containerHeader"  *ngIf="requestModel.RequestTypeName == 'P2P' && isBioMaterial"> Packed With 
                                &nbsp; </div>
                            <div class="col-2 containerHeader"  *ngIf="requestModel.RequestTypeName == 'P2P' && isBioMaterial" style="text-align: center;"> Permit required?
                                &nbsp; </div>
                            <div class="col-2 containerHeader"  *ngIf="requestModel.RequestTypeName == 'P2P' && requestItem.IsPermitRequired === true && isBioMaterial" style="text-align: center;">Do you have a permit?
                                &nbsp; </div>
                            <div class="col-2 containerHeader" *ngIf="requestModel.RequestTypeName !== 'P2P'"> Approver
                                &nbsp; </div>
                            <div class="col-2 containerHeader" *ngIf="requestModel.RequestTypeName !== 'P2P'"> Approval Status
                                &nbsp; </div>
                            <div class="col-3 containerHeader" *ngIf="requestModel.RequestTypeName !== 'P2P' && (requestItem.IsApproved === false || requestItem.IsApproved === tru)e"> 
                                Reason For 
                                <span *ngIf="requestItem.IsApproved === false">Rejection</span>
                                <span *ngIf="requestItem.IsApproved === true">Approval</span>
                                &nbsp; </div>
                        </div>
                        <div class="grid">
                            <div class="space"></div>
                            <div class="col-12">
                            <div *ngFor="let requestItemContainer of requestItem.RequestItemContainerList; let i=index">
                                <div class="grid container-grid">
                                    <div class="col-1 containerContent">
                                        {{requestItemContainer.NumberOfContainer}}
                                    </div>
                                    <div class="col-2 containerContent">
                                        {{requestItemContainer.AmountPerContainer}}
                                        {{requestItem.UnitOfMeasure}}
                                    </div>
                                    <div class="containerContent" [ngClass]="{'col-4':requestModel.RequestTypeName === 'P2P', 'col-3': requestModel.RequestTypeName !== 'P2P'}">
                                        {{CheckSampleComments(requestItem?.RequestItemComment[0]?.Comment?.Comment1) ? requestItem?.RequestItemComment[0]?.Comment?.Comment1 : 'No sample comments entered'}}
                                    </div>
                                    <div class="col-1 containerContent"  *ngIf="requestModel.RequestTypeName == 'P2P' && isBioMaterial">
                                        {{requestItem.PackedWithChoiceName}}
                                    </div>
                                    <div class="col-2 containerContent"  *ngIf="requestModel.RequestTypeName == 'P2P' && isBioMaterial" style="text-align: center;">
                                        {{requestItem.IsPermitRequired === true ? 'Yes' : 'No'}}
                                    </div>
                                    <div class="col-2 containerContent"  *ngIf="requestModel.RequestTypeName == 'P2P' && requestItem.IsPermitRequired === true && isBioMaterial" style="text-align: center;">
                                        {{requestItem.IsPermitAvailable === true ? 'Yes' : 'No'}}
                                    </div>
                                    <div class="col-2 containerContent" [attr.rowspan]="requestItem.RequestItemContainerList.length"
                                    *ngIf="requestModel.RequestTypeName !== 'P2P'">
                                        <a style="font-weight: bold" href="mailto:{{requestItem.ApproverEmail}}">
                                            <u>
                                                <span *ngIf="requestItem.ApproverName !== null">{{requestItem.ApproverName}}</span>
                                                <span *ngIf="requestItem.ApproverName === null">{{requestItem.ApproverEmail}}</span>
                                            </u>
                                        </a>
                                    </div>
                                    <div class="col-2 containerContent" [attr.rowspan]="requestItem.RequestItemContainerList.length"
                                    *ngIf="requestModel.RequestTypeName !== 'P2P'">
                                        <button *ngIf="requestItem.IsApproved !== null && requestItem.IsApproved === true" pButton type="button"
                                        label="Approved" class="p-button-success green-button"></button>

                                        <button *ngIf="requestItem.IsApproved !== null && requestItem.IsApproved === false" pButton type="button"
                                            label="Rejected" class="p-button-danger red-button"></button>
                                    </div>
                                    <div class="col-3 containerContent rejectReason" *ngIf="requestModel.RequestTypeName !== 'P2P' && (requestItem.IsApproved === false || requestItem.IsApproved === true)"
                                    [attr.rowspan]="requestItem.RequestItemContainerList.length">
                                       {{rejectedComments(requestItem.RequestItemComment)}}
                                    </div>
                                </div>
                            </div>

                                <div  class="col-12 text-align-center">
                                    <span *ngIf="requestItem.RequestItemContainerList.length == 0"
                                        class="mandatory-field">
                                        No containers
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div class="col-12 text-align-center mandatory-field" *ngIf="this.requestModel.RequestItemList.length == 0">
            No Samples added
        </div>
    </div>

</div>
