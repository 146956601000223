import { AddressModel } from 'src/app/address/address.model';

export class RecipientModel {

    RecipientId?: number = 0;

    // Properties for internal recipient.
    InternalRecipientId?: number = 0;
    DisplayName?: string
    FirstName?: string
    LastName?: string
    Email?: string
    IsActive?: boolean
    IsDeleted?: boolean
    FunctionId?: number
    Function?: string
    Country?: string
    CountryId?: number
    SupervisorName?: string
    ContactNumber?: string;
    SupervisorEmail?: string
    //--------------End------------------

    // Properties for external recipient
    ExternalRecipientId?: number = 0;
    InternalContactName?: string;
    InternalContactEmail?: string;
    LegalName?: string;
    FullName?: string;
    //--------------End-------------------
    ShowAddressBar?: boolean = false;

    AddressList?: AddressModel[];
}