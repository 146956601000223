<div class="col-12 spacing"></div>
<p-toast position="top-right"></p-toast>
<div class="col-12 resShadow">
    <div class="col-12">
        <div class="col-12 header-bar" *ngIf="notificationHeader===null||notificationHeader===undefined">Request Notification</div>
        <div class="col-12 header-bar" *ngIf="notificationHeader!==null&&notificationHeader!==undefined">{{notificationHeader}}</div>

        <div class="grid">
            <div class="col-2">Users to be notified</div>
            <div class="col-3 descriptionDiv">Description</div>
        </div>

        <div class="grid">
            <div class="usersToBeNotified col-2">
                <span class="ui-fluid">

                    <sein-user-search [id]="'notify'" class="address" [searchApiUrl]="restApiURL +'{searchWord}'"
                        [(ngModel)]="notifyUser" [defaultUserList]="notifyUserList"
                        [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                        placeholder="Search by name or email address">
                    </sein-user-search>

                </span>

            </div>
            <div class="descriptionBoxStyle col-3">
                <textarea maxlength="100" [(ngModel)]="selectedDescription" pInputTextarea rows="1"
                   style="width:100%;height: unset !important;" class="w-100"></textarea>
            </div>
            <div class="col-2 float-left">
                <button pButton type="button" label="Add" class="ui-button-info" icon="pi pi-plus"
                    (click)="addUserToNotify()"></button>
            </div>
            <div *ngIf="divforErrorMessageNotification" class="col-12 invalid-feedback space">
                <div class="col-2 errorMessageforUserToBeNotified">
                    {{errorMessageforUserToBeNotified}}
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="ui-g tableDiv">
                <table class="tableWidth" *ngIf="requestNotificationList.length > 0">
                    <tbody>
                        <tr *ngFor="let requestNotification of requestNotificationList;"
                            class="border-top-1px line-height-05em">
                            <td *ngIf="requestNotification.IsRequestNotificationDeleted == false"
                                class="col-4 line-height-20px tdEmail">
                                {{requestNotification.Email}}</td>
                            <td *ngIf="requestNotification.IsRequestNotificationDeleted == false"
                                class="col-7 line-height-20px tdDescription">
                                {{requestNotification.Description}}
                            </td>
                            <td *ngIf="requestNotification.IsRequestNotificationDeleted == false"
                                class="col-1 tdRemove">
                                <i label="Remove" class="pi pi-times removeButton"
                                    (click)="removeUserToBeNotified(requestNotification)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p-confirmDialog #removeUserConfirmDialog key="removeUserConfirmDialog" header="Confirmation"
            icon="pi pi-exclamation-triangle">
            <p-footer>
                <button type="button" pButton icon="pi pi-check" label="OK"
                    (click)="removeUserConfirmDialog.accept()"></button>
                <button type="button" pButton icon="pi pi-times" label="Cancel"
                    (click)="removeUserConfirmDialog.reject()"></button>
            </p-footer>
        </p-confirmDialog>
    </div>
</div>