import { Component, OnInit, ViewChild} from '@angular/core';
import { RecipientService } from 'src/app/services/recipient-service/recipient.service';
import { RecipientModel } from 'src/app/recipient-profile/recipient-profile/recipient.model';
import { MessageService, SelectItem, ConfirmationService } from 'primeng/api';
import { RequestService } from 'src/app/services/request-service/request.service';
import { RequestStatusTypeModel } from 'src/app/request-status-type/request-status-type.model';
import { RequestStatusType } from 'src/app/request-status-type/request-status-type.enum';

import { RequestTypeModel } from 'src/app/request-type/request-type.model';
import { RequestTypeService } from 'src/app/services/request-type-service/request-type.service';
import { CountryModel } from 'src/app/country/country.model';
import { CountryService } from 'src/app/services/country-service/country.service';
import { UserModel } from 'src/app/user/user.model';
import { RequesterInformationModel } from 'src/app/request/requester-information/requester-information.model';
import { RequesterInformationService } from 'src/app/services/requester-information-service/requester-information.service';
import { AdvancedSearchService } from 'src/app/services/advanced-search-service/advanced-search.service';
import { RequestManagementComponent } from '../request-management.component';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter-service/filter.service';
import { FavouriteService } from 'src/app/services/favourite-service/favourite.service';
import { FavouriteModel } from '../favourites/favourite.model';
import { RequestManagementModel } from '../request-management.model';
import { RequestManagement } from '../request-management.enum';
import { CommonService } from 'src/app/services/common-service/common.service';
import { RequestType } from 'src/app/request-type/request-type.enum';
import { DataView } from 'primeng/dataview';




@Component({
    selector: 'app-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['../request-management.component.css'],
    providers: [MessageService, RecipientService, RequestTypeService, CountryService,
        RequesterInformationService,AdvancedSearchService,CommonService]
})
export class AdvancedSearchComponent extends RequestManagementComponent implements OnInit {

    recipientTypeList: SelectItem[];   
    selectedRecipientType: SelectItem;
    
    recipientList: RecipientModel[] = [];
    selectedRecipient:RecipientModel[] = [];
    
    requestStatusTypeList: RequestStatusTypeModel[] = [];
    selectedRequestStatusTypeModel: RequestStatusTypeModel[] = [];
    
    yearList: SelectItem[];
    selectedYear: SelectItem;
    
    requestTypeList: RequestTypeModel[] = [];
    selectedRequestType: RequestTypeModel[] = [];

    countryList: CountryModel[] = [];
    selectedCountry: CountryModel[] = [];

    requestCreatedByList: UserModel[] = [];
    selectedRequestCreatedBy: UserModel[] = [];
    
    requesterList: RequesterInformationModel[] = [];
    selectedRequester:RequesterInformationModel[] = [];

    sampleText :string = '';
    requestNumber: string = '';
    requestNumberPattern = new RegExp('^[A-Za-z0-9-]{0,12}$');

    @ViewChild('dv') dataView: DataView;
    constructor(router: Router,
        messageService: MessageService,
        filterService: FilterService,
        favouriteService: FavouriteService,
        requestService: RequestService,
        confirmationService: ConfirmationService,
        commonService:CommonService,
        private recipientService: RecipientService,
        private countryService: CountryService,
        private requestTypeService: RequestTypeService,
        private requesterInformationService:RequesterInformationService,
        private advancedSearchService:AdvancedSearchService,
        ) {
        super(router, filterService, requestService, confirmationService, favouriteService, messageService,commonService)
    }

    ngOnInit() {

        this.requestOption = RequestManagement.AdvancedSearch;
        this.recipientTypeList = [
            { label: 'Internal', value: 'Internal' },
            { label: 'External', value: 'External' }
        ]
        this.yearList = [
            { label: 'Current year', value: 'Currentyear' },
            { label: 'Last 12 Months', value: 'Lastyear' },
            { label: 'Last 3 years', value: 'Last3years' },
            { label: 'Last 5 years', value: 'Last5years' },
            { label: 'All', value: 'All' }
        ]

        this.selectedYear = { label: 'Last 12 Months', value: 'Lastyear'};
        
        this.getRequestStatusTypeList();
        this.getRequestCreatedBy();
        this.getRequesterList();
        this.getRequestTypeList();
        this.getCountryList();
      
    }

    public recipientTypeChange() {
        this.selectedRecipient = []
        if(this.selectedRecipientType!=null)
        {
            this.getRecipientProfileList(this.selectedRecipientType.label);
        }
        else
        {
            this.recipientList = [];
        }
    }   

    private getRecipientProfileList(type: string) {
        let loggedInUserEmail = localStorage["loggedInUsername"];
        this.recipientService.getRecipients(type,loggedInUserEmail).subscribe(response => {
            this.recipientList =  response.sort((a,b)=>{
            if(a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase()) { return -1; }
        if(a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase()) { return 1; }
            
        return 0;
            }
            );
            
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
        })
    }

    doNothing(e, index) {
        if (e) {
          e.stopPropagation();
        }
      }
      
    private getRequestStatusTypeList() {
        this.requestService.getRequestStatusTypes().subscribe(response => {
            this.requestStatusTypeList = response;
            //this.requestStatusTypeList = this.requestStatusTypeList.filter(s=>s.Code !== RequestStatusType.Draft);
            this.selectedRequestStatusTypeModel = Array.from(this.requestStatusTypeList);
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        })
    }

    private getRequestTypeList() {
        this.requestTypeService.getRequestTypesByEmail(localStorage["loggedInUsername"]).subscribe(response => {
            this.requestTypeList = response;
            this.requestTypeList = this.requestTypeList.filter(s => s.Name == RequestType.InStock || s.Name == RequestType.GLP);
            let rtp2p = new RequestTypeModel();
            rtp2p.Name = 'P2P';
            rtp2p.RequestTypeId = 4;
            this.requestTypeList.push(rtp2p);
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        })
    }

    public getCountryList() {
        if (this.countryList.length == 0) {
            this.countryService.getShippingAddressCountries().subscribe(response => {
                this.countryList = response
            },
                error => {
                    this.messageService.add({ severity: 'error', summary: '', detail: error.error });
                })
        }
    }

    public getRequestCreatedBy() {
        this.requestService.getRequestCreatedByList().subscribe(response => {
            this.requestCreatedByList = response;
            this.requestCreatedByList.map(item =>
                {
                    item.DisplayName = item.FirstName + ' ' + item.LastName
                })
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        })
    }

    public getRequesterList() {
        this.requesterInformationService.getRequesterList().subscribe(response => {
            this.requesterList = response
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        })
    }

    public onRequestNumberPaste(e) {
        let clipboardData = e.clipboardData;
        let pastedText = clipboardData.getData('text');
        if (!(this.requestNumberPattern.test(pastedText))) {
          e.preventDefault();
          return false;
        }
      }
      AllowAlphaNumericDash(e)
      {
        if ((this.requestNumberPattern.test(e.key)) && 
            this.requestNumber.length < 12) {
            return true;
          } else {
            e.preventDefault();
            return false;
          }
      }
      
      
    public getSearchResult()
    {
        if (!this.requestNumberPattern.test(this.requestNumber)) {
            this.messageService.add({ severity: 'error', summary: 'Invalid request number format', detail: 'Invalid request number format. Please retry.' });
            this.requestNumber = '';
            return;
        }
        
        let recipientEmail = this.selectedRecipient.map(function(s) {return s.Email;});
        let requesterEmail = this.selectedRequester.map(function(s) {return s.Email;});
        let requestCreatedBy =  this.selectedRequestCreatedBy.map(function(s) {return s.UserId;});
        let countryId =  this.selectedCountry.map(function(s) {return s.CountryId;});
        let requestTypeId =  this.selectedRequestType.map(function(s) {return s.RequestTypeId;});
        let requestStatusTypeModel =  this.selectedRequestStatusTypeModel.map(function(s) {return s.RequestStatusTypeId;});
        let year = this.selectedYear.value; 
        let selectedRecipientType = this.selectedRecipientType != undefined ? this.selectedRecipientType.label : '';
        
        this.advancedSearchService.getAdvancedSearchResult(year,requestStatusTypeModel,
            recipientEmail,requesterEmail, requestCreatedBy, countryId,requestTypeId,this.sampleText
            ,selectedRecipientType, this.requestNumber).subscribe(response => {

                this.requestManagementModelList = response;
                this.requestManagementModelList = this.requestManagementModelList.sort((a, b) => b.RequestId - a.RequestId);
    
                this.dataList = this.requestManagementModelList;
                this.totalRecordCount = this.requestManagementModelList.length;
               
            },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.error });
            })
            this.dataView.first = 0;
    }

    public favoriteClick(requestManagementModel: RequestManagementModel) {
        if (requestManagementModel.IsFavorite) {
            this.deletefavourite(requestManagementModel.FavoriteId).then(res => {
                if (res) {
                    this.messageService.add({ severity: 'success', summary: 'Request has been removed from your favorites list', detail: '' });
                    requestManagementModel.IsFavorite = false;
                }
            });
        }
        else {
            let favouriteModel = new FavouriteModel();
            favouriteModel.UserId = localStorage["msal.userId"];
            this.createfavourite(requestManagementModel.RequestNumber, favouriteModel).then(res => {
                if (res) {
                    this.messageService.add({ severity: 'success', summary: 'Request has been added to your favorites list', detail: '' });
                    requestManagementModel.IsFavorite = true;
                }

            });
        }
    }

    public getTitleStyle(requestManagementModel: RequestManagementModel) {
        switch (requestManagementModel.RequestStatus)
        {
          case  "Draft" :  return 'clsDraftTitleBar' ; break ;
          case  "Submitted" :  return 'clsSubmittedTitleBar' ; break ;
          case  "Ordered" :  return 'clsOrderedTitleBar' ; break ;
          case  "Cancelled" :  return 'clsCancelledTitleBar' ; break ;
          case "Material Approval": return 'clsMaterialApprovalTitleBar'; break;
          case  "Awaiting ITC" :  return 'clsAwaitingITCTitleBar' ; break ;
          case "ITC Rejected": return 'clsITCRejectedTitleBar'; break;
          case  "In Process" :  return 'clsInProcessTitleBar' ; break ;
          case "Shipped": return 'clsShippedTitleBar'; break;
        }
        return '';
      }

}