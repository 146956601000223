import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { SaveCartModel } from '../../cart/save-cart.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class CartService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private _http: HttpClient) { }

  public async createCart(requestType: string, saveCartModel: SaveCartModel): Promise<any> {
    let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/carts/cart-items"
    let body = JSON.stringify(saveCartModel);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new Cart"))
      ).toPromise();
  }

  public getCarts(): any {
    let url = ConfigurationSettings.REST_API_URL + "/carts/cart-items/for-current-user"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched Carts")));
  }


  public deleteCart(requestType: string, cartItemId: number): any {
    let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/carts/cart-items/" + cartItemId;
    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted Cart")));
  }
}