

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class ExceptionService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }


    public getExceptions(): any {
        let url = ConfigurationSettings.REST_API_URL + "/exceptionlogs"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Exceptions")));
    }

}