

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AddressModel } from '../../address/address.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class AddressService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private _http: HttpClient) { }

  public createAddress(address: AddressModel, email: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + email + "/addresses"
    let body = JSON.stringify(address);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new Address"))
      );
  }

  public getAddresss(): any {
    let url = ConfigurationSettings.REST_API_URL + "/api/addresss"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched Addresss")));
  }

  public ValidateAddressLine1(address: AddressModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/ValidateUSPSAddress/?AddressLine1="+address.AddressLine1+"&City="+address.City+"&State="+address.State+"&PostalCode="+address.PostalCode; 
    return this._http.get(url).pipe(
      tap(x => console.log("Address line validated")));
  }

  public GetStateAndCityForZip(zip: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/GetStateAndCity/"+zip;
    return this._http.get(url).pipe(
      tap(x => console.log("Get city and state for zip code")));
  }


  public async getAddressesByEmail(email: string): Promise<any> {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + email + "/addresses"
    return await this._http.get(url).pipe(
      tap(x => console.log("Fetched Addresss"))).toPromise();
  }

  public async getAddressesOfAllRecipient(): Promise<any> {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/addresses"
    return await this._http.get(url).pipe(
      tap(x => console.log("Fetched Addresss"))).toPromise();
  }

  public updateAddress(address: AddressModel, email: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/recipients/" + email + "/addresses"
    let body = JSON.stringify(address);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("address updated"))
      );
  }

  public deleteAddress(Id: number): any {
    let url = ConfigurationSettings.REST_API_URL + "/api/addresss/" + Id;
    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted Address")));
  }
}