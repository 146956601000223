<p-toast position="top-right"></p-toast>

<div class="col-12 resShadow">
    <div class="grid external-label">
        <div class="col-1 font-weight-bold" style="padding-right:0px !important"><span>*Requested By</span></div>
        <div class="col-2">
                <span class="ui-fluid">
                <sein-user-search [id]="'recipientSearch'" class="address" [searchApiUrl]="restApiURL +'{searchWord}'"
                    [(ngModel)]="userLookUpModel" [defaultUserList]="userLookUpModelList"
                    [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                    placeholder="Search by name or email address" (onSelect)="requesterOnSelect()"
                    suggestionTemplateLine2Label="Company Name" suggestionTemplateLine2Field="LegalName">
                </sein-user-search>
            </span>
        </div>
        <div class="col-3">
            <span class="font-weight-bold">*Requested Delivery</span>
        </div>
        <div class="col-2">
        <p-calendar id="requestedDeliveryDate"  name="RequestedDeliveryDate" 
            [(ngModel)]="requesterInformationModel.DeliveryDate"
            [showIcon]="true"  dateFormat="dd-M-yy" [minDate]="minimumDate" (onBlur)="saveRequesterDetails(shippingOption, null)"
            ></p-calendar>
        </div>
        <div class="col-1">
        <span class="font-weight-bold">*Site</span>
        </div>
        <div class="col-2">
        <p-dropdown id="site" *ngIf="siteList.length" [options]="siteList" placeholder="Select a site"
            [(ngModel)]="requesterInformationModel.SiteName"  style="width: 120px !important;" (onChange)="saveRequesterDetails(shippingOption, null)">
        </p-dropdown>
        </div>
        <div class="col-1">
            <button pButton type="submit" class="p-button-success float-right" pButton icon="pi pi-save" label="Save"
              (click)="saveRequesterDetails(shippingOption, null)"> </button>
          </div>
    </div>
    <p-messages [(value)]="msgs" key="addressNotification" class="messages"></p-messages>

    <div class="col-12 spacing" [ngStyle]="!recipientModel.IsActive ? {'pointer-events':'none'}: ''">
        <div class="col-12 header-bar">
            <span> Requester Address </span>
        </div>

        <div class="grid">
            <div class="col-3"> <span *ngIf="addressList.length > 0 " class="selectedAddressLabel">Selected Address
                </span>
            </div>
            <div class="col-7"> <span *ngIf="addressList.length > 0 " class="availableAddressLabel">Available
                    Addresses</span>
            </div>
            <div class="col-2"> <a class="addAddressLink" href="javascript:void(0)"
                    (click)="showAddAddressDialog(true)">+ Add
                    Address</a></div>
        </div>

        <div class="grid" *ngIf="addressList.length > 0 ">
            <div class=" col-3 ui-widget">
                <div class="col-3" class="padding-5-px">
                    <div class="selected-address" [@selectedAddressAnimation]=AddressSelectionState
                        (@selectedAddressAnimation.done)="handleTransitionDone( $event )">
                        <div class="address-content">
                            <span class="font-weight-bold">{{shippingAddress.PersonFullName}}</span>

                            <div>{{shippingAddress.AddressLine1}}</div>
                            <div>{{shippingAddress.AddressLine2}}</div>
                            <div>{{shippingAddress.AddressLine3}}</div>
                            <div *ngIf="shippingAddress.AddressId > 0">{{shippingAddress.City}},
                                {{shippingAddress.State}} -
                                {{shippingAddress.PostalCode}}</div>
                            <div>{{shippingAddress.Country}}</div>
                            <div *ngIf="shippingAddress.AddressId > 0"><span>Phone Number : </span>
                                {{shippingAddress.Mobile}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel col-9">

                <p-carousel #addressCarousel [value]="addressList" [page]="page" [numVisible]="3" [numScroll]="3">
                    <ng-template let-address pTemplate="item">

                        <div class="col-3" class="padding-5-px">
                            <div [ngStyle]="address.IsDefaultAddress ? {'background-color':'aliceblue'}:''"
                                class="available-address" (click)="setRequestShipment(address, addressCarousel)">
                                <p id="bg-text" *ngIf="address.IsDefaultAddress">Default</p>
                                <div class="address-content">
                                    <span class="font-weight-bold">{{address.PersonFullName}}</span>
                                    <div>{{address.AddressLine1}}</div>
                                    <div>{{address.AddressLine2}}</div>
                                    <div>{{address.AddressLine3}}</div>
                                    <div>{{address.City}}, {{address.State}} - {{address.PostalCode}}</div>
                                    <div>{{address.Country}}</div>
                                    <div><span>Phone Number : </span> {{address.Mobile}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>

    <!-- Address Pop up -->
    <app-address [visible]="showAddressDialog" [addressModel]="addressModel" [recipientEmail]="recipientModel.Email"
        (eventToReceiveNewCreatedAddress)="receiveNewCreatedAddress($event,true)" [recipientType]="shippingOption"
        (eventToCloseAddressPopUp)="onAddressPopUpClose($event)" *ngIf="showAddressDialog == true"></app-address>

    <add-external-recipient-profile [visible]="showRecipientDialog"
        (eventToReceiveNewCreatedRecipient)="newRecipient($event)"
        (eventToCloseAddRecipientPopUp)="onCloseAddRecipientPopUp()"></add-external-recipient-profile>

</div>

<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>