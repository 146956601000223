<div class="col-12 padding-top-20-px padding-bottom-20-px">
  <p-radioButton name="shippingGroup" value="Internal" label="Internal Shipment" [(ngModel)]="shippingOption">
  </p-radioButton>&nbsp;&nbsp;
  <p-radioButton name="shippingGroup" value="External" label="External Shipment" [(ngModel)]="shippingOption">
  </p-radioButton>
</div>
<add-internal-shipping-address *ngIf="shippingOption == 'Internal'" [requestModel]="requestModel"
  [shippingOption]="shippingOption">
</add-internal-shipping-address>

<add-external-shipping-address *ngIf="shippingOption == 'External'" [requestModel]="requestModel"
  [shippingOption]="shippingOption">
</add-external-shipping-address>