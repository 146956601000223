<p-toast position="top-right"></p-toast>
<p-dataView #dv [paginator]="false"  [rows]="10"  [value]="requestModel.RequestItemList"  layout="list"
   paginatorPosition="both" pageLinks="5" styleClass="hover samples">
  <p-header>
    <!-- Data view header section -->
    <div class="col-12">
      <div class="grid header-container">
        <span class="col-2">
          Material Name
        </span>
        <span class="col-1">
          Lot#
        </span>
        <span class="col-1">
          Priority
        </span>
        <span class="col-2" hidden=true>
          Send Invoice Before
        </span>
        <span class="col-1">
          CoA
        </span>
        <span class="col-1">
          Total Amount
        </span>
        <span class="col-3" style="text-align: left;">
          Comments
        </span>
      </div>
    </div>
  </p-header>
  <ng-template let-requestItem pTemplate="listItem">
    <div class="col-12 ui-dataview-content ui-widget-content">
      <div class="grid p-align-center resShadow-1">

        <div class="col-2 text-align-left">
          <span class="font-weight-bold"> {{requestItem.ChemicalName}} </span> <br />
          <span class="font-style-italic"> {{requestItem.ContainerLabel}}</span> <br />
          <span>
            <i class="pi pi-paperclip attachment-anchor"></i>
            {{requestItem.RequestItemAttachmentList.length}} file(s) attached
          </span>
        </div>
        <div class="col-1 rowContent">
          <span>{{requestItem.LotNumber}}</span>
        </div>

        <div class="col-1 rowContent">
          <button *ngIf="requestItem.Priority == 'Standard'" pButton type="button" label="Standard"
            class="ui-button-success green-button"></button>

          <button *ngIf="requestItem.Priority == 'Urgent'" pButton type="button" label="Urgent"
            class="p-button-danger red-button"></button>
        </div>
        <div class="col-2 rowContent" hidden=true>
          <button *ngIf="requestItem.SendInvoiceBefore == true" pButton type="button" label="Yes"
            class="p-button-success  green-button"></button>
          <button *ngIf="requestItem.SendInvoiceBefore == false" pButton type="button" label="No"
            class="p-button-danger  red-button"></button>
        </div>

        <div class="col-1 rowContent">
          <button *ngIf="requestItem.CoA == true" pButton type="button" label="Yes"
            class="p-button-success  green-button"></button>
          <button *ngIf="requestItem.CoA == false" pButton type="button" label="No"
            class="p-button-danger  red-button"></button>
        </div>

        <div class="col-1 rowContent">
          <span> {{requestItem.TotalRequestedQuantity | number : '1.2-4'}} {{requestItem.UnitOfMeasure}}</span>

        </div>
        <div class="col-4 rowContent" style="text-align: left;"
          [attr.title]="requestItem?.RequestItemComment[0]?.Comment?.Comment1">
          <span>{{requestItem?.RequestItemComment[0]?.Comment?.Comment1}}</span>
        </div>

        <div class="col rowContent rowButton">
          <button type="button" pButton icon="pi pi-trash" label="Remove"
            class="p-button-danger float-right remove-button" (click)="removeRequestItem(requestItem)"></button>
          <button type="button" class="editButton" pButton icon="pi pi-pencil" label="Edit"
            (click)="showDialog(requestItem)"></button>
        </div>

      </div>
    </div>
    <!-- Dialog box open on edit click  -->

    <p-dialog [contentStyle]="{'width':'69vw','max-height':'56vh','overflow':'auto'}" [draggable]="true" [modal]="true"
      [closeOnEscape]="false" class="dialog" [(visible)]="requestItem.Display" positionLeft=500 [closable]="false">
      <ng-template
         pTemplate="header">
        <div class="grid" style="width: 100%">
          <div class="col-10 dialog-header-chemical-name">{{requestItem.ChemicalName}}</div>
          <div class="col-2">
            <div class="dialog-header-total-quantity">
              {{editRequestItemModel.TotalRequestedQuantity | number : '1.2-4'}} {{editRequestItemModel.UnitOfMeasure}}
            </div>
            <button pButton type="button" style="float: right;width: 18px;margin-top: -34px;margin-right: -22px;" label="X" (click)="closeDialog(requestItem)"></button>
          </div>
        </div>
      </ng-template>
      <div class="grid" style="margin-top:-20px;margin-bottom:10px;" *ngIf="editRequestItemModel.RequestLocationId > 0">
        <div class="col-8"></div>
        <div class="col-2">Forecasted : {{editRequestItemModel.AmountForecasted | number : '1.2-4'}}
          {{editRequestItemModel.UnitOfMeasure}}</div>
        <div class="col-2">Remaining : {{editRequestItemModel.AmountRemaining | number : '1.2-4'}}
          {{editRequestItemModel.UnitOfMeasure}}</div>
      </div>
    <div class="grid">
        <div class="col-9 leftDiv">

          <div class="col-12 section-with-border">
            <div class="documentCheckboxes font-weight-bold d-flex">
              <div class="grid">
                <div class="containerLabel col-8 sm:col-8 md:col-8 lg:col-8">
                  <div>*Container Label</div>
                  <p-dropdown [disabled]="editRequestItemModel.isCustomLabel" displayProperty = "label" [options]="containerLabelList"
                    placeholder="Select Label" [(ngModel)]="editRequestItemModel.ContainerLabel">
                    <ng-template let-item pTemplate="item">
                      <div class="flex align-items-center gap-2" [ngClass] = "item.isRaceLabel ? 'raceLabel' : ''">
                          <div >{{ item.label }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="containerLabel col-4 sm:col-4 md:col-4 lg:col-4">
                  <div class="custom-container">
                    <p-checkbox [(ngModel)]="editRequestItemModel.isCustomLabel" binary="true"></p-checkbox>&nbsp;&nbsp;
                    <span [ngStyle]="ShipNowStyle"> *Custom Label </span>
                    <input type="text" [(ngModel)]="editRequestItemModel.requestedContainerLabel"
                      [disabled]="!editRequestItemModel.isCustomLabel" placeholder="Enter Custom Label" />
                  </div>
                  <span class="warning-msg" [ngClass]="{'custom-label': editRequestItemModel.isCustomLabel}">
                    <i class="fa fa-exclamation-triangle"></i> Please ensure correct custom label spelling
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12">
              <span class="uomLabel font-weight-bold">Lot Number:</span>
              <span class="col-8"><input type="text" [disabled]="'disabled'"
                  [(ngModel)]="editRequestItemModel.LotNumber" /></span>
            </div>

            <div class="space"> </div>
            <div class="uomDiv">
              <span class="uomLabel font-weight-bold">*Unit Of Measure</span>
              <div class="select-button">
                <p-selectButton [options]="unitOfMeasureList" [(ngModel)]="selectedUnitOfMeasure"
                  (onChange)="onMeasureUnitChange(selectedUnitOfMeasure)" optionLabel="UnitOfMeasure">
                </p-selectButton>
              </div>
            </div>
            <div class="space"> </div>
            <div class="col-12" *ngIf="requestTypeName!=='P2P'">
              <span class="uomLabel font-weight-bold">REACH Compliant?</span>
              <span class="col-8">
                <p-inputSwitch [disabled]="editRequestItemModel.RequestLocationId > 0"
                  [(ngModel)]="editRequestItemModel.IsReachCompliant"></p-inputSwitch>
              </span>

            </div>
            <div class="space"> </div>
            <div class="containerLabel col-12 lg:col-8"
              *ngIf="materialSearchType == 'P2PBiological' || materialSearchType == 'P2POther'">
              <span class="uomLabel font-weight-bold">*Packed with:</span>
              <p-dropdown [options]="packedWithChoices" optionLabel="Name" placeholder="Select Packed with"
                [(ngModel)]="PackedWithChoice">
              </p-dropdown>
            </div>
            <div class="space"> </div>
            <div class="col-12" *ngIf="requestTypeName ==='P2P'">
              <span class="uomLabel font-weight-bold">*Value:</span>
              <span class="col-8"><input type="text" [(ngModel)]="editRequestItemModel.CustomsValue" decimalNumber/></span>
            </div>
          </div>

          <div class="col-12 addContainerDiv" *ngIf="requestItem.Display == true">
            <!-- This component is for add container section  -->
            <app-add-container [selectedUnitOfMeasure]="selectedUnitOfMeasure" (UpdateRemainingAmount)="UpdateRemainingAmount($event)"
              [editRequestItemModel]="editRequestItemModel">
            </app-add-container>
            <!-- add container end -->
            <div class="grid permit" *ngIf="isPermitContainerShowForP2P && isBioMaterialType(requestItem)">
              <div class="col-4 permitContainer">
                <span style="float: left;"> *Permit Required? </span>
                <span style="float: right;">
                  <button type="button" pButton label="No" class="permitBtnLeft" [ngClass]="(requestItem.IsPermitRequired !== null && !requestItem.IsPermitRequired) ? 'primary' : 'white'" (click)="onSelectPermit('NotRequired', requestItem)"></button>
                  <button type="button" pButton label="Yes" class="permitBtnRight" [ngClass]="(requestItem.IsPermitRequired !== null && requestItem.IsPermitRequired) ? 'primary' : 'white'" (click)="onSelectPermit('Required', requestItem)"></button>
                </span>
              </div>
              <div class="col-4 permitContainer" *ngIf="requestItem.IsPermitRequired">
                <span style="float: left;"> *Do you have a permit? </span>
                <span style="float: right;">
                  <button type="button" pButton label="No" [disabled]="!requestItem.IsPermitRequired" class="permitBtnLeft"
                  [ngClass]="(requestItem.IsPermitAvailable !== null && !requestItem.IsPermitAvailable) ? 'primary' : 'white'" (click)="onSelectPermit('permitNotAvailable', requestItem)"></button>
                  <button type="button" pButton label="Yes" [disabled]="!requestItem.IsPermitRequired" class="permitBtnRight"
                  [ngClass]="(requestItem.IsPermitAvailable !== null && requestItem.IsPermitAvailable) ? 'primary' : 'white'" (click)="onSelectPermit('permitAvailable', requestItem)"></button>
                </span>
              </div>
            <div *ngIf="requestItem.IsPermitAvailable !== null && requestItem.IsPermitAvailable" class="col-4 permitContainer bgBlue">
              <div class="grid">
                <div class="col-9">
                  *Please attach permit
                </div>
                <div class="col-3" style="transform: rotate(180deg); pointer-events: none;">
                  <p-button icon="pi pi-info" styleClass="p-button-rounded"></p-button>
                </div>
              </div>
            </div>
            <div *ngIf="requestItem.IsPermitAvailable !== null && !requestItem.IsPermitAvailable" class="col-4 permitContainer bgLightRed">
              <div class="grid">
                <div class="col-9">
                  Please contact <a href="mailto:dl-gbpc-ppq@corteva.com"><strong>GBPC</strong></a> team for further assistance
                </div>
                <div class="col-3">
                  <p-button icon="pi pi-exclamation-triangle" styleClass="p-button-rounded p-button-danger"></p-button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <!-- Right Section on Edit -->
        <!-- Date and Shipping Readiness -->
        <div class="col-3 rightDiv">
          <div class="col-12 section-with-border add-sample-priority">

            <div class="priorityDiv">
              <div>
                <div>
                  <span>Priority</span>
                </div>

                <div>
                  <p-radioButton name="priorityGroup" value="Standard" label="Standard"
                    [(ngModel)]="editRequestItemModel.Priority">
                  </p-radioButton>
                </div>
                <div class="padding-bottom-7px">
                  <p-radioButton name="priorityGroup" value="Urgent" label="Urgent"
                    [(ngModel)]="editRequestItemModel.Priority">
                  </p-radioButton>
                </div>
                <!-- <div class="shipNowCheckbox padding-bottom-7px">

                  <p-checkbox binary="true" [(ngModel)]="editRequestItemModel.ShipNow"></p-checkbox>&nbsp;&nbsp;
                  <span [ngStyle]="ShipNowStyle"> Ship Now </span>
                </div>
                <div>
                  <span>Need By Date &nbsp;</span>
                </div>
                <div class="padding-bottom-7px">
                  <p-calendar [readonlyInput]="true" [minDate]="minNeedByDate" class="needByDate"
                    [(ngModel)]="editRequestItemModel.NeedByDate" dateFormat="dd-M-yy">
                  </p-calendar>

                </div> -->


                <div>
                  <button pButton class="btnAll" type="button" label="Apply"
                    (click)="priorityApplyToAllSamples()"></button>
                </div>
              </div>
            </div>

            <!-- <div *ngIf="materialSearchType == 'P2PBiological'">
              <div style="padding-top: 25px;">
                <div><span style="font-weight: bold;">*Biological Questions</span></div>
              </div>
              <div>
                <button pButton class="btnAll" type="button" label="Click Here"
                  (click)="showQuestionnaireModal()"></button>
              </div>
            </div> -->
          </div>

          <div class="priorityDiv section-with-border add-sample-priority" style="margin-top: 5px;"
          *ngIf="editRequestItemModel.ApproverEmail !== null&&requestTypeName!=='P2P'">
            <div>
              Approver
            </div>
            <div>
              <a style="font-weight: bold" href="mailto:{{editRequestItemModel.ApproverEmail}}">
                <u>
                  <span *ngIf="editRequestItemModel.ApproverName !== null">{{editRequestItemModel.ApproverName}}</span>
                  <span *ngIf="editRequestItemModel.ApproverName === null">{{editRequestItemModel.ApproverEmail}}</span>
                </u>
            </a>
            </div>
          </div>

          <!--this div is hidden intentionally for now as per KAT 399-->
          <div class="col-12 eupDiv" hidden=true>
            <div class="col-12 section-border-background-color">
              <div class="documentCheckboxes">
                <span>Documents Required for EUP</span>
                <div>
                  <p-checkbox binary="true" [(ngModel)]="editRequestItemModel.SendInvoiceBefore"></p-checkbox>
                  &nbsp;&nbsp;Invoice
                </div>

                <div>
                  <p-checkbox binary="true" [(ngModel)]="editRequestItemModel.SDS"></p-checkbox>
                  &nbsp;&nbsp;SDS
                </div>

                <div>
                  <p-checkbox binary="true" [(ngModel)]="editRequestItemModel.COO"></p-checkbox>
                  &nbsp;&nbsp;COO
                </div>

                <div>
                  <p-checkbox binary="true" [(ngModel)]="editRequestItemModel.CoA"></p-checkbox>
                  &nbsp;&nbsp;CoA
                </div>
                <div>
                  <p-checkbox id="OtherDocCheckBox" binary="true" [(ngModel)]="editRequestItemModel.OtherDoc"
                    (onChange)="handleOtherDocTextBoxVisibility($event)"></p-checkbox>
                  &nbsp;&nbsp;Other
                </div>
                <div>
                  <button pButton class="btnAll" type="button" label="Apply to all samples"
                    (click)="applyDocumentEUPToAllSamples()"></button>
                </div>
              </div>
              <div id="OtherDocTextBox" class="col" [ngStyle]="otherDocTextBoxStyle">
                <textarea [(ngModel)]="editRequestItemModel.OtherDocText" maxlength="150" autoresize="true"
                  rows="1"></textarea>
              </div>
              <div class="space"> </div>

            </div>
          </div>

        </div>
      </div>
      <div *ngIf="requestItem.Display == true">
        <!-- File Upload section -->
        <app-request-item-attachments [requestItem]="requestItem" [editRequestItemModel]="editRequestItemModel"
          (uploadedFilesEvent)="GetUploadedFiles($event)" (deleteFile)="DeleteFile($event)"
          (updateDocumentType)="UpdateDocumentType($event)">
        </app-request-item-attachments>
        <!-- End -->
      </div>
      <p-footer>
        <button pButton [disabled]="disableSaveBn" type="button" class="p-button-success" pButton icon="pi pi-save"
          label="Save" (click)="saveRequestItemDetails(editRequestItemModel)"> </button>
        <button pButton type="button" icon="pi pi-times" label="Cancel" (click)="closeDialog(requestItem)"></button>
      </p-footer>
    </p-dialog>

    <p-dialog class="questionnaire-dialog" [resizable]="false" header="Questionnaire"
      [(visible)]="showQuestionnaireDialog" [style]="{'min-width':'700px'}" [modal]="true" [dismissableMask]="true">
      <form [formGroup]="questionnairesForm">
        <div class="form-group row" *ngFor="let question of biologicalQuestionnaire">
          <ng-container *ngIf="question.QuestionType.QuestionType1 === 'YesNo'">
            <div class="col-md-6">
              <label for="Shipment value">*{{question.QuestionCaption}}</label>
              <i *ngIf="question.QuestionCaption === 'Were donor animals bred and raised in captivity?'
              ||question.QuestionCaption === 'Is this material being exported from the USA?'"
                class="pi pi-info-circle info custom-info" pTooltip="{{question.InfoCaption}}"></i>
            </div>
            <div class="col-md-6 switch">
              <span>No</span>
              <p-inputSwitch [formControlName]="question.QuestionId"></p-inputSwitch>
              <span>Yes</span>
            </div>
          </ng-container>
          <ng-container *ngIf="question.QuestionType.QuestionType1 === 'FreeText'">

            <div class="col-md-5">
              <label for="Shipment value">*{{question.QuestionCaption}}
              </label>
            </div>
            <div *ngIf="question.QuestionCaption == 'Origin'" class="col-md-7">
              <p-autoComplete id="autoCompleteCountry" [suggestions]="filteredCountriesSingle"
                (completeMethod)="filterCountrySingle($event)" [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}" field="Name" [formControlName]="question.QuestionId"
                (onBlur)="onOriginBlurValidation($event, question.QuestionId)" placeholder="Enter Country of Origin"
                [minLength]="1" #autocomplete>
              </p-autoComplete>

            </div>
            <div *ngIf="question.QuestionCaption != 'Origin'" class="col-md-7">
              <input type="text" maxlength="50" pInputText placeholder="Enter {{question.QuestionCaption}} here"
                [formControlName]="question.QuestionId">
            </div>

          </ng-container>
          <ng-container *ngIf="question.QuestionType.QuestionType1 === 'MultipleChoice'">
            <div class="col-md-5"><label for="Shipment value">*{{question.QuestionCaption}}</label></div>
            <div [ngClass]="[question.IsOthersSelected ? 'col-md-4' :'col-md-7']">
              <p-dropdown class="pDropDownClass" (onChange)="questionChhoicesChange(question,$event)"
                [options]="question.QuestionChoices" placeholder="Select {{question.QuestionCaption}}"
                [autoDisplayFirst]="false" optionValue="QuestionChoiceId" optionLabel="ChoiceName"
                [formControlName]="question.QuestionId">

              </p-dropdown>

            </div>
            <div class="col-md-3" *ngIf="question.IsOthersSelected">
              <input type="text" maxlength="50" placeholder="Please describe" id="question.FCName"
                [formControlName]="question.FCName">
            </div>
          </ng-container>
          <ng-container *ngIf="question.QuestionType.QuestionType1 === 'Boolean'">
            <div class="col-md-1">
              <p-checkbox [binary]="true" [(ngModel)]="isQuestionaireTermsChecked"
                [formControl]="questionnairesForm.controls[question.QuestionId]"></p-checkbox>
            </div>
            <div class="col-md-11 confirmation">
              <label for="Shipment value">*{{question.QuestionCaption}}</label>
              <i class="pi pi-info-circle info custom-info" pTooltip="{{question.InfoCaption}}"></i>
            </div>
          </ng-container>
        </div>



      </form>
      <p-footer>
        <button pButton [disabled]="!questionnairesForm || questionnairesForm.invalid || !isQuestionaireTermsChecked"
          type="button" class="p-button-success" pButton icon="pi pi-save" label="Save" (click)="saveQuetionnaire()">
        </button>
      </p-footer>
    </p-dialog>
  </ng-template>
</p-dataView>

<!-- confirmation dialog box to remove chemical from summary page -->
<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
    <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>
