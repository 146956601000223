import { Component, OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ConfigurationSettings } from '../configuration-settings';

@Component({
  selector: 'app-maintenance-message',
  templateUrl: './maintenance-message.component.html',
  styleUrls: ['./maintenance-message.component.scss']
})
export class MaintenanceMessageComponent implements OnInit {
public theMessage: string;
connection: signalR.HubConnection;
constructor() { }

  ngOnInit(): void {
    this.connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(ConfigurationSettings.CurrentEnvironment.ApiPath+"/hub")
    .build();
  
    this.connection.start().then(function () {  
      console.log('SignalR Connected!');  
    });  

  }
  public sendMessage() {
    this.connection.send('sendGreeting', 'Admin user ' + localStorage.getItem("loggedInUsername") + ' has sent the following message: ' + this.theMessage);
  }
}
