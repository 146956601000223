<p-toast position="top-right"></p-toast>
<div class="col-12">
  <div class="grid request main-div">
    <div class="col border-right">
      <span class="font-weight-bold"> Request ID :
      </span>{{requestModel.RequestNumber}}
    </div>
    <div class="col  border-right"><span class="font-weight-bold">
        Requester : </span> {{requestModel.RequesterInformationModel.DisplayName}}
    </div>
    <div class="col  border-right"><span class="font-weight-bold">
        Created By : </span> {{requestModel.RequestCreatedBy}}
    </div>
    <div class="col  border-right"><span class="font-weight-bold">
        Created On : </span> {{requestModel.RequestCreatedOn | date: "dd-MMM-yyyy"}}
    </div>
    <div class="col text-left">
      <div class="grid"> 
        <div class="col-12">
        <span class="font-weight-bold">
          Request Status : </span> {{requestModel.RequestStatus}}
        </div>
      </div>
      <div class="grid" *ngIf="requestModel.RequestStatus!='Draft'"> 
        <div class="col-12">
            <span class="font-weight-bold">
          Submitted By : </span> {{requestModel.SubmittedBy}}
        </div>
      </div>
      <div class="grid" *ngIf="requestModel.RequestStatus!='Draft'">
        <div class="col-12">
          <span class="font-weight-bold">
            Submitted on : </span> {{requestModel.SubmittedOn | date: "dd-MMM-yyyy"}}
        </div>
      </div>
    </div>
  </div>
</div>