
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AddressModel } from './address.model';
import { AddressService } from '../services/address-service/address.service'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryModel } from '../country/country.model';
import { CountryService } from '../services/country-service/country.service';
import { ValidationService } from '../services/validation-service/validation-service';
import {cloneDeep} from 'lodash';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
  providers: [AddressService, ConfirmationService, MessageService, CountryService, ValidationService]
})

export class AddressComponent implements OnInit {

  addressForm: UntypedFormGroup;
  get f() { return this.addressForm.controls; }
  serviceResponse: any;
   public alphaNumericRegEx =/[~`!@#$%^()_={}[\]:;,.<>+\/?-]/;
  @Input() visible: boolean;
  @Input() addressModel: AddressModel;
  @Input() recipientEmail: string;
  @Output() eventToReceiveNewCreatedAddress = new EventEmitter<AddressModel>();
  @Output() eventToCloseAddressPopUp = new EventEmitter<AddressModel>();

  @Input() recipientType?: string;

  validatedAddress: AddressModel = new AddressModel(); 
  assignedCountry: string = "";
  errorMessage: string = "";
  showSuggestedAddress: boolean = false;
  shouldValidateAddress: boolean = true;
  disableNonEditableField: boolean = false;
  isStateRequired: boolean = false;
  invalidAddressLine:boolean = false;

  countryList: CountryModel[] = [];
  selectedCountry: CountryModel;
  addressList: AddressModel[] = [];

  submitted = false;
  contentStyleObject: object = {
    'width': '53vw',
    'max-height': '75vh',
    'overflow': 'auto',
  }
  constructor(private _formBuilder: UntypedFormBuilder, private addressService: AddressService, private confirmationService: ConfirmationService,
    private countryService: CountryService, private messageService: MessageService, public validationService: ValidationService
  ) {

  }

  ngOnInit() {
    this.addressForm = this._formBuilder.group({
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      addressLine3: [''],
      company: ['', Validators.required],
      contactNumber: ['', Validators.required],
      zipCode: ['', Validators.required],
      city: ['', Validators.required],
      state: [''],
      country: ['', Validators.required]
    });

    
    this.addressForm.get('country').valueChanges
  .subscribe(value => {
     
    if(value?.Name == "UNITED STATES") {
      this.addressForm.controls['state'].setValidators([Validators.required]);
      this.isStateRequired = true;
    } else {
      this.addressForm.controls['state'].clearValidators();
      this.isStateRequired = false;
    }
    this.addressForm.controls['state'].updateValueAndValidity();
  });
    if (this.addressModel.CountryId > 0) {
      this.selectedCountry = { CountryId: this.addressModel.CountryId, Name: this.addressModel.Country };
    }

    if (this.recipientType == "External") {
      this.assignedCountry = this.addressModel.Country;
      if (this.addressModel.AddressId > 0) {
        this.disableNonEditableField = true;
      }
    }

    this.getCountryList();


  }

 public RestrictUnwantedCharecters(e)
 {
  
  if(!this.alphaNumericRegEx.test(e.key)) {
    return true;
  }
  else{
   e.preventDefault();
   return false;
  }  
 }
  //------------------------------------------------
  // To save address
  //------------------------------------------------
  saveAddress() {
    this.submitted = true;
    // stop here if form is invalid 
    if (this.addressForm.invalid) {
      return;
    }
    else {
      if (this.recipientType == "External") {
        if (this.assignedCountry != "" && this.assignedCountry != null && this.assignedCountry != this.selectedCountry.Name) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.assignedCountry + " is the assigned country to the recipient. Please select " + this.assignedCountry });
          return;
        }
      }
      console.log("All the field(s) are valid. Call service()");
      this.addressModel.CountryId = this.selectedCountry.CountryId;
      this.addressModel.Country = this.selectedCountry.Name;
      this.invalidAddressLine = false;
      if( this.selectedCountry.Name == "UNITED STATES" && this.addressModel?.AddressLine1 != null  
      && this.addressModel?.AddressLine1.trim().length > 0 && this.shouldValidateAddress)
      {
            this.addressService.ValidateAddressLine1(this.addressModel).subscribe(res => {
              if(res.Address.Error ==  null)
              {
                if(this.addressModel.PostalCode != res.Address.Zip5 || this.addressModel.City != res.Address.City || this.addressModel.AddressLine1 != res.Address.Address2 || this.addressModel.State != res.Address.State)
                {
                  this.validatedAddress.PostalCode = res.Address.Zip5
                  this.validatedAddress.City = res.Address.City
                  this.validatedAddress.AddressLine1 = res.Address.Address2
                  this.validatedAddress.State = res.Address.State
                  this.showSuggestedAddress = true;                              
                }
                else
                {
                  if (this.addressModel.AddressId > 0) {
                    this.callServiceToUpdateAddress();
                  }
                  else {
                    this.callServiceToSaveAddress();
                  }
                }

                
              }
              else
              {
                this.invalidAddressLine = true;
                this.errorMessage = res.Address.Error.Description;
                //show yes/no popup
                this.confirmationService.confirm({
                  message: 'USPS does not consider this address valid do you still want to continue?',
                  header: 'Address Not Found',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {                           
                    this.shouldValidateAddress = false;
                    this.callServiceToSaveAddress();
                  }
                });            
              }
            },
              error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
              });
      }
      else{
        if (this.addressModel.AddressId > 0) {
          this.callServiceToUpdateAddress();
        }
        else {
          this.callServiceToSaveAddress();
        }
      }
      
    }
  }
  closePopUp(){
 this.showSuggestedAddress = false;
  }

  UseEnteredAddress(){
    this.showSuggestedAddress = false;
    if (this.addressModel.AddressId > 0) {
      this.callServiceToUpdateAddress();
    }
    else {
      this.callServiceToSaveAddress();
    }
  }

  AcceptUSPSAddress(){
    this.showSuggestedAddress = false;
    this.addressModel.PostalCode = this.validatedAddress.PostalCode;
    this.addressModel.City = this.validatedAddress.City;
    this.addressModel.AddressLine1 = this.validatedAddress.AddressLine1;
    this.addressModel.State = this.validatedAddress.State;

    if (this.addressModel.AddressId > 0) {
      this.callServiceToUpdateAddress();
    }
    else {
      this.callServiceToSaveAddress();
    }
  }

  public clearErroMessage(messages)
  {
    if(this.errorMessage.indexOf(messages) !== -1)
    {
      this.errorMessage = "";
      this.invalidAddressLine = false;
    }
  }
  public ValidateAddressLine()
{
  this.invalidAddressLine = false;
  if( this.selectedCountry.Name == "UNITED STATES" && this.addressModel?.AddressLine1 != null  && this.addressModel?.AddressLine1.trim().length > 0)
  {
  this.addressService.ValidateAddressLine1(this.addressModel).subscribe(res => {
    this.invalidAddressLine = !res;
  },
    error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
    });
  }
}

public GetStateAndCityFromZip()
{
  if( this.selectedCountry.Name == "UNITED STATES" && this.addressModel?.PostalCode != null)
  {
      this.addressService.GetStateAndCityForZip(this.addressModel?.PostalCode).subscribe(res => {
        if(res.ZipCode.Error ==  null)
        {
          
          if(this.addressModel.City != res.ZipCode.City)
          {
            this.addressModel.City = res.ZipCode.City
          }
          
          if(this.addressModel.State != res.ZipCode.State)
          {
            this.addressModel.State = res.ZipCode.State
          }
        }
        else
              {
                this.invalidAddressLine = true;
                this.errorMessage = res.ZipCode.Error.Description;
              }
      });
  }
}
public DisplayCommanError()
{
  return this.errorMessage.indexOf('Address') == -1 && this.errorMessage.indexOf('City') == -1 
  && this.errorMessage.indexOf('Zip') == -1 && this.errorMessage.indexOf('State') == -1;
}
  //-----------------------------------------------
  // Call api to save the address
  //-----------------------------------------------
  public callServiceToSaveAddress() {

    console.log("Calling api to create a new address for selected recipient");
    this.addressService.createAddress(this.addressModel, this.recipientEmail).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
      },
      () => {
        this.addressModel.AddressId = this.serviceResponse;
        if (this.addressModel.AddressId == 0) {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Address already exists with the recipient' })
          return false;
        }
        else {
          console.log("Address added for selected recipient with address id = " + this.serviceResponse);
          this.messageService.add({ severity: 'success', summary: 'Address saved successfully', detail: '', life: 500 });
          this.actionAfterSaveAndUpdateSuccess();
        }
      });
  }

  //-----------------------------------------------
  // Call api to update the address
  //-----------------------------------------------
  public callServiceToUpdateAddress() {
    console.log("Calling api to update selected address id = " + this.addressModel.AddressId);
    this.addressService.updateAddress(this.addressModel, this.recipientEmail).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
      },
      () => {
        console.log("Address upadted for address id = " + this.addressModel.AddressId);
        this.messageService.add({ severity: 'success', summary: 'Address updated successfully', detail: '', life: 500 });
        this.actionAfterSaveAndUpdateSuccess();
      });
  }

  //----------------------------------------------------
  // Action need to be done once save/update success
  //----------------------------------------------------
  public actionAfterSaveAndUpdateSuccess() {
    this.eventToReceiveNewCreatedAddress.emit(cloneDeep(this.addressModel));
    this.visible = false;
    this.submitted = false;
    this.addressForm.reset();
  }

  //--------------------------------
  // close address dialog pop up
  //--------------------------------
  public closeAddressDialog() {
    this.eventToCloseAddressPopUp.emit();
    this.submitted = false;
    this.addressForm.reset();
  }

  //------------------------------------
  // To get the list of address
  //-----------------------------------
  public getCountryList() {
    if (this.countryList.length == 0) {
      this.countryService.getCountryList().subscribe(res => this.serviceResponse = res,
        error => {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
        },
        () => {
          this.countryList = this.serviceResponse;
        })
    }
  }

}

