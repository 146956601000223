

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';


import { ConfigurationSettings } from "../../configuration-settings"
import { RequestItemModel } from 'src/app/request/add-chemical-details/request-item.model';
import { RequestItemPriorityModel } from 'src/app/request/add-chemical-details/request-item-priority.model';
import { RequestItemDocumentEUPModel } from 'src/app/request/add-chemical-details/request-item-document-eup.model';


@Injectable()
export class RequestItemService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  isQuestionnaireSaved: boolean = false;

    constructor(private _http: HttpClient) { }



    public getRequestItems(requestTypeName: string, requestNumber: string): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestTypeName + "/requests/" + requestNumber + "/request-items";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched RequestItems")));
    }
    public async getRequestItemsAsync(requestTypeName: string, requestNumber: string): Promise<any> {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestTypeName + "/requests/" + requestNumber + "/request-items";
        return await this._http.get(url).pipe(
            tap(x => console.log("Fetched RequestItems"))).toPromise();          
    }

    public create(requestitemModel: RequestItemModel[], requestNumber: string, addToExisting: boolean, requestType: string): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/requests/" + requestNumber + "/request-items?addToExisting=" + addToExisting;
        let body = JSON.stringify(requestitemModel);
        let httpOptionsForFile = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
       
        return this._http.post(
            url,
            body,         httpOptionsForFile
                ).pipe(
                tap(x => console.log("Created new RequestItem"))
            );
    }

    public createRequestItem(requestTypeName: string, requestitemModel: RequestItemModel[]): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestTypeName + "/request-items/" + requestitemModel[0].RequestItemId;
        let body = JSON.stringify(requestitemModel);
        
        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new RequestItem"))
            );
    }

    //------------------------------
    // To get the list of synonyms
    //-------------------------------
    public getSynonyms(requestItemId: number, requestTypeName: string): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestTypeName + "/request-items/" + requestItemId + "/synonyms";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Synonyms")));
    }

    //------------------------------
    // To delete item from request
    //-------------------------------
    public deleteRequestItem(requestTypeName: string, requestItemId: number): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestTypeName + "/request-items/" + requestItemId;
        return this._http.delete(url).pipe(
            tap(x => console.log("Deleted Request Item")));
    }


    public applyPriorityToAllSamples(requestType: string, requestItemPriorityModel: RequestItemPriorityModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/request-items/priority/apply-to-all";
        let body = JSON.stringify(requestItemPriorityModel);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Priority applied to all samples successfully"))
            );
    }

    public applyDocumentEUPToAllSamples(requestType: string, requestItemDocumentEUPModel: RequestItemDocumentEUPModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/request-types/" + requestType + "/request-items/eup-documents/apply-to-all";
        let body = JSON.stringify(requestItemDocumentEUPModel);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Priority applied to all samples successfully"))
            );
    }

    public getBiologicalQuestionnaire(requestNumber): any {
        let url = ConfigurationSettings.REST_API_URL + "/questionnaiers/BiologicalQuestionnaire/request-item/"+requestNumber;
        return this._http.get(url);
    }

    public saveQuestionnaire(payload, requestNumber): any {
        let url = ConfigurationSettings.REST_API_URL + "/save/"+requestNumber+"/request-item";
        return this._http.post(url, payload);
    }

}