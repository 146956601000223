<div class="col-12 padding-top-20-px padding-bottom-20-px">
  <p-radioButton name="shippingGroup" value="Internal" label="Internal Address" [(ngModel)]="shippingOption">
  </p-radioButton>&nbsp;&nbsp;
  <p-radioButton name="shippingGroup" value="External" label="External Address" [(ngModel)]="shippingOption">
  </p-radioButton>
</div>
<add-internal-requester-address *ngIf="shippingOption == 'Internal'" [requestModel]="requestModel"
[requesterInformationModel]="requesterInformationModel" [shippingOption]="shippingOption">
</add-internal-requester-address>

<add-external-requester-address *ngIf="shippingOption == 'External'" [requestModel]="requestModel"
[requesterInformationModel]="requesterInformationModel" [shippingOption]="shippingOption">
</add-external-requester-address>
<div class="grid" style="margin-top:5px;">
  <div class="col-11"></div>
</div>
