import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { ApproveRequestModel } from './approve-request-model';

@Injectable()
export class ApprovalService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private options = { headers: this.headers };
  
    constructor(private _http: HttpClient) { }

    public getMaterialApprovalRequestItems(email:string,pageIndex:number,pageSize:number):any
    { 
        let url = ConfigurationSettings.REST_API_URL + "/GetMaterialApproval/" + email + "/"+pageIndex + "/"+pageSize;
        return this._http.get(url)
          .pipe(map((response: Response) => response, console.log(Response)));
    }

    public updateApproval(approveRequestItem: ApproveRequestModel): any {
      let url = ConfigurationSettings.REST_API_URL + "/RequestApproval"
      let body = JSON.stringify(approveRequestItem);
  
      return this._http.post(
        url,
        body, this.options).pipe(
          tap(x => console.log("Update approval"))
        );
    }

    public returnForReview(approveRequestItem: ApproveRequestModel): any {
      let url = ConfigurationSettings.REST_API_URL + "/ReturnForReview"
      let body = JSON.stringify(approveRequestItem);
  
      return this._http.post(
        url,
        body, this.options).pipe(
          tap(x => console.log("Update approval"))
        );
    }
}
