<res-loading></res-loading>
<res-layout>
	<res-header>
		<res-logo appName="CPSS" [showAppName]="false">
			<img res-logo src="assets/cpsampleshoplogo.PNG" style="width:203px; height:35px;" >
		</res-logo>
		<res-navigation *ngIf="nonAdminMenus">
			<res-navigation-item *ngFor="let menu of nonAdminMenus" [routerLink]="menu.ActionURL" resNavItem>{{menu.Name}}</res-navigation-item>
			<res-navigation-group title="Administration" name="Administration" *ngIf="adminMenus">
				<div *ngFor="let menu of adminMenus">
					<res-navigation-item *ngIf="menu.Name != 'Administration' "[routerLink]="menu.ActionURL" resNavItem>{{menu.Name}}</res-navigation-item>
				</div>
			</res-navigation-group>
			<res-navigation-group title="Support" *ngIf="supportMenus" name="Support" >
				<div *ngFor="let menu of supportMenus" >
					<res-navigation-item *ngIf="menu.Name != 'Support'" [routerLink]="menu.ActionURL" resNavItem>{{menu.Name}}</res-navigation-item>
				</div>
			</res-navigation-group>
		</res-navigation>
		<div res-app-settings>
		</div>
	</res-header>
	<div class="grid" style="width:100%">
		<div class="col-12" *ngIf="cartItemsCount == 0 && isRequester">
			<!--There is no item then no need to allow click on the cart in header  -->
			<i class="shoppingCart pi pi-shopping-cart"><span
					class="badge badge-color">{{cartItemsCount}}</span></i>
		</div>
		<div class="col-12" *ngIf="cartItemsCount > 0 && isRequester">
			<!--There is item then allow click on the cart in header  -->
			<i class="shoppingCart pi pi-shopping-cart" (click)="showCartDetailsPage()"
				><span class="badge badge-color">{{cartItemsCount}}</span></i>
		</div>
	</div>
	<res-body>
		<router-outlet></router-outlet>
	</res-body>

	<res-footer version="one hunnit">
		<div res-footer-content>
			<ul>
				<li>
					<a href="https://confluence.research.corteva.com/display/CPKB/CP+Sample+Shop"><strong>Help</strong></a>
				</li>
				<li>
					<a href="https://confluence.research.corteva.com/display/CPKB/CPSS+FAQ"><strong>FAQ</strong></a>
				</li>
				<li>
					<a href="mailto:fglrdtc@corteva.com"><strong>ITC</strong></a>
				</li>
			</ul>
		</div>
		<div res-footer-application-details>
			<div class="d-flex justify-content-end">
				<div>
					<div class="ml-3 align-self-center" style="opacity: 1" *ngIf="false">
					</div>
				</div>
			</div>
			<ul>
				<li>
					Version: {{appVersion}}
				</li>
				<li>
					Developed by Team Katalyst
				</li>
				<li>
					Internal Use Only
				</li>
			</ul>
		</div>
	</res-footer>
</res-layout>
<p-dialog header="System-wide maintenance message" [(visible)]="displayMaintenanceMessage" class="maintenance-message-dialog"
[style]="{'min-width':'500px'}" [modal]="true" [dismissableMask]="true" [contentStyle]="{minHeight: '300px'}">
	<div class="grid">
		<div class="col-12">
			<p-editor [style]="{ height: '250px' }" [readonly]="true" [(ngModel)]="theMessage"></p-editor>
		</div>
	</div>
	<p-footer>
		<button type="button" pButton icon="fa fa-check" (click)="closeMaintenanceMessage()"
			label="Ok"></button>
	</p-footer>
</p-dialog>
<p-toast></p-toast>