<p-toast position="top-right"></p-toast>
<div>
    <h5>Unit of Measures</h5>
</div>

<div class="col-12">

    <div class="padding-5em width-100percent">
        <!-- <div class="ui-dataview-content ui-widget-content"> -->
        <div class="col-12 padding-0-em resShadow padding-left-20px padding-right-20px padding-top-10px">
            <!-- <div class="ui-g"> -->
            <div class="grid">
                <div class="col float-left">
                    <span class="spanUOM padding-left-17px">Add UOM</span>
                </div>
                <div class="col float-left">
                    <span class="float-left spanUOM padding-left-30px">Map UOM</span>
                </div>
            </div>
            <div class="grid">
                <div class="col float-left">

                    <div class="col-12">
                        <div class="col-12">
                            <span class="fontSize12px float-left padding-right-15px padding-left-5px font-weight-bold">*Unit Of
                                Measure </span>
                            <input type="text" [(ngModel)]="selectedUnitOfMeasure" />
                        </div>

                        <div class="col-12 padding-left-13px">
                            <div class="float-left">
                                <span class="fontSize12px float-left padding-right-39px font-weight-bold">*Description
                                </span>
                                <input class="float-left" type="text" [(ngModel)]="selectedDescription" />
                            </div>
                            <div class="padding-left-5px float-left">
                                <button pButton type="button" class="p-button-success" (click)="addNewUnitOfMeasure()"
                                    pButton icon="pi pi-plus" label="Add" *ngIf = "isAdminstrator">
                                </button>
                            </div>
                        </div>
                    </div>
                    <br />



                    <div class="col-12 padding-top-unset">
                        <div class="ui-g">

                            <div
                                class="width-92percent padding-top-11em padding-left-5em padding-right-5em padding-bottom-5em">
                                <!-- <div class="ui-dataview-content ui-widget-content"> -->
                                <div
                                    class="col-12 resShadow padding-0-em padding-left-20px padding-top-10px padding-right-20px">

                                    <div class=" col-12 padding-0-em fontSize16px">
                                        <b> Available Unit of Measure</b>
                                    </div>

                                    <table class="width-100percent"
                                        *ngIf="unitOfMeasureList != null && unitOfMeasureList.length > 0">
                                        <tbody>
                                            <tr class="tableBackground">
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    Unit Of
                                                    Measure</td>
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    Description
                                                </td>
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    Added By
                                                </td>
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    Added On
                                                </td>
                                                <td class="text-align-right"></td>
                                            </tr>
                                            <tr *ngFor="let unitOfMeasure of unitOfMeasureList" class="border-top-1px">
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    {{unitOfMeasure.UnitOfMeasure}}</td>
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    {{unitOfMeasure.Description}}</td>
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    {{unitOfMeasure.CreatedBy}}</td>
                                                <td class="col-3 padding-left-30px text-align-left">
                                                    {{unitOfMeasure.CreatedDate | date: "dd-MMM-yyyy"}}</td>
                                                <td class="text-align-left padding-25px">


                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col float-right padding-7px padding-left-24px">
                    <div class="col-12 padding-bottom-0px">
                        <div class="grid">
                            <div class="col-3">
                                <span class="fontSize12px padding-right-15px padding-left-5px font-weight-bold">*Chemical Type
                                    &nbsp; </span>
                            </div>

                            <div class="col">
                                <p-selectButton [options]="requestTypeList" [(ngModel)]="selectedRequestType"
                                    (onChange) = "onRequestTypeChange(selectedRequestType)" optionLabel="Name">
                                </p-selectButton>
                            </div>
                        </div>

                        <div class="col-12 uomMapMultiSelect padding-bottom-8px padding-left-13px">
                            <div class="float-left">
                                <span class="fontSize12px float-left padding-right-10px font-weight-bold">*Unit Of measure
                                    &nbsp; </span>

                                <p-multiSelect (onClick)="clickEvent()" defaultLabel="Select unit of measure"
                                    [filter]="false" [options]="unMappedUnitOfMeasureListWithRequestType"
                                    [(ngModel)]="selectedUnitOfMeasuresToMapWithRequestType"
                                    (onChange) =  "onMeasureUnitChange(selectedUnitOfMeasuresToMapWithRequestType)"
                                    optionLabel="UnitOfMeasure"></p-multiSelect>
                            </div>
                            <div class="padding-left-5px float-left">
                                <button pButton type="button" class="p-button-success" pButton icon="pi pi-save"
                                    label="Map" (click)="onMappingSave()" *ngIf = "isAdminstrator"> </button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div class="col-12 padding-top-0px">
                        <div class="ui-g">
                            <div class="col-12 padding-top-0px" *ngFor="let requestType of requestTypeList;">
                                <div class="padding-5em width-100percent">
                                    <!-- <div class="ui-dataview-content ui-widget-content"> -->
                                    <div
                                        class="col-12 padding-0-em resShadow padding-left-20px padding-top-10px padding-right-20px">

                                        <div class="col-12 padding-0-em fontSize16px">
                                            <b>{{requestType.Name}}</b>
                                        </div>

                                        <table class="width-100percent"
                                            *ngIf="unitOfMeasureListForAllRequestType != null && unitOfMeasureListForAllRequestType.length > 0">
                                            <tbody>
                                                <tr class="tableBackground">
                                                    <td class="col-3 padding-left-30px text-align-left">

                                                        Unit
                                                        Of Measure</td>
                                                    <td class="col-9 padding-left-30px text-align-left">
                                                        Description</td>
                                                    <td class="text-align-right"></td>
                                                </tr>
                                                <tr *ngFor="let measureUnit of filterUnitOfMeasureListForAllRequestType(requestType.RequestTypeId)"
                                                    class="border-top-1px">
                                                    <td class="col-3 padding-left-30px text-align-left">
                                                        {{measureUnit.UnitOfMeasure}}</td>
                                                    <td class="col-9 padding-left-30px text-align-left">
                                                        {{measureUnit.Description}}</td>

                                                </tr>
                                                <tr *ngIf="isUnitOfMeasureNotMappedWithRequestType">
                                                    <td colspan="2" class="text-align-left padding-left-100px">
                                                        No
                                                        record(s) found.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- </div> -->
    </div>
</div>