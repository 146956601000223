import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AddressService } from '../../services/address-service/address.service'
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RequestService } from '../../services/request-service/request.service';
import { ConfigurationSettings } from '../../configuration-settings';
import { trigger, state, style, animate, transition, keyframes, AnimationEvent } from '@angular/animations';
import { AddShippingAddressComponent } from '../add/add-shipping-address.component';
import { UserLookUpModel } from '../../user-look-up/user-look-up.model';
import { CountryService } from 'src/app/services/country-service/country.service';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';


@Component({
  selector: 'add-external-shipping-address',
  templateUrl: './add-external-shipping-address.component.html',
  styleUrls: ['../add/add-shipping-address.component.css'],
  providers: [AddressService, ConfirmationService, MessageService, RecipientService, RequestService],
  animations: [
    trigger('selectedAddressAnimation', [
      state('selected', style({
      })),
      transition('initial => selected', animate('1000ms', keyframes([
        style({ backgroundColor: 'white', offset: 0 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(0.1)', offset: 0.5 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(1)', offset: 1 })
      ])))
    ])
  ]
})

export class AddExternalShippingAddressComponent extends AddShippingAddressComponent implements OnInit {

  public userLookUpModel: UserLookUpModel;
  public msgs: any[];
  constructor(recipientService: RecipientService, messageService: MessageService,
    confirmationService: ConfirmationService, addressService: AddressService,
    requestService: RequestService, countryService: CountryService,
    requestItemService: RequestItemService
  ) {
    super(recipientService, messageService, confirmationService, addressService, requestService, countryService, requestItemService);
    this.shippingOption = "External";
  }

  ngOnInit() {
    this.restApiURL = ConfigurationSettings.REST_API_URL + "/recipients/external/search?searchString=";
    this.getRequestRecipient(this.requestModel.RequestNumber);
  }

  //----------------------------------------------------------
  // Fire when user select user from recipient search text bxo
  //-----------------------------------------------------------
  public recipientOnSelect() {

    this.confirmationService.confirm({
      message: 'It is your responsibility to ensure all required agreements are in place and current.',
      header: 'Confirmation Message',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.validateSelectedRecipient();
      },
      reject: () => {
        if (this.requestRecipientModel.Recipient.RecipientId > 0 && this.shippingAddress.AddressId > 0) {
          this.getRequestRecipient(this.requestModel.RequestNumber);
        }
        else {
          this.userLookUpModel = new UserLookUpModel;
          this.recipientModel = new UserLookUpModel;
          this.addressList = [];
        }
        this.onCloseAddRecipientPopUp();
      }
    });
  }

  public newRecipient($event) {
    this.userLookUpModel = $event;
    this.recipientOnSelect()
  }


}
