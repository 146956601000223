<div class="row">
  <div class="col-md-12 text-center">
    <label style="font-size: 96px; color: red;">
      <span>
        <i class="pi pi-exclamation-circle" style="font-size: 6rem"></i>
      </span>
      Access Restricted
      <span>
        <i class="pi pi-exclamation-circle" style="font-size: 6rem"></i>
      </span>
    </label>
    <h4 style="text-align: center;">Currently, you do not have access to CP Sample Shop.</h4>
    <h4>Please contact <a href="mailto:fglgssm@corteva.com" title="fglgssm@corteva.com">fglgssm@corteva.com</a> to
      request access.</h4>
    <p></p>
    <p></p>
  </div>
</div>