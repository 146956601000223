import { UserRequestTypeRoleModel } from '../user-request-type-role/user-request-type-role.model';

export class UserModel {
    UserId?: number;
    UserName?: string;
    FirstName?: string;
    LastName?: string;
    Email?: string;
    IsEnabled?: boolean;
    CreatedDate?: Date;
    CreatedBy?: number;
    ModifiedDate?: Date;
    ModifiedBy?: number;

    JobTitle?: string;
    DisplayName?: string;

    FunctionId?: number;
    FunctionName?:string;
    SupervisorName?: string;
    RegionId?: number;
    RegionName?:string;
    CountryId?: number;
    CountryName?:string;
    UserRequestTypeRoleList?: UserRequestTypeRoleModel[];
    RoleName?:string = '';
    RequestTypeName?:string = '';
    AwaitingApproval?: boolean = false;
    OptInEmailNotification?: boolean = true;
    IsApprover?: boolean = false;
}
