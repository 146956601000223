import { Injectable } from '@angular/core';

@Injectable()
export class FilterService {

    // This holds the applied/selected filter criteria object list (column name and the value for that column supplied)
    // Global variable that will last till the life of the page since the filter service is instantiated when the component is loaded.
    // This array is updated within this class.

    appliedFilters: any[] = [];


    constructor() { }

    //----------------------------------------------------------------------
    //  ---| FILTER SERVICE |---- 
    //----------------------------------------------------------------------

    //----------------------------------------------------------------------
    // Method to filter records based on provided column and its value
    //----------------------------------------------------------------------
    // currentFilterColumn -> Name of column on which to apply filter. (Rename to search column)
    // value -> search Term value to filter on for the column (Rename to searchTerm)
    // filterableColumns -> list of filterable columns passed by caller in an array of objects.
    // [{ 'Text': 'Name', 'Column': 'DisplayName' },
    // { 'Text': 'Country', 'Column': 'Country' },
    // { 'Text': 'Company Name', 'Column': 'LegalName' },
    // { 'Text': 'Internal Contact', 'Column': 'InternalContactName' },
    // { 'Text': 'Show Profiles without Address', 'Column': 'Address' }
    // ];
    // dataRows -> An array of rows of data on which to apply the filter. Array of json objects
    // clearAll -> Flag if true, then clear all fiilters and return the full data rows 

    public filter(currentFilterColumn, filterTerm, filterableColumns: any[], dataRows: any[], clearAll: boolean): any[] {

        var appliedFilter: any;  // single Object that has filter column name and the value supplied

        //Clear All
        if (clearAll) {
            this.appliedFilters = [];
            return dataRows;
        }

        // Remove the column from appliedFilters in case user has removed the text.
        // If user has cleared the supplied filter term from a column, then the value will be blank and you need to remove that filter
        if (filterTerm == '' || (filterTerm == false && typeof (filterTerm) == "boolean")) {
            appliedFilter = this.appliedFilters.find(c => c.appliedFilterColumn == currentFilterColumn);

            if (appliedFilter != undefined) {
                this.appliedFilters.splice(this.appliedFilters.indexOf(appliedFilter), 1);
            }
        }

        // If user has entered or updated the filter text value
        if ((filterTerm != '') || this.appliedFilters.length > 0) {

            let filteredRows: any[] = [];  // declaration of array of matched/filtered rows as per the searchTerm

            // See if the filter is already applied
            appliedFilter = this.appliedFilters.find(c => c.appliedFilterColumn == currentFilterColumn);

            // If the supplied filter column and value is found, just remove it (We could have updated, but are cleaning and reading in next step)
            if (appliedFilter != undefined) {
                this.appliedFilters.splice(this.appliedFilters.indexOf(appliedFilter), 1);
            }

            // When column filter applied for the first time, add to the list
            if (filterTerm != false && filterTerm != '') {
                this.appliedFilters.push({ 'appliedFilterColumn': currentFilterColumn, 'filterValue': filterTerm });
            }

            // Till this point creating the array that contains the colunms on which filters applied and their values.

            // Here now loop through all the filterable columns that are passed from UI

            //Loop through each filterable column
            // TO-DO - WHY to do this?? Instead just loop thru applied filters and find the column name

            filterableColumns.forEach(filterableColumn => {

                filteredRows = [];

                // Each time filter changes, start looping through the entire dataset/all rows.
                // Initially it is full data set. With each pass of filtered columns, these become the incrementally filtered rows
                dataRows.forEach(currentRow => {

                    appliedFilter = this.appliedFilters.find(c => c.appliedFilterColumn == filterableColumn.Column);

                    if (appliedFilter != undefined) {
                        if (typeof (currentRow[filterableColumn.Column]) == 'boolean' && (appliedFilter.filterValue == true && currentRow[filterableColumn.Column] == appliedFilter.filterValue)) {
                            filteredRows.push(currentRow);
                        }
                        else if (typeof (appliedFilter.filterValue) == 'object') {
                            if(appliedFilter.filterValue.includes(currentRow[filterableColumn.Column])) {
                                filteredRows.push(currentRow);
                            }
                        }
                        else if (typeof (currentRow[filterableColumn.Column]) == 'string' && currentRow[filterableColumn.Column].toLowerCase().includes(appliedFilter.filterValue.toLowerCase())) {
                            filteredRows.push(currentRow);
                        }
                    }
                });

                if (appliedFilter != undefined)
                    dataRows = filteredRows;
            });
        }
        return dataRows;
    }

}