<div>
    <h5>Manage Users</h5>
</div>
<div>
    <p-table #dt [columns]="userTableHeaders" [value]="users" [paginator]="true" [rows]="10"
        [(selection)]="selectedUsers" [reorderableColumns]="true" [resizableColumns]="true">
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Global Filter"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto;float:right">
                </span>
                <button pButton type="button" label="Add new" (click)="AddnewItem()" icon="pi pi-plus"
                    *ngIf="isAdminstrator"></button>


            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [ngStyle]="{'display': col.display,'text-align':'center','width':col.width}" pReorderableColumn
                    pResizableColumn>
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th></th>
            </tr>
            <tr>
                <th *ngFor="let col of columns">
                    <input pInputText type="text" [ngStyle]="filterColumnWidth"
                        (input)="dt.filter($event.target.value, col.field, col.filterMatchMode='contains')">
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngStyle]="{'display': col.display,'text-align':'center'}">
                   <div *ngIf="col.field != 'IsApprover'"> {{rowData[col.field]}}</div>  
                   <div *ngIf="col.field == 'IsApprover'">
                    <input type="checkbox" 
                    [checked]="rowData[col.field]" disabled="true"  >
                   </div>
                </td>
                <td style="text-align:center">
                    <button type="button" pButton icon="pi pi-pencil" title="Edit" (click)="Edit(rowData)"
                        *ngIf="isAdminstrator"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>



<p-dialog class="userDialog" header="{{dialogHeaderText}} user" [contentStyle]="contentStyleObject" [draggable]="true"
    [modal]="true" [closeOnEscape]="false" [(visible)]="displayDialog" positionLeft=300 positionTop=85
    [closable]="false" [dismissableMask]="'true'">

    <p-fieldset legend="{{dialogHeaderText}} user">
        <div class="col-12">
            <div class="grid">
                <div class="col-6" hidden>
                    <span class="label">User Id</span>
                    <input type="text" id="userId" name="UserId" placeholder="User Id" [(ngModel)]="user.UserId"
                        pInputText>
                </div>
                <div class="col-6"></div>
                <div class="col-6">
                    <div class="grid">
                        <div class="col-10 p-text-right">
                            <span class="label font-weight-bold" style="width: 100% !important;">Material Approver</span>
                        </div>
                        <div class="col">
                            <input type="checkbox" [(ngModel)]="user.IsApprover" [disabled]="true"/>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="grid">
                        <div class="col-3">
                            <span class="label font-weight-bold">*Name</span>
                        </div>
                        <div class="col-9">
                            <sein-user-search id="username" [searchApiUrl]="restApiURL +'{searchWord}'"
                                [(ngModel)]="userLookupModel" [defaultUserList]="userLookUpModelList"
                                [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                                placeholder="Search by name or email address" (onSelect)="userOnSelect()"
                                [disabled]="userReadonly">
                            </sein-user-search>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="grid">
                        <div class="col-3 label">
                            <span class="label">Supervisor</span>
                        </div>
                        <div class="col-9">
                            <input type="text" id="supervisor" name="Supervisor" placeholder="Supervisor"
                                [(ngModel)]="user.SupervisorName" pInputText readonly>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col-6">
                    <div class="grid">
                        <div class="col-3">
                            <span class="label">Email</span>
                        </div>
                        <div class="col-9">
                            <input type="text" id="email" name="Email" placeholder="Email" [(ngModel)]="user.Email"
                                pInputText readonly>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="grid">
                        <div class="col-3 label">
                            <span class="label font-weight-bold">*Function</span>
                        </div>
                        <div class="col-9">
                            <p-dropdown *ngIf="functionList.length" id="function" appendTo="body" [options]="functionList" placeholder="Function"
                                [(ngModel)]="selectedFunction" optionLabel="Name"
                                [disabled]="disableNonEditableField" (onChange)="functionChange()">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col-6">
                </div>
                <div class="col-6">
                    <div class="grid">
                        <div class="col-3 label">
                            <span class="label font-weight-bold">*Country</span>
                        </div>
                        <div class="col-9">
                            <p-dropdown *ngIf="countryList.length" id="country" appendTo="body" [filter]="true" [options]="countryList"
                                placeholder="Country" [(ngModel)]="selectedCountry" optionLabel="Name"
                                (onChange)="countryChange()">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col-6">
                    <div class="grid">
                        <div class="col-7">
                            <span class="label font-weight-bold" style="width: 100% !important;">Email Notification</span>
                        </div>
                        <div class="col-fixed">
                            <input type="checkbox" [(ngModel)]="user.OptInEmailNotification" />
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="grid">
                        <div class="col-5">
                            <span class="label font-weight-bold" style="width: 100% !important;">User Enabled</span>
                        </div>
                        <div class="col">
                            <input type="checkbox" [(ngModel)]="user.IsEnabled"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-fieldset>
    <br />    
    <p-fieldset legend="Assign Role & Request Type">
        <div class="col-12">
            <table>
                <tr>
                    <td class="roleLabel">
                        <span class="header-text">Role/Request Type</span>
                    </td>                    
                    <td *ngFor="let requestType of this.requestTypeList" class="col"><span
                            class="header-text">{{requestType.Name}}</span></td>
                </tr>
                <tr *ngFor="let role of this.roles">
                    <td class="roleLabel"><span>{{role.Name}}</span></td>
                    <td *ngFor="let reqType of this.requestTypeList" class="col">
                        <input type="checkbox" *ngIf="role.Name == 'Requester' || reqType.Name == requestType.InStock"
                            (click)="roleClick($event.target.checked,role.RoleId,reqType.RequestTypeId)"
                            [checked]="isRoleAssigned(role.RoleId,reqType.RequestTypeId,user.UserRequestTypeRoleList)" />
                    </td>
                </tr>
            </table>
        </div>
    </p-fieldset>

    <div class="space">

    </div>
    <p-footer>
        <button type="button" class="p-button-success" pButton icon="pi pi-check-square" (click)="Save()" label="Save"
            *ngIf="!isEditable"></button>
        <button type="button" class="p-button-success" pButton icon="pi pi-save" (click)="Update()" *ngIf="isEditable"
            label="Update"></button>
        <!--This delete moved into grid column beside edit button.-->
        <button type="button" pButton icon="pi pi-times" (click)="Cancel()" label="Cancel"></button>
    </p-footer>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [closable]="false" acceptLabel="Yes" rejectLabel="No"></p-confirmDialog>
