<p-toast position="top-right"></p-toast>

<p-dialog class="recipientProfileDialog" [contentStyle]="contentStyleObject" [draggable]="true" [modal]="true"
    [closeOnEscape]="false" [(visible)]="visible" positionLeft=300 positionTop=40 [closable]="false">
    <p-header>
        <span class="dialog-header-text">New Recipient Profile</span>
    </p-header>


    <div class="grid">
        <div class="col-6">
            <div class="label font-weight-bold">
                <span>*Name</span>
            </div>
            <div class="float-left">
                <input type="text" id="name" (keypress)="removeRedBorder($event)" [(ngModel)]="recipientModel.FullName"
                    pInputText maxlength="100">
            </div>
        </div>
        <div class="col-6">
            <div class="label font-weight-bold">
                <span>*Internal Contact</span>
            </div>
            <div class="float-left">
                <sein-user-search id="internalContactSearch" [searchApiUrl]="restApiURL +'{searchWord}'"
                    [(ngModel)]="internalContactLookupModel" [defaultUserList]="userLookUpModelList"
                    [ngModelOptions]="{standalone: true}" appendTo="body" [multiple]="false"
                    placeholder="Search by name or email address" (onSelect)="internalContactOnSelect()">
                </sein-user-search>
            </div>
        </div>
    </div>

    <div class="grid">

        <div class="col-6">
            <div class="label font-weight-bold">
                <span>*Email</span>
            </div>
            <div class="float-left">
                <input type="text" id="email" (keypress)="removeRedBorder($event)" [(ngModel)]="recipientModel.Email"
                    pInputText maxlength="100">
            </div>
        </div>
        <div class="col-6">

            <div class="label font-weight-bold">
                <span>*Company Name</span>
            </div>
            <div class="float-left">
                <input type="text" id="legalName" (keypress)="removeRedBorder($event)"
                    [(ngModel)]="recipientModel.LegalName" pInputText maxlength="250">
            </div>
        </div>

    </div>
    <div class="grid">
        <div class="col-6">

            <div class="label font-weight-bold">
                <span>*Contact No</span>
            </div>
            <div class="float-left">
                <input type="text" id="contactNumber"
                    (keypress)="removeRedBorder($event); validationService.contactNumberValidation($event); "
                    class="form-control input-box float-left" [(ngModel)]="recipientModel.ContactNumber" size=20
                    pInputText maxlength="30">
            </div>
        </div>
    </div>
    <p-footer>
        <div class="dialog-footer">
            <button pButton type="button" class="p-button-success" pButton icon="pi pi-save" label="Save"
                (click)="save()">
            </button>
            <button pButton type="button" label="Close" (click)="closeAddRecipientDialog()"></button>
        </div>
    </p-footer>
</p-dialog>