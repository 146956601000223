
import { AddressModel } from 'src/app/address/address.model'
import { RecipientModel } from '../recipient-profile/recipient.model'

export class RecipientAddressModel {

    Recipient: RecipientModel
    Address: AddressModel

    RecipientId?: number
    AddressId?: number
    IsDefaultAddress?: boolean

}