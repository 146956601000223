<app-instock-cart [cartItemList]="cartItemList" *ngIf="cartItemRequestType == requestType.InStock">
</app-instock-cart>
<app-glp-cart [cartItemList]="cartItemList" *ngIf="cartItemRequestType == requestType.GLP">
</app-glp-cart>
<app-p2p-cart [cartItemList]="cartItemList" *ngIf="cartItemRequestType == requestType.PeerToPeer">
</app-p2p-cart>
<div class="p-footer">
  <button pButton type="button" style="background-color: #0072ce; border: 1px solid #0072ce !important;" class="p-button-success" label="Existing Request"
          [disabled]="appComponent.cartItemsCount == 0"
          (click)="proceedToExistingRequest(cartItemList[0].RequestTypeName)"></button>
  <button pButton type="button" class="request-button p-button-success" label="New Request"
          [disabled]="appComponent.cartItemsCount == 0" (click)="proceedToRequest()"></button>
</div>

<p-toast position="top-right"></p-toast>


<p-dialog [contentStyle]="contentStyleObject" [draggable]="true" [modal]="true" [closeOnEscape]="false"
          [(visible)]="visible" positionLeft=500 [closable]="false">
  <ng-template pTemplate="header">
    <div class="grid" style="width: 100%;">
      <div class="col-11">
        <span class="dialog-header-text" style="color:white;font-weight:bold;">Add To Existing {{requestTypeName}} Request</span>
      </div>
      <div class="col-1">
        <button pButton type="button" icon="pi pi-times" style="margin-right:-20px;float: right;border: none;" (click)="close()"></button>
      </div>
    </div>
  </ng-template>
  <p-messages key="NoRequestNotification" [(value)]="msgs" class="messages" [escape]='false'></p-messages>
  <div class="col-12">
    <span>Request Id : </span>
    <p-dropdown [options]="requestList" appendTo="body" placeholder="Select Request" [(ngModel)]="selectedRequest"
                optionLabel="RequestNumber">
    </p-dropdown>
  </div>
  <p-footer>
    <div class="form-group dialog-footer">
      <button pButton type="button" (click)="addToRequest()" class="ui-button-success" pButton icon="pi pi-plus"
              label="Add to Request">
      </button>
    </div>
  </p-footer>
</p-dialog>
