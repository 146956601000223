import { EnvironmentService } from "./services/environment-service/environment.service";
/*
    This class will contain all configuration related keys.
*/
export class ConfigurationSettings {

    constructor(environmentService: EnvironmentService)
    {
        environmentService = environmentService;
    }
    // environmentService is the object of the EnvironmentDefinition interface type. 
    // It will provide you the the current environment related key and its value
    private static environmentService = new EnvironmentService();

    public static CurrentEnvironment = ConfigurationSettings.environmentService.currentEnvironment;
    public static CLIENT_ID: string = ConfigurationSettings.environmentService.currentEnvironment.clientID;
    public static AUTHORITY: string = "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4";
    /* Getting RestApiUrl */
    public static REST_API_URL: string = ConfigurationSettings.environmentService.currentEnvironment.ApiPath;
    public static CURRENT_ENVIRONMENT_NAME: string = ConfigurationSettings.environmentService.currentEnvironment.name;
    public static APP_UI_URL: string = ConfigurationSettings.environmentService.currentEnvironment.UIPath;
    public static APP_INSIGHTS_KEY: string = ConfigurationSettings.environmentService.currentEnvironment.appInsightsKey;
    public static SUPPORT_BOT_SETTINGS = ConfigurationSettings.environmentService.currentEnvironment.SUPPORT_BOT_SETTINGS;
    public static COMPOSITE_SEARCH_SETTINGS = ConfigurationSettings.environmentService.currentEnvironment.COMPOSITE_SEARCH_SETTINGS;
    public static FARM_API_BASE_URL = ConfigurationSettings.environmentService.currentEnvironment.farmApiPath;
    
    public static ADMIN_USERS: string[] = [];
    public static ACCESS_REQUEST_EMAIL : string = 'FGLGSSM@Corteva.com';
}