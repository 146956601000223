import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { AddressService } from '../../services/address-service/address.service';
import { FilterService } from '../../services/filter-service/filter.service';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RecipientModel } from '../recipient-profile/recipient.model';
import { RecipientAddressService } from '../../services/recipient-address/recipient-address.service';
import { FunctionService } from '../../services/function-service/function.service';
import { RecipientProfileComponent } from '../recipient-profile/recipient-profile.component';
import { ValidationService } from 'src/app/services/validation-service/validation-service';
import { FunctionModel } from 'src/app/function/function.model';
import { CommonService } from 'src/app/services/common-service/common.service';


@Component({
    selector: 'internal-recipient-profile',
    templateUrl: './internal-recipient-profile.component.html',
    styleUrls: ['../recipient-profile/recipient-profile.component.css'],
    providers: [MessageService, ConfirmationService, RecipientService, AddressService, FilterService, RecipientAddressService, FunctionService, ValidationService,CommonService]
})
export class InternalRecipientProfileComponent extends RecipientProfileComponent implements OnInit, OnChanges {

    functionList: FunctionModel[] = [];
    selectedFunction: FunctionModel;
    @Input() results;
   

    constructor(router: Router, _formBuilder: UntypedFormBuilder,
        confirmationService: ConfirmationService,
        messageService: MessageService, recipientService: RecipientService,
        addressService: AddressService, filterService: FilterService,
        recipientAddressService: RecipientAddressService, private functionService: FunctionService,
        private validationService: ValidationService,public commonService:CommonService
    ) {

        super(router, _formBuilder, confirmationService, messageService, recipientService,
            addressService, filterService, recipientAddressService,commonService)

        this.filterColumns = [{ 'Text': 'Name', 'Column': 'DisplayName' },
        { 'Text': 'Country', 'Column': 'Country' },
        { 'Text': 'Function', 'Column': 'Function' },
        { 'Text': 'Supervisor', 'Column': 'SupervisorName' }];
        this.recipientOption = 'Internal';
        this.isAdminstrator = this.commonService.checkAdministrator();     
        this.isRequester = this.commonService.checkRequester();
        this.getFunctionList();
        this.getRecipientProfiles(this.recipientOption);
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.results = this.results;
    }

    //--------------------------------------------------------------------
    // Method to update recipient function on change of dropdown value
    //--------------------------------------------------------------------
    public functionChange(recipient: RecipientModel) {
        recipient.Function = this.selectedFunction.Name;
        recipient.FunctionId = this.selectedFunction.FunctionId;

        this.recipientService.update(recipient, this.recipientOption).subscribe(res => {
            this.enableDisableEditControl('divEditFunction' + recipient.RecipientId,
                'editFunction' + recipient.RecipientId,
                'divEditFunctionIcon' + recipient.RecipientId,
                'divEditFunctionCancelIcon' + recipient.RecipientId, 'block');
            this.messageService.add({ severity: 'success', summary: 'Function updated successfully', detail: '', life: 500 });

        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating' });
            }
        );
    }

    //----------------------------------------------
    // Method to save supervisor on Edit
    //-----------------------------------------------
    public supervisorOnSelect(recipient: RecipientModel) {
        recipient.SupervisorName = this.supervisorLookupModel.DisplayName;
        recipient.SupervisorEmail = this.supervisorLookupModel.Email;

        this.recipientService.update(recipient, this.recipientOption).subscribe(res => {
            this.enableDisableEditControl('divEditSupervisor' + recipient.RecipientId,
                'editSupervisor' + recipient.RecipientId,
                'divEditSupervisorIcon' + recipient.RecipientId,
                'divEditSupervisorCancelIcon' + recipient.RecipientId, 'block');
            this.messageService.add({ severity: 'success', summary: 'Supervisor updated successfully', detail: '', life: 500 });

        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating' });
            }
        );
    }

    //-------------------------------------------------------------------------------
    // Method to enable editable function control to select function
    //-------------------------------------------------------------------------------
    public editFunctionButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditFunction' + recipient.RecipientId,
            'editFunction' + recipient.RecipientId,
            'divEditFunctionIcon' + recipient.RecipientId,
            'divEditFunctionCancelIcon' + recipient.RecipientId, 'none');

        this.selectedFunction = this.functionList.filter(c => c.Name == recipient.Function)[0];
    }


    //-------------------------------------------------------------------------------
    // Method to cancel edit control
    //-------------------------------------------------------------------------------
    public editFunctionCancelButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditFunction' + recipient.RecipientId,
            'editFunction' + recipient.RecipientId,
            'divEditFunctionIcon' + recipient.RecipientId,
            'divEditFunctionCancelIcon' + recipient.RecipientId, 'block');
    }

    //-------------------------------------------------------------------------------
    // Method to enable editable sein-user search control to select Supervisor
    //-------------------------------------------------------------------------------
    public editSupervisorButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditSupervisor' + recipient.RecipientId,
            'editSupervisor' + recipient.RecipientId,
            'divEditSupervisorIcon' + recipient.RecipientId,
            'divEditSupervisorCancelIcon' + recipient.RecipientId, 'none');
        this.supervisorLookupModel = this.setRecipientObject(recipient);
    }

    //-------------------------------------------------------------------------------
    // Method to cancel edit control
    //-------------------------------------------------------------------------------
    public editSupervisorCancelButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditSupervisor' + recipient.RecipientId,
            'editSupervisor' + recipient.RecipientId,
            'divEditSupervisorIcon' + recipient.RecipientId,
            'divEditSupervisorCancelIcon' + recipient.RecipientId, 'block');
    }

    //------------------------------------
    // To get the list of Functions
    //-----------------------------------
    public getFunctionList() {
        if (this.functionList.length == 0) {
            this.functionService.getFunctionList().subscribe(res => this.serviceResponse = res,
                error => {
                    this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
                },
                () => {
                    this.functionList = this.serviceResponse;
                })
        }
    }
    
    public parseToLocalDate(date: Date) {

        date.setHours(date.getHours() - (date.getTimezoneOffset() / 60))
        return date;
    
      }
    
    
    public canEditRecipientProfile(recipientEmail:string)
    {
//      this.isRecipientProfileEditable = false;     
//      if(this.isRequester && !this.isAdminstrator)
//      {
//          if(localStorage.getItem("loggedInUsername") == recipientEmail)
//          {
//              return (this.isRecipientProfileEditable = true);
//          }
//      }
//      return this.isRecipientProfileEditable = this.isAdminstrator ? true : false ;
//    }
      return true;
    }
}

