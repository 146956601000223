

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../.././configuration-settings"
import { UnitOfMeasureModel, UOMItemConversionModel } from 'src/app/unit-of-measure/unit-of-measure.model';

@Injectable()
export class UnitOfMeasureService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private _http: HttpClient) { }

  public createUnitOfMeasure(unitofmeasure: UnitOfMeasureModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/unitofmeasures"
    let body = JSON.stringify(unitofmeasure);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new UnitOfMeasure"))
      );
  }

  public createMappingUnitOfMeasureWithRequestType(requestTypeId: Number, unitofmeasureIdList: Number[]): any {
    let url = ConfigurationSettings.REST_API_URL + "/requesttypes/" + requestTypeId + "/unitofmeasures/map"
    let body = JSON.stringify(unitofmeasureIdList);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("UnitOfMeasure mapped successfully."))
      );
  }

  public getUnitOfMeasures(requestTypeId): any {

    let url = ConfigurationSettings.REST_API_URL + "/requesttype/" + requestTypeId + "/unitofmeasures";
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched UnitOfMeasures")));
  }

  public getUnitOfMeasureListForAllRequestType(): any {
    let url = ConfigurationSettings.REST_API_URL + "/requesttypes/unitofmeasures";
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched UnitOfMeasures")));
  }

  public getUnitOfMeasureList(): any {

    let url = ConfigurationSettings.REST_API_URL + "/unitofmeasures";
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched UnitOfMeasures")));
  }

  public updateUnitOfMeasure(unitofmeasure: UnitOfMeasureModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/requesttypes/unitofmeasures/status/update"
    let body = JSON.stringify(unitofmeasure);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Updated UnitOfMeasure"))
      );
  }

  public deleteUnitOfMeasure(Id: number): any {
    let url = ConfigurationSettings.REST_API_URL + "/api/unitofmeasures/" + Id;
    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted UnitOfMeasure")));
  }

  public uomConvert(uomItemConversionModel: UOMItemConversionModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/UOMConvert/"
    let body = JSON.stringify(uomItemConversionModel);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Unit conversion is successfull."))
      );
  }

}