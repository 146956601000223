import { RequestItemModel } from '../add-chemical-details/request-item.model';
import { RequesterInformationModel } from '../requester-information/requester-information.model';


export class RequestModel {
    RequestId: number;
    RequestNumber: string;
    RequestCreatedBy: string;
    RequestCreatedOn: string;
    RequestStatus: string;
    RequestItemList: RequestItemModel[] = [];
    RequesterInformationModel: RequesterInformationModel;
    ShippingOption?: string;
    RequestTypeName?: string;
    RequestTypeId?: number;
    CountryId?: number;
    IsForecastRequest?: boolean;
    IsRegion?: boolean;
    CountryName?: string;
    SubmittedBy?: string;
    SubmittedOn?: Date;
    IsIndyAligned?:boolean;
}

export class RequestCommentModel {
    Id: number;
    CommentId: number;
    RequestId:number;
    Comment: CommentModel;
}
export class CommentModel {
    Id?: number;
    Comment1?: string;
    EnteredByUserId?: number;
    EnteredAt?: Date;
}
