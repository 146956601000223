
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../.././configuration-settings"
import { DelegateModel } from 'src/app/delegate/delegate.model';

@Injectable()
export class DelegateService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private _http: HttpClient) { }

  public createDelegate(delegate: DelegateModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/requesterdelegators"
    let body = JSON.stringify(delegate);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new RequesterDelegator"))
      );
  }

  public getDelegators(): any {
    let url = ConfigurationSettings.REST_API_URL + "/requesterdelegators"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched RequesterDelegators")));
  }

  public updateDelegate(requesterdelegator: DelegateModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/requesterdelegators"
    let body = JSON.stringify(requesterdelegator);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Updated RequesterDelegator"))
      );
  }

  public deleteRequesterDelegator(id: number): any {
    let url = ConfigurationSettings.REST_API_URL + "/requesterdelegators/" + id;
    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted RequesterDelegator")));
  }
  public getRequesters(): any {
    let url = ConfigurationSettings.REST_API_URL + "/delegators/requesters"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched delegator's requesters")));
  }
}