import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Router } from '@angular/router';
import { AddressModel } from '../../address/address.model';
import { AddressService } from '../../services/address-service/address.service';
import { FilterService } from '../../services/filter-service/filter.service';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RecipientModel } from './recipient.model';
import { RecipientAddressService } from '../../services/recipient-address/recipient-address.service';
import { FunctionService } from '../../services/function-service/function.service';
import { UserLookUpModel } from '../../user-look-up/user-look-up.model';
import { ConfigurationSettings } from '../../configuration-settings';
import { RecipientAddressModel } from '../recipient-address/recipient-address.model';
import { CommonService } from 'src/app/services/common-service/common.service';

@Component({
    selector: 'recipient-profile',
    templateUrl: './recipient-profile.component.html',
    styleUrls: ['./recipient-profile.component.css'],
    providers: [MessageService, ConfirmationService, RecipientService, AddressService, FilterService, RecipientAddressService, FunctionService,CommonService]
})
export class RecipientProfileComponent implements OnInit {

    @Input() recipientOption: string = '';

    public results: RecipientModel[] = [];
    growlMsgs: ToastModule[] = [];
    showFilter: boolean;
    totalRecordCount: number;
    addressList: AddressModel[] = [];
    showMoreInformation: boolean;
    dataList: RecipientModel[] = [];
    filterColumns: any[] = [];
    public isHidden = false;
    addressModel: AddressModel = {
        AddressId: 0,
        AddressLine1: '',
        AddressLine2: '',
        AddressLine3: '',
        PostalCode: '',
        City: '',
        State: '',
        CountryId: 0
    };
    showAddressDialog: boolean = false;
    showRecipientDialog: boolean = false;
    recipientEmail: string;
    personFullName: string;

    divToClose: HTMLElement;
    selectedDefaultAddress: number = 0;

    serviceResponse: any;

    restApiURL: string;
    public supervisorLookupModel: UserLookUpModel;

    public selectedRecipientModel: RecipientModel;

    public isRecipientProfileEditable:boolean = false;

    public isAdminstrator:boolean = false;
    public isRequester:boolean = false;
    public loggedInUsername: string;

    constructor(private router: Router, private _formBuilder: UntypedFormBuilder,
        private confirmationService: ConfirmationService,
        public messageService: MessageService, public recipientService: RecipientService,
        protected addressService: AddressService, protected filterService: FilterService,
        private recipientAddressService: RecipientAddressService,public commonService:CommonService) {

        this.restApiURL = ConfigurationSettings.REST_API_URL + "/azure-ad/users/search?searchString=";
    }

    ngOnInit() {
        this.recipientOption = "Internal";
       
    }

    public getRecipientProfiles(type: string) {
        let loggedInUserEmail = localStorage["loggedInUsername"];
        this.recipientService.getRecipients(type,loggedInUserEmail).subscribe(async response => {
            this.results = response.sort((a, b) => b.RecipientId - a.RecipientId);
            this.dataList = this.results;
            this.totalRecordCount = this.results.length;
            await this.getAddressesOfAllRecipient().then(res => {
                let index = 0;
                while (index < this.results.length) {
                    var recipientAddressList = this.addressList.filter(s => s.RecipientId == this.results[index].RecipientId);
                    this.results[index].AddressList = [];
                    this.results[index].AddressList.unshift({
                        PersonFullName: "Add", AddressLine2: "", AddressLine3: "",
                        PostalCode: "", City: "", State: "", CountryId: 0
                    });

                    if (recipientAddressList.length > 0) {
                        recipientAddressList.forEach((value) => {
                            var addressModel = JSON.parse(JSON.stringify(value));
                            addressModel.PersonFullName = this.results[index].DisplayName == '' ? this.results[index].FullName : this.results[index].DisplayName;
                            this.results[index].AddressList.push(addressModel);
                        })
                    }
                    index++;
                }
            })
                .catch(error => {
                    return;
                });
        },
            error => {
                this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
            });
    }

    public setShowFilter() {
        if (this.showFilter) {
            this.showFilter = false;
        }
        else {
            this.showFilter = true;
        }
    }

    public clearFilter() {
        this.filterColumns.forEach(element => {
            var obj = document.getElementById('input' + element.Column + 'Filter');
            (<HTMLInputElement>obj).value = '';
        });
        this.results = this.filterService.filter('', '', this.filterColumns, this.dataList, true);
    }

    //----------------------------------------------------------------------
    // Method to filter records based on provided column and its value
    //----------------------------------------------------------------------
    public filter(column, value, filterColumns: any[], results: RecipientModel[]) {
        this.results = this.filterService.filter(column, value, filterColumns, results, false);
    }

    //--------------------------------
    // To show Add Address link
    //--------------------------------
    public addAddressLink() {
        this.addressList.unshift({
            PersonFullName: "Add", AddressLine2: "", AddressLine3: "",
            PostalCode: "", City: "", State: "", CountryId: 0
        });
    }

    //---------------------------------------------------
    // To get list of all the addressed of recipient and
    // show / hide the address block.
    //---------------------------------------------------
    public showRecipientAddresses(recipient: RecipientModel, recipientName: string) {
        this.personFullName = recipientName;
        this.selectedRecipientModel = recipient;
        this.showHideAddressBlock(recipient);
    }

    //----------------------------------------------------
    // Show/Hide address block of the recipient.
    //----------------------------------------------------
    public showHideAddressBlock(recipient: RecipientModel) {
        var divControl = document.getElementById('div' + recipient.Email);
        if (divControl.style.display == 'none' || divControl.style.display == '') {

            if (this.divToClose != undefined) {
                this.divToClose.style.display = 'none';
            }
            divControl.style.display = 'block';
            this.divToClose = divControl;
            recipient.ShowAddressBar = true;
        }
        else {
            divControl.style.display = 'none';
            recipient.ShowAddressBar = false;
        }
    }

    //------------------------------------------------------
    // To get all the addresses of selected user 
    //-----------------------------------------------------
    public getAddressesOfAllRecipient(): Promise<any> {
        this.addressList = [];
        //call api to get the list of addresses saved for the user in database address table.
        return this.addressService.getAddressesOfAllRecipient().then(response => {
            if (response.length > 0) {
                response.forEach((value) => {
                    var addressModel = JSON.parse(JSON.stringify(value));
                     this.addressList.push(addressModel);
                })
            }

            var defaultAddress = this.addressList.find(c => c.IsDefaultAddress == true);
            if (defaultAddress != undefined) {
                this.selectedDefaultAddress = defaultAddress.AddressId;
            }
        })
            .catch(error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
                return;
            });
    }

    public showAddAddressDialog(selectedRecipient: RecipientModel, assignValueFromRecipient: boolean) {
        this.selectedRecipientModel = selectedRecipient;
        this.addressModel.AddressId = 0;
        this.recipientEmail = selectedRecipient.Email;
        this.personFullName = (selectedRecipient.DisplayName !== null && selectedRecipient.DisplayName !== undefined) ? selectedRecipient.DisplayName : selectedRecipient.FullName;
        if (assignValueFromRecipient) {
            this.addressModel.CountryId = selectedRecipient.CountryId;
            this.addressModel.Country = selectedRecipient.Country;
            this.addressModel.Mobile = selectedRecipient.ContactNumber;
        }
        else {
            this.addressModel.CountryId = 0;
            this.addressModel.Country = "";
            this.addressModel.Mobile = "";
        }
        this.addressModel.ModifiedBy = localStorage.getItem('msal.displayName');
        this.addressModel.ModifiedAt = new Date(Date.now());
        this.showAddressDialog = true;
    }

    //-------------------------------------------------
    // Open pop up to edit the selected address
    //------------------------------------------------
    public showEditAddressDialog(address: AddressModel, recipientEmail: string, personFullName: string) {
        this.addressModel = JSON.parse(JSON.stringify(address));
        this.personFullName = personFullName;
        this.recipientEmail = recipientEmail;
        this.addressModel.ModifiedBy = localStorage.getItem('msal.displayName');
        this.addressModel.ModifiedAt = new Date(Date.now());
        this.showAddressDialog = true;
    }

    //--------------------------------
    // Open Add Recipient dialog pop up 
    //--------------------------------
    public showAddRecipientDialog() {
        this.showRecipientDialog = true;
    }

    //--------------------------------------------------------
    // This method will receive the created and updated address
    // from address component. This method executed once address
    // is created or updated. 
    //-------------------------------------------------------
    public receiveAddressModelFromAddressComponent(newAddress: AddressModel, canAssignedCountryToRecepint: boolean) {
        this.showAddressDialog = false;
        newAddress.PersonFullName = this.personFullName;
        var addressModel = JSON.parse(JSON.stringify(newAddress));
        var recipient = this.results.find(c => c.Email == this.recipientEmail);
        var index = recipient.AddressList.findIndex(s => s.AddressId == newAddress.AddressId);
        if (index < 0) {
            recipient.AddressList.push(addressModel);
            recipient.AddressList = recipient.AddressList;
            recipient = recipient;
            this.messageService.add({ severity: 'success', summary: 'Address saved successfully', detail: '', life: 500 });
            // In  case of external recipient country of first address becomes country of the recipient.
            if (canAssignedCountryToRecepint == true && this.addressList.length == 2) {
                this.selectedRecipientModel.Country = newAddress.Country;
                this.selectedRecipientModel.CountryId = newAddress.CountryId;
            }
        }
        else {
            recipient.AddressList[index] = addressModel;
            this.messageService.add({ severity: 'success', summary: 'Address updated successfully', detail: '', life: 500 });
        }
        this.addressModel = new AddressModel();
    }

    //--------------------------------------------------------
    // This method will receive the newly created address
    // and set to the array list
    //-------------------------------------------------------
    public receiveNewCreatedRecipient() {
        this.showRecipientDialog = false;
        this.getRecipientProfiles(this.recipientOption);
    }

    //--------------------------------------
    // This method will close address up.
    //--------------------------------------
    public onAddressPopUpClose() {
        this.showAddressDialog = false;
    }

    //--------------------------------------
    // This method will close recipient pop up.
    //--------------------------------------
    public onCloseAddRecipientPopUp() {
        this.showRecipientDialog = false;
    }

    public setAsDefaultOnChange(recipient: RecipientModel, address: AddressModel) {
        var recipientAddressModel = new RecipientAddressModel();
        recipientAddressModel.RecipientId = recipient.RecipientId;
        recipientAddressModel.AddressId = address.AddressId;
        recipient.AddressList.forEach(a => {a.IsDefaultAddress = false;});

        recipientAddressModel.IsDefaultAddress = !address.IsDefaultAddress;
        address.IsDefaultAddress = !address.IsDefaultAddress;

        this.recipientAddressService.update(recipientAddressModel).subscribe(response => {
            this.messageService.add({ severity: 'success', summary: 'Default address for recipient has been saved', detail: '', life: 500 });
        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.error });
            });
    }

    //----------------------------------------------
    // Method to save Contact Number on Edit
    //-----------------------------------------------
    public onBlurContactNumber(recipient: RecipientModel) {
        this.recipientService.update(recipient, this.recipientOption).subscribe(res => {
            this.enableDisableEditControl('divEditContact' + recipient.RecipientId,
                'editContact' + recipient.RecipientId,
                'divEditContactIcon' + recipient.RecipientId,
                'divEditContactCancelIcon' + recipient.RecipientId, 'block');
            this.messageService.add({ severity: 'success', summary: 'Contact Number updated successfully', detail: '', life: 500 });

        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating' });
            }
        );
    }


    //-------------------------------------------------------------------------------
    // Method to enable editable control to provide contact number
    //-------------------------------------------------------------------------------
    public editContactButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditContact' + recipient.RecipientId,
            'editContact' + recipient.RecipientId,
            'divEditContactIcon' + recipient.RecipientId,
            'divEditContactCancelIcon' + recipient.RecipientId, 'none');
    }

    //-------------------------------------------------------------------------------
    // Method to cancel edit control
    //-------------------------------------------------------------------------------
    public editContactCancelButtonClick(recipient: RecipientModel) {
        this.enableDisableEditControl('divEditContact' + recipient.RecipientId,
            'editContact' + recipient.RecipientId,
            'divEditContactIcon' + recipient.RecipientId,
            'divEditContactCancelIcon' + recipient.RecipientId, 'block');
    }

    //-------------------------------------------------------
    // Method to Remove the Address
    //-------------------------------------------------------
    public removeAddress(recipient, addressId, canRemoveCountryOfRecepint: boolean) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete the selected address?',
            header: 'Remove Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: "removeAddressConfirmDialog",
            accept: () => {
                this.recipientAddressService.delete(recipient.RecipientId, addressId).subscribe(response => {
                    var existingRecipient = this.results.find(s => s.Email == recipient.Email);
                    existingRecipient.AddressList = existingRecipient.AddressList.filter(function (item) {
                        return item.AddressId != addressId;
                    });

                    if (canRemoveCountryOfRecepint && existingRecipient.AddressList.length == 1) {
                        existingRecipient.Country = "";
                        existingRecipient.CountryId = 0;
                    }
                    this.messageService.add({ severity: 'success', summary: 'Address has been removed', detail: '', life: 500 });

                },
                    error => {
                        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while removing' });
                    })
            },
            reject: () => {

            }
        });

    }

    //-------------------------------------------------------------------
    // Method to enable disable edit controls
    //-------------------------------------------------------------------
    public enableDisableEditControl(input: string, label: string, icon: string, cancel: string, displayOption: string) {
        var labelElement = document.getElementById(label);
        var inputElement = document.getElementById(input);
        var iconElement = document.getElementById(icon);
        var cancelIconElement = document.getElementById(cancel);


        inputElement.style.display = (displayOption == 'block' ? 'none' : 'block');
        labelElement.style.display = displayOption;
        iconElement.style.display = displayOption;
        cancelIconElement.style.display = (displayOption == 'block' ? 'none' : 'block');
    }

    //---------------------------------------------------------------------
    // To set the properties of UserLookModel and return the object.
    //---------------------------------------------------------------------
    public setRecipientObject(recipientModel: RecipientModel): UserLookUpModel {
        var recipient = {
            FirstName: '',
            MiddleName: '',
            LastName: '',
            DisplayName: recipientModel.SupervisorName,
            Email: recipientModel.SupervisorEmail,

        }
        return recipient;
    }

    //---------------------------------------------
    // To changes the status of the recipient
    //-----------------------------------------------
    //public changeRecipientProfileStatus(recipient: RecipientModel) {
    public changeRecipientProfileStatus(e, recipient: RecipientModel) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to change the status of Recipient?',
            header: 'Status Change Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: "changeProfileStatusConfirmDialog",
            accept: () => {
                this.recipientService.update(recipient, this.recipientOption).subscribe(response => {
                    this.messageService.add({ severity: 'success', summary: 'Status changed', detail: '', life: 500 });
                    // To close the address block.
                    var divControl = document.getElementById('div' + recipient.Email);
                    divControl.style.display = 'none';
                    recipient.ShowAddressBar = false;
                },
                    error => {
                        recipient.IsActive = !e.checked;
                        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while changing status' });
                    })
            },
            reject: () => {
                recipient.IsActive = !e.checked;
            }
        });
    }    
}

