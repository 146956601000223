
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../.././configuration-settings"
import { ForecastRequestModel } from 'src/app/forecast/forecast-request.model';
import { ForecastResponseModel } from 'src/app/forecast/forecast-response.model';
import { Observable } from 'rxjs';

@Injectable()
export class ForecastService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public getPaginatedForecasts(request: ForecastRequestModel): Observable<ForecastResponseModel> {
        let url = ConfigurationSettings.REST_API_URL + "/PaginatedForecasts"
        return this._http.post(url, request, this.httpOptions).pipe(
            tap(x => console.log("Fetched Forecasts"))) as Observable<ForecastResponseModel>;
    }


    public getForecasts(email: string): any {
        let url = ConfigurationSettings.REST_API_URL + "/requesters/" + email + "/forecasts"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Forecasts")));
    }

    public getForecastSampleRequestHistory(requestLocationId: number): any {
        let url = ConfigurationSettings.REST_API_URL + "/forecasts/samplerequesthistory/" + requestLocationId;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched SampleRequest History")));
    }
}