import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Chemical } from './chemical';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ChemicalService } from '../services/chemical-service/chemical.service';
import { CartService } from '../services/cart-service/cart.service';
import { SaveCartModel } from '../cart/save-cart.model'
import { AppComponent } from '../app.component';
import { RequestTypeModel } from '../request-type/request-type.model';
import { RequestTypeService } from '../services/request-type-service/request-type.service';
import { RequestType } from '../request-type/request-type.enum';
import { UserRequestTypeRoleModel } from '../user-request-type-role/user-request-type-role.model';
import { Role } from '../role/role.enum';
import { CommonService } from '../services/common-service/common.service';
import { CompositeSearchComponent } from '../composite-search/composite-search.component';
import { MaterialResult } from '../services/gsscommon-api-client.service';
import { CartItemModel, RequestItemAttributeModel } from '../cart/cart-item.model';
import { UserService } from '../services/user-service/user.service';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [ChemicalService, MessageService, CartService, RequestTypeService, CommonService]
})
export class SearchComponent implements OnInit {
  @Input() searchResults: Chemical[];
  @Input() results: Chemical[] = [];
  @Input() isHidden: boolean;
  protected filterByList: string;
  @Input() searchText: string
  moveSearch: object;
  growlMsgs: ToastModule[] = [];

  //These variables are used for sorting
  protected sortOptions: SelectItem[];
  sortKey: string;
  sortField: Chemical;
  sortOrder: number;
  @Input() hideHideAllButton: boolean = true;

  requestTypeList: RequestTypeModel[] = [];
  @Input() selectedRequestType: RequestTypeModel = { RequestTypeId: 0, Name: '' };
  @Input() existingCartItemRequestType: string = '';
  @Input() isForecast: boolean;

  requestTypeDropDownStyle: object;
  sortDrowDownStyle: object;
  @Input() isRequesterForSelectedRequestType: boolean = false;
  isGlpEligible: boolean = false;
  cartItems: any[] = [];

  constructor(protected router: Router, protected chemicalService: ChemicalService, protected messageService: MessageService,private userService:UserService,
    protected cartService: CartService, protected appComponent: AppComponent, private requestTypeService: RequestTypeService, private commonService: CommonService) {
  }

  ngOnInit() {
    this.isHidden = true;
    this.moveSearch = {
      'margin-top': '12%'
    }

    this.requestTypeDropDownStyle = { 'minWidth': '100px', 'width': '80px', 'border': 'none' };
    this.sortDrowDownStyle = { 'min-width': '140px', 'float': 'right' };
    this.getCarts();
    this.getRequestTypeList();
  }

  getCarts() {
    this.cartService.getCarts().subscribe(response => {
      this.cartItems = response;
      this.appComponent.cartItemsCount = response.length;
      if (response.length > 0) {
        this.isForecast = (response.length > 0 && response[0].RequestLocationId > 0) ? true : false;
        this.existingCartItemRequestType = this.GetExistingRequestType(response[0].CartRequestTypeId);
      }
      //(response.length > 0 ? response[0].RequestTypeName : '');
    },
      error => {
        this.router.navigate(['login']);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });

      });
  }

  GetExistingRequestType(cartRequestTypeId: number)
  {
    var requestType = this.requestType.InStock;
    switch(cartRequestTypeId)
    {
      case 1: requestType = this.requestType.InStock;
              break;
      case 2: requestType = this.requestType.GLP;
              break;
      case 3: requestType = this.requestType.Radioactive;
              break;
      case 4: requestType = this.requestType.PeerToPeer;
              break;
    }

    return requestType;
  }

  //--------------------------------------
  // Method to show search details in card layout
  //--------------------------------------
  public search() {
    if (this.searchText == null || this.searchText == "" || this.searchText.trim().length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Please enter a valid search criteria with minimum 2 characters' });
      this.searchText = "";
    }
    else {
      this.getChemicals(this.searchText);
    }
  }

  public UpdateMaterialApprover(emailId)
  {
    this.userService.UpdateMaterialApprover(emailId).subscribe( res =>{},
      error => {}
      );
  }

  //--------------------------------------
  // Method to get chemicals from API
  //--------------------------------------
  private getChemicals(searchText) {
    this.results = [];
    this.chemicalService.getChemicals(this.selectedRequestType.Name, searchText).subscribe(response => {
      this.results = response;
      this.isHidden = false;
      this.moveSearch = { 'margin-top': 'unset' };
      this.isRequesterForSelectedRequestType = this.commonService.checkRequestForRequestType(this.selectedRequestType.Name);

    },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
        this.ngOnInit();
      })
  }

  //---------------------------------------------------------------------
  // This method add item to the cart and save it to the database.
  //---------------------------------------------------------------------
  public createCart(saveCartModel: SaveCartModel): Promise<boolean> {
    return this.cartService.createCart(this.selectedRequestType.Name, saveCartModel).then(response => {
      return true;
    })
      .catch(error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
        return false;
      });

  }

  //--------------------------------------------
  // Remove item from cart
  //--------------------------------------------
  public removeFromCart(item) {
    item.IsAdded = false;
    this.messageService.add({ severity: 'error', summary: item.FormulationName + " removed from list", detail: '' });

  }

  public async markDuplicates(items: Array<MaterialResult>) {
    this.cartService.getCarts().subscribe(response => {
      items.forEach(x => {
        if (response.some(y => y.MaterialId === x.materialIdInTargetSystem && y.LotNumber === x.lotNumber && y.RepoName === x.sourceSystem)) {
          x["isAdded"] = true;
        }
      })
    });
  }
  private getProductName(item: MaterialResult): string {
    let res: string = "";
    switch (item.sourceSystem) {
      case 'COFORM':
        res = item.materialName;
        break;
      case 'CORE':
        res = item.commonName === null ? item.materialName : item.commonName + ' (' + item.materialName + ')';
        break;
      case 'FACTS':
        res = (item['preferredSynonym'] === null || item['preferredSynonym'] === undefined || item['preferredSynonym'] === '') ? 
        item.materialName : item.materialName+' ('+ item['preferredSynonym'] + ')';
        break;
      default:
        res = item.materialName;
        break;
    }
    return res;
  }
  public async AddCartItem(item: MaterialResult) {
    console.log(item);
    if (this.isForecast) {
      this.messageService.add({ severity: 'error', summary: 'Cart cannot contain forecasted and unforecasted items at the same time', detail: '' });
      return;
    }
    if (this.existingCartItemRequestType != '' &&
    (this.existingCartItemRequestType === RequestType.InStock && item.isGLP ||
      this.existingCartItemRequestType === RequestType.GLP && !item.isGLP)) {
      this.messageService.add({ severity: 'error', summary: 'Cart can contain only one type of samples at a time', detail: '' });
      return;
    }

    if(item["searchType"] == this.requestType.PeerToPeer && this.cartItems && this.cartItems.length > 0){
        var itemAlreadyExists = this.cartItems.find(a => a.RequestTypeName == RequestType.PeerToPeer
          && a.MaterialSearchType == item.materialSearchType
          && a.MaterialName?.toUpperCase() == item.materialName?.toUpperCase());
          if(itemAlreadyExists) {
            this.messageService.add({ severity: 'error', summary: 'Cart cannot contain same material name', detail: '' });
            return;
          }
    }

    if (item['sourceSystem'] === this.requestType.Bionexus && this.cartItems && this.cartItems.length > 0){
      var itemAlreadyExists = this.cartItems.find(a => a.RequestTypeName == RequestType.GLP
        && a.MaterialName?.toUpperCase() == item.materialName?.toUpperCase());
        if(itemAlreadyExists) {
          this.messageService.add({ severity: 'error', summary: 'Cart cannot contain same material name', detail: '' });
          return;
        }
    }

    var cartModel = new CartItemModel();
    var saveCartModel = new SaveCartModel();

    if(item["searchType"] == this.requestType.PeerToPeer && item["cartItemType"] !== "Stock-GLP")
    {
      this.selectedRequestType = this.requestTypeList.find(a => a.Name == RequestType.PeerToPeer);
      cartModel.RequestTypeId = this.selectedRequestType.RequestTypeId;

      cartModel.MaterialId = item.materialIdInTargetSystem;
      cartModel.MaterialName = this.getProductName(item);
      cartModel.RepoName = item.sourceSystem;
      cartModel.MaterialSearchType = item.materialSearchType;
      cartModel.ApproverEmail = item.approverEmail;
      cartModel.IsBioFormulation = item.isBioFormulation || false;
//      cartModel.ApproverName = item.approverName;
      saveCartModel.CartItem = cartModel;
      saveCartModel.SearchString = item.materialName;
      saveCartModel.CartRequestTypeId= this.selectedRequestType.RequestTypeId;
      let res = await this.createCart(saveCartModel);

      if(res)
      {
      item["isAdded"] = true;
      let ItemAddedToCart = item.commonName == null ? item.tradeName : item.commonName ;
      if(ItemAddedToCart  == null)
      {
        ItemAddedToCart = item.materialName;
      }
      this.messageService.add({ severity: 'success', summary: ItemAddedToCart + ' added successfully', detail: '', life: 1000 });
      // Increase no. of items in the cart.
      this.appComponent.cartItemsCount = this.appComponent.cartItemsCount + 1;
      if (this.existingCartItemRequestType === '') {
        this.existingCartItemRequestType = RequestType.PeerToPeer;
      }
    }
    } else if (item['sourceSystem'] === this.requestType.Bionexus) {
      if (item.isGLP) {
        this.selectedRequestType = this.requestTypeList.find(a => a.Name == RequestType.GLP);

      } else if (item["cartItemType"] === "Stock-GLP") {
        this.selectedRequestType = this.requestTypeList.find(a => a.Name == RequestType.PeerToPeer);
      } else {
        this.selectedRequestType = this.requestTypeList.find(a => a.Name == RequestType.InStock);
      }
      cartModel.ApproverEmail = item.approverEmail;
      cartModel.ApproverName = item.approverFullName;
      cartModel.MaterialId = item.materialId;
      cartModel.MaterialName = item.materialName;
      cartModel.materialIdInTargetSystem = item.materialIdInTargetSystem;
      cartModel.MaterialSearchType = item.materialSearchType;
      cartModel.ingredientId = item.ingredientId;
      cartModel.ActiveIngredient = item.actives;
      cartModel.RepoName = item.sourceSystem;
      cartModel.RecertificationDate = item.recertificationDate;
      cartModel.LotNumber = item.lotNumber;
      cartModel.AvailableAmount = item.quantity?.toString();
      cartModel.Synonyms = this.itemSynonyms(item.synonyms);
      cartModel.cartItemType = item['cartItemType'];
      cartModel.IsBioFormulation = item.isBioFormulation || false;
      if (item.lotAttributes !== null && item.lotAttributes !== undefined && item.lotAttributes.length > 0) {
        cartModel.requestItemAttributes = this.cartAttributes(item.lotAttributes, true);
      }
      saveCartModel.CartItem = cartModel;
      saveCartModel.SearchString = item.materialName;
      saveCartModel.CartRequestTypeId= this.selectedRequestType.RequestTypeId;
      console.log(saveCartModel);

      let res = await this.createCart(saveCartModel);

      if(res)
      {
        item["isAdded"] = true;
        let ItemAddedToCart = item.commonName == null ? item.tradeName : item.commonName ;
        if(ItemAddedToCart  == null)
        {
          ItemAddedToCart = item.materialName;
        }
        this.messageService.add({ severity: 'success', summary: ItemAddedToCart + ' added successfully', detail: '', life: 1000 });
        // Increase no. of items in the cart.
        this.appComponent.cartItemsCount = this.appComponent.cartItemsCount + 1;
        if (this.existingCartItemRequestType === '') {
          this.existingCartItemRequestType = RequestType.PeerToPeer;
        }
      }
    }
    else{

     this.selectedRequestType = item.isGLP ? this.requestTypeList.find(a => a.Name == RequestType.GLP) : this.requestTypeList.find(a => a.Name == RequestType.InStock);

    cartModel.RequestTypeId = this.selectedRequestType.RequestTypeId;
    cartModel.MaterialId = item.materialIdInTargetSystem;
    cartModel.MaterialName = this.getProductName(item);
    cartModel.RepoName = item.sourceSystem;
    cartModel.ApproverEmail = item.approverEmail;
    cartModel.MaterialSearchType = item.materialSearchType;
//      cartModel.ApproverName = item.approverName;
    cartModel.Synonyms = (item.synonyms !== null && item.synonyms !== undefined && item.synonyms.length > 0) ? item.synonyms.join("||") : "";
    cartModel.ActiveIngredient = (item.actives !== null && item.actives !== undefined && item.actives.length > 0) ?
    item.actives.reduce((x, y, z) => {
      return x +  (z > 0 ? ", " : "") + y.ingredientName + " [" + y.concentrationPrimaryUom +
      ((y.concentrationSecondaryUom !== undefined && y.concentrationSecondaryUom != null) ? ", " + y.concentrationSecondaryUom : "")
      + "]";} , "") : "";
    cartModel.LotNumber = item.sourceSystem === 'CORE' ? item.lotId : item.lotNumber;
    cartModel.TsnNumber = item.tsn;
    cartModel.RecertificationDate = (item.recertificationDate !== null && item.recertificationDate !== undefined) ? item.recertificationDate.getFullYear() + "-"+ (item.recertificationDate.getMonth()+1) + "-" + item.recertificationDate.getDate() : "";
    cartModel.AvailableAmount = item.quantity?.toString();
    cartModel.IsBioFormulation = item.isBioFormulation || false;
    saveCartModel.CartItem = cartModel;
    saveCartModel.SearchString = item["searchCriteria"];

    if(item["searchType"] == this.requestType.PeerToPeer)
    {
      saveCartModel.CartRequestTypeId = this.requestTypeList.find(a => a.Name == RequestType.PeerToPeer).RequestTypeId;
    }
    else{
      saveCartModel.CartRequestTypeId = this.selectedRequestType.RequestTypeId;
    }

    let res = await this.createCart(saveCartModel);

    if (res) {
        item["isAdded"] = true;
        let ItemAddedToCart = item.commonName == null ? item.tradeName : item.commonName ;
        if(ItemAddedToCart  == null)
        {
          ItemAddedToCart = item.materialName;
        }
        this.messageService.add({ severity: 'success', summary: ItemAddedToCart + ' added successfully', detail: '', life: 1000 });
        // Increase no. of items in the cart.
        this.appComponent.cartItemsCount = this.appComponent.cartItemsCount + 1;
        if (this.existingCartItemRequestType === '') {
          this.existingCartItemRequestType = item.isGLP ? RequestType.GLP : RequestType.InStock;

          if(item["searchType"] == this.requestType.PeerToPeer)
          {
            this.existingCartItemRequestType = RequestType.PeerToPeer;
          }
        }
    }
  }
  if(item.approverEmail != null && item.approverEmail != undefined)
  {
    this.UpdateMaterialApprover(item.approverEmail);
  }
  this.getCarts();
  }

  public ShowMessage(msg: string)
  {
    this.messageService.add({ severity: 'error', summary: msg, detail: '' });
  }

  //--------------------------------------------
  // Filter the result
  //--------------------------------------------
  public setFilter(event, value) {
    if (event.checked) {
      this.filterByList = value;
    }
  }

  //------------------------------------------------
  //To sort the search result by selected column
  //------------------------------------------------
  public sortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  //---------------------------------------------------------
  // To show the Hide All button and display all the synonyms
  //---------------------------------------------------------
  showDiv(synonymdivId) {
    var synonymDiv = document.getElementById(synonymdivId);
    var material = this.results.find(result => result.MaterialName == synonymdivId);
    if (material == undefined) {
      material = this.results.find(result => result.TsnNumber == synonymdivId);
    }
    synonymDiv.innerHTML = material.Synonyms.replace(/\|/g, "<br>");
    var showButton = document.getElementById("show" + synonymdivId);
    var hideButton = document.getElementById("hide" + synonymdivId)
    var spanId = document.getElementById("span" + synonymdivId);
    synonymDiv.style.display = "block";
    spanId.style.display = "none";
    showButton.style.display = "none";
    hideButton.style.display = "block";
  }

  //---------------------------------------------------------
  // To show the Show All button and hide all the synonyms
  //---------------------------------------------------------
  hideDiv(synonymdivId) {
    var synonymDiv = document.getElementById(synonymdivId);
    var showButton = document.getElementById("show" + synonymdivId);
    var hideButton = document.getElementById("hide" + synonymdivId);
    var spanId = document.getElementById("span" + synonymdivId);
    spanId.style.display = "block";
    synonymDiv.style.display = "none";
    showButton.style.display = "block";
    hideButton.style.display = "none";

  }

  private getRequestTypeList() {
    // localStorage["loggedInUsername"] gives loggedIn user email
    this.requestTypeService.getRequestTypesByEmail(localStorage["loggedInUsername"]).subscribe(response => {
      this.requestTypeList = response;
      this.requestTypeList = this.requestTypeList.filter(s => s.Name == RequestType.InStock || s.Name == RequestType.GLP);
      this.isGlpEligible = this.requestTypeList.some(a => a.Name == RequestType.GLP);
      this.requestTypeService.getRequestTypes().subscribe(response => {
        let rtList: RequestTypeModel[] = response;
        this.requestTypeList.push(rtList.filter(s => s.Name == RequestType.PeerToPeer)[0])
        this.setDefaultRequestType();
      });
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      })
  }

  private setDefaultRequestType() {
    if (this.requestTypeList.find(s => s.Name == RequestType.InStock) != null || this.requestTypeList.find(s => s.Name == RequestType.InStock) != undefined) {
      this.selectedRequestType = this.requestTypeList.find(s => s.Name == RequestType.InStock);
    }
    else {
      if (this.requestTypeList.length > 0)
        this.selectedRequestType = this.requestTypeList[0];
    }
  }


  private requestTypeOnChange() {
    this.isHidden = true;
    this.moveSearch = {
      'margin-top': '12%'
    }
    this.searchText = '';

    this.cartService.getCarts().subscribe(response => {
      this.appComponent.cartItemsCount = response.length;
      this.existingCartItemRequestType = (response.length > 0 ? response[0].RequestTypeName : '');
    },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
      });
  }

  public get requestType(): typeof RequestType {
    return RequestType;
  }

  itemSynonyms(synonyms: string[]): any {
    let val = '';
    synonyms.map((syn, i) => {
      if (synonyms.length === i + 1){
        val += syn;
      } else {
        val += syn + '||'
      }
    });
    return val;
  }

  cartAttributes(attributes: any, isLotAttribute: boolean) {
    let attList: RequestItemAttributeModel[] = [];
    attributes.map(att => {
      let item: RequestItemAttributeModel = new RequestItemAttributeModel();
      item.AttributeName = att.name;
      item.CartItemId = 0;
      item.RequestItemId = null;
      item.IsLotLevelAttribute = isLotAttribute;
      let values: any = att.values;
      let attValue = '';
      values.map((v, i) => {
        if (values.length === 1 && values[0] === '""'){
          attValue = '';
        } else {
          if (values[i] === '""') {
            attValue = attValue;
          } else {
            if (values.length === i + 1 ){
              attValue += v;
            } else {
              attValue += v + ', ';
            }
          }
        }
      });
      item.AttributeValue = attValue;
      attList.push(item);
    });
    return attList;
  }

  itemLotAttributes(lotAttributes: any): any {
    let val = '';
    lotAttributes.map((lot, index) => {
      let valIn = '';
      let values: any = lot.values;
      values.map((v, i) => {
        if (values.length === 1 && values[0] === '""'){
          valIn = '';
        } else {
          if (values[i] === '""') {
            valIn = valIn;
          } else {
            if (values.length === i + 1 ){
              valIn += v;
            } else {
                valIn += v + ', ';
            }
          }
        }
      });
      if (valIn.length ===  0) {
        val += lot.name;
      } else {
        val += lot.name + ':: ' + valIn
      }
      if (lotAttributes.length !== index + 1) {
        val += ' | ';
      }
    })
    return val;
  }
}

