import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../../configuration-settings"
import { RequestManagementModel } from 'src/app/request-management/request-management.model';

@Injectable()
export class RequestManagementService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public getMyRequests(userId: bigint): any {
        let url = ConfigurationSettings.REST_API_URL + "/users/" + userId + "/requests";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Requests")));
    }

    public filterP2PRequests(requestModels: RequestManagementModel[]): any {
        let url = ConfigurationSettings.REST_API_URL + "/users/filterP2PRequests";
        let body = JSON.stringify(requestModels);
        return this._http.post(url, body, this.httpOptions).pipe(
            tap(x => console.log("Fetched filterP2PRequests")));
    }
}
