
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RecipientAddressService } from '../../services/recipient-address/recipient-address.service';
import { ConfigurationSettings } from '../../configuration-settings';
import { RecipientModel } from '../recipient-profile/recipient.model';

@Component({
    selector: 'app-base',
    template: `<div></div>`,
    providers: [RecipientService, MessageService, RecipientAddressService]
})

export class AddRecipientProfileComponent implements OnInit {

    serviceResponse: any;

    @Input() visible: boolean;
    @Output() eventToReceiveNewCreatedRecipient = new EventEmitter<RecipientModel>();
    @Output() eventToCloseAddRecipientPopUp = new EventEmitter<RecipientModel>();
    public recipientType: string = "";

    public recipientModel: RecipientModel = {};
    public restApiURL: string;

    public contentStyleObject: object = {
        'width': '53vw',
        'max-height': '75vh',
        'overflow': 'auto',
    }

    constructor(public recipientService: RecipientService, public messageService: MessageService
    ) {
        this.restApiURL = ConfigurationSettings.REST_API_URL + "/azure-ad/users/search?searchString=";
    }
    ngOnInit() {

    }

    //-------------------------------------------------
    // To validate if a recipient already exists or not
    //-------------------------------------------------
    public async validateIfRecipientExists(): Promise<boolean> {
        return this.recipientService.getRecipientExistsAsync(this.recipientModel.Email)
            .catch(error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
                return true;
            })
            .then(res => {
                var recipientProfile = res;
                if (recipientProfile.RecipientId == 0) {
                    return false;

                }
                else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: "Recipient Profile with this email already exists" });
                    return true;
                }
            });
    }

    //-----------------------------------------------
    // Call api to save the recipient
    //-----------------------------------------------
    public saveRecipient() {
        this.recipientService.create(this.recipientModel, this.recipientType).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
            },
            () => {
                if (this.serviceResponse.RecipientId == 0) {
                    this.messageService.add({ severity: 'error', summary: '', detail: 'Email already exists!' });
                }
                else {
                    console.log("Recipient Saved Successfuly");
                    this.recipientModel = this.serviceResponse;
                    this.messageService.add({ severity: 'success', summary: 'Recipient saved successfully', detail: '', life: 500 });
                    this.eventToReceiveNewCreatedRecipient.emit(this.recipientModel);
                    this.recipientModel = new RecipientModel();
                    this.visible = false;

                }
            });
    }
}

