
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


import { ConfigurationSettings } from "../../configuration-settings";
import { RequestNotificationModel } from 'src/app/request/request-notification/request-notification.model';


@Injectable()
export class RequestNotificationService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    constructor(private _http: HttpClient) { }


    //--------------------------------------------------
    // Adds a user to be notified along with description
    //--------------------------------------------------
    public addUserToBeNotified(requestNumber: string, requestNotificationModel: RequestNotificationModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/notification-users";
        let body = JSON.stringify(requestNotificationModel);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Saved User to be notified"))
            );
    }

    //-----------------------------------
    // Gets the list of user notifications 
    //------------------------------------
    public getUsersToBeNotified(requestNumber: string): any {
        let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/notification-users";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched users to be notified"))
        );
    }

    //----------------------------------------------------
    // Removes a user to be notified along with description
    //-----------------------------------------------------
    public removeUserToBeNotified(requestNumber: string, requestNotificationId: number): any {
        let url = ConfigurationSettings.REST_API_URL + "/requests/" + requestNumber + "/notification-users/" + requestNotificationId;
        return this._http.delete(url).pipe(
            tap(x => console.log(" Request Notification Deleted"))
        )
    }
}


