
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationSettings } from "../.././configuration-settings"


@Injectable()
export class RoleService {
    constructor(private _http: HttpClient) { }

    public getRoles(): any {

        let url = ConfigurationSettings.REST_API_URL + "/roles";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Roles")));
    }

}