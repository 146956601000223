export class AddressModel {
    AddressId?: number
    ParentAddressId?: number
    RecipientId?: number
    AddressLine1?: string
    AddressLine2?: string
    AddressLine3?: string
    City?: string
    State?: string
    CountryId?: number
    Country?: string
    OfficePhone1?: string
    OfficePhone2?: string
    HomePhone1?: string
    HomePhone2?: string
    Mobile?: string
    PostalCode?: string
    IsActive?: boolean
    IsActiveDirectoryAddress?: boolean
    IsDeletedAddress?: boolean
    PersonFullName?: string
    IsShippingAddress?: boolean
    IsDefaultAddress?: boolean
    IsParentUpdated?: boolean
    IsParentDeleted?:boolean
    OrgName?:string
    ModifiedBy?:string
    ModifiedAt?:Date

}