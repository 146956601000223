import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-navigate',
  templateUrl: './navigate.component.html',
  styleUrls: ['./navigate.component.scss']
})
export class NavigateComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  showLoggedInMessage = false;

  constructor(private router: Router, 
    private activatedRoute: ActivatedRoute,
    private msalBroadcastService: MsalBroadcastService, 
    private msalService: MsalService) {
  }

  ngOnInit(): void {

    //redirect user if not logged in
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        filter(() => { return this.msalService.instance.getAllAccounts().length === 0; }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.msalService.loginRedirect();
      });

    // login successful - first message
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        this.showLoggedInMessage = true;
      });

    // get permissions, redirect user
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        filter(() => { return this.msalService.instance.getAllAccounts().length > 0; }),
        takeUntil(this._destroying$),
        mergeMap(() => { return []; })
      )
      .subscribe(permissions => {
        this.showLoggedInMessage = false;
        localStorage.setItem("loggedInUsername",this.msalService.instance.getActiveAccount().username);
//        console.log(this.activatedRoute.snapshot.url[0].toString());
//        this.router.navigateByUrl(this.activatedRoute.snapshot.url[0].toString());
        this.router.navigate(['/search']);

      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
