import { Component, Input, OnInit } from '@angular/core';
import { Chemical } from '../search/chemical';
import { SelectItem, MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { CartService } from '../services/cart-service/cart.service';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from '../app.component';
import { RequestService } from '../services/request-service/request.service';
import { RequestManagementModel } from '../request-management/request-management.model';
import { RequestManagementService } from '../services/request-management-service/request-management.service';
import { RequestStatusType } from '../request-status-type/request-status-type.enum';
import { RequestItemService } from '../services/request-item-service/request-item.service';
import { RequestItemModel } from '../request/add-chemical-details/request-item.model';
import { RequestType } from '../request-type/request-type.enum';
import { CountryModel } from '../country/country.model';
import { RegionModel } from '../region/region.model';
import { RegionService } from '../services/region-service/region-service';
import { CountryService } from '../services/country-service/country.service';

@Component({
  selector: 'cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.css'],
  providers: [Chemical, MessageService, CartService, ConfirmationService, RequestService, RequestManagementService,
    RequestItemService, RegionService, CountryService]
})
export class CartDetailsComponent implements OnInit {
  classifications: SelectItem[];
  public cartItemList: Chemical[] = [];
  selectedItems: Array<any> = [];
  growlMsgs: ToastModule[] = [];
  public msgs: any[];
  serviceResponse: any;
  hideHideAllButton: boolean = true;
  contentStyleObject: object = {
    'width': '50vw',
    'max-height': '40vh',
    'overflow': 'auto',
  }
  visible: boolean = false;
  requestList: RequestManagementModel[] = [];
  selectedRequest: RequestManagementModel;
  existingSamples: Chemical[] = [];
  cartItemRequestType: string;
  isForecast: boolean;
  countries: CountryModel[];
  regions: RegionModel[];
  isValid: boolean = true;
  requestTypeName:string = '';

  constructor(private messageService: MessageService,
    private router: Router, protected cartService: CartService, protected appComponent: AppComponent,
    private confirmationService: ConfirmationService, private requestService: RequestService,
    private requestManagementService: RequestManagementService, private requestItemService: RequestItemService,
    private regionService: RegionService, private countryService: CountryService) {
  }

  ngOnInit() {
    // Call method to  get list of item added by use to the cart.
    this.cartService.getCarts().subscribe(response => {
      this.cartItemList = response;
      this.isForecast = (this.cartItemList.length > 0 && this.cartItemList[0].RequestLocationId != undefined && this.cartItemList[0].RequestLocationId > 0) ? true : false;
      this.cartItemRequestType = this.cartItemList.length > 0 ? this.GetExistingRequestType(this.cartItemList[0].CartRequestTypeId) : this.requestType.InStock;

      // To set no of item in header on page load/refresh of this page.
      this.appComponent.cartItemsCount = response.length;
      this.requestTypeName = this.cartItemRequestType;
    },
      error => {
        this.growlMsgs.push({ severity: 'error', summary: "", detail: error.message });
      });

    this.getRegions();
    this.getCountries();
  }

  GetExistingRequestType(cartRequestTypeId: number)
  {
    var requestType = this.requestType.InStock;
    switch(cartRequestTypeId)
    {
      case 1: requestType = this.requestType.InStock;
              break;
      case 2: requestType = this.requestType.GLP;
              break;
      case 3: requestType = this.requestType.Radioactive;
              break;
      case 4: requestType = this.requestType.PeerToPeer;
              break;
    }

    return requestType;
  }

  //----------------------------------------------------------
  // This method is used to select the itme from cart
  //----------------------------------------------------------
  public cartItemCheckChanged(checked, item) {
    if (checked) {
      this.selectedItems.push(item);
    }
    else {
      this.selectedItems = this.selectedItems.filter(c => c.FormulationName !== item.FormulationName);
    }
  }

  //-------------------------------------------------------------------------------------
  // On click of Proceed To Request below four actions are going to happen
  // 1. Create new request in request table.
  // 2. Add all the item which are added by user to the cart in RequestItem table.
  // 3. Remove all the item added by user from Cart table.
  // 4. If all went well user will proceed to Request page with new Request Number.
  //-------------------------------------------------------------------------------------
  public proceedToRequest() {
    this.requestService.createRequest(this.cartItemList, this.cartItemRequestType).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.message });
      },
      () => {
        this.messageService.add({ severity: 'success', summary: 'Request created successfully with request ID ' + this.serviceResponse, detail: '', life: 500 })
        this.router.navigateByUrl("request-types/" + this.cartItemRequestType + "/request/" + this.serviceResponse);
      });
  }

  //------------------------------------
  // Remove item form cart.
  //------------------------------------
  removeFromCart(cartItem) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the selected chemical?',
      header: 'Remove Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.clear();
        this.cartService.deleteCart(cartItem.RequestTypeName, cartItem.CartItemId).subscribe(res => this.serviceResponse = res,
          error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.message });
          },
          () => {
            this.messageService.add({ severity: 'success', summary: '', detail: cartItem.MaterialName + ' removed from the Cart', life: 500 });
            // Remove the deleted cart item from the list.
            this.cartItemList = this.cartItemList.filter(s => s.CartItemId != cartItem.CartItemId).map(u => Object.assign({},u));;
            this.appComponent.cartItemsCount = this.cartItemList.length;
            setTimeout(() => {
              const currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
              }
            , 100);
        });
      },
      reject: () => {

      }
    });
  }

  //---------------------------------------------------------
    // To show the Hide All button and display all the synonyms
    //---------------------------------------------------------
    showDiv(synonymdivId) {
      var synonymDiv = document.getElementById(synonymdivId);
      var material;
      if (this.cartItemRequestType == RequestType.InStock) {
        material = this.cartItemList.find(result => result.MaterialName == synonymdivId);
      }
      else if (this.cartItemRequestType == RequestType.GLP) {
        material = this.cartItemList.find(result => result.TsnNumber == synonymdivId);
      }

      if (material == undefined) {
          material = this.cartItemList.find(result => result.RequestLocationId == synonymdivId);
          synonymDiv.innerHTML = material.SampleRequestHistory.replace(/\|/g, "<br>");
      }
      else
      {
          synonymDiv.innerHTML = material.Synonyms.replace(/\|/g, "<br>");
      }

      var showButton = document.getElementById("show" + synonymdivId);
      var hideButton = document.getElementById("hide" + synonymdivId)
      var spanId = document.getElementById("span" + synonymdivId);
      synonymDiv.style.display = "block";
      spanId.style.display = "none";
      showButton.style.display = "none";
      hideButton.style.display = "block";
  }

  //---------------------------------------------------------
  // To show the Show All button and hide all the synonyms
  //---------------------------------------------------------
  hideDiv(synonymdivId) {
      var synonymDiv = document.getElementById(synonymdivId);
      var showButton = document.getElementById("show" + synonymdivId);
      var hideButton = document.getElementById("hide" + synonymdivId);
      var spanId = document.getElementById("span" + synonymdivId);
      spanId.style.display = "block";
      synonymDiv.style.display = "none";
      showButton.style.display = "block";
      hideButton.style.display = "none";

  }

  public proceedToExistingRequest(requestTypeName: string) {
    this.visible = true;
    this.getMyRequests();
  }

  public close() {
    this.visible = false;
  }

  public getMyRequests() {
    this.requestManagementService.getMyRequests(localStorage["msal.userId"]).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error })
      },
      () => {
        this.requestList = this.serviceResponse.filter(c => c.RequestStatus == RequestStatusType.Draft && c.RequestTypeName == this.cartItemRequestType);
        // for non forcasted item
        if (this.cartItemList.length > 0 && this.cartItemList[0].RequestLocationId === 0) {
          this.requestList = this.requestList.filter(x => x.IsForecastRequest === false);
        }
        // for forcasted item
        if (this.cartItemList.length > 0 && this.cartItemList[0].RequestLocationId > 0) {
          this.requestList = this.requestList.filter(x => x.IsForecastRequest === true);
        }

        if(this.requestTypeName == RequestType.PeerToPeer){
          this.requestManagementService.filterP2PRequests(this.requestList).subscribe(res => this.serviceResponse = res,
            error => {
              this.messageService.add({ severity: 'error', summary: '', detail: error.error })
            },
            () => {
              this.requestList = this.serviceResponse;
            });
        }
        this.messageService.clear();
        if (this.requestList.length == 0) {
          this.messageService.add({ key: 'NoRequestNotification', severity: 'error', summary: 'Please create at least one request for adding the samples', detail: '' });
        }
      });
  }

  public addToRequest() {
    this.messageService.clear('NoRequestNotification');
    this.existingSamples = [];
    this.requestItemService.getRequestItems(this.cartItemRequestType, this.selectedRequest.RequestNumber).subscribe(res => this.serviceResponse = res,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error })
      },
      () => {

        this.cartItemList.filter(c => c.RequestLocationId > 0).forEach(cartItem => {
          if (!this.canAddItemToRequest(cartItem)) {
            this.isValid = false;
            this.messageService.add({ key: 'NoRequestNotification', severity: 'error', summary: 'Request can contain samples belonging to the same country/region', detail: '' });
            return;
          }
        });

        if (this.isValid) {
          let existing: Chemical[];
          if (this.cartItemRequestType == RequestType.InStock || this.cartItemRequestType == RequestType.PeerToPeer) {
            existing = this.cartItemList.filter(c => this.serviceResponse.find(a => a.ChemicalName == c.MaterialName));
          }
          else if (this.cartItemRequestType == RequestType.GLP) {
            existing = this.cartItemList.filter(c => this.serviceResponse.find(a => a.TsnNumber == c.TsnNumber));
          }
          existing.forEach(cartItem => {
            this.existingSamples.push(cartItem);
          });

          if (this.existingSamples.length > 0) {
            let duplicateSamplesMessage = '<table><tr><td>Sample Name</td></tr>';
            this.existingSamples.forEach(item => {
              duplicateSamplesMessage = duplicateSamplesMessage + '<tr><td>' + item.MaterialName + ' already exists</td></tr>';
            });
            this.messageService.add({ key: 'NoRequestNotification', severity: 'error', summary: duplicateSamplesMessage, detail: '' });
          }
          else {
            if (this.existingSamples.length != this.cartItemList.length) {
              var newChemicalsList: RequestItemModel[] = [];
              var newChemicals = this.cartItemList.filter(c => !this.existingSamples.find(a => a.MaterialName == c.MaterialName));

              newChemicals.forEach(item => {
                var requestItemModel = JSON.parse(JSON.stringify(item));
                requestItemModel.ChemicalName = item.MaterialName;
                newChemicalsList.push(requestItemModel);
              })
              this.requestItemService.create(newChemicalsList, this.selectedRequest.RequestNumber, true, this.cartItemRequestType).subscribe(res => this.serviceResponse = res,
                error => {
                  this.messageService.add({ severity: 'error', summary: '', detail: error.error });
                },
                () => {
                  this.visible = this.existingSamples.length == 0 ? false : true;
                  this.cartItemList = [];
                  this.appComponent.cartItemsCount = 0;
                  this.messageService.add({ severity: 'success', summary: 'Sample(s) added successfully', detail: '' });

                  setTimeout(() =>
                    this.router.navigateByUrl("/requestmanagement")
                    , 500);

                });
            }
          }
        }
      });
  }
  public get requestType(): typeof RequestType {
    return RequestType;
  }


  public canAddItemToRequest(item): boolean {
    if (this.serviceResponse.filter(c => c.RequestLocationId > 0).length == 0) {
      return true;
    }
    else if (this.serviceResponse.find(c => c.Country == item.Country)) {
      return true;
    }
    else {
      let skipRegionCheck: boolean;
      if (this.countries.find(c => this.serviceResponse.find(a => a.Country == c.Name))) {
        skipRegionCheck = true;
      }
      if (!skipRegionCheck) {
        // get region of the provided item.
        var selectedCountry = this.countries.filter(c => c.Name == item.Country);
        if (selectedCountry.length > 0) {
          var selectedRegion = this.regions.filter(c => c.RegionId == selectedCountry[0].RegionId)
          if (selectedRegion.length > 0) {
            if (this.serviceResponse.find(c => c.Country == selectedRegion[0].Description)) {
              return true;
            }
          }
        }
      }

      // get country of the provided item (Assuming user has selected region and cart list contains country).
      var selectedRegion = this.regions.filter(c => c.Description == item.Country);
      if (selectedRegion.length > 0) {
        if (this.countries.find(c => c.RegionId == selectedRegion[0].RegionId &&
          c.Name == this.serviceResponse[0].Country)) {
          return true;
        }

      }

    }
    //this.message = "Cart cannot contain sample forecasts from different country/regions";
    return false;

  }

  public getRegions() {
    if (this.regions == undefined) {
      this.regionService.getRegions().subscribe(response => this.regions = response,
        error => {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        },
        () => {
        })
    }
  }

  public getCountries() {
    if (this.countries == undefined) {
      this.countryService.getCountryList().subscribe(res => this.serviceResponse = res,
        error => {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
        },
        () => {
          this.countries = this.serviceResponse;
        })
    }
  }

}
