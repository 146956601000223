<p-toast position="top-right"></p-toast>

<div class="col-12">
  <span class="font-weight-bold">Attachment(s)</span>
</div>

<div class="col-12">
  <div class="grid p-justify-start">


    <p-fileUpload chooseLabel="Choose" mode="basic" name="uploadedFiles" multiple="true" customUpload="true"
      (uploadHandler)="onUpload($event,requestItem,form,editRequestItemModel)" [auto]="true" #form>
    </p-fileUpload>
    <span class="allowed-files-label">&nbsp;&nbsp; Allowed file extensions are .pdf, .rtf, .jpg, .png, .docx, .xlsx, .mht, .htm, .msg</span>
  </div>
</div>
<div class="col-12 attached-file-list-container">

  <p-messages key="fileUploadSummary" class="messages" [escape]='false'></p-messages>
  <p-messages [(value)]="msgs" key="fileUploadValidation" class="messages" [escape]='false'></p-messages>

  <div class="grid attached-files-list"
    *ngFor="let requestItemAttachment of editRequestItemModel.RequestItemAttachmentList;">

    <div class="col-6">
      <div class="grid">
      <div class="col-1">
      <i class="pi pi-download download-file-link" *ngIf="requestItemAttachment.RequestItemAttachmentId != 0"
        (click)="downloadAttachment(requestItem.RequestId,requestItemAttachment.RequestItemId, requestItemAttachment.RequestItemAttachmentId,requestItemAttachment.FileName)"></i></div>
        <div class="col-11">
      {{requestItemAttachment.FileName}}
       <span class="badge new-file-text badge-success"
        *ngIf="requestItemAttachment.RequestItemAttachmentId > requestItemAttachmentId">New</span>
      </div>
    </div>
    </div>
    <div class="col-2 comment">
      <input type="text"   [(ngModel)]="requestItemAttachment.Comment" maxlength="50"/>
    
    </div>
    <div class="col-2">
      <p-dropdown [options]="documentTypes"  optionLabel="Name" (onChange)="updateDocumentTypeId(requestItemAttachment.FileName,$event)"
      appendTo="body" [style]="{'width':'100%'}"  [(ngModel)]="requestItemAttachment.DocumentType"
      >
  </p-dropdown>
  </div>
    <div class="col-2"><button icon="pi pi-trash" pButton type="button" class="p-button-danger remove-button-danger"
        label="Remove" (click)="removeAttachment(requestItem,requestItemAttachment.RequestItemAttachmentId,requestItemAttachment.FileName)"></button>
    </div>
  </div>
</div>

<!-- confirmation dialog box to remove chemical from summary page -->
<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" appendTo="body"
  [closable]="false">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
    <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>